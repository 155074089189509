import React from "react";
import Button from "@material-ui/core/Button";

import { useHistory } from "react-router-dom";

import theme from '../../../theme';
import { useTheme } from "@material-ui/core";

export default function GoBackButton(props) {
    const history = useHistory();
    const themeToUse = useTheme();
    return (
        <Button
            type="button"
            variant="contained"
            size="medium"
            disabled={props.disabled}
            style={{
                color: themeToUse.palette.text.main,
                margin: '10px',
                backgroundColor: props.disabled ?
                    theme.palette.disabled.main
                    :
                    (props.grayVersion ? theme.palette.secondary.other : themeToUse.palette.secondary.main)
            }}
            onClick={() => {
                history.goBack();
            }}
        >
            {props.label}
        </Button>
    );
}