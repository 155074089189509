import React from "react";
import Button from "@material-ui/core/Button";

import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import theme from "../../../theme";
import { useTheme } from "@material-ui/core";

export default function GoToButton(props) {
  const history = useHistory();
  const themeToUse = useTheme();
  return (
    <Button
      type="button"
      variant="contained"
      size="medium"
      disabled={props.disabled}
      style={{
        color: props.disabled
          ? theme.palette.text.disabled
          : themeToUse.palette.text.main,
        margin: "10px",
        backgroundColor: props.disabled
          ? theme.palette.disabled.main
          : props.grayVersion
          ? theme.palette.secondary.other
          : props.secondaryButton
          ? themeToUse.palette.secondary.main
          : themeToUse.palette.primary.main,
      }}
      onClick={() => {
        if (props.showAlert === true) {
          var r = window.confirm(
            "Modifiche non salvate. Lasciando la pagina verranno persi tutti i dati inseriti. Procedere comunque?"
          );
          if (r === true) {
            history.push(props.path);
          }
        } else {
          history.push(props.path);
        }
      }}
    >
      {props.label}
    </Button>
  );
}

GoToButton.propTypes = {
  showAlert: PropTypes.bool,
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  grayVersion: PropTypes.bool.isRequired,
};
