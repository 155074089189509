import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import image from "../../BS_FOOD_logo.png";
import { retrieveToken } from "../../utils/storage.js";
import { withStyles } from "@material-ui/styles";

const styleToUse = (theme) => ({
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  controlPanelButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "6px",
    border: 0,
    color: theme.palette.text.main,
    height: 68,
    width: 234,
    fontSize: 16,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
  loginButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "6px",
    border: 0,
    color: theme.palette.text.main,
    height: 54,
    width: 104,
    fontSize: 16,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.main,
    },
  },
  firstTypography: {
    padding: "20px",
    color: theme.palette.primary.main,
  },
  secondTypography: {
    padding: "10px",
    color: theme.palette.primary.main,
  },
});

class HomePage extends Component {
  constructor() {
    super();
    this.state = {
      isAuthenticated: false,
      message: "Effettua il Login per gestire la piattaforma.",
      img: image,
      titleImg: "BS_FOOD_logo",
    };
  }

  componentDidMount() {
    const token = retrieveToken();
    if (token !== null) {
      this.setState({
        isAuthenticated: true,
        message: "Clicca sul pulsante per procedere",
      });
    } else {
      this.setState({
        isAuthenticated: false,
        message: "Effettua il Login per gestire la piattaforma.",
      });
    }
  }

  render() {
    const { classes } = this.props;
    var accessButton = this.state.isAuthenticated ? (
      <NavLink
        to="/amministrazione/responsabili_sicurezza"
        style={{ textDecoration: "none" }}
      >
        <Button variant="contained" className={classes.controlPanelButton}>
          Pannello Controllo
        </Button>
      </NavLink>
    ) : (
      <NavLink to={"/login"} style={{ textDecoration: "none" }}>
        <Button variant="contained" className={classes.loginButton}>
          Login
        </Button>
      </NavLink>
    );
    var msg = this.state.message;

    return (
      <React.Fragment>
        <CssBaseline />
        <Container id="home_container" fixed>
          <Typography component="div" className={classes.container}>
            <Typography
              component="div"
              style={{
                padding: "10px 0",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h4" className={classes.firstTypography}>
                Pannello di controllo di {this.props.theme.nomeBrand}
              </Typography>
              <img
                src={
                  this.props.theme.logo.url !== undefined
                    ? this.props.theme.logo.url
                    : this.state.img
                }
                alt={this.state.titleImg}
                style={{ width: "270px" }}
              />
            </Typography>
            <Typography
              component="div"
              style={{
                margin: "20px 0",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" className={classes.secondTypography}>
                {msg}
              </Typography>
              <Typography component="div" style={{ padding: "10px" }}>
                {accessButton}
              </Typography>
            </Typography>
          </Typography>
        </Container>
      </React.Fragment>
    );
  }
}
export default withStyles(styleToUse, { withTheme: true })(HomePage);
