import React from "react";
import { Modal, TextField, Typography } from "@material-ui/core";

import ReactTable from "react-table";
import ActionButton from "../../../components/ActionButton";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
    padding: "26px 10px",
    minHeight: "90vh",
  },
  typo: {
    align: "center",
  },
  modalFirstElement: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  modalcontainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
    marginLeft: "20%",
    marginRight: "20%",
    //height: '85%'
    overflowY: "scroll",
    height: "600px",
    display: "flex",
  },
  miniModalContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
    marginLeft: "20%",
    marginRight: "20%",
    //height: '85%'
    overflowY: "scroll",
    height: "300px",
    display: "flex",
  },
  modalsectioncontainer: {
    flex: 1,
    display: "flex",
    //marginTop: 20,
    //marginBottom: 20,
    flexDirection: "column",
    justifyContent: "space-evely",
  },
  buttoncontainer: {
    justifyContent: "center",
    padding: "10px 0px",
  },
  modalTitle: {
    fontSize: 20,
    alignSelf: "center",
  },
};

export default class ModalAggiuntaValori extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnData: this.props.columnData,
      records: [],
      columns: [],
      newValue: "",
      isEditing: false,
    };
  }

  componentDidMount = () => {
    let valoriAggiuntiToPass =
      this.state.columnData[this.props.selectedIndex].valoriAggiunti;
    if (
      this.state.columnData[this.props.selectedIndex].valoriAggiunti === null
    ) {
      valoriAggiuntiToPass = [];
    }
    let a = this.getColumnsForModal();
    this.setState(
      {
        columns: a,
        records: valoriAggiuntiToPass,
      },
      () => {}
    );
  };

  deleteRow = (cellInfo) => {
    let recordsToChange = [...this.state.records];
    recordsToChange.splice(cellInfo._index, 1);
    this.setState({
      records: recordsToChange,
      isEditing: true,
    });
  };

  getColumnsForModal = () => {
    return [
      {
        Header: "Valore",
        id: "valore",
        width: 500,
        accessor: (d) => d,
        Cell: (props) => (
          <TextField
            onInput={(event) => {
              let arrayOfChange = [...this.state.records];
              arrayOfChange[props.row._index] = event.target.value;
              const caret = event.target.selectionStart;
              const element = event.target;
              window.requestAnimationFrame(() => {
                element.selectionStart = caret;
                element.selectionEnd = caret;
              });
              this.setState({ records: arrayOfChange, isEditing: true });
            }}
            inputProps={{ min: 0, style: { textAlign: "center" } }}
            style={{ width: "80%", margin: 10 }}
            value={props.row.valore}
          ></TextField>
        ),
      },
      {
        Header: "Elimina",
        id: "Elimina",
        width: 150,
        accessor: (d) => d,
        Cell: (props) => (
          <ActionButton
            label="Elimina"
            onClick={() => this.deleteRow(props.row)}
          ></ActionButton>
        ),
      },
    ];
  };

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.onClose}>
        <div style={styles.modalcontainer}>
          <div style={{ display: "flex", flex: 0.1 }}></div>
          <div style={styles.modalFirstElement}>
            <Typography style={{ margin: 5 }}>
              Inserisci un nuovo valore:{" "}
            </Typography>
            <TextField
              value={this.state.newValue}
              style={{ width: "35vw" }}
              inputProps={{ min: 0 }}
              onInput={(event) => {
                this.setState({
                  newValue: event.target.value,
                  isEditing: true,
                });
              }}
            ></TextField>
          </div>
          <div style={{ marginLeft: 10 }}>
            <ActionButton
              onClick={() => {
                var arrayToChange = [...this.state.records];
                arrayToChange.push(this.state.newValue);
                this.setState({
                  records: arrayToChange,
                  newValue: "",
                  isEditing: true,
                });
              }}
              label="Aggiungi"
            ></ActionButton>
          </div>
          <div style={{ display: "flex", flex: 1 }}>
            <ReactTable
              filterable={false}
              resizable={true}
              enableEditing={true}
              showPageSizeOptions={false}
              showPageJump={true}
              defaultPageSize={10}
              style={{
                height: 400,
                alignSelf: "center",
              }}
              pages={this.state.totalPages}
              data={this.state.records}
              columns={this.state.columns}
              /*manual 
                onFetchData={ (state, instance) => { 
                  let a = 0;
                  getTotaleLocali(this.props.idManuale).then(result => {
                    a = result;
                        console.log(a);
                        this.setState({
                          totalPages:Math.ceil(a/state.pageSize),
                    })
                  }).then(result => {
                    getLocaliManuale(this.props.idManuale,state.page,state.pageSize,"id").then(result => {
                      this.setState({
                          records:result,
                      })
                    })
                  }).catch(error => {
                    if (error.status === 403) {
                      this.props.handleInvalidToken();
                      } else {
                      this.props.handleError(true, error.message);
                      }
                  })
                }}*/
              previousText="Precedente"
              nextText="Successivo"
              noDataText="Nessun record"
              pageText="Pagina"
              ofText="di"
              rowsText="righe"
              pageJumpText="Vai a pagina"
              rowsSelectorText="righe per pagina"
            />
          </div>
          <div style={{ display: "flex" }}>
            <ActionButton
              label="Salva"
              onClick={() => {
                this.props.saveColumnData(this.state.records);
              }}
            ></ActionButton>
          </div>
        </div>
      </Modal>
    );
  }
}
