import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import image from '../../BS_FOOD_logo.png';

const styles = {
    mainContainer: {
        textAlign: 'center',
        color: 'black',
        padding: '10px'
    },
}

export default class ResetPswSuccessPage extends Component {

    constructor() {
        super();
        this.state = {
            img: image
        };
    }

    render() {
        return (
            <div style={styles.mainContainer} >
                <Typography component="div" style={{ height: '100vh' }} >
                    <Typography component="div" style={{ paddingTop: '10px', paddingBottom: '10px' }} >
                        <img src={this.state.img} alt='logo' style={{width:'270px'}} />
                        <Typography variant="h5" style={{ padding: '20px' }} >
                            Operazione completata
                        </Typography>
                    </Typography>
                    <Typography component="div" >
                        <Typography variant="h7" style={{ padding: '10px', color: 'black' }} >
                            La password è stata reimpostata con successo.
                        </Typography>
                    </Typography>
                </Typography>
            </div>
        );
    }
  
}