import React, { Component } from "react";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';

const styles = {
    root: {
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    text: {
        padding: '0px 0px 10px 0px'
    },
    formcontrol: {
        marginBottom: '30px',
        width: '250px',
    },
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: '146px',
            // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: '250px',
        },
    },
};

export default class SelezioneEsercizio extends Component {

    handleSelection = (event) => {
        this.props.onEsercizioSelected(event.target.value);
    }

    render () {
        return (
            <Container style={styles.root}>
                <Typography variant="body1" style={styles.text} >
                    {this.props.description}
                </Typography>
                <FormControl style={styles.formcontrol}>
                    <InputLabel id="select-label">
                        Seleziona un esercizio
                    </InputLabel>
                    <Select
                        value={this.props.esercizioId}
                        name="esercizioId"
                        onChange={this.handleSelection}
                        disabled={this.props.disabled}
                        MenuProps={MenuProps}
                    >
                        {/* <MenuItem value={''} >
                            <em></em>
                        </MenuItem> */}
                        { this.props.esercizi.map((value, index) => 
                        (<MenuItem key={index} value={value.id}>{value.nome}</MenuItem>))
                        }
                    </Select>
                    { this.props.hasError
                        && <FormHelperText error={this.props.hasError}>
                             Selezionare un esercizio
                            </FormHelperText>
                    }
                </FormControl>
            </Container>
        );
    }
}

SelezioneEsercizio.propTypes = {
    hasError: PropTypes.bool.isRequired,
    esercizi: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    onEsercizioSelected: PropTypes.func.isRequired
}