import React from "react";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import theme from "../../../theme.js";
import SpinnerComponent from "../../../components/SpinnerComponent";

const styles = {
  modalcontainer: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '6px',
    //paddingBottom: '6px',
    //paddingLeft: '10px',
    //paddingRight: '10px',
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px " +
      theme.palette.primary.main,
    marginLeft: "35%",
    marginRight: "35%",
    overflowY: "scroll",
    height: "250px",
    maxWidth: "560px",
  },
  title: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
};

export default function LoadingModal(props) {
  return (
    <Modal open={props.open}>
      <div style={styles.modalcontainer}>
        <Typography style={styles.title} variant="h6">
          {props.testo}
        </Typography>
        <SpinnerComponent size={32}></SpinnerComponent>
      </div>
    </Modal>
  );
}
