import React from "react";
import { Container, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
  },
  sectionContainer: {
    border: `1px solid ${theme.palette.disabled.main}`,
    borderRadius: "6px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "20px",
    paddingBottom: "10px",
  },
  fieldsContainer: {
    marginTop: "10px",
    marginBottom: "10px",
    padding: "10px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "320px",
  },
}));

const FormDettagli = ({ record, handleChange, validationErrors }) => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Grid container justify="center" alignItems="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.sectionContainer}
        >
          <Grid container className={classes.fieldsContainer}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ paddingBottom: "10px" }}
              >
                <TextField
                  label="ID esercizio"
                  disabled
                  value={
                    record.esercizio.id === null ? "" : record.esercizio.id
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  className={classes.textfield}
                />
                <TextField
                  label="ID consulente"
                  disabled
                  value={
                    record.esercizio.consulenteId === null
                      ? ""
                      : record.esercizio.consulenteId
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  className={classes.textfield}
                />
                <TextField
                  label="Nome"
                  value={
                    record.esercizio.nome === null ? "" : record.esercizio.nome
                  }
                  name="nome"
                  onChange={handleChange}
                  className={classes.textfield}
                  helperText={validationErrors.nome}
                  error={validationErrors.nome.length > 0}
                />
                <TextField
                  label="Ragione sociale"
                  value={
                    record.esercizio.ragioneSociale === null
                      ? ""
                      : record.esercizio.ragioneSociale
                  }
                  name="ragioneSociale"
                  onChange={handleChange}
                  className={classes.textfield}
                  helperText={validationErrors.ragioneSociale}
                  error={validationErrors.ragioneSociale.length > 0}
                />
                <TextField
                  label="Partita IVA"
                  value={
                    record.esercizio.partitaIva === null
                      ? ""
                      : record.esercizio.partitaIva
                  }
                  name="partitaIva"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="Codice ATECO"
                  value={
                    record.esercizio.codiceAteco === null
                      ? ""
                      : record.esercizio.codiceAteco
                  }
                  name="codiceAteco"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="Telefono"
                  value={
                    record.esercizio.telefono === null
                      ? ""
                      : record.esercizio.telefono
                  }
                  name="telefono"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="Nome titolare"
                  value={
                    record.esercizio.nomeGestore === null
                      ? ""
                      : record.esercizio.nomeGestore
                  }
                  name="nomeGestore"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="Cognome titolare"
                  value={
                    record.esercizio.cognomeGestore === null
                      ? ""
                      : record.esercizio.cognomeGestore
                  }
                  name="cognomeGestore"
                  onChange={handleChange}
                  className={classes.textfield}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ paddingBottom: "10px" }}
              >
                <TextField
                  label="Email titolare"
                  value={
                    record.esercizio.emailGestore === null
                      ? ""
                      : record.esercizio.emailGestore
                  }
                  name="emailGestore"
                  onChange={handleChange}
                  className={classes.textfield}
                  helperText={validationErrors.emailGestore}
                  error={validationErrors.emailGestore.length > 0}
                />
                <TextField
                  label="Telefono titolare"
                  value={
                    record.esercizio.telefonoGestore === null
                      ? ""
                      : record.esercizio.telefonoGestore
                  }
                  name="telefonoGestore"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="Indirizzo"
                  value={
                    record.esercizio.indirizzoSede === null
                      ? ""
                      : record.esercizio.indirizzoSede
                  }
                  name="indirizzoSede"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="Numero civico"
                  value={
                    record.esercizio.numeroSede === null
                      ? ""
                      : record.esercizio.numeroSede
                  }
                  name="numeroSede"
                  onChange={handleChange}
                  className={classes.textfield}
                />

                <TextField
                  label="Città"
                  value={
                    record.esercizio.cittaSede === null
                      ? ""
                      : record.esercizio.cittaSede
                  }
                  name="cittaSede"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="Provincia"
                  value={
                    record.esercizio.provinciaSede === null
                      ? ""
                      : record.esercizio.provinciaSede
                  }
                  name="provinciaSede"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="Codice SDI"
                  defaultValue={
                    record.esercizio.codiceSdi === null
                      ? ""
                      : record.esercizio.codiceSdi
                  }
                  name="codiceSdi"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="PEC"
                  defaultValue={
                    record.esercizio.pec === null ? "" : record.esercizio.pec
                  }
                  name="pec"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="termini d'uso e privacy policy accettate"
                  disabled
                  defaultValue={
                    record.esercizio.privacyPolicyAccettata &&
                    record.esercizio.terminiUsoAccettati
                      ? "Si"
                      : "No"
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  className={classes.textfield}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

FormDettagli.propTypes = {
  handleChange: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
  validationErrors: PropTypes.object.isRequired,
};

export default FormDettagli;
