import React from "react";

import { useHistory } from "react-router-dom";
// import PropTypes from 'prop-types';

import theme from "../../../theme";
import { IconButton, useTheme } from "@material-ui/core";
import { Settings } from "@material-ui/icons";

export default function GoToIcon(props) {
  const history = useHistory();
  const themeToUse = useTheme();
  return (
    <IconButton
      size="medium"
      style={{
        color: props.disabled
          ? theme.palette.disabled.main
          : props.grayVersion
          ? theme.palette.secondary.other
          : themeToUse.palette.primary.main,
      }}
      onClick={() => {
        if (props.mode === "Scheda") {
          history.push(
            "/amministrazione/schedaProcedura/" +
              props.id +
              "/" +
              props.idManuale +
              "/" +
              props.idParagrafo
          );
        } else if (props.mode === "Procedura") {
          history.push(
            "/amministrazione/dettaglioProcedura/" +
              props.id +
              "/" +
              props.idManuale +
              "/" +
              props.idParagrafo
          );
        }
      }}
    >
      <Settings></Settings>
    </IconButton>
  );
}
