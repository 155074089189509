export const giorniControlli = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28];

export const cadenzeControlli = [
    {
        value: "GIORNALIERA",
        label: "Giornaliera"
    },
    {
        value: "SETTIMANALE",
        label: "Settimanale"
    },
    {
        value: "MENSILE",
        label: "Mensile"
    },
    {
        value: "ANNUALE",
        label: "Annuale"
    },
    {
        value: "ALTRO",
        label: "Altra cadenza"
    }
];

export const cadenzeControlliScheda = [
    {
        value: "GIORNALIERA",
        label: "GIORNALIERA"
    },
    {
        value: "SETTIMANALE",
        label: "SETTIMANALE"
    },
    {
        value: "MENSILE",
        label: "MENSILE"
    },
    {
        value: "ANNUALE",
        label: "ANNUALE"
    },
];

export const mesi = [
    {
        value: "JANUARY",
        label: "Gennaio"
    },
    {
        value: "FEBRUARY",
        label: "Febbraio"
    },
    {
        value: "MARCH",
        label: "Marzo"
    },
    {
        value: "APRIL",
        label: "Aprile"
    },
    {
        value: "MAY",
        label: "Maggio"
    },
    {
        value: "JUNE",
        label: "Giugno"
    },
    {
        value: "JULY",
        label: "Luglio"
    },
    {
        value: "AUGUST",
        label: "Agosto"
    },
    {
        value: "SEPTEMBER",
        label: "Settembre"
    },
    {
        value: "OCTOBER",
        label: "Ottobre"
    },
    {
        value: "NOVEMBER",
        label: "Novembre"
    },
    {
        value: "DECEMBER",
        label: "Dicembre"
    }
];

export const orariControlli = [
    {
        value: "00:00:00",
        label: "00:00"
    },
    {
        value: "01:00:00",
        label: "01:00"
    },
    {
        value: "02:00:00",
        label: "02:00"
    },
    {
        value: "03:00:00",
        label: "03:00"
    },
    {
        value: "04:00:00",
        label: "04:00"
    },
    {
        value: "05:00:00",
        label: "05:00"
    },
    {
        value: "06:00:00",
        label: "06:00"
    },
    {
        value: "07:00:00",
        label: "07:00"
    },
    {
        value: "08:00:00",
        label: "08:00"
    },
    {
        value: "09:00:00",
        label: "09:00"
    },
    {
        value: "10:00:00",
        label: "10:00"
    },
    {
        value: "11:00:00",
        label: "11:00"
    },
    {
        value: "12:00:00",
        label: "12:00"
    },
    {
        value: "13:00:00",
        label: "13:00"
    },
    {
        value: "14:00:00",
        label: "14:00"
    },
    {
        value: "15:00:00",
        label: "15:00"
    },
    {
        value: "16:00:00",
        label: "16:00"
    },
    {
        value: "17:00:00",
        label: "17:00"
    },
    {
        value: "18:00:00",
        label: "18:00"
    },
    {
        value: "19:00:00",
        label: "19:00"
    },
    {
        value: "20:00:00",
        label: "20:00"
    },
    {
        value: "21:00:00",
        label: "21:00"
    },
    {
        value: "22:00:00",
        label: "22:00"
    },
    {
        value: "23:00:00",
        label: "23:00"
    }
];

export const giorniSettimana = [
    {
        value: "MONDAY",
        label: "Lunedì"
    },
    {
        value: "TUESDAY",
        label: "Martedì"
    },
    {
        value: "WEDNESDAY",
        label: "Mercoledì"
    },
    {
        value: "THURSDAY",
        label: "Giovedì"
    },
    {
        value: "FRIDAY",
        label: "Venerdì"
    },
    {
        value: "SATURDAY",
        label: "Sabato"
    },
    {
        value: "SUNDAY",
        label: "Domenica"
    }
];