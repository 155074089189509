import React, { useState } from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { BlockPicker } from "react-color";
import ActionButton from "../../../components/ActionButton";
import LogoHeaderTemplate from "./LogoHeaderTemplate";
import { paletteColors } from "../../../utils/paletteColors";
import { calculateAnalogousColors } from "../../../utils/analogousColors";
import PropTypes from "prop-types";
import AppColorCombination from "./AppColorCombination";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "50px",
    gap: "24px",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "35px",
    width: "890px",
    padding: "30px 0",
    boxShadow: theme.shadows[5],
  },
  buttonWrapper: {
    padding: "20px 0",
  },
}));

const DettagliApp = ({
  logoAppUrl,
  uploadLogoApp,
  templateData,
  selectedColor,
  handleColorChange,
  handleBackClick,
  createOrUpdateBrand,
}) => {
  const classes = useStyles();

  const [analogousColors, setAnalogousColors] = useState({
    primary: templateData.primaryColorApp,
    secondary: templateData.secondaryColorApp,
    tertiary: templateData.tertiaryColorApp,
  });

  const handleColorChangeWithAnalogous = (colorType, color) => {
    handleColorChange(colorType, color);

    const [secondary, tertiary] = calculateAnalogousColors(color.hex);

    setAnalogousColors({
      primary: color.hex,
      secondary,
      tertiary,
    });
    // Aggiorna anche templateData con i nuovi colori
    handleColorChange("appPrimary", { hex: color.hex });
    handleColorChange("appSecondary", { hex: secondary });
    handleColorChange("appTertiary", { hex: tertiary });
  };

  return (
    <Box className={classes.mainContainer}>
      <div>
        <Typography variant="h6">Icona interna</Typography>
        <Typography>
          Qui è possibile personalizzare l'icona interna dell'applicazione.
        </Typography>
        <Typography>Il file caricato deve essere in formato SVG.</Typography>
        {/* <Typography>La dimensione massima consentita è xxxKB.</Typography> */}
      </div>
      <LogoHeaderTemplate
        logoHeader={logoAppUrl}
        uploadLogoHeader={uploadLogoApp}
      />
      <Paper className={classes.paper}>
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
          Colori dell'app
        </Typography>
        <Typography>
          Seleziona il colore di riferimento. Ti verrà fornita una combinazione
          di colori analoghi.
        </Typography>
        <BlockPicker
          color={selectedColor.appPrimary}
          onChangeComplete={(color) =>
            handleColorChangeWithAnalogous("appPrimary", color)
          }
          colors={paletteColors}
        />
        <AppColorCombination analogousColors={analogousColors} />
      </Paper>
      <div className={classes.buttonWrapper}>
        <ActionButton
          label="Indietro"
          secondaryButton
          onClick={handleBackClick}
          grayVersion={false}
          disabled={false}
        />
        <ActionButton
          label="Salva modifiche"
          onClick={createOrUpdateBrand}
          grayVersion={false}
        />
      </div>
    </Box>
  );
};

DettagliApp.propTypes = {
  logoAppUrl: PropTypes.string.isRequired,
  uploadLogoApp: PropTypes.func.isRequired,
  templateData: PropTypes.object.isRequired,
  selectedColor: PropTypes.object.isRequired,
  handleColorChange: PropTypes.func.isRequired,
  handleBackClick: PropTypes.func.isRequired,
  createOrUpdateBrand: PropTypes.func.isRequired,
};

export default DettagliApp;
