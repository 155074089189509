import React from "react";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import theme from "../../../theme.js";
import PropTypes from "prop-types";
import { getCapitoliCount } from "../../../utils/api/capitoli_api.js";
import { useTheme } from "@material-ui/core";

export default function GoToPaginaCostruzioneManuale(props) {
  let history = useHistory();
  let themeToUse = useTheme();
  return (
    <Button
      disabled={props.disabled}
      variant="contained"
      style={{
        color:props.disabled ? theme.palette.text.disabled : themeToUse.palette.text.main,
        backgroundColor: props.disabled
          ? theme.palette.disabled.main
          : themeToUse.palette.primary.main,
        margin: "10px",
      }}
      onClick={() => {
        getCapitoliCount(props.manualeId)
          .then((result) => {
            if (result > 0) {
              history.push(
                "/amministrazione/manuali/nuovomanuale/" +
                  props.manualeId +
                  "/manuale/true"
              );
            } else {
              history.push(
                "/amministrazione/manuali/nuovomanuale/" +
                  props.manualeId +
                  "/manuale/false"
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
        //history.push("/amministrazione/manuali/nuovomanuale/" + props.manualeId + "/manuale")
      }}
    >
      Modifica contenuto
    </Button>
  );
}

GoToPaginaCostruzioneManuale.propTypes = {
  manualeId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
};
