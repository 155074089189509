import { retrieveToken } from '../storage';
import { wrapFetch, ApiError } from './api';
const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getLocaliManuale = (manualeId, page, size, sortby) => {
  const token = retrieveToken();
  const PATH = '/api/locali';
  const URL = BASE_URL + PATH 
    + "?manualeId.equals=" + manualeId 
    + "&page=" + page
    + "&size=" + size
    + "&sort=" + sortby
    + "&" + sortby + ".dir=ASC"
    ;
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
        headers: {
          'Authorization' : authtoken
        },
        withCredentials: true,
        credentials: 'include'
    }), errorFunction
  );
}

export const getLocale = (manualeId, localeId, page, size, sortby) => {
  const token = retrieveToken();
  const PATH = '/api/locali';
  const URL = BASE_URL + PATH 
    + "?manualeId.equals=" + manualeId 
    + "&id.equals=" + localeId
    + "&page=" + page
    + "&size=" + size
    + "&sort=" + sortby
    + "&" + sortby + ".dir=ASC"
    ;
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
        headers: {
          'Authorization' : authtoken
        },
        withCredentials: true,
        credentials: 'include'
    }), errorFunction
  );
}

export const getTotaleLocali = (manualeId) => {
  const token = retrieveToken();
  const PATH = '/api/locali/count?manualeId.equals='+manualeId;
  const URL = BASE_URL+PATH;
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
        headers: {
          'Authorization' : authtoken
        },
        withCredentials: true,
        credentials: 'include'
    }), errorFunction
  );
}

export const updateLocaliManuale = (requestBody) => {
  const token = retrieveToken();
  const PATH = '/api/locali';
  const URL = BASE_URL + PATH;
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400) {
      message = 'Salvataggio non riuscito: i dati non sono corretti.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'PUT',
      headers: {
          'Authorization' : authtoken,
          'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(requestBody)
    }), errorFunction
  );
}

export const getAttrezzature = (manualeId, localeId, page, size, sortby) => {
  const token = retrieveToken();
  const PATH = '/api/locali/attrezzature';
  const URL = BASE_URL + PATH 
    + "?manualeId.equals=" + manualeId 
    + "&localeEsercizioId.equals=" + localeId
    + "&page=" + page
    + "&size=" + size
    + "&sort=" + sortby
    + "&" + sortby + ".dir=ASC"
    ;
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
        headers: {
          'Authorization' : authtoken
        },
        withCredentials: true,
        credentials: 'include'
    }), errorFunction
  );
}
export const getAttrezzatureWithOnlyManualeId = (manualeId,page, size, sortby) => {
  const token = retrieveToken();
  const PATH = '/api/locali/attrezzature';
  const URL = BASE_URL + PATH 
    + "?manualeId.equals=" + manualeId 
    + "&page=" + page
    + "&size=" + size
    + "&sort=" + sortby
    + "&" + sortby + ".dir=ASC"
    ;
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
        headers: {
          'Authorization' : authtoken
        },
        withCredentials: true,
        credentials: 'include'
    }), errorFunction
  );
}
export const getAttrezzatureWithManualeIdAndTipologia = (manualeId,tipologiaId,page, size, sortby) => {
  const token = retrieveToken();
  const PATH = '/api/locali/attrezzature';
  const URL = BASE_URL + PATH 
    + "?manualeId.equals=" + manualeId 
    + "&page=" + page
    + "&size=" + size
    + "&sort=" + sortby
    + "&" + sortby + ".dir=ASC"
    + "&tipologiaId.equals="+tipologiaId
    ;
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
        headers: {
          'Authorization' : authtoken
        },
        withCredentials: true,
        credentials: 'include'
    }), errorFunction
  );
}

export const getAttrezzatureCount = (manualeId) => {
  const token = retrieveToken();
  const PATH = '/api/locali/attrezzature/count?manualeId.equals='+manualeId;
  const URL = BASE_URL + PATH ;
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
        headers: {
          'Authorization' : authtoken
        },
        withCredentials: true,
        credentials: 'include'
    }), errorFunction
  );
}

export const updateAttrezzatureLocale = (requestBody) => {
  const token = retrieveToken();
  const PATH = '/api/locali/attrezzature';
  const URL = BASE_URL + PATH;
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400) {
      message = 'Salvataggio non riuscito: i dati non sono corretti.';
    } else if (status === 404) {
      message = 'Salvataggio non riuscito: locale non trovato.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'PUT',
      headers: {
          'Authorization' : authtoken,
          'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(requestBody)
    }), errorFunction
  );
}
export const getTipologiaStrumentazione = (manualeId, page, size, sortby) => {
  const token = retrieveToken();
  const PATH = '/api/strumentazioni';
  const URL = BASE_URL + PATH 
    + "?manualeId=" + manualeId 
    + "&page=" + page
    + "&size=" + size
    + "&sort=" + sortby;
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
        headers: {
          'Authorization' : authtoken
        },
        withCredentials: true,
        credentials: 'include'
    }), errorFunction
  );
}
export const addNewTipologiaStrumentazione = (body) => {
  const token = retrieveToken();
  const PATH = '/api/strumentazioni';
  const URL = BASE_URL + PATH 
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400) {
      message = 'Registrazione esercizio già confermata.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'POST',
      headers: {
        'Authorization' : authtoken,
        'Content-Type': 'application/json'
      },
      withCredentials: true,
      body: JSON.stringify(body),
      credentials: 'include'
    }), errorFunction
  );
}
export const deleteTipologiaStrumentazione = (tipologiaId) => {
  const token = retrieveToken();
  const PATH = '/api/strumentazioni/'+tipologiaId;
  const URL = BASE_URL + PATH ;
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    else if(status === 404){
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.'
    }
    else if(status === 400){
      message = "Cancellazione non effettuata: la tipologia scelta risulta associata già ad almeno un'attrezzatura del manuale. Modificare le attrezzature assegnandogli una diversa tipologia e ripetere la cancellazione se desiderato.";
    }

    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'DELETE',
        headers: {
          'Authorization' : authtoken
        },
        withCredentials: true,
        credentials: 'include'
    }), errorFunction
  );
}
