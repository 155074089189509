import React, { Component, Fragment } from "react";
import {
  Box,
  CssBaseline,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import ActionButton from "../../../components/ActionButton";
import ComponenteSelezioneBrand from "./ComponenteSelezioneBrand";
import SpinnerComponent from "../../../components/SpinnerComponent";
import ErrorDialog from "../../../components/ErrorDialog";
import GoToButton from "../components/GoToButton";

import { getBrands } from "../../../utils/api/brand_api";
import { createGruppo } from "../../../utils/api/gruppi_api";
import { clearToken } from "../../../utils/storage";

import PropTypes from "prop-types";
import theme from "../../../theme";

const styles = (theme) => ({
  root: {
    textAlign: "center",
    color: "black",
    paddingTop: "26px",
    minHeight: "86vh",
  },
  texts: {
    marginBottom: "20px",
  },
  sectionContainer: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: "6px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "320px",
  },
  buttonContainer: {
    margin: "38px 8px 8px 8px",
  },
});

class NuovoGruppoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      record: {
        id: null,
        nome: "",
        costoUnitario: null,
        tipologiaPagamento: null,
        brand: {
          id: -1,
          nome: "",
        },
      },
      brands: [],

      validationErrors: {
        nome: "",
        brandId: "",
        tipologiaPagamento: "",
        costoUnitario: "",
      },

      changesNotSaved: false,
      disableSaveButton: true,

      errorDialogVisible: false,
      errorDialogTitle: null,
      errorDialogMessage: "",
      recordSaved: false,
    };
  }

  componentDidMount() {
    this.fetchBrands();
  }

  fetchBrands = () => {
    getBrands(0, 1000, "id")
      .then((result) => {
        this.setState({
          loading: false,
          brands: result,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  openErrorDialog = (errorMessage) => {
    this.setState({
      errorDialogTitle: null,
      errorDialogVisible: true,
      errorDialogMessage: errorMessage,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  handleInvalidToken = () => {
    const errorMessage =
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    this.openErrorDialog(errorMessage);
    clearToken();
    const { path } = this.props.theme;
    window.setTimeout(function () {
      window.location.href = `/${path}`;
    }, 4000);
  };

  // Using the ErrorDialog to display a message if operation is successful.
  displayMessage = (title, message) => {
    this.setState({
      errorDialogTitle: title,
      errorDialogVisible: true,
      errorDialogMessage: message,
    });
  };

  validateForm = () => {
    this.validateField("nome", this.state.record.nome);
    this.validateField("brandId", this.state.record.brand.id);
    this.validateField(
      "tipologiaPagamento",
      this.state.record.tipologiaPagamento
    );
    this.validateField("costoUnitario", this.state.record.costoUnitario);
    let hasErrors = false;
    let validationErrors = this.state.validationErrors;
    Object.values(validationErrors).forEach((val) => {
      hasErrors = hasErrors || val.length > 0;
    });
    return hasErrors;
  };

  validateField = (key, value) => {
    let validationErrors = this.state.validationErrors;
    switch (key) {
      case "nome":
        if (value === null || value.length === 0) {
          validationErrors.nome = "Inserire almeno un carattere.";
        } else {
          validationErrors.nome = "";
        }
        break;
      case "brandId":
        if (value === null || value === -1 || value === undefined) {
          validationErrors.brandId = "Selezionare un brand";
        } else {
          validationErrors.brandId = "";
        }
        break;
      case "tipologiaPagamento":
        if (value === null) {
          validationErrors.tipologiaPagamento =
            "Seleziona una tipologia di pagamento";
        } else {
          validationErrors.tipologiaPagamento = "";
        }
        break;
      case "costoUnitario":
        if (value === null || value.length === 0) {
          validationErrors.costoUnitario = "Inserisci il costo per utenza";
        } else {
          validationErrors.costoUnitario = "";
        }
        break;
      default:
        break;
    }
    this.setState({
      validationErrors,
    });
  };

  onTextChanged = (event) => {
    let record = this.state.record;
    record.nome = event.target.value;
    //Reset validation :
    let validationErrors = this.state.validationErrors;
    validationErrors.nome = "";
    this.setState({
      record,
      validationErrors,
      changesNotSaved: true,
      disableSaveButton: false,
    });
  };

  onBrandSelected = (event) => {
    let selectedBrand = {};
    for (let i = 0; i < this.state.brands.length; i++) {
      if (this.state.brands[i].id === event.target.value) {
        selectedBrand = Object.assign({}, this.state.brands[i]);
        break;
      }
    }
    let validationErrors = this.state.validationErrors;
    validationErrors.brandId = "";
    this.setState((prevState) => ({
      record: {
        ...prevState.record,
        brand: selectedBrand,
      },
      validationErrors,
      changesNotSaved: true,
      disableSaveButton: false,
    }));
  };

  handleRadioChange = (event) => {
    const value = event.target.value;
    this.setState((prevState) => ({
      record: {
        ...prevState.record,
        tipologiaPagamento: value,
      },
      validationErrors: {
        ...prevState.validationErrors,
        tipologiaPagamento: "",
      },
      changesNotSaved: true,
      disableSaveButton: false,
    }));
  };

  handleCostoUnitarioChange = (event) => {
    const value = event.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      this.setState((prevState) => ({
        record: {
          ...prevState.record,
          costoUnitario: value,
        },
        validationErrors: { ...prevState.validationErrors, costoUnitario: "" },
        changesNotSaved: true,
        disableSaveButton: false,
      }));
    }
  };

  onSubmit = () => {
    const invalidForm = this.validateForm();
    if (invalidForm) {
      this.setState({
        disableSaveButton: true,
      });
    } else {
      this.setState({
        loading: true,
      });

      createGruppo(this.state.record)
        .then((result) => {
          this.setState({
            record: result,
            recordSaved: true,
            disableSaveButton: true,
            changesNotSaved: false,
          });
          this.displayMessage(
            "Registrazione effettuata",
            "Il nuovo gruppo è stato registrato con successo."
          );
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.openErrorDialog(error.message);
          }
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <CssBaseline />
        <Container className={classes.root}>
          <Typography variant="h4" className={classes.texts}>
            Registra nuovo gruppo
          </Typography>
          {this.state.loading ? (
            <SpinnerComponent size={24} />
          ) : (
            <Fragment>
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.sectionContainer}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="body1" style={{ paddingTop: "10px" }}>
                    Inserisci il nome del nuovo gruppo:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    label="Nome gruppo"
                    name="nome"
                    value={this.state.record.nome}
                    onChange={this.onTextChanged}
                    disabled={this.state.recordSaved}
                    className={classes.textfield}
                    helperText={this.state.validationErrors.nome}
                    error={this.state.validationErrors.nome.length > 0}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ marginTop: "10px" }}
                >
                  <ComponenteSelezioneBrand
                    title="Brand del gruppo"
                    description="Selezionare il brand da associare al nuovo gruppo"
                    brands={this.state.brands}
                    brandId={this.state.record.brand.id}
                    disabled={this.state.recordSaved}
                    onSelectionChanged={this.onBrandSelected}
                    validationMessage={this.state.validationErrors.brandId}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ marginTop: "10px" }}
                >
                  <FormControl
                    component="fieldset"
                    disabled={this.state.recordSaved}
                    error={
                      this.state.validationErrors.tipologiaPagamento.length > 0
                    }
                  >
                    <FormLabel component="legend" style={{ color: "black" }}>
                      Pagamenti gestiti da BIORSAF o via Partner
                    </FormLabel>
                    <RadioGroup
                      row
                      name="tipologiaPagamento"
                      value={this.state.record.tipologiaPagamento || ""}
                      onChange={this.handleRadioChange}
                    >
                      <FormControlLabel
                        value="BIORSAF"
                        control={<Radio color="primary" />}
                        label="BIORSAF"
                      />
                      <FormControlLabel
                        value="PARTNER"
                        control={<Radio color="primary" />}
                        label="Partner (contratto)"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      {this.state.validationErrors.tipologiaPagamento}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ marginTop: "10px" }}
                >
                  <Typography variant="body1" style={{ paddingTop: "10px" }}>
                    Costo unitario base riservato ai clienti dei consulenti del
                    gruppo
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    label="Inserisci il costo per utenza"
                    name="costoUnitario"
                    value={this.state.record.costoUnitario || ""}
                    onChange={this.handleCostoUnitarioChange}
                    disabled={this.state.recordSaved}
                    className={classes.textfield}
                    error={this.state.validationErrors.costoUnitario.length > 0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justify="center"
                style={{ paddingTop: "10px", paddingBottom: "10px" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ textAlign: "right" }}
                >
                  <ActionButton
                    label="Registra"
                    disabled={
                      !this.state.changesNotSaved ||
                      this.state.disableSaveButton
                    }
                    onClick={this.onSubmit}
                    grayVersion={false}
                  />
                </Grid>
              </Grid>
              <Box p={1} style={styles.buttonContainer}>
                <GoToButton
                  secondaryButton
                  showAlert={this.state.changesNotSaved}
                  path="/amministrazione/gruppi"
                  label="Torna alla tabella dei gruppi"
                  disabled={false}
                  grayVersion={false}
                />
                <GoToButton
                  showAlert={false}
                  path={"/amministrazione/gruppi/" + this.state.record.id}
                  label="Vai alla pagina di modifica del gruppo"
                  disabled={!this.state.recordSaved}
                  grayVersion={false}
                />
              </Box>
            </Fragment>
          )}
          <ErrorDialog
            open={this.state.errorDialogVisible}
            title={this.state.errorDialogTitle}
            message={this.state.errorDialogMessage}
            onCloseButtonClicked={this.closeErrorDialog}
          />
        </Container>
      </Fragment>
    );
  }
}

NuovoGruppoPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles)(NuovoGruppoPage);
