import React, { Component } from "react";
// React-table library requires to import css as well:
import ReactTable from "react-table";
import "react-table/react-table.css"; // eslint-disable-next-line
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { ExpandMore, InfoOutlined } from "@material-ui/icons";
import HighlightOff from "@material-ui/icons/HighlightOff";
import SpinnerComponent from "../../../../components/SpinnerComponent";
import CustomTooltip from "../../../../components/CustomTooltip";
import PropTypes from "prop-types";

const styles = (theme) => ({
  container: {
    maxWidth: "1000px",
    //minHeight: '400px' //da rivedere
  },
  gridcontainer: {
    padding: "20px 0px",
  },
  textfieldContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 50,
  },
  textfieldWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
  },
  tablecontainer: {
    padding: "20px 0px",
  },
  inputfield: {
    marginBottom: "20px",
    width: "276px",
  },
  controllicause_container: {
    border: "1px solid lightgray",
    borderRadius: "4px",
    margin: "4px 0px",
    padding: "8px 0px",
  },
  buttonEnabled: {
    color: theme.palette.text.main,
    backgroundColor: theme.palette.primary.main,
    margin: "10px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  buttonDisabled: {
    color: theme.palette.text.disabled,
    backgroundColor: theme.palette.disabled.main,
    margin: "10px",
  },
  accordionButton: {
    color: theme.palette.text.main,
    margin: "10px",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
});

class ControlliNonConformita extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      disableButtonControlloCausa: true,
      disableButtonAzioneCorrettiva: [true],
      newAzioneControlloSemplificato: "",
      newSiglaControlloSemplificato: "",
      isFormValid: false,
      nuovoControlloNonConformita: {
        id: null,
        controlloConformitaId: null,
        limite: null,
        controlliCause: [
          {
            limite: null,
            limiteValidationError: "",
            azioniCorrettive: [
              {
                descrizione: null,
                descrizioneValidationError: "",
                sigla: null,
              },
            ],
          },
        ],
      },
      validationErrors: {
        limite: "",
        controlliCause: [],
      },
    };
  }

  componentDidMount() {
    if (this.props.controlloConformitaId !== null) {
      let nuovoControlloNonConformita = this.state.nuovoControlloNonConformita;
      nuovoControlloNonConformita.controlloConformitaId =
        this.props.controlloConformitaId;
      this.setState({
        nuovoControlloNonConformita,
      });
    }
  }

  removeNullAzioniCorrettive = (controlloNonConformita) => {
    let controllicause = controlloNonConformita.controlliCause;
    for (let i = 0; i < controllicause.length; i++) {
      const azioni = controllicause[i].azioniCorrettive;
      const result = azioni.filter(
        (azione) => azione.descrizione !== null && azione.descrizione.length > 0
      );
      controllicause[i].azioniCorrettive = result;
    }
    //controlloNonConformita.controlliCause = controllicause;
    return controlloNonConformita;
  };

  removeNullControlliCause = (controlloNonConformita) => {
    let controlli = controlloNonConformita.controlliCause;
    const result = controlli.filter(
      (controllo) =>
        controllo.azioniCorrettive.length > 0 ||
        (controllo.limite !== null && controllo.limite.length > 0)
    );
    controlloNonConformita.controlliCause = result;
    return controlloNonConformita;
  };

  validateField = (
    key,
    value,
    relatedValue = null,
    indexControllo = null,
    indexAzione = null
  ) => {
    const {
      validationErrors,
      nuovoControlloNonConformita,
      disableButtonAzioneCorrettiva,
    } = this.state;

    let isValid = true;

    switch (key) {
      case "limite":
        validationErrors.limite = this.validateLimite(value);
        if (validationErrors.limite) {
          isValid = false;
        }
        break;

      case "azioneCorrettiva": {
        if (!validationErrors.controlliCause[indexControllo]) {
          validationErrors.controlliCause[indexControllo] = {
            azioniCorrettive: [],
          };
        }
        validationErrors.controlliCause[indexControllo].azioniCorrettive[
          indexAzione
        ] = {
          descrizione: this.validateDescrizioneAzioneCorrettiva(
            value,
            relatedValue
          ),
        };
        if (
          validationErrors.controlliCause[indexControllo].azioniCorrettive[
            indexAzione
          ].descrizione
        ) {
          isValid = false;
        }

        // Verifica se tutte le azioni di questo controllo sono valide
        const disableForThisControl =
          nuovoControlloNonConformita.controlliCause[
            indexControllo
          ].azioniCorrettive.some(
            (azione) => !azione.descrizione || azione.descrizione.trim() === ""
          );

        // Aggiorna lo stato per questo controllo specifico
        disableButtonAzioneCorrettiva[indexControllo] = disableForThisControl;

        this.setState({
          disableButtonAzioneCorrettiva,
        });
        break;
      }

      default:
        break;
    }
    this.setState(
      {
        validationErrors,
      },
      this.checkFormValidity
    );

    return isValid;
  };

  validateLimite = (value) => {
    if (value === null || value.trim().length === 0) {
      return "Inserire almeno un carattere";
    }

    const { controlliNonConformita } = this.props;
    const isLimiteAlreadyPresent = controlliNonConformita.some(
      (record) => record.limite === value
    );

    if (isLimiteAlreadyPresent) {
      return "Esiste già un controllo di non conformità con lo stesso limite";
    }
    return "";
  };

  validateDescrizioneAzioneCorrettiva = (descrizione, sigla) => {
    if (!descrizione || descrizione.trim().length === 0) {
      if (sigla && sigla.trim().length > 0) {
        return "Specificare l'azione correttiva se si inserisce una sigla";
      }
    }
    return "";
  };

  checkFormValidity = () => {
    const { nuovoControlloNonConformita, validationErrors } = this.state;

    let isFormValid = true;

    // Verifica il limite principale
    if (validationErrors.limite || !nuovoControlloNonConformita.limite) {
      isFormValid = false;
    }

    // Se ci sono cause di controllo
    nuovoControlloNonConformita.controlliCause.forEach((controllo) => {
      // Per ogni azione correttiva, se è presente una sigla, deve esserci anche una descrizione
      controllo.azioniCorrettive.forEach((azione) => {
        if (azione.sigla && !azione.descrizione) {
          isFormValid = false;
        }
      });
    });

    this.setState({ isFormValid });
  };

  addControlloNonConformita = () => {
    const { nuovoControlloNonConformita } = this.state;
    const isLimiteValid = this.validateField(
      "limite",
      nuovoControlloNonConformita.limite
    );

    let isAzioneCorrettivaValid = true;

    nuovoControlloNonConformita.controlliCause.forEach(
      (controllo, indexControllo) => {
        controllo.azioniCorrettive.forEach((azione, indexAzione) => {
          const isValid = this.validateField(
            "azioneCorrettiva",
            azione.descrizione,
            azione.sigla,
            indexControllo,
            indexAzione
          );
          if (!isValid) {
            isAzioneCorrettivaValid = false;
          }
        });
      }
    );

    if (isLimiteValid && isAzioneCorrettivaValid) {
      let controllononconformita = this.removeNullAzioniCorrettive(
        nuovoControlloNonConformita
      );
      controllononconformita = this.removeNullControlliCause(
        controllononconformita
      );

      this.props.onAggiungiButtonClicked(controllononconformita);
      this.resetNuovoControlloNonConformita();
    }

    this.setState({
      disableButtonAzioneCorrettiva: [true],
      disableButtonControlloCausa: true,
    });
  };

  resetNuovoControlloNonConformita = () => {
    const nuovoControlloNonConformita = {
      id: null,
      controlloConformitaId: this.props.controlloConformitaId,
      limite: null,
      controlliCause: [
        {
          limite: null,
          azioniCorrettive: [
            {
              descrizione: null,
              descrizioneValidationError: "",
            },
          ],
        },
      ],
    };

    this.setState({
      nuovoControlloNonConformita,
    });
  };

  handleChangeControlloNonConformita = (event) => {
    let nuovoControlloNonConformita = this.state.nuovoControlloNonConformita;
    nuovoControlloNonConformita[event.target.name] = event.target.value;
    this.setState({
      nuovoControlloNonConformita,
    });
    this.validateField(event.target.name, event.target.value);
  };

  addControlloCausa = () => {
    let nuovoControlloNonConformita = this.state.nuovoControlloNonConformita;
    let controllicause = nuovoControlloNonConformita.controlliCause;
    const controllocausa = {
      limite: null,
      azioniCorrettive: [
        {
          descrizione: null,
          sigla: null,
        },
      ],
    };
    controllicause.push(controllocausa);
    nuovoControlloNonConformita.controlliCause = controllicause;

    const updatedDisableButtonAzioneCorrettiva = [
      ...this.state.disableButtonAzioneCorrettiva,
      true,
    ];

    this.setState({
      nuovoControlloNonConformita,
      disableButtonControlloCausa: true,
      disableButtonAzioneCorrettiva: updatedDisableButtonAzioneCorrettiva,
    });
  };

  addAzioneCorrettiva = (indexControlloCausa) => {
    const { nuovoControlloNonConformita, disableButtonAzioneCorrettiva } =
      this.state;

    const updatedControlliCause =
      nuovoControlloNonConformita.controlliCause.map((controllo, i) => {
        if (i === indexControlloCausa) {
          return {
            ...controllo,
            azioniCorrettive: [
              ...controllo.azioniCorrettive,
              {
                descrizione: "",
                descrizioneValidationError: "",
                sigla: "",
              },
            ],
          };
        }
        return controllo;
      });

    const updatedDisableButtonAzioneCorrettiva = [
      ...disableButtonAzioneCorrettiva,
    ];
    updatedDisableButtonAzioneCorrettiva[indexControlloCausa] = true;

    this.setState({
      nuovoControlloNonConformita: {
        ...nuovoControlloNonConformita,
        controlliCause: updatedControlliCause,
      },
      disableButtonAzioneCorrettiva: updatedDisableButtonAzioneCorrettiva,
    });
  };

  handleChangeControlloCausa = (event, index) => {
    let nuovoControlloNonConformita = this.state.nuovoControlloNonConformita;
    let value = event.target.value;
    nuovoControlloNonConformita.controlliCause[index].limite = value;
    this.setState({
      nuovoControlloNonConformita,
    });
    this.validateControlliCause();
  };

  validateControlliCause = () => {
    let isValid = true;
    let nuovoControlloNonConformita = this.state.nuovoControlloNonConformita;
    let controlli = nuovoControlloNonConformita.controlliCause;
    for (let i = 0; i < controlli.length; i++) {
      if (
        controlli[i].limite === null ||
        controlli[i].limite === "" ||
        controlli[i].limite.trim().length === 0
      ) {
        controlli[i].limiteValidationError = "Inserire almeno un carattere";
        isValid = false;
      } else {
        controlli[i].limiteValidationError = "";
      }
    }
    nuovoControlloNonConformita.controlliCause = controlli;
    this.setState({
      nuovoControlloNonConformita,
      disableButtonControlloCausa: !isValid,
    });
  };

  handleChangeAzioneCorrettiva = (
    event,
    indexControlloCausa,
    indexAzioneCorrettiva
  ) => {
    const { name, value } = event.target;

    const { nuovoControlloNonConformita } = this.state;
    const updatedControlliCause =
      nuovoControlloNonConformita.controlliCause.map((controllo, i) => {
        if (i === indexControlloCausa) {
          const updatedAzioni = controllo.azioniCorrettive.map((azione, j) => {
            if (j === indexAzioneCorrettiva) {
              return { ...azione, [name]: value };
            }
            return azione;
          });
          return {
            ...controllo,
            azioniCorrettive: updatedAzioni,
          };
        }
        return controllo;
      });

    this.setState(
      {
        nuovoControlloNonConformita: {
          ...nuovoControlloNonConformita,
          controlliCause: updatedControlliCause,
        },
      },
      this.validateAzioniCorrettive
    );
  };

  validateAzioniCorrettive = () => {
    let isValid = true;
    let nuovoControlloNonConformita = this.state.nuovoControlloNonConformita;

    nuovoControlloNonConformita.controlliCause.forEach((controllo, i) => {
      controllo.azioniCorrettive.forEach((azione, j) => {
        const isActionValid = this.validateField(
          "azioneCorrettiva",
          azione.descrizione,
          azione.sigla,
          i, // indice del controllo
          j // indice dell'azione
        );

        if (!isActionValid) {
          isValid = false;
        }
      });
    });

    return isValid;
  };

  render() {
    const { classes, mode } = this.props;

    const columns = this.props.controlliSemplificati
      ? [
          {
            Header: "Azione correttiva",
            id: "descrizione",
            accessor: (d) => d.descrizione,
            minWidth: 140,
            Cell: (props) => (props.value === null ? "" : props.value),
          },
          {
            Header: "Sigla",
            id: "sigla",
            accessor: (d) => d.sigla,
            minWidth: 50,
            Cell: (props) => (props.value === null ? "" : props.value),
          },
          {
            id: "eliminaBtn",
            Header: "Elimina",
            accessor: (row) => row,
            width: 150,
            Cell: (props) => (
              <IconButton
                size="small"
                disabled={false}
                style={{
                  color: this.props.theme.palette.primary.main,
                }}
                onClick={() => {
                  this.props.onDeleteButtonClickedControlliSemplificato(
                    props.index
                  );
                }}
              >
                <HighlightOff />
              </IconButton>
            ),
          },
        ]
      : [
          {
            Header: "Indice",
            id: "indice",
            accessor: (d) => d.indice,
            show: false,
          },
          {
            Header: "Id",
            id: "id",
            accessor: (d) => d.id,
            show: false,
          },
          {
            Header: `Limite di non conformità ${
              mode === "OLIO_FRIGGITRICE" ? "TPM" : ""
            }`,
            id: "limite",
            accessor: (d) => d.limite,
            minWidth: 140,
            Cell: (props) => props.value || "",
          },
          {
            Header: "Controllo causa",
            id: "limiteCausa",
            accessor: (d) => d.limiteCausa,
            minWidth: 140,
            Cell: (props) => (props.value === null ? "" : props.value),
          },
          {
            Header: "Azione correttiva",
            id: "descrizioneAzione",
            accessor: (d) => d.descrizioneAzione,
            minWidth: 140,
            Cell: (props) => (props.value === null ? "" : props.value),
          },
          {
            Header: "Sigla",
            id: "siglaAzione",
            accessor: (d) => d.siglaAzione,
            minWidth: 120,
            Cell: (props) => props.value ?? "",
          },
          {
            id: "eliminaBtn",
            Header: "Elimina",
            accessor: (row) => row,
            width: 80,
            Cell: (props) => (
              <IconButton
                size="small"
                disabled={false}
                style={{
                  color: this.props.theme.palette.primary.main,
                }}
                onClick={() => {
                  this.props.onDeleteButtonClicked(props.value);
                }}
              >
                <HighlightOff />
              </IconButton>
            ),
          },
        ];

    return (
      <Container className={classes.container}>
        {this.state.loading ? (
          <SpinnerComponent size={32} />
        ) : (
          <Grid container justify="center" className={classes.gridcontainer}>
            {this.props.controlliSemplificati ? (
              <>
                <div className={classes.textfieldContainer}>
                  <div className={classes.textfieldWrapper}>
                    <Typography style={{ alignSelf: "flex-start" }}>
                      Azione correttiva
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={this.state.newAzioneControlloSemplificato}
                      onChange={(e) => {
                        this.setState({
                          newAzioneControlloSemplificato: e.target.value,
                        });
                      }}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className={classes.textfieldWrapper}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 5 }}
                    >
                      <Typography>Sigla Azione correttiva</Typography>
                      <CustomTooltip
                        title={
                          <Typography variant="caption">
                            Inserire una sigla di max 12 caratteri alfanumerici
                            che rappresenti l'azione correttiva.
                          </Typography>
                        }
                      >
                        <IconButton size="small">
                          <InfoOutlined
                            color="secondary"
                            style={{ width: 20, height: 20 }}
                          />
                        </IconButton>
                      </CustomTooltip>
                    </div>
                    <TextField
                      inputProps={{ maxLength: 12 }}
                      variant="outlined"
                      size="small"
                      value={this.state.newSiglaControlloSemplificato}
                      onChange={(e) => {
                        this.setState({
                          newSiglaControlloSemplificato: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div style={{ marginTop: 20 }}>
                  <Button
                    type="button"
                    onClick={() => {
                      this.props.onAggiungiButtonClickedSemplificato(
                        this.state.newAzioneControlloSemplificato,
                        this.state.newSiglaControlloSemplificato
                      );
                      this.setState({
                        newAzioneControlloSemplificato: "",
                        newSiglaControlloSemplificato: "",
                      });
                    }}
                    variant="contained"
                    size="medium"
                    disabled={false}
                    className={classes.buttonEnabled}
                  >
                    Aggiungi azione correttiva
                  </Button>
                </div>
              </>
            ) : (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Accordion
                  expanded={this.props.expandedAccordion}
                  onChange={this.props.onChangeAccordion}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    id="accordion-header"
                  >
                    <div>
                      <Typography>{`Nuovo controllo di non conformità ${
                        mode === "OLIO_FRIGGITRICE" ? "TPM" : ""
                      }`}</Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container justify="center" alignItems="center">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                          label="Limite non conformità *"
                          value={
                            this.state.nuovoControlloNonConformita.limite ?? ""
                          }
                          name="limite"
                          className={classes.inputfield}
                          onChange={this.handleChangeControlloNonConformita}
                          helperText={this.state.validationErrors.limite}
                          error={this.state.validationErrors.limite.length > 0}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {this.state.nuovoControlloNonConformita.controlliCause.map(
                          (controllo, indexControllo) => (
                            <Grid
                              container
                              justify="center"
                              alignItems="center"
                              key={indexControllo}
                              className={classes.controllicause_container}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <TextField
                                  label="Causa di non conformità"
                                  value={controllo.limite ?? ""}
                                  name="limite"
                                  className={classes.inputfield}
                                  onChange={(event) => {
                                    this.handleChangeControlloCausa(
                                      event,
                                      indexControllo
                                    );
                                  }}
                                />
                              </Grid>
                              {controllo.azioniCorrettive.map(
                                (azioneCorrettiva, indexAzione) => (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    key={indexAzione}
                                    index={indexAzione}
                                  >
                                    <Grid
                                      container
                                      justify="center"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Grid item xs={5}>
                                        <TextField
                                          label="Azione correttiva"
                                          name="descrizione"
                                          value={
                                            azioneCorrettiva.descrizione ?? ""
                                          }
                                          className={classes.inputfield}
                                          onChange={(event) => {
                                            this.handleChangeAzioneCorrettiva(
                                              event,
                                              indexControllo,
                                              indexAzione
                                            );
                                          }}
                                          helperText={
                                            this.state.validationErrors
                                              .controlliCause[indexControllo]
                                              ?.azioniCorrettive[indexAzione]
                                              ?.descrizione || ""
                                          }
                                          error={
                                            !!this.state.validationErrors
                                              .controlliCause[indexControllo]
                                              ?.azioniCorrettive[indexAzione]
                                              ?.descrizione
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={5}>
                                        <TextField
                                          label="Sigla azione correttiva"
                                          name="sigla"
                                          value={azioneCorrettiva.sigla ?? ""}
                                          onChange={(event) => {
                                            this.handleChangeAzioneCorrettiva(
                                              event,
                                              indexControllo,
                                              indexAzione
                                            );
                                          }}
                                          className={classes.inputfield}
                                          inputProps={{ maxLength: 12 }}
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <CustomTooltip
                                                  title={
                                                    <Typography variant="caption">
                                                      Inserire una sigla di max
                                                      12 caratteri alfanumerici
                                                      che rappresenti l'azione
                                                      correttiva.
                                                    </Typography>
                                                  }
                                                  arrow
                                                  placement="top"
                                                >
                                                  <InfoOutlined
                                                    color="secondary"
                                                    fontSize="small"
                                                  />
                                                </CustomTooltip>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )
                              )}
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Button
                                  variant="contained"
                                  size="small"
                                  disabled={
                                    this.state.disableButtonAzioneCorrettiva[
                                      indexControllo
                                    ]
                                  }
                                  className={
                                    this.state.disableButtonAzioneCorrettiva[
                                      indexControllo
                                    ]
                                      ? classes.buttonDisabled
                                      : classes.buttonEnabled
                                  }
                                  onClick={() =>
                                    this.addAzioneCorrettiva(indexControllo)
                                  }
                                >
                                  Aggiungi azione correttiva
                                </Button>
                              </Grid>
                            </Grid>
                          )
                        )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button
                          variant="contained"
                          size="small"
                          disabled={this.state.disableButtonControlloCausa}
                          className={
                            this.state.disableButtonControlloCausa
                              ? classes.buttonDisabled
                              : classes.buttonEnabled
                          }
                          onClick={this.addControlloCausa}
                        >
                          Aggiungi controllo causa
                        </Button>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                  <Divider />
                  <AccordionActions>
                    <Button
                      size="small"
                      onClick={this.addControlloNonConformita}
                      variant="contained"
                      disabled={!this.state.isFormValid}
                      className={classes.accordionButton}
                    >
                      Aggiungi
                    </Button>
                  </AccordionActions>
                </Accordion>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.tablecontainer}
            >
              <ReactTable
                filterable={false}
                resizable={true}
                showPageSizeOptions={true}
                showPageJump={true}
                defaultPageSize={5}
                //pages={this.state.pages}
                data={this.props.controlliNonConformita}
                columns={columns}
                //manual // informs React Table that you'll be handling sorting and pagination server-side
                //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                previousText="Precedente"
                nextText="Successivo"
                noDataText="Nessun record"
                pageText="Pagina"
                ofText="di"
                rowsText="righe"
                pageJumpText="Vai a pagina"
                rowsSelectorText="righe per pagina"
              />
            </Grid>
          </Grid>
        )}
      </Container>
    );
  }
}

ControlliNonConformita.propTypes = {
  classes: PropTypes.object.isRequired,
  controlliNonConformita: PropTypes.array.isRequired,
  controlliSemplificati: PropTypes.bool.isRequired,
  controlloConformitaId: PropTypes.number,
  expandedAccordion: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  onChangeAccordion: PropTypes.func.isRequired,
  onAggiungiButtonClicked: PropTypes.func.isRequired,
  onAggiungiButtonClickedSemplificato: PropTypes.func.isRequired,
  onDeleteButtonClicked: PropTypes.func.isRequired,
  onDeleteButtonClickedControlliSemplificato: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles)(ControlliNonConformita);
