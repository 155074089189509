import React, { Component } from "react";

// React-table library requires to import css as well:
import ReactTable from "react-table";
import "react-table/react-table.css"; // eslint-disable-next-line

import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import HighlightOff from "@material-ui/icons/HighlightOff";

import { orariControlli } from "../../../../utils/constants.js";
import { Button } from "@material-ui/core";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "190px",
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 270,
    },
  },
};
const styles = {
  formcontrol: {
    marginBottom: "30px",
    width: "270px",
    padding: "0px 4px",
  },
  container: {
    //border: '1px solid #e0e0e0',
    //borderRadius: '6px',
    margin: "10px 0px 14px 0px",
    padding: "10px 0px 14px 0px",
  },
  tablecontainer: {
    margin: "10px 0px 10px 0px",
    paddingBottom: "10px",
  },
  modalcontainer: {
    overflowY: "scroll",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    paddingTop: "26px",
    paddingBottom: "26px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
    marginLeft: "15%",
    marginRight: "15%",
    height: "80%",
  },
};

export default class FormGiornaliero extends Component {
  openTable = (cadenze) => {
    this.setState({ visible: true, cadenze });
  };
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      cadenza: {
        orario: "08:00:00",
        giornoSettimana: null,
        mese: null,
        giornoMese: null,
        schedaId: null,
        id: null,
        controlloConformitaId: null,
      },
    };
  }

  onSelect = (event) => {
    this.handleChange(event.target.name, event.target.value);
  };
  handleChange = (name, value) => {
    let cadenza = JSON.parse(JSON.stringify(this.state.cadenza));
    cadenza[name] = value;

    this.setState({
      cadenza,
    });
  };
  handleClose = () => {
    this.setState({ visible: false, cadenze: [] });
  };
  render() {
    // Added a special field named indice in each controllo conformità
    // to recognize records when ID is null.
    const columns = [
      {
        Header: "ID",
        id: "id",
        accessor: (d) => d.id,
        show: false,
      },
      {
        Header: "Orario",
        id: "orario",
        accessor: (d) => d.orario,
        minWidth: 104,
      },
      {
        id: "eliminaBtn",
        Header: "Elimina",
        accessor: (row) => row,
        width: 66,
        Cell: (props) => (
          <IconButton
            size="small"
            disabled={false}
            onClick={() => {
              this.props.onEliminaCadenzaButtonClicked(props.index);
            }}
            style={{
              color: this.props.theme.palette.primary.main,
            }}
          >
            <HighlightOff />
          </IconButton>
        ),
      },
    ];
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={styles.container}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body1" style={{ fontWeight: 500 }} gutterBottom>
            Ora del controllo
          </Typography>
        </Grid>
        <FormControl style={styles.formcontrol}>
          <InputLabel id="orario-label">Ora</InputLabel>
          <Select
            value={this.state.cadenza.orario}
            name="orario"
            onChange={this.onSelect}
            MenuProps={MenuProps}
          >
            <MenuItem disabled value={""}>
              <em>Seleziona un orario</em>
            </MenuItem>
            {orariControlli.map((orario) => (
              <MenuItem key={orario.value} value={orario.value}>
                {orario.label}
              </MenuItem>
            ))}
          </Select>
          <Button
            size="small"
            onClick={() => this.props.onAddCadenza(this.state.cadenza)}
            variant="contained"
            disabled={false}
            style={{
              color: this.props.theme.palette.text.main,
              margin: "10px",
              backgroundColor: this.props.theme.palette.primary.main,
            }}
          >
            Aggiungi
          </Button>
        </FormControl>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={styles.tablecontainer}
        >
          <ReactTable
            filterable={false}
            resizable={true}
            showPageSizeOptions={true}
            showPageJump={true}
            defaultPageSize={5}
            //pages={this.state.pages}
            data={this.props.cadenze}
            columns={columns}
            //manual // informs React Table that you'll be handling sorting and pagination server-side
            //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
            previousText="Precedente"
            nextText="Successivo"
            noDataText="Nessun record"
            pageText="Pagina"
            ofText="di"
            rowsText="righe"
            pageJumpText="Vai a pagina"
            rowsSelectorText="righe per pagina"
          />
        </Grid>
      </Grid>
    );
  }
}

FormGiornaliero.propTypes = {};
