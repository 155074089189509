import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import itLocale from "date-fns/locale/it";
import React,{ Component } from "react";

const dateFormat = "dd-MM-yyyy";

const styles = {
   mainContainer:{
    
   },
   title:{
    fontSize: 25,
    fontWeight: 500,
    lineHeight: 1.6,
   },
   pickerLabel:{
    display: "flex",
    justifyContent: "space-evenly",
   },
   pickerLabel2:{
    display:"flex",
    justifyContent:"space-evenly",
    marginLeft:75,
   },
   labelStyle:{
    fontSize:20,
   },
   labelPartRight:{
    justifyContent: "center",
    display: "flex",
    marginRight:50,
   },
   labelPartLeft:{
    justifyContent: "center",
    display: "flex",
    marginLeft:50,
   }
};

export default class OrarioStraordinarioComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
            checked:true,
            orarioDiInizio:null,
            orarioDiFine:null,
            dataInizio:this.props.dataInizio,
            dataFine:this.props.dataFine,
            mode:this.props.mode,
            apertura:this.props.aperto,
        }
    }
    
    handleDataInizio(data){
        this.props.handleDataInizio(data);
    }
    handleDataFine(data){
        this.props.handleDataFine(data);
    }
    handleOrarioInizio(data){
        this.props.handleOrarioInizio(data);
    }
    handleOrarioFine(data){
        this.props.handleOrarioFine(data);
    }
    handleInteraGiornata(data){
        this.props.handleInteraGiornata(data);
    }

    componentDidMount(){
        console.log(this.state.orarioDiInizio);
        console.log(this.state.orarioDiFine);
    }

    render(){
        return(
          <div style={styles.mainContainer}>
            {(this.props.mode === "SPECIFICO" && this.state.apertura === false) && <><div style={styles.title}>Orario:</div>
            <div>
            <FormControlLabel
            label="Esercizio chiuso per l'intera giornata"
            control={
            <Checkbox
            color="primary"
            checked={this.state.checked}
            onClick={() => {
                this.setState({
                    checked: !this.state.checked
                })
                this.handleInteraGiornata(!this.state.checked);
            }}
             />
            }
            />
            </div>
            <div style={styles.pickerLabel}>
                <div style={styles.labelStyle}>Chiuso dalle</div>
                <TimePicker
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioInizio) => {
                    this.setState({
                        orarioDiInizio:newOrarioInizio
                    })
                    this.handleOrarioInizio(newOrarioInizio);
                    console.log("Orario Specifico di inizio"+newOrarioInizio);
                }}
                value={this.state.orarioDiInizio}
                ></TimePicker>
            </div>
            <div style={styles.pickerLabel2}>
                <div style={styles.labelStyle}>Alle</div>
                <TimePicker
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioFine) => {
                    this.setState({
                        orarioDiFine:newOrarioFine
                    })
                    this.handleOrarioFine(newOrarioFine);
                    console.log("Orario specifico finale"+newOrarioFine);
                }}
                value={this.state.orarioDiFine}
                ></TimePicker>
            </div>
            </>}
            {(this.props.mode === "SPECIFICO" && this.state.apertura === true) && <><div style={styles.title}>Orario:</div>
            <div>
            <FormControlLabel
            label="Esercizio aperto per l'intera giornata"
            control={
            <Checkbox
            color="primary"
            checked={this.state.checked}
            onClick={() => {
                this.setState({
                    checked: !this.state.checked
                })
                this.handleInteraGiornata(!this.state.checked);
            }}
             />
            }
            />
            </div>
            <div style={styles.pickerLabel}>
                <div style={styles.labelStyle}>Aperto dalle</div>
                <TimePicker
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioInizio) => {
                    this.setState({
                        orarioDiInizio:newOrarioInizio
                    })
                    this.handleOrarioInizio(newOrarioInizio);
                    console.log("Orario Specifico di inizio"+newOrarioInizio);
                }}
                value={this.state.orarioDiInizio}
                ></TimePicker>
            </div>
            <div style={styles.pickerLabel2}>
                <div style={styles.labelStyle}>Alle</div>
                <TimePicker
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioFine) => {
                    this.setState({
                        orarioDiFine:newOrarioFine
                    })
                    this.handleOrarioFine(newOrarioFine);
                    console.log("Orario specifico finale"+newOrarioFine);
                }}
                value={this.state.orarioDiFine}
                ></TimePicker>
            </div>
            </>}
            {(this.props.mode === "PERIODO" && this.state.apertura === false) && <><div style={styles.title}>Orario:</div>
            <div>
            <FormControlLabel
            label="Esercizio chiuso per l'intera giornata"
            control={
            <Checkbox
            color="primary"
            checked={this.state.checked}
            onClick={() => {
                this.setState({
                    checked: !this.state.checked
                })
                this.handleInteraGiornata(!this.state.checked);
            }}
             />
            }
            />
            </div>
            <div style={styles.pickerLabel}>
                <div style={styles.labelPartRight}>
                <div style={styles.labelStyle}>Chiuso dalle</div>
                <div style={{marginLeft:40}}>
                <TimePicker
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioInizio) => {
                    this.setState({
                        orarioDiInizio:newOrarioInizio
                    })
                    this.handleOrarioInizio(newOrarioInizio);
                }}
                value={this.state.orarioDiInizio}
                ></TimePicker>
                </div>
                </div>
                <div style={styles.labelPartLeft}>
                <div style={styles.labelStyle}>Del giorno</div>
                <div style={{marginLeft:63}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                <DatePicker onChange={(data) => {
                    this.setState({
                        dataInizio:data,
                    })
                    this.handleDataInizio(data);
                }} 
                disabled={false} 
                format={dateFormat}
                value={this.state.dataInizio}>
                </DatePicker>
                </MuiPickersUtilsProvider>
                </div>
                </div>
            </div>
            <div style={styles.pickerLabel2}>
            <div style={styles.labelPartRight}>
                <div style={styles.labelStyle}>Alle</div>
                <div style={{marginLeft:40}}>
                <TimePicker
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioFine) => {
                    this.setState({
                        orarioDiFine:newOrarioFine
                    })
                    this.handleOrarioFine(newOrarioFine);
                }}
                value={this.state.orarioDiFine}
                ></TimePicker>
                </div>
                </div>
                <div style={styles.labelPartLeft}>
                <div style={styles.labelStyle}>Del giorno</div>
                <div style={{marginLeft:63}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                <DatePicker
                onChange={(data) => {
                    this.setState({
                        dataFine:data,
                    })
                    this.handleDataFine(data);
                }}
                format={dateFormat}
                disabled={false}
                value = {this.state.dataFine}
                >

                </DatePicker>
                </MuiPickersUtilsProvider>
                </div>
                </div>
            </div>
            </>}
            {(this.props.mode === "PERIODO" && this.state.apertura === true) && <><div style={styles.title}>Orario:</div>
            <div>
            <FormControlLabel
            label="Esercizio aperto per l'intera giornata"
            control={
            <Checkbox
            color="primary"
            checked={this.state.checked}
            onClick={() => {
                this.setState({
                    checked: !this.state.checked
                })
                this.handleInteraGiornata(!this.state.checked);
            }}
             />
            }
            />
            </div>
            <div style={styles.pickerLabel}>
                <div style={styles.labelPartRight}>
                <div style={styles.labelStyle}>Aperto dalle</div>
                <div style={{marginLeft:40}}>
                <TimePicker
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioInizio) => {
                    this.setState({
                        orarioDiInizio:newOrarioInizio
                    })
                    this.handleOrarioInizio(newOrarioInizio);
                }}
                value={this.state.orarioDiInizio}
                ></TimePicker>
                </div>
                </div>
                <div style={styles.labelPartLeft}>
                <div style={styles.labelStyle}>Del giorno</div>
                <div style={{marginLeft:63}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                <DatePicker onChange={(data) => {
                    this.setState({
                        dataInizio:data,
                    })
                    this.handleDataInizio(data);
                }} 
                disabled={false} 
                value={this.state.dataInizio}
                format = {dateFormat}
                >
                </DatePicker>
                </MuiPickersUtilsProvider>
                </div>
                </div>
            </div>
            <div style={styles.pickerLabel2}>
            <div style={styles.labelPartRight}>
                <div style={styles.labelStyle}>Alle</div>
                <div style={{marginLeft:40}}>
                <TimePicker
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioFine) => {
                    this.setState({
                        orarioDiFine:newOrarioFine
                    })
                    this.handleOrarioFine(newOrarioFine);
                }}
                value={this.state.orarioDiFine}
                ></TimePicker>
                </div>
                </div>
                <div style={styles.labelPartLeft}>
                <div style={styles.labelStyle}>Del giorno</div>
                <div style={{marginLeft:63}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                <DatePicker
                onChange={(data) => {
                    this.setState({
                        dataFine:data,
                    })
                    this.handleDataFine(data);
                }}
                disabled={false}
                value = {this.state.dataFine}
                format = {dateFormat}
                >
                </DatePicker>
                </MuiPickersUtilsProvider>
                </div>
                </div>
            </div>
            </>}
            {(this.props.mode === "ORARIO" && this.state.apertura === false) && 
            <>
            <div style={styles.title}>Orario:</div>
            <div>
            <FormControlLabel
            label="Esercizio chiuso per l'intera giornata"
            control={
            <Checkbox
            color="primary"
            checked={this.state.checked}
            onClick={() => {
                this.setState({
                    checked: !this.state.checked
                })
                this.handleInteraGiornata(!this.state.checked);
            }}
             />
            }
            />
            </div>
            <div style={styles.pickerLabel}>
                <div style={styles.labelStyle}>Chiuso dalle</div>
                <TimePicker
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioInizio) => {
                    this.setState({
                        orarioDiInizio:newOrarioInizio
                    })
                    this.handleOrarioInizio(newOrarioInizio);
                }}
                value={this.state.orarioDiInizio}
                ></TimePicker>
            </div>
            <div style={styles.pickerLabel2}>
                <div style={styles.labelStyle}>Alle</div>
                <TimePicker
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioFine) => {
                    this.setState({
                        orarioDiFine:newOrarioFine
                    })
                    this.handleOrarioFine(newOrarioFine);
                }}
                value={this.state.orarioDiFine}
                ></TimePicker>
            </div>
            </>}
            {(this.props.mode === "ORARIO" && this.state.apertura === true) && 
            <>
            <div style={styles.title}>Orario:</div>
            <div>
            <FormControlLabel
            label="Esercizio aperto per l'intera giornata"
            control={
            <Checkbox
            color="primary"
            checked={this.state.checked}
            onClick={() => {
                this.setState({
                    checked: !this.state.checked
                })
                this.handleInteraGiornata(!this.state.checked);
            }}
             />
            }
            />
            </div>
            <div style={styles.pickerLabel}>
                <div style={styles.labelStyle}>Aperto dalle</div>
                <TimePicker
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioInizio) => {
                    this.setState({
                        orarioDiInizio:newOrarioInizio
                    })
                    this.handleOrarioInizio(newOrarioInizio);
                }}
                value={this.state.orarioDiInizio}
                ></TimePicker>
            </div>
            <div style={styles.pickerLabel2}>
                <div style={styles.labelStyle}>Alle</div>
                <TimePicker
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioFine) => {
                    this.setState({
                        orarioDiFine:newOrarioFine
                    })
                    this.handleOrarioFine(newOrarioFine);
                }}
                value={this.state.orarioDiFine}
                ></TimePicker>
            </div>
            </>}
            {(this.props.mode === "DATAEORARIO" && this.state.apertura === false) && <><div style={styles.title}>Orario:</div>
            <div>
            <FormControlLabel
            label="Esercizio chiuso per l'intera giornata"
            control={
            <Checkbox
            color="primary"
            checked={this.state.checked}
            onClick={() => {
                this.setState({
                    checked: !this.state.checked
                })
                this.handleInteraGiornata(!this.state.checked);
            }}
             />
            }
            />
            </div>
            <div style={styles.pickerLabel}>
                <div style={styles.labelPartRight}>
                <div style={styles.labelStyle}>Chiuso dalle</div>
                <div style={{marginLeft:40}}>
                <TimePicker
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioInizio) => {
                    this.setState({
                        orarioDiInizio:newOrarioInizio
                    })
                    this.handleOrarioInizio(newOrarioInizio);
                }}
                value={this.state.orarioDiInizio}
                ></TimePicker>
                </div>
                </div>
                <div style={styles.labelPartLeft}>
                <div style={styles.labelStyle}>Del giorno</div>
                <div style={{marginLeft:63}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                <DatePicker onChange={(data) => {
                    this.setState({
                        dataInizio:data,
                    })
                    this.handleDataInizio(data);
                }} 
                disabled={false} 
                format = {dateFormat}
                value={this.state.dataInizio}>
                </DatePicker>
                </MuiPickersUtilsProvider>
                </div>
                </div>
            </div>
            <div style={styles.pickerLabel2}>
            <div style={styles.labelPartRight}>
                <div style={styles.labelStyle}>Alle</div>
                <div style={{marginLeft:40}}>
                <TimePicker
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioFine) => {
                    this.setState({
                        orarioDiFine:newOrarioFine
                    })
                    this.handleOrarioFine(newOrarioFine);
                }}
                value={this.state.orarioDiFine}
                ></TimePicker>
                </div>
                </div>
                <div style={styles.labelPartLeft}>
                <div style={styles.labelStyle}>Del giorno</div>
                <div style={{marginLeft:63}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                <DatePicker
                onChange={(data) => {
                    this.setState({
                        dataFine:data,
                    })
                    this.handleDataFine(data);
                }}
                disabled={false}
                value = {this.state.dataFine}
                format = {dateFormat}
                >

                </DatePicker>
                </MuiPickersUtilsProvider>
                </div>
                </div>
            </div>
            </>}
            {(this.props.mode === "DATAEORARIO" && this.state.apertura === true) && <><div style={styles.title}>Orario:</div>
            <div>
            <FormControlLabel
            label="Esercizio aperto per l'intera giornata"
            control={
            <Checkbox
            color="primary"
            checked={this.state.checked}
            onClick={() => {
                this.setState({
                    checked: !this.state.checked
                })
                this.handleInteraGiornata(!this.state.checked);
            }}
             />
            }
            />
            </div>
            <div style={styles.pickerLabel}>
                <div style={styles.labelPartRight}>
                <div style={styles.labelStyle}>Aperto dalle</div>
                <div style={{marginLeft:40}}>
                <TimePicker
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioInizio) => {
                    this.setState({
                        orarioDiInizio:newOrarioInizio
                    })
                    this.handleOrarioInizio(newOrarioInizio);
                }}
                value={this.state.orarioDiInizio}
                ></TimePicker>
                </div>
                </div>
                <div style={styles.labelPartLeft}>
                <div style={styles.labelStyle}>Del giorno</div>
                <div style={{marginLeft:63}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                <DatePicker onChange={(data) => {
                    this.setState({
                        dataInizio:data,
                    })
                    this.handleDataInizio(data);
                }} 
                disabled={false} 
                value={this.state.dataInizio}
                format={dateFormat}
                >
                </DatePicker>
                </MuiPickersUtilsProvider>
                </div>
                </div>
            </div>
            <div style={styles.pickerLabel2}>
            <div style={styles.labelPartRight}>
                <div style={styles.labelStyle}>Alle</div>
                <div style={{marginLeft:40}}>
                <TimePicker
                ampm={false}
                minutesStep={5}
                disabled = {this.state.checked}
                onChange={(newOrarioFine) => {
                    this.setState({
                        orarioDiFine:newOrarioFine
                    })
                    this.handleOrarioFine(newOrarioFine);
                }}
                value={this.state.orarioDiFine}
                ></TimePicker>
                </div>
                </div>
                <div style={styles.labelPartLeft}>
                <div style={styles.labelStyle}>Del giorno</div>
                <div style={{marginLeft:63}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                <DatePicker
                onChange={(data) => {
                    this.setState({
                        dataFine:data,
                    })
                    this.handleDataFine(data);
                }}
                disabled={false}
                value = {this.state.dataFine}
                format={dateFormat}
                >
                </DatePicker>
                </MuiPickersUtilsProvider>
                </div>
                </div>
            </div>
            </>}
          </div>
        );
    }
}