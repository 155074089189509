import { retrieveToken } from '../storage';
import { wrapFetch, ApiError } from './api';

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getSchedeTramiteId = (id) => {
    const PATH = "/api/paragrafi/schede/"+id; 
  const URL = BASE_URL + PATH;
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
      headers: {
      'Authorization' : authtoken
      },
      withCredentials: true,
      credentials: 'include'
    }), errorFunction
  );
}
export const getCategorieSchede = () => {
  const PATH = "/api/v2/schede/categorie"
  const URL = BASE_URL + PATH;
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
      headers: {
      'Authorization' : authtoken
      },
      withCredentials: true,
      credentials: 'include'
    }), errorFunction
  );
}
export const putModificaScheda = (requestBody) => {
  const PATH = '/api/paragrafi/schede';
  const URL = BASE_URL + PATH;
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400) {
      message = 'Si è verificato un errore durante il salvataggio.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'PUT',
      headers: {
        'Authorization' : authtoken,
        'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(requestBody)
    }), errorFunction
  );
}
export const uploadSchedaImage = (file) => {
  const PATH = "/api/schede/immagini/"; 
  const URL = BASE_URL + PATH;
  let formData = new FormData();
  formData.append("file", file);
  const errorFunction = (status) => {
      let message = null;
      if (status === 403) {
          message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
      } else if (status === 400 || status === 413) {
          message = "Caricamento fallito: il formato dell'immagine o la dimensione dell'immagine non sono corretti.";
      }
      return new ApiError(status, message);
  };
  return wrapFetch(
      fetch(URL, {
          method: 'POST',
          body: formData
      }), errorFunction
  );
}