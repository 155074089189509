import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

import IndietroButton from "../../../components/IndietroButton";
import ErrorDialog from "../../../components/ErrorDialog";
import SpinnerComponent from "../../../components/SpinnerComponent";
import SelezioneEsercizio from "./SelezioneEsercizio";

import { clearToken } from "../../../utils/storage";
import { registerResponsabileSicurezzaAssociatedToEsercizio } from "../../../utils/api/responsabili_sicurezza_api";
import { getEserciziCommerciali } from "../../../utils/api/esercizi_commerciali_api";

import validator from "validator";

const styles = {
  sectioncontainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "6px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  userdatacontainer: {
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "250px",
  },
};

export default class FormRegistraResponsabilePerConsulente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {
        email: "",
        password: "",
        confirmPassword: "",
        nome: null,
        cognome: null,
        telefono: null,
        terminiUsoAccettati: false,
        privacyPolicyAccettata: false,
        gruppoId: null,
        esercizioId: "",
      },
      validationErrors: {
        email: "",
        password: "",
        confirmPassword: "",
        esercizioId: "",
      },
      esercizi: [],
      showPassword1: false,
      showPassword2: false,
      errorDialogVisible: false,
      errorDialogMessage: "",
      loading: true,
      disableForm: false,
      saveButtonEnabled: false,
    };
  }

  componentDidMount() {
    let id = this.props.gruppoId;
    let record = this.state.record;
    record.gruppoId = id;
    this.setState({
      record,
    });
    this.fetchEserciziConsulente();
  }

  handleInvalidToken = () => {
    this.handleError(
      true,
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi."
    );
    clearToken();
    const { path } = this.props.theme;
    window.setTimeout(function () {
      window.location.href = `/${path}`;
    }, 4000);
  };

  fetchEserciziConsulente = () => {
    getEserciziCommerciali(
      this.props.gruppoId,
      this.props.consulenteId,
      null,
      0,
      1000,
      "id"
    )
      .then((result) => {
        this.setState({
          esercizi: result,
          loading: false,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };

  handleClickShowPassword1 = () => {
    let flag = this.state.showPassword1;
    this.setState({
      showPassword1: !flag,
    });
  };

  handleClickShowPassword2 = () => {
    let flag = this.state.showPassword2;
    this.setState({
      showPassword2: !flag,
    });
  };

  validateForm = () => {
    let data = this.state.record;
    Object.keys(data).forEach((key) => {
      this.validateField(key, data[key]);
    });
    //Return false if there are validation errors:
    let valid = true;
    let validationErrors = this.state.validationErrors;
    Object.values(validationErrors).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  validateField = (key, value) => {
    let insertedPsw = this.state.record.password;
    let validationErrors = this.state.validationErrors;
    switch (key) {
      case "email":
        if (value.length === 0) {
          validationErrors.email = "Inserire l'indirizzo email";
        } else {
          if (validator.isEmail(value)) {
            validationErrors.email = "";
          } else {
            validationErrors.email = "L'indirizzo email inserito non è valido";
          }
        }
        break;
      case "password":
        if (value.length >= 8) {
          validationErrors.password = "";
        } else {
          validationErrors.password =
            "Inserire una password di almeno 8 caratteri";
        }
        break;
      case "confirmPassword":
        if (value.length === 0) {
          validationErrors.confirmPassword = "Ripetere la password";
        } else {
          if (value === insertedPsw) {
            validationErrors.confirmPassword = "";
          } else {
            validationErrors.confirmPassword =
              "Le due password non corrispondono";
          }
        }
        break;
      case "esercizioId":
        if (value.length === 0) {
          validationErrors.esercizioId = "Selezionare un esercizio";
        } else {
          validationErrors.esercizioId = "";
        }
        break;
      default:
        break;
    }
    this.setState({
      validationErrors,
    });
  };

  handleSelectedEsercizio = (esercizioId) => {
    this.handleFormChange("esercizioId", esercizioId);
  };

  handleChange = (event) => {
    this.handleFormChange(event.target.name, event.target.value);
  };

  handleFormChange = (fielName, value) => {
    this.props.onFormModified();
    if (!this.state.saveButtonEnabled) {
      this.setState({
        saveButtonEnabled: true,
      });
    }
    let validationErrors = this.state.validationErrors;
    let data = this.state.record;
    data[fielName] = value;
    //Reset validation :
    if (
      fielName === "email" ||
      fielName === "password" ||
      fielName === "confirmPassword" ||
      fielName === "esercizioId"
    ) {
      validationErrors[fielName] = "";
    }
    this.setState({
      data,
      validationErrors,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let isFormValid = this.validateForm();
    if (isFormValid) {
      registerResponsabileSicurezzaAssociatedToEsercizio(this.state.record)
        .then((result) => {
          this.setState({
            loading: false,
            saveButtonEnabled: false,
            disableForm: true,
          });
          this.props.onFormSubmitted();
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            let enableSave = true;
            if (error.status === 409) {
              enableSave = false;
            }
            this.setState({
              loading: false,
              saveButtonEnabled: enableSave,
            });
            this.handleError(true, error.message);
          }
        });
    } //no-op if form is not valid
  };

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  render() {
    const disabledSubmitbutton =
      this.state.loading || !this.state.saveButtonEnabled;
    return (
      <Container
        id="form_container"
        style={{
          paddingLeft: "4px",
          paddingRight: "4px",
          maxWidth: "700px",
          minHeight: "500px",
        }}
      >
        {this.state.loading ? (
          <SpinnerComponent size={24} />
        ) : (
          <form autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container justify="center" alignItems="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={styles.sectioncontainer}
              >
                <Typography
                  variant="h5"
                  style={{ paddingTop: "6px", paddingBottom: "6px" }}
                >
                  Dati utente
                </Typography>
                <Grid container style={styles.userdatacontainer}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      style={{ paddingBottom: "10px" }}
                    >
                      <TextField
                        label="Email*"
                        value={
                          this.state.record.email === null
                            ? ""
                            : this.state.record.email
                        }
                        name="email"
                        onChange={this.handleChange}
                        disabled={this.state.disableForm}
                        style={styles.textfield}
                        helperText={this.state.validationErrors.email}
                        error={
                          this.state.validationErrors.email.length > 0
                            ? true
                            : false
                        }
                      />
                      <TextField
                        label="Password*"
                        value={this.state.record.password}
                        name="password"
                        onChange={this.handleChange}
                        disabled={this.state.disableForm}
                        style={styles.textfield}
                        helperText={this.state.validationErrors.password}
                        error={
                          this.state.validationErrors.password.length > 0
                            ? true
                            : false
                        }
                        type={this.state.showPassword1 ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="Mostra password">
                                <IconButton
                                  label="Mostra password"
                                  onClick={this.handleClickShowPassword1}
                                >
                                  {this.state.showPassword1 ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        label="Ripeti password*"
                        value={this.state.record.confirmPassword}
                        name="confirmPassword"
                        onChange={this.handleChange}
                        disabled={this.state.disableForm}
                        style={styles.textfield}
                        helperText={this.state.validationErrors.confirmPassword}
                        error={
                          this.state.validationErrors.confirmPassword.length > 0
                            ? true
                            : false
                        }
                        type={this.state.showPassword2 ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="Mostra password">
                                <IconButton
                                  label="Mostra password"
                                  onClick={this.handleClickShowPassword2}
                                >
                                  {this.state.showPassword2 ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      style={{ paddingBottom: "10px" }}
                    >
                      <TextField
                        label="Nome"
                        value={
                          this.state.record.nome === null
                            ? ""
                            : this.state.record.nome
                        }
                        name="nome"
                        onChange={this.handleChange}
                        disabled={this.state.disableForm}
                        style={styles.textfield}
                      />
                      <TextField
                        label="Cognome"
                        value={
                          this.state.record.cognome === null
                            ? ""
                            : this.state.record.cognome
                        }
                        name="cognome"
                        onChange={this.handleChange}
                        disabled={this.state.disableForm}
                        style={styles.textfield}
                      />
                      <TextField
                        label="Telefono"
                        defaultValue={
                          this.state.record.telefono === null
                            ? ""
                            : this.state.record.telefono
                        }
                        name="telefono"
                        onChange={this.handleChange}
                        disabled={this.state.disableForm}
                        style={styles.textfield}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Container style={styles.sectioncontainer}>
              <Typography variant="h6" style={styles.text}>
                Esercizio commerciale associato
              </Typography>
              <SelezioneEsercizio
                hasError={
                  this.state.validationErrors.esercizioId.length > 0
                    ? true
                    : false
                }
                esercizioId={this.state.record.esercizioId}
                esercizi={this.state.esercizi}
                disabled={this.state.disableForm}
                description="Selezionare l'esercizio commerciale al quale associare il nuovo responsabile sicurezza."
                onEsercizioSelected={this.handleSelectedEsercizio}
              />
            </Container>
            <Grid
              container
              justify="center"
              style={{ paddingTop: "10px", paddingBottom: "20px" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ textAlign: "right" }}
              >
                <IndietroButton isDisabled={this.state.loading} />
                <Button
                  type="button"
                  onClick={this.handleSubmit}
                  variant="contained"
                  size="medium"
                  disabled={disabledSubmitbutton}
                  style={{
                    color: disabledSubmitbutton
                      ? this.props.theme.palette.text.disabled
                      : this.props.theme.palette.text.main,
                    margin: "10px",
                    backgroundColor: disabledSubmitbutton
                      ? this.props.theme.palette.disabled.main
                      : this.props.theme.palette.primary.main,
                  }}
                >
                  Registra
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        ></ErrorDialog>
      </Container>
    );
  }
}

FormRegistraResponsabilePerConsulente.propTypes = {
  gruppoId: PropTypes.number,
  consulenteId: PropTypes.number,
  onFormModified: PropTypes.func.isRequired,
  onFormSubmitted: PropTypes.func.isRequired,
};
