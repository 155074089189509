import {
  Checkbox,
  Container,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import ReactTable from "react-table";
import ActionButton from "../../../components/ActionButton";
import OneLineTextInputComponent from "../manualecomponents/components/OneLineTextInputComponent";
import MultilineTextInputComponent from "../manualecomponents/components/MultilineTextInputComponent";
import UploadImages from "../manualecomponents/dettaglio_procedura/UploadImages";
import {
  getCategorieSchede,
  getSchedeTramiteId,
  putModificaScheda,
  uploadSchedaImage,
} from "../../../utils/api/schede_api";
import {
  getAttrezzatureWithOnlyManualeId,
  getLocaliManuale,
  getTipologiaStrumentazione,
} from "../../../utils/api/locali_e_attrezzature_api";
import { getReparti } from "../../../utils/api/reparti";
import { Edit } from "@material-ui/icons";
import ModalSelezioneAssociazioneScheda from "./ModalSelezioneAssociazioneScheda";
import ModalSelezioneOrarioScheda from "./ModalSelezioneOrarioScheda";
import IndietroButtonColorFul from "../../../components/IndietroButtonColorful";
import ErrorDialog from "../../../components/ErrorDialog";
import ImmaginiProcedureScheda from "../manualecomponents/dettaglio_procedura/ImmaginiProcedureScheda";
import SpinnerComponent from "../../../components/SpinnerComponent";
import ModalAggiuntaValori from "./ModalAggiuntaValori";
import { clearToken } from "../../../utils/storage";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
    padding: "26px 10px",
    minHeight: "90vh",
  },
  typo: {
    align: "center",
    marginTop: "2%",
    marginBottom: "2%",
  },
};

class DettaglioSchedaNuovoManuale extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      columns2: [],
      records2: [],
      schedaData: [],
      cadenzeDisponibili: [
        "GIORNALIERA",
        "SETTIMANALE",
        "MENSILE",
        "ANNUALE",
        "ALTRO",
      ],
      immaginiProcedure: [],
      records: [],
      isAssociazioneModalOpen: false,
      isValoreModalOpen: false,
      totalPages: 0,
      uploadDisabled: false,
      categorieSchede: [],
      isOrarioModalOpen: false,
      currentScheda: null,
      tipologieElementi: [],
      isListaOrariDisabled: false,
      attrezzature: [],
      reparti: [],
      pageLoading: true,
      uploadingImage: false,
      locali: [],
      tipiDiColonna: [""],
      indexToChange: 0,
      edit: false,
      errorDialogVisible: false,
      errorDialogTitle: "",
      errorDialogMessage: "",
    };
  }

  handleInvalidToken = () => {
    this.setState({
      errorDialogVisible: true,
      errorDialogMessage:
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.",
    });
    clearToken();
    const { path } = this.props.theme;
    window.setTimeout(function () {
      window.location.href = "/" + path;
    }, 4000);
  };
  openErrorDialog = (errorMessage) => {
    this.setState({
      errorDialogTitle: "Errore",
      errorDialogVisible: true,
      errorDialogMessage: errorMessage,
    });
  };
  openSuccessDialog = (successMessage) => {
    this.setState({
      errorDialogTitle: "Operazione effettuata",
      errorDialogVisible: true,
      errorDialogMessage: successMessage,
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let a = this.generateSchedaColumns();
    let b = this.generateColumnsForColumns();
    this.setState({
      columns: a,
      columns2: b,
    });
    this.getSchede();
  }

  handleSaveScheda = () => {
    let errorSaveMessage = "";
    let schedaData = [...this.state.schedaData];
    let recordsToSave = [...this.state.records];
    if (recordsToSave[0].selectedCadenza === "NoChoice") {
      schedaData[0].cadenza = null;
    } else {
      schedaData[0].cadenza = recordsToSave[0].selectedCadenza;
    }
    schedaData[0].categoria = recordsToSave[0].selectedCategoria;
    schedaData[0].reparti = recordsToSave[0].reparti;
    schedaData[0].calendarizzato = recordsToSave[0].isCalendarizzata;
    schedaData[0].cadenze = recordsToSave[0].cadenze;
    schedaData[0].periodico = recordsToSave[0].periodico;
    let arrayToUseToFill = [...this.state.reparti];
    arrayToUseToFill.map((item) => {
      if (item.repartoDefault) {
        schedaData[0].reparti.push(item.id);
      }
    });
    if (!schedaData[0].calendarizzato && schedaData[0].cadenze !== null) {
      errorSaveMessage =
        "Il campo cadenza non deve essere vuoto solo nel caso in cui la scheda è calendarizzata";
    }
    if (
      schedaData[0].calendarizzato &&
      schedaData[0].cadenza !== null &&
      schedaData[0].cadenze.length === 0
    ) {
      errorSaveMessage =
        "Una volta scelta una tipologia di cadenza, bisogna associare delle cadenze nell'apposita tabella";
    }
    if (schedaData[0].calendarizzato && schedaData[0].cadenza === null) {
      errorSaveMessage =
        "Attenzione, se la scheda è calendarizzata, non può avere come tipo di cadenza altra cadenza";
    }
    if (schedaData[0].reparti.length === 0) {
      errorSaveMessage =
        "Attenzione, è necessario associare almeno un reparto alla scheda";
    }
    if (schedaData[0].periodico === true) {
      schedaData[0].cadenze = [];
      let record = {};
      if (schedaData[0].cadenza === "GIORNALIERA") {
        record.orario = "08:00:00";
        record.giornoSettimana = null;
        record.mese = null;
        record.giornoMese = null;
        record.schedaId = null;
        record.controlloConformitaId = null;
        record.id = null;
        record.schedaId = schedaData[0].id;
      } else if (schedaData[0].cadenza === "SETTIMANALE") {
        record.orario = "08:00:00";
        record.giornoSettimana = "MONDAY";
        record.mese = null;
        record.giornoMese = null;
        record.schedaId = null;
        record.controlloConformitaId = schedaData[0].id;
        record.id = null;
        record.schedaId = schedaData[0].id;
      } else if (schedaData[0].cadenza === "MENSILE") {
        record.orario = "08:00:00";
        record.giornoSettimana = null;
        record.mese = null;
        record.giornoMese = 1;
        record.schedaId = null;
        record.controlloConformitaId = schedaData[0].id;
        record.id = null;
        record.schedaId = schedaData[0].id;
      } else if (schedaData[0].cadenza === "ANNUALE") {
        record.orario = "08:00:00";
        record.giornoSettimana = null;
        record.mese = "JANUARY";
        record.giornoMese = 1;
        record.schedaId = null;
        record.controlloConformitaId = schedaData[0].id;
        record.id = null;
        record.schedaId = schedaData[0].id;
      }
      //console.log(record);
      schedaData[0].cadenze.push(record);
    }
    let schedaColumnsData = [...this.state.records2];
    let arrayOfTitles = [];
    let arrayOfColumns = [];
    schedaColumnsData.map((item, index) => {
      if (arrayOfTitles.includes(item.titoloColonna)) {
        errorSaveMessage =
          "Attenzione, non è possibile avere due colonne con lo stesso nome";
      }
      arrayOfTitles.push(item.titoloColonna);

      if (item.selectedTipo === "testo_libero") {
        let newObject = {
          nomeColonna: item.titoloColonna,
          tipo: item.selectedTipo,
          opzioni: null,
        };
        arrayOfColumns.push(newObject);
      } else {
        let newObject = {
          nomeColonna: item.titoloColonna,
          tipo: item.selectedTipo,
          opzioni: item.valoriAggiunti,
        };
        arrayOfColumns.push(newObject);
      }
    });
    schedaData[0].colonne = JSON.stringify(arrayOfTitles);
    schedaData[0].definizioniColonne = JSON.stringify(arrayOfColumns);
    putModificaScheda(schedaData[0])
      .then((response) => {
        this.getSchede();
        this.openSuccessDialog("Elemento salvato con successo");
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          if (errorSaveMessage === "") this.openErrorDialog(error.message);
          else this.openErrorDialog(errorSaveMessage);
        }
      });
  };

  handleSaveColumn = (data) => {
    let arrayToSave = [...this.state.records2];
    arrayToSave[this.state.indexToChange].valoriAggiunti = data;
    this.setState({
      isValoreModalOpen: false,
    });
  };

  populateSchedaTable = () => {
    let arrayOfSchede = [...this.state.schedaData];
    if (arrayOfSchede.length > 0) {
      let records = [];
      let cadenzaToAssign = arrayOfSchede[0].cadenza;
      if (arrayOfSchede[0].cadenza === null) {
        cadenzaToAssign = "NoChoice";
        this.setState({
          isListaOrariDisabled: true,
        });
      }
      let newRecord = {
        selectedCategoria: arrayOfSchede[0].categoria,
        isCalendarizzata: arrayOfSchede[0].calendarizzato,
        selectedCadenza: cadenzaToAssign,
        cadenze: arrayOfSchede[0].cadenze,
        reparti: arrayOfSchede[0].reparti,
        periodico: arrayOfSchede[0].periodico,
      };
      records.push(newRecord);
      this.setState({
        records: records,
        pageLoading: false,
      });

      let parsedData = JSON.parse(arrayOfSchede[0].definizioniColonne);
      let arrayOfRecords = [];
      parsedData.map((item) => {
        let newRecord2 = {
          selectedTipo: item.tipo,
          titoloColonna: item.nomeColonna,
          valoriAggiunti: item.opzioni,
        };
        arrayOfRecords.push(newRecord2);
      });
      this.setState({
        records2: arrayOfRecords,
      });
    }
  };
  openAssociazioneModal = (indexToChange) => {
    this.setState({
      isAssociazioneModalOpen: true,
      indexToChange: indexToChange,
    });
  };
  closeAssociazioneModal = () => {
    this.setState({
      isAssociazioneModalOpen: false,
    });
  };
  closeOrarioModal = () => {
    this.setState({
      isOrarioModalOpen: false,
    });
  };
  openOrarioModal = (cadenza, scheda, index) => {
    this.setState(
      {
        isOrarioModalOpen: true,
        orarioMode: "Normal",
        currentScheda: scheda,
        cadenzaPassata: cadenza,
        indexToChange: index,
      },
      () => {}
    );
  };
  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };
  openNewValueModal = (index) => {
    this.setState({
      isValoreModalOpen: true,
      indexToChange: index,
    });
  };
  closeNewValueModal = (index) => {
    this.setState({
      isValoreModalOpen: false,
      indexToChange: index,
    });
  };
  setCadenzeToControllo = (cadenze) => {
    var arrayOfControlli = [...this.state.records];
    var schedaDataToChange = [...this.state.schedaData];
    schedaDataToChange[0].cadenze = cadenze;
    arrayOfControlli[this.state.indexToChange].cadenze = cadenze;
    this.setState({
      records: arrayOfControlli,
      schedaData: schedaDataToChange,
      isOrarioModalOpen: false,
    });
  };

  getSchede = () => {
    getSchedeTramiteId(this.props.id).then((response) => {
      let arrayOfData = [];
      arrayOfData.push(response);
      this.setState({
        schedaData: arrayOfData,
        edit: false,
        immaginiProcedure: response.immagini,
      });
      getCategorieSchede()
        .then((response) => {
          this.setState({
            categorieSchede: response,
          });
        })
        .then((response) => {
          getTipologiaStrumentazione(this.props.idManuale, 0, 1000, "nome,ASC")
            .then((response) => {
              let tipologieElementi = response;
              this.setState({
                tipologieElementi: tipologieElementi,
              });
            })
            .then((response) => {
              getAttrezzatureWithOnlyManualeId(
                this.props.idManuale,
                0,
                10000,
                "indiceOrdinamento,ASC"
              )
                .then((response) => {
                  this.setState({
                    attrezzature: response,
                  });
                })
                .then((response) => {
                  getLocaliManuale(
                    this.props.idManuale,
                    0,
                    1000,
                    "nome,ASC"
                  ).then((response) => {
                    this.setState(
                      {
                        locali: response,
                      },
                      () => {}
                    );
                    getReparti(this.props.idManuale, 0, 1000, "nome,asc").then(
                      (response) => {
                        this.setState({
                          reparti: response,
                        });
                        this.populateSchedaTable();
                      }
                    );
                  });
                });
            });
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.openErrorDialog(error.message);
          }
        });
    });
  };

  handleNewRow = () => {
    var arrayOfRecord2 = [...this.state.records2];
    if (arrayOfRecord2.length < 20) {
      let newRecord2 = {
        selectedTipo: "testo_libero",
        titoloColonna: "",
        valoriAggiunti: [],
      };
      arrayOfRecord2.push(newRecord2);
      this.setState(
        {
          records2: arrayOfRecord2,
        },
        () => {}
      );
    } else {
      alert("Attenzione, non si possono aggiungere più di 20 colonne");
    }
  };
  deleteRow = (cellInfo) => {
    let arrayOfRecord2 = [...this.state.records2];
    arrayOfRecord2.splice(cellInfo._index, 1);
    this.setState({
      records2: arrayOfRecord2,
    });
  };

  addAttrezzatureOrRepartiToControllo = (recordsToAdd, mode) => {
    let arrayOfRepartiToAssociate = [];
    recordsToAdd.map((item, index) => {
      if (item.checked) {
        arrayOfRepartiToAssociate.push(item.idReparto);
      }
    });
    let array = [...this.state.records];
    array[this.state.indexToChange].reparti = arrayOfRepartiToAssociate;
    this.setState({
      isAssociazioneModalOpen: false,
    });
  };
  uploadImage = (file, event) => {
    this.setState({
      uploadingImage: true,
    });
    uploadSchedaImage(file)
      .then((result) => {
        let id = result;
        this.confirmImageRegistration(id);
      })
      .catch((error) => {
        this.setState({
          loading: false,
          uploadingImage: false,
        });
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };
  addImage = (id, index) => {
    let image = {
      id: id,
      titolo: null,
      note: null,
      posizione: index,
    };
    return image;
  };
  confirmImageRegistration = (id) => {
    let procedura = [...this.state.schedaData];
    let immaginiProcedure = [...this.state.immaginiProcedure];
    immaginiProcedure.push(this.addImage(id, immaginiProcedure.length));
    procedura[0].immagini = immaginiProcedure;
    let uploadDisabled = false;
    this.setState(
      {
        immaginiProcedure: immaginiProcedure,
        schedaData: procedura,
        uploadDisabled,
        uploadingImage: false,
        edit: true,
      },
      () => {}
    );
  };

  deleteImage = (id, clickEvent) => {
    let immaginiProcedure = this.state.immaginiProcedure;
    let newarray = immaginiProcedure.filter((item) => item.id !== id);
    let procedura = [...this.state.schedaData];
    procedura[0].immagini = newarray;
    newarray.forEach((a, index) => (a.posizione = index));
    this.setState({
      immaginiProcedure: newarray,
      schedaData: procedura,
      uploadDisabled: false,
      changesNotSaved: true,
      disableSubmitButton: false,
      notSaved: true,
    });
  };
  changeTitolo = (value, index) => {
    let immaginiProcedure = this.state.immaginiProcedure;
    immaginiProcedure[index].titolo = value;

    this.setState({
      immaginiProcedure,
      notSaved: true,
    });
  };
  changeNote = (value, index) => {
    let immaginiProcedure = this.state.immaginiProcedure;
    immaginiProcedure[index].note = value;

    this.setState({
      immaginiProcedure,
      notSaved: true,
    });
  };

  moveComponente = (fromIndex, toIndex) => {
    let immaginiProcedure = this.state.immaginiProcedure;
    var element = immaginiProcedure[fromIndex];
    immaginiProcedure.splice(fromIndex, 1);
    immaginiProcedure.splice(toIndex, 0, element);
    immaginiProcedure.forEach((a, index) => (a.posizione = index));
    this.setState({
      immaginiProcedure,
      notSaved: true,
    });
  };
  handleChangedTitolo = (event) => {
    const value = event.target.value;
    let procedura = [...this.state.schedaData];
    procedura[0].titolo = value;
    this.setState({
      SchedaData: procedura,
      notSaved: true,
    });
  };
  handleChangedDescrizione = (event) => {
    const value = event.target.value;
    let procedura = [...this.state.schedaData];
    procedura[0].descrizione = value;
    this.setState({
      schedaData: procedura,
      notSaved: true,
    });
  };

  generateSchedaColumns = () => {
    return [
      {
        Header: "Categoria Scheda",
        id: "CategoriaScheda",
        width: 320,
        accessor: (d) => d.selectedCategoria,
        Cell: (props) => (
          <Select
            style={{ marginTop: 10, width: "100%" }}
            onClick={(event) => {
              let arrayOfRecords = [...this.state.records];
              arrayOfRecords[0].selectedCategoria = event.target.value;
              this.setState({
                records: arrayOfRecords,
                edit: true,
              });
            }}
            value={props.row.CategoriaScheda}
          >
            {this.state.categorieSchede.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        ),
      },
      {
        Header: "Scheda Calendarizzata",
        id: "schedaCalendarizzata",
        width: 200,
        accessor: (d) => d.isCalendarizzata,
        Cell: (props) => (
          <Select
            onClick={(event) => {
              let arrayOfRecords = [...this.state.records];
              arrayOfRecords[0].isCalendarizzata = event.target.value;
              //console.log(event.target.value);
              if (event.target.value === true) {
                arrayOfRecords[0].selectedCadenza = "GIORNALIERA";
                arrayOfRecords[0].cadenze = [];
                this.setState({
                  isListaOrariDisabled: false,
                });
              } else {
                arrayOfRecords[0].selectedCadenza = "NoChoice";
                //console.log(arrayOfRecords[0]);
                arrayOfRecords[0].cadenze = [];
                arrayOfRecords[0].periodico = false;
                this.setState({
                  isListaOrariDisabled: true,
                });
              }
              this.setState({
                records: arrayOfRecords,
                edit: true,
              });
            }}
            value={props.row.schedaCalendarizzata}
            style={{ marginTop: 10, width: "100%" }}
          >
            <MenuItem key={1} value={true}>
              Si
            </MenuItem>
            <MenuItem key={2} value={false}>
              No
            </MenuItem>
          </Select>
        ),
      },
      {
        Header: "Periodica",
        id: "Periodica",
        width: 120,
        accessor: (d) => d.periodico,
        Cell: (props) => (
          <Checkbox
            disabled={!props.row.schedaCalendarizzata}
            value={props.row.Periodica}
            checked={props.row.Periodica}
            onClick={() => {
              let records = [...this.state.records];
              records[props.row._index].periodico =
                !records[props.row._index].periodico;
              if (records[props.row._index].periodico === true) {
                this.setState({
                  isListaOrariDisabled: true,
                });
              }
              this.setState({
                records: records,
                edit: true,
              });
              //console.log(records[props.row._index]);
            }}
          ></Checkbox>
        ),
      },
      {
        Header: "Cadenza",
        id: "Cadenza",
        width: 320,
        accessor: (d) => d.selectedCadenza,
        Cell: (props) => (
          <Select
            style={{ marginTop: 10, width: "100%" }}
            onClick={(event) => {
              let arrayOfRecords = [...this.state.records];
              arrayOfRecords[0].selectedCadenza = event.target.value;
              let dataToUpdate = [...this.state.schedaData];
              dataToUpdate[0].cadenze = [];
              arrayOfRecords[0].cadenze = [];
              if (
                event.target.value === "NoChoice" ||
                event.target.value === 0
              ) {
                this.setState({
                  isListaOrariDisabled: true,
                });
              } else {
                this.setState({
                  isListaOrariDisabled: false,
                });
              }
              this.setState({
                records: arrayOfRecords,
                schedaData: dataToUpdate,
                edit: true,
              });
            }}
            value={props.row.Cadenza}
          >
            {this.state.cadenzeDisponibili.map((item, index) => {
              if (props.row.schedaCalendarizzata === true) {
                if (item !== "ALTRO")
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
              } else {
                if (item === "ALTRO") {
                  return (
                    <MenuItem key={index} value={"NoChoice"}>
                      {item}
                    </MenuItem>
                  );
                }
              }
            })}
          </Select>
        ),
      },
      {
        Header: "Lista Orari",
        id: "ListaOrari",
        width: 120,
        accessor: (d) => d,
        Cell: (props) => (
          <IconButton
            disabled={this.state.isListaOrariDisabled}
            onClick={() =>
              this.openOrarioModal(
                props.row.Cadenza,
                this.state.schedaData,
                props.row._index
              )
            }
            style={{ marginTop: 10 }}
            size="small"
          >
            <Edit></Edit>
          </IconButton>
        ),
      },
      {
        Header: "Associazioni",
        id: "Associazioni",
        width: 120,
        accessor: (d) => d,
        Cell: (props) => (
          <IconButton
            onClick={() => this.openAssociazioneModal(props.row._index)}
            style={{ marginTop: 10 }}
            size="small"
          >
            <Edit></Edit>
          </IconButton>
        ),
      },
    ];
  };

  generateColumnsForColumns = () => {
    return [
      {
        Header: "Tipo di Colonna",
        id: "tipoColonna",
        width: 300,
        accessor: (d) => d,
        Cell: (props) => (
          <Select
            value={props.row.tipoColonna.selectedTipo}
            onChange={(event) => {
              var recordsToChange = [...this.state.records2];
              recordsToChange[props.row._index].selectedTipo =
                event.target.value;
              this.setState({
                records2: recordsToChange,
              });
            }}
            style={{ marginTop: 10, width: "100%" }}
          >
            <MenuItem value="testo_libero">Testo libero</MenuItem>
            <MenuItem value="selezione">Con Valori</MenuItem>
          </Select>
        ),
      },
      {
        Header: "Aggiungi Valore",
        id: "aggiungiValore",
        width: 300,
        accessor: (d) => d,
        Cell: (props) => (
          <IconButton
            disabled={!(props.row.tipoColonna.selectedTipo === "selezione")}
            size="small"
            style={{ marginTop: 10 }}
            onClick={() => this.openNewValueModal(props.row._index)}
          >
            <Edit></Edit>
          </IconButton>
        ),
      },
      {
        Header: "Titolo Colonna",
        id: "titoloColonna",
        width: 480,
        accessor: (d) => d.titoloColonna,
        Cell: (props) => (
          <TextField
            inputProps={{ min: 0, style: { textAlign: "center" } }}
            onInput={(event) => {
              var colonneRecords = [...this.state.records2];
              colonneRecords[props.row._index].titoloColonna =
                event.target.value;
              const caret = event.target.selectionStart;
              const element = event.target;
              window.requestAnimationFrame(() => {
                element.selectionStart = caret;
                element.selectionEnd = caret;
              });
              this.setState({
                records2: colonneRecords,
              });
            }}
            value={props.row.titoloColonna}
            style={{ marginTop: 10, width: "80%" }}
          ></TextField>
        ),
      },
      {
        Header: "Elimina",
        id: "Elimina",
        width: 120,
        accessor: (d) => d,
        Cell: (props) => (
          <ActionButton
            label="Elimina"
            onClick={() => this.deleteRow(props.row)}
          ></ActionButton>
        ),
      },
    ];
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        {this.state.pageLoading ? (
          <SpinnerComponent size={32}></SpinnerComponent>
        ) : (
          <Container>
            <OneLineTextInputComponent
              label={"Titolo:"}
              fieldname={"titolo"}
              fieldvalue={this.state.schedaData[0].titolo}
              onChangeEvent={this.handleChangedTitolo}
            />
            <MultilineTextInputComponent
              label={"Descrizione procedura"}
              fieldname={"descrizione"}
              fieldvalue={this.state.schedaData[0].descrizione}
              onChangeEvent={this.handleChangedDescrizione}
            />
            {/*
                                    TO BE REMOVED: for now this component is just hidden,
                                    it could be removed with next updates.

                                    <PuntiDiControlloTable
                                        puntiDiControllo={this.state.procedura.punticontrollo}
                                        onAggiungiButtonClicked={this.openPuntiControlloSelectionPage}
                                        onRimuoviButtonClicked={this.removePuntoDiControllo}
                                    /> */}
            <UploadImages
              disabled={this.state.uploadDisabled}
              loading={this.state.uploadingImage}
              onUpload={this.uploadImage}
            />
            <ImmaginiProcedureScheda
              loading={this.state.uploadingImage}
              immaginiProcedure={this.state.immaginiProcedure}
              onRimuoviImage={this.deleteImage}
              onMove={this.moveComponente}
              onChangeTitolo={this.changeTitolo}
              onChangeNote={this.changeNote}
              //submitButtonDisabled={this.state.disableSubmitButton}
            />
          </Container>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {/*(!this.state.currentTabValue.obbligatorio) ? (<Typography style={styles.typo} variant="h4">
              {this.state.currentTabValue.label}
            </Typography>) : (
              <Typography style={styles.typo} variant="h4">
              {this.state.modalTitle}
              </Typography>
            )*/}
          <Typography style={styles.typo} variant="h4">
            Scheda
          </Typography>
          <ReactTable
            filterable={false}
            resizable={true}
            enableEditing={true}
            showPageSizeOptions={false}
            showPageJump={true}
            defaultPageSize={1}
            style={{
              height: 130,
              alignSelf: "center",
            }}
            pages={this.state.totalPages}
            data={this.state.records}
            columns={this.state.columns}
            /*manual 
                onFetchData={ (state, instance) => { 
                  let a = 0;
                  getTotaleLocali(this.props.idManuale).then(result => {
                    a = result;
                        console.log(a);
                        this.setState({
                          totalPages:Math.ceil(a/state.pageSize),
                    })
                  }).then(result => {
                    getLocaliManuale(this.props.idManuale,state.page,state.pageSize,"id").then(result => {
                      this.setState({
                          records:result,
                      })
                    })
                  }).catch(error => {
                    if (error.status === 403) {
                      this.props.handleInvalidToken();
                      } else {
                      this.props.handleError(true, error.message);
                      }
                  })
                }}*/
            previousText="Precedente"
            nextText="Successivo"
            noDataText="Nessun record"
            pageText="Pagina"
            ofText="di"
            rowsText="righe"
            pageJumpText="Vai a pagina"
            rowsSelectorText="righe per pagina"
          />
          <Typography style={styles.typo} variant="h4"></Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <ActionButton
              label="Aggiungi"
              disabled={false}
              grayVersion={false}
              onClick={() => {
                this.handleNewRow();
              }}
            ></ActionButton>
          </div>
          <ReactTable
            filterable={false}
            resizable={true}
            enableEditing={true}
            showPageSizeOptions={false}
            showPageJump={true}
            defaultPageSize={20}
            style={{
              height: 400,
              alignSelf: "center",
            }}
            pages={this.state.totalPages}
            data={this.state.records2}
            columns={this.state.columns2}
            /*manual 
                onFetchData={ (state, instance) => { 
                  let a = 0;
                  getTotaleLocali(this.props.idManuale).then(result => {
                    a = result;
                        console.log(a);
                        this.setState({
                          totalPages:Math.ceil(a/state.pageSize),
                    })
                  }).then(result => {
                    getLocaliManuale(this.props.idManuale,state.page,state.pageSize,"id").then(result => {
                      this.setState({
                          records:result,
                      })
                    })
                  }).catch(error => {
                    if (error.status === 403) {
                      this.props.handleInvalidToken();
                      } else {
                      this.props.handleError(true, error.message);
                      }
                  })
                }}*/
            previousText="Precedente"
            nextText="Successivo"
            noDataText="Nessun record"
            pageText="Pagina"
            ofText="di"
            rowsText="righe"
            pageJumpText="Vai a pagina"
            rowsSelectorText="righe per pagina"
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <IndietroButtonColorFul
            secondaryButton
            alert={this.state.edit}
            isDisabled={false}
          ></IndietroButtonColorFul>
          <ActionButton
            label="Salva"
            disabled={false}
            grayVersion={false}
            onClick={this.handleSaveScheda}
          ></ActionButton>
        </div>
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
          title={this.state.errorDialogTitle}
        />
        {this.state.isAssociazioneModalOpen && (
          <ModalSelezioneAssociazioneScheda
            open={this.state.isAssociazioneModalOpen}
            onClose={this.closeAssociazioneModal}
            mode={this.state.associazioneMode}
            reparti={this.state.reparti}
            schedaData={this.state.schedaData}
            saveValues={this.addAttrezzatureOrRepartiToControllo}
            indexToChange={this.state.indexToChange}
          ></ModalSelezioneAssociazioneScheda>
        )}
        {this.state.isOrarioModalOpen && (
          <ModalSelezioneOrarioScheda
            theme={this.props.theme}
            open={this.state.isOrarioModalOpen}
            onClose={this.closeOrarioModal}
            mode={this.state.orarioMode}
            addCadenza={this.handleCadenza}
            cadenza={this.state.cadenzaPassata}
            cadenze={this.state.schedaData[0].cadenze}
            indexToUpdate={this.state.indexToChange}
            setCadenzeToControllo={this.setCadenzeToControllo}
            scheda={this.state.currentScheda}
            categorieSchede={this.state.categorieSchede}
            schedaId={this.state.schedaData[0].id}
          ></ModalSelezioneOrarioScheda>
        )}
        {this.state.isValoreModalOpen && (
          <ModalAggiuntaValori
            open={this.state.isValoreModalOpen}
            onClose={this.closeNewValueModal}
            columnData={this.state.records2}
            selectedIndex={this.state.indexToChange}
            saveColumnData={this.handleSaveColumn}
          ></ModalAggiuntaValori>
        )}
      </div>
    );
  }
}
export default DettaglioSchedaNuovoManuale;
