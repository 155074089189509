import { makeStyles, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

const useStyles = makeStyles((theme) => ({
  paginationContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "stretch",
    flexWrap: "wrap",
    padding: 3,
    boxShadow: "0 0 15px 0 rgba(0,0,0,0.1)",
    borderTop: "2px solid rgba(0,0,0,0.1)",
  },
  counter: {
    display: "flex",
    margin: "3px 10px",
    whiteSpace: "nowrap",
    textAlign: "center",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  center: {
    flex: "1.5 1",
    textAlign: "center",
    marginBottom: 0,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-around",
  },
  inputWrapper: {
    border: "1px solid rgba(0,0,0,0.1)",
    background: theme.palette.background.paper,
    padding: "5px 7px",
    fontSize: "inherit",
    borderRadius: 3,
    fontWeight: "normal",
    outlineWidth: 0,
  },
  buttonBase: {
    appearance: "none",
    display: "block",
    width: "100%",
    height: "100%",
    border: 0,
    borderRadius: "3px",
    padding: "6px",
    fontSize: "1em",
    color: "white",
    transition: "all .1s ease",
    cursor: "pointer",
    outlineWidth: 0,
  },
}));

const CustomPaginationComponent = ({ page, pages, onPageChange }) => {
  const themeToUse = useTheme();
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(page + 1);
  const [isHover1, setIsHover1] = useState(false);
  const [isHover2, setIsHover2] = useState(false);

  const handleMouseEnter1 = () => {
    setIsHover1(true);
  };
  const handleMouseLeave1 = () => {
    setIsHover1(false);
  };

  const handleMouseEnter2 = () => {
    setIsHover2(true);
  };
  const handleMouseLeave2 = () => {
    setIsHover2(false);
  };

  useEffect(() => {
    if (pages === 0) {
      setInputValue(page);
    } else {
      setInputValue(page + 1);
    }
  }, [page, pages]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      let newPage = parseInt(inputValue, 10);
      if (!isNaN(newPage) && newPage >= 1 && newPage <= pages) {
        onPageChange(newPage - 1);
      }
      if (newPage > pages) {
        onPageChange(pages - 1);
        setInputValue(pages);
      }

      if (newPage < 1) {
        onPageChange(0);
        setInputValue(1);
      }
    }
  };

  const handleChange = (e) => {
    let input = e.target.valueAsNumber;
    if (Number.isNaN(input)) {
    } else if (input === 0) {
      setInputValue(1);
    } else {
      if (e.target.value > pages) {
        setInputValue(pages);
      } else {
        setInputValue(e.target.value);
      }
    }
  };

  const handleOnBlurChange = (e) => {
    if (e.target.value > pages) {
      setInputValue(pages);
    } else {
      setInputValue(e.target.value);
      onPageChange(e.target.value - 1);
    }
  };

  const handleNextClick = () => {
    const nextPage = page + 1;
    if (nextPage < pages) {
      setInputValue(nextPage + 1);
      onPageChange(nextPage);
    }
  };

  const handlePreviousClick = () => {
    const previousPage = page - 1;
    if (previousPage >= 0) {
      setInputValue(previousPage + 1);
      onPageChange(previousPage);
    }
  };

  function shadeColor(color, percent) {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    R = Math.round(R);
    G = Math.round(G);
    B = Math.round(B);

    var RR =
      R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
    var GG =
      G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
    var BB =
      B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

    return "#" + RR + GG + BB;
  }

  return (
    <div className={classes.paginationContainer}>
      <div
        style={{
          flex: "1 1",
          textAlign: "center",
        }}
      >
        <button
          className={classes.buttonBase}
          style={{
            background:
              inputValue > 1
                ? isHover1
                  ? shadeColor(themeToUse.palette.secondary.main, -40)
                  : themeToUse.palette.secondary.main
                : themeToUse.palette.action.disabledBackground,
            cursor: inputValue > 1 ? "pointer" : "default",
            /*pointerEvents: inputValue > 1
                ?  "pointer" : "none"*/
          }}
          onMouseEnter={handleMouseEnter1}
          onMouseLeave={handleMouseLeave1}
          onClick={handlePreviousClick}
        >
          Precedente
        </button>
      </div>
      <div className={classes.center}>
        <span className={classes.counter}>
          <div>Pagina</div>
          <div className={classes.inputWrapper}>
            <input
              id="myNumberInput"
              type="number"
              value={inputValue}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              style={{ width: "70px", textAlign: "center", fontSize: "1rem" }}
              min={1}
              onBlur={handleOnBlurChange}
            />
          </div>
          <div>di {pages}</div>
        </span>
      </div>
      <div
        style={{
          flex: "1 1",
          textAlign: "center",
        }}
      >
        <button
          onClick={handleNextClick}
          onMouseEnter={handleMouseEnter2}
          onMouseLeave={handleMouseLeave2}
          className={classes.buttonBase}
          style={{
            background:
              inputValue < pages
                ? isHover2
                  ? shadeColor(themeToUse.palette.primary.main, -40)
                  : themeToUse.palette.primary.main
                : themeToUse.palette.action.disabledBackground,
            cursor: inputValue < pages ? "pointer" : "default",
            /*pointerEvents: inputValue < pages
                ?  "pointer" : "none"*/
          }}
        >
          Successivo
        </button>
      </div>
    </div>
  );
};

const BasicTable = ({
  data,
  columns,
  defaultPageSize,
  onPageChange,
  pages,
  style,
  page,
}) => {
  return (
    <div>
      <ReactTable
        data={data}
        columns={columns}
        sortable={false}
        filterable
        resizable
        defaultPageSize={defaultPageSize}
        pages={pages}
        manual
        style={style}
        onPageChange={onPageChange}
        page={page}
        getTrProps={(state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo !== undefined &&
                rowInfo.row.stato === "IN ATTESA PAGAMENTO"
                  ? "#fef8a0"
                  : "white",
            },
          };
        }}
        defaultFilterMethod={(filter, row, _column) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined
            ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
            : true;
        }}
        showPageSizeOptions={false}
        PaginationComponent={CustomPaginationComponent}
        noDataText="Nessuna riga trovata"
      />
    </div>
  );
};

export default BasicTable;
