import React, { Component, Fragment } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import validator from "validator";
import SpinnerComponent from "../../../components/SpinnerComponent";
import StoricoAbbonamentiEsercizio from "./StoricoAbbonamentiEsercizio";
import ErrorDialog from "../../../components/ErrorDialog";
import IndietroButton from "../../../components/IndietroButton";
import GiorniChiusuraEsercizio from "./GiorniChiusuraEsercizio";
import FormPagamento from "./FormPagamento.js";
import { clearToken } from "../../../utils/storage";
import { getAbbonamenti } from "../../../utils/api/abbonamenti_api";
import {
  getEsercizioCommerciale,
  updateEsercizioCommerciale,
  sendEmailToConfirmRegistration,
  getFunzionalitaApp,
  updateFunzionalitaApp,
} from "../../../utils/api/esercizi_commerciali_api";
import TwoActionsAlertDialog from "../../../components/TwoActionsAlertDialog.js";
import { getGruppo } from "../../../utils/api/gruppi_api.js";
import ChipSection from "./ChipSection.jsx";
import FormDettagli from "./FormDettagli.jsx";

const styles = (theme) => ({
  mainContainer: {
    textAlign: "center",
    color: "black",
    padding: "26px",
  },
  invioMailButton: {
    color: theme.palette.text.main,
    margin: "10px",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
});

class DettaglioEsercizioCommerciale extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false; // Flag per controllare se il componente è montato
    this.state = {
      record: {
        esercizio: {
          id: null,
          consulenteId: null,
          nome: null,
          ragioneSociale: null,
          partitaIva: null,
          codiceAteco: null,
          telefono: null,
          nomeGestore: null,
          cognomeGestore: null,
          emailGestore: null,
          telefonoGestore: null,
          indirizzoSede: null,
          numeroSede: null,
          codiceSdi: null,
          pec: null,
          cittaSede: null,
          provinciaSede: null,
          gruppoId: null,
        },
        storicoPricingDTO: {
          unitPrice: null,
          imponibile: null,
          moltiplicatore: null,
          anniValidita: null,
          iva: null,
          totalAmount: null,
          modalita: null,
          esercizioCommercialeId: null,
          consulenteId: null,
          dataAggiornamento: null,
        },
        giorniChiusura: [],
      },
      abbonamenti: [],
      originalRecord: null,
      validationErrors: {
        nome: "",
        ragioneSociale: "",
        emailGestore: "",
      },
      tipologiaPagamento: null,
      costoUnitario: null,
      funzionalitaApp: {},
      errorDialogVisible: false,
      errorDialogMessage: "",
      loading: false,
      notfoundRecord: false,
      saveButtonEnabled: false,
      saveMessageVisible: false,
      twoActionsAlertVisible: false,
      edit: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchRecord(this.props.esercizioId);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleInvalidToken = () => {
    this.setState({
      errorDialogVisible: true,
      errorDialogMessage:
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.",
    });
    clearToken();
    const { path } = this.props.theme;
    window.setTimeout(function () {
      window.location.href = "/" + path;
    }, 4000);
  };

  fetchRecord = (esercizioId) => {
    this.setState({
      loading: true,
    });
    getEsercizioCommerciale(esercizioId)
      .then((result) => {
        if (this._isMounted) {
          if (this.props.consulenteId !== null) {
            if (result.storicoPricingDTO === null) {
              //result.storicoPricingDTO.consulenteId = null;
            } else {
              result.storicoPricingDTO.consulenteId = this.props.consulenteId;
            }
          } else {
            result.storicoPricingDTO.consulenteId = null;
          }
          this.setState({
            notfoundRecord: false,
            record: {
              ...result,
            },
            originalRecord: JSON.parse(JSON.stringify(result)),
          });
          this.fetchAbbonamenti(esercizioId);
          this.fetchFunzionalitaApp();
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
              errorDialogVisible: true,
              errorDialogMessage: error.message,
              notfoundRecord: true,
            });
          }
        }
      });
  };

  fetchAbbonamenti = (esercizioId) => {
    getAbbonamenti(null, null, esercizioId, 0, 1000, "id")
      .then((result) => {
        this.setState(
          {
            //loading: false,
            abbonamenti: result,
          },
          () => {
            this.fetchGruppo(this.state.record.esercizio.gruppoId);
          }
        );
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        }
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: error.message,
        });
      });
  };

  fetchAbbonamentiAfterSave = () => {
    getAbbonamenti(null, null, this.state.record.esercizio.id, 0, 1000, "id")
      .then((result) => {
        this.setState(
          {
            //loading: false,
            abbonamenti: result,
            edit: false,
          },
          () => {
            this.fetchGruppo(this.state.record.esercizio.gruppoId);
          }
        );
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        }
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: error.message,
        });
      });
  };

  fetchGruppo = (gruppoId) => {
    getGruppo(gruppoId)
      .then((response) => {
        this.setState({
          loading: false,
          tipologiaPagamento: response.tipologiaPagamento,
          costoUnitario: response.costoUnitario,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        }
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: error.message,
        });
      });
  };

  fetchFunzionalitaApp = () => {
    const isConsulente =
      this.props.consulenteId !== null && this.props.superconsulente === false;

    if (isConsulente) return;

    getFunzionalitaApp(this.props.esercizioId)
      .then((response) => {
        this.setState({
          funzionalitaApp: response,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        }
        this.setState({
          errorDialogVisible: true,
          errorDialogMessage: error.message,
        });
      });
  };

  validateForm = () => {
    let data = this.state.record;
    Object.keys(data).forEach((key) => {
      this.validateField(key, data[key]);
    });
    //Return false if there are validation errors:
    let valid = true;
    let validationErrors = this.state.validationErrors;
    Object.values(validationErrors).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  validateField = (key, value) => {
    let validationErrors = this.state.validationErrors;
    switch (key) {
      case "nome":
        if (value === null || value.length === 0) {
          validationErrors.nome = "Inserire nome";
        } else {
          validationErrors.nome = "";
        }
        break;
      case "ragioneSociale":
        if (value === null || value.length === 0) {
          validationErrors.ragioneSociale = "Inserire ragione sociale";
        } else {
          validationErrors.ragioneSociale = "";
        }
        break;
      case "emailGestore":
        if (value === null || value.length === 0) {
          validationErrors.emailGestore =
            "Inserire indirizzo email del titolare";
        } else {
          if (validator.isEmail(value)) {
            validationErrors.emailGestore = "";
          } else {
            validationErrors.emailGestore =
              "L'indirizzo email inserito non è valido";
          }
        }
        break;
      default:
        break;
    }
    this.setState({
      validationErrors,
    });
  };

  handleChange = (event) => {
    if (!this.state.saveButtonEnabled) {
      this.setState({
        saveButtonEnabled: true,
      });
    }
    if (this.state.saveMessageVisible) {
      this.setState({
        saveMessageVisible: false,
      });
    }
    let name = event.target.name;
    let value = event.target.value;
    let validationErrors = this.state.validationErrors;
    let data = this.state.record;
    let esercizio = this.state.record.esercizio;

    esercizio[name] = value;
    //Reset validations :
    if (name === "nome") {
      validationErrors[name] = "";
    }
    this.setState({
      data,
      validationErrors,
    });
  };

  handleChangeAbbonamento = (value, fielName) => {
    if (!this.state.saveButtonEnabled) {
      this.setState({
        saveButtonEnabled: true,
        edit: true,
      });
    }
    let data = this.state.record;
    data.storicoPricingDTO[fielName] = value;
    this.setState({
      record: data,
    });
  };

  onChipSave = (updatedData) => {
    this.setState({
      funzionalitaApp: updatedData,
      loading: true,
    });
    updateFunzionalitaApp(updatedData)
      .then((_response) => console.log("Salvataggio avvenuto con successo."))
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        }
        this.setState({
          errorDialogVisible: true,
          errorDialogMessage: error.message,
        });
      })
      .finally(() => this.setState({ loading: false }));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let isFormValid = this.validateForm();
    if (
      this.state.tipologiaPagamento === "BIORSAF" ||
      this.props.consulenteId === null
    ) {
      let calculatedNetto =
        Number.parseInt(this.state.costoUnitario) *
        Number.parseInt(this.state.record.storicoPricingDTO.moltiplicatore) *
        Number.parseInt(this.state.record.storicoPricingDTO.anniValidita);
      if (calculatedNetto !== this.state.record.storicoPricingDTO.imponibile) {
        this.setState({
          saveMessageVisible: false,
          twoActionsAlertVisible: true,
        });
      } else {
        if (isFormValid) {
          this.setState({
            loading: true,
          });
          updateEsercizioCommerciale(this.state.record)
            .then((result) => {
              this.setState(
                {
                  loading: false,
                  saveButtonEnabled: false,
                  saveMessageVisible: true,
                },
                () => {
                  this.fetchAbbonamentiAfterSave();
                }
              );
            })
            .catch((error) => {
              if (error.status === 403) {
                this.handleInvalidToken();
              } else {
                this.setState({
                  loading: false,
                  errorDialogVisible: true,
                  errorDialogMessage: error.message,
                });
              }
            });
        }
      }
    } else {
      if (isFormValid) {
        this.setState({
          loading: true,
        });
        updateEsercizioCommerciale(this.state.record)
          .then((result) => {
            this.setState(
              {
                loading: false,
                saveButtonEnabled: false,
                saveMessageVisible: true,
              },
              () => {
                this.fetchAbbonamentiAfterSave();
              }
            );
          })
          .catch((error) => {
            if (error.status === 403) {
              this.handleInvalidToken();
            } else {
              this.setState({
                loading: false,
                errorDialogVisible: true,
                errorDialogMessage: error.message,
              });
            }
          });
      }
    } // no-op if data is not valid
  };

  saveUpdates = () => {
    let isFormValid = this.validateForm();
    if (isFormValid) {
      this.setState({
        loading: true,
      });
      let jsonToSend = JSON.parse(JSON.stringify(this.state.record));
      jsonToSend.storicoPricingDTO.dataAggiornamento = null;
      jsonToSend.storicoPricingDTO.imponibile =
        Number.parseInt(this.state.costoUnitario) *
        Number.parseInt(this.state.record.storicoPricingDTO.moltiplicatore) *
        Number.parseInt(this.state.record.storicoPricingDTO.anniValidita);
      updateEsercizioCommerciale(jsonToSend)
        .then((result) => {
          this.setState(
            {
              loading: false,
              saveButtonEnabled: false,
              saveMessageVisible: true,
              twoActionsAlertVisible: false,
              originalRecord: JSON.parse(JSON.stringify(jsonToSend)),
              record: JSON.parse(JSON.stringify(jsonToSend)),
            },
            () => {
              this.fetchAbbonamentiAfterSave();
            }
          );
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
              errorDialogVisible: true,
              errorDialogMessage: error.message,
              twoActionsAlertVisible: false,
            });
          }
        });
    }
  };

  closeTwoActionsAlert = () => {
    let originalRecord = JSON.parse(JSON.stringify(this.state.originalRecord));
    this.setState({
      record: originalRecord,
      twoActionsAlertVisible: false,
    });
  };

  inviomail = () => {
    let isFormValid = this.validateForm();
    if (isFormValid) {
      this.setState({
        loading: true,
      });
      sendEmailToConfirmRegistration(this.state.record.esercizio.id)
        .then((result) => {
          this.setState({
            loading: false,
          });
          alert("Email inviata");
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
              errorDialogVisible: true,
              errorDialogMessage: error.message,
            });
          }
        });
    }
  };

  updateParentState = (data) => {
    let record = this.state.record;
    record.giorniChiusura = data;
    this.setState({
      record: record,
      saveButtonEnabled: true,
      loading: true,
    });
    updateEsercizioCommerciale(record)
      .then((result) => {
        this.setState(
          {
            loading: false,
          },
          () => {
            this.fetchAbbonamentiAfterSave();
          }
        );
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
          });
        }
      });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  render() {
    const { classes } = this.props;
    const disabledSubmitbutton =
      this.state.loading || !this.state.saveButtonEnabled;
    const isConsulente =
      this.props.consulenteId !== null && this.props.superconsulente === false;

    return (
      <div className={classes.mainContainer}>
        <Typography variant="h4">Dettagli esercizio commerciale</Typography>
        <Grid
          container
          justify="center"
          style={{ marginTop: "26px", marginBottom: "26px" }}
        >
          <div style={{ minHeight: "700px" }}>
            {this.state.loading ? (
              <SpinnerComponent size={24} />
            ) : this.state.notfoundRecord ? (
              <Typography
                variant="h6"
                style={{ paddingTop: "6px", paddingBottom: "6px" }}
              >
                Nessun record trovato
              </Typography>
            ) : (
              <Fragment>
                {this.state.record.esercizio.terminiUsoAccettati === true &&
                this.state.record.esercizio.privacyPolicyAccettata ===
                  true ? null : (
                  <Button
                    type="submit"
                    onClick={this.inviomail}
                    variant="contained"
                    size="medium"
                    className={classes.invioMailButton}
                  >
                    Invia mail di attivazione
                  </Button>
                )}
                <form autoComplete="off" onSubmit={this.handleSubmit}>
                  <FormDettagli
                    record={this.state.record}
                    handleChange={this.handleChange}
                    validationErrors={this.state.validationErrors}
                  />
                  {(this.props.consulenteId === null ||
                    (this.props.consulenteId !== null &&
                      this.state.tipologiaPagamento === "BIORSAF")) && (
                    <FormPagamento
                      costoUnitario={this.state.costoUnitario}
                      abbonamentoData={this.state.record.storicoPricingDTO}
                      handleChangeAbbonamento={this.handleChangeAbbonamento}
                    />
                  )}
                  {!isConsulente && (
                    <ChipSection
                      funzionalitaApp={this.state.funzionalitaApp}
                      onChipSave={this.onChipSave}
                    />
                  )}
                  <StoricoAbbonamentiEsercizio
                    edit={this.state.edit}
                    consulenteId={this.props.consulenteId}
                    tipologiaPagamento={this.state.tipologiaPagamento}
                    abbonamenti={this.state.abbonamenti}
                    theme={this.props.theme}
                    esercizioId={this.state.record.esercizio.id}
                  />
                  <GiorniChiusuraEsercizio
                    esercizioId={this.state.record.esercizio.id}
                    giorniChiusura={this.state.record.giorniChiusura}
                    updateParentState={this.updateParentState}
                    theme={this.props.theme}
                  />
                  <Grid
                    container
                    justify="center"
                    style={{ paddingTop: "10px" }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ textAlign: "center" }}
                    >
                      <Typography
                        style={{
                          color: "green",
                          visibility: this.state.saveMessageVisible
                            ? "inherit"
                            : "hidden",
                        }}
                      >
                        Dati aggiornati con successo.
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ textAlign: "right" }}
                    >
                      <IndietroButton isDisabled={this.state.loading} />
                      <Button
                        type="submit"
                        onClick={this.handleSubmit}
                        variant="contained"
                        size="medium"
                        disabled={disabledSubmitbutton}
                        style={{
                          color: disabledSubmitbutton
                            ? this.props.theme.palette.text.disabled
                            : this.props.theme.palette.text.main,
                          margin: "10px",
                          backgroundColor: disabledSubmitbutton
                            ? this.props.theme.palette.disabled.main
                            : this.props.theme.palette.primary.main,
                        }}
                      >
                        Salva modifiche
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Fragment>
            )}
          </div>
        </Grid>
        <TwoActionsAlertDialog
          visible={this.state.twoActionsAlertVisible}
          title="Attenzione"
          message="La variazione dei parametri di abbonamento, comporterà una variazione di prezzo per l'utente finale, che verrà avvisato di conseguenza. Si desidera proseguire? "
          onIndietroClicked={this.closeTwoActionsAlert}
          onOkClicked={this.saveUpdates}
        />
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
      </div>
    );
  }
}

DettaglioEsercizioCommerciale.propTypes = {
  consulenteId: PropTypes.number,
  superconsulente: PropTypes.bool.isRequired,
  esercizioId: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DettaglioEsercizioCommerciale);
