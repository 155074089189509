import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from '@material-ui/icons/Settings';
import PropTypes from 'prop-types';
import theme from '../../../theme.js';
import { setChapterIndex, setParagraphIndex } from "../../../utils/storage.js";
import { useTheme } from "@material-ui/core";

export default function DettaglioManualeButton(props) {

  let history = useHistory();
  const match = useRouteMatch();
  const themeToColor = useTheme();
  return (
    <IconButton 
    size="small" 
    disabled={props.disabled}
    style={{
      color: props.disabled ? theme.palette.disabled.main : themeToColor.palette.primary.main,
    }}
    onClick={() => { 
      setChapterIndex(0);
      setParagraphIndex(0);
      history.push(`${match.url}/dettagli/${props.revisioneManualeId}`) }} >
      <SettingsIcon />
    </IconButton>
  );

}

DettaglioManualeButton.propTypes = {
  revisioneManualeId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired
}