import { retrieveToken } from "../storage";
import { wrapFetch, ApiError } from "./api";

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getModelli = (
  hidden,
  gruppoId,
  isConsulenteView,
  stato,
  page,
  size,
  sortby
) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/manuali";
  let query =
    "?" +
    "page=" +
    page +
    "&personalizzato.equals=true" +
    "&size=" +
    size +
    "&sort=" +
    sortby +
    "&" +
    sortby +
    ".dir=ASC" +
    "&isModello.equals=true";

  if (isConsulenteView) {
    query += "&stato.equals=PUBBLICATO&hidden.equals=false";
  } else {
    query = query + "&hidden.equals=" + hidden;
  }
  if (gruppoId !== null && gruppoId !== -1) {
    query = query + "&gruppoId.equals=" + gruppoId;
  }
  if (stato !== null && stato !== undefined) {
    query = query + "&stato.equals=" + stato;
  }
  const URL = BASE_URL + PATH + query;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};
export const getModelliFiltered = (
  hidden,
  gruppoId,
  isConsulenteView,
  stato,
  page,
  size,
  sortby,
  idModello,
  titolo,
  regolamenti
) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/manuali";
  let query = `?page=${page}&personalizzato.equals=true&size=${size}&sort=${sortby}&${sortby}.dir=ASC&isModello.equals=true`;

  if (isConsulenteView) {
    query += "&stato.equals=PUBBLICATO&hidden.equals=false";
  } else {
    query = query + "&hidden.equals=" + hidden;
  }
  if (gruppoId !== null && gruppoId !== -1) {
    query = query + "&gruppoId.equals=" + gruppoId;
  }
  if (stato !== null && stato !== undefined) {
    query = query + "&stato.equals=" + stato;
  }
  if (idModello !== null && idModello !== "") {
    query = query + "&id.equals=" + idModello;
  }
  if (regolamenti !== null && regolamenti !== "") {
    query += `&regolamenti.contains=${encodeURIComponent(regolamenti)}`;
  }
  if (titolo !== null && titolo !== "") {
    query += `&titolo.contains=${encodeURIComponent(titolo)}`;
  }
  const URL = BASE_URL + PATH + query;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getModelliCount = (hidden, gruppoId, isConsulenteView, stato) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/manuali/count";
  let query = "?personalizzato.equals=true&isModello.equals=true";

  if (isConsulenteView) {
    query += "&stato.equals=PUBBLICATO&hidden.equals=false";
  } else {
    query = query + "&hidden.equals=" + hidden;
  }
  if (gruppoId !== null && gruppoId !== -1) {
    query = query + "&gruppoId.equals=" + gruppoId;
  }
  if (stato !== null && stato !== undefined) {
    query = query + "&stato.equals=" + stato;
  }
  const URL = BASE_URL + PATH + query;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getModelliCountFiltered = (
  hidden,
  gruppoId,
  isConsulenteView,
  stato,
  idModello,
  titolo,
  regolamenti
) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/manuali/count";
  let query = "?personalizzato.equals=true&isModello.equals=true";

  if (isConsulenteView) {
    query += "&stato.equals=PUBBLICATO&hidden.equals=false";
  } else {
    query = query + "&hidden.equals=" + hidden;
  }
  if (gruppoId !== null && gruppoId !== -1) {
    query = query + "&gruppoId.equals=" + gruppoId;
  }
  if (stato !== null && stato !== undefined) {
    query = query + "&stato.equals=" + stato;
  }
  if (idModello !== null && idModello !== "") {
    query = query + "&id.equals=" + idModello;
  }
  if (regolamenti !== null && regolamenti !== "") {
    query += `&regolamenti.contains=${encodeURIComponent(regolamenti)}`;
  }
  if (titolo !== null && titolo !== "") {
    query += `&titolo.contains=${encodeURIComponent(titolo)}`;
  }
  const URL = BASE_URL + PATH + query;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getManuale = (manualeId) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/manuali/" + manualeId;
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getFlagControlliSemplificatiManualeFromId = (manualeId) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/manuali/is-semplificato/" + manualeId;
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const updateDoppioLogin = (manualeId, doppioLogin) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/manuali/gestione_operatori";
  const body = {
    modelId: manualeId,
    doppioLogin: doppioLogin,
  };
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      message = "Errore salvataggio: i dati non sono corretti.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(body),
    }),
    errorFunction
  );
};

export const updateModello = (modello) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/manuali";
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Errore salvataggio: i dati non sono corretti.";
    } else if (status === 404) {
      message = "Errore salvataggio: modello non trovato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(modello),
    }),
    errorFunction
  );
};

export const cancellaModello = (modelloId, hidden) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/manuali/cancella";
  const URL = BASE_URL + PATH;
  const requestBody = {
    id: modelloId,
    hidden: hidden,
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      // Error 404 if ID does not correspond to an existing record:
      message = "Errore salvataggio: i dati non sono corretti.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};

export const copyEntireContent = (manualeBaseId, manualeDestinazioneId) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/manuali/copia_contenuto";
  const URL = BASE_URL + PATH;
  const requestBody = {
    manualeBaseId,
    manualeDestinazioneId,
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      // Error 404 if one Manauale does not exist.
      message =
        "Si è verificato un errore. Copia del contenuto del manuale non riuscita.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};

export const creaManualePersonalizzato = (
  esercizioCommercialeId,
  manualeBaseId,
  consulenteId,
  hasChapters,
  isControlloSemplificato
) => {
  const token = retrieveToken();
  const PATH = "/api/manuali/personalizzati";
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const requestBody = {
    manualeBaseId: manualeBaseId,
    consulenteId: consulenteId,
    esercizioCommercialeId: esercizioCommercialeId,
    useChapters: hasChapters,
    controlliSemplificati: isControlloSemplificato,
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      message = "Creazione manuale non riuscita: i dati non sono corretti.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};

export const creaModelloPersonalizzato = (
  manualeBaseId,
  gruppoId,
  hasChapters,
  isControlloSemplificato
) => {
  const token = retrieveToken();
  const PATH = "/api/modelli/personalizzati";
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const requestBody = {
    manualeBaseId: manualeBaseId,
    gruppoId: gruppoId,
    useChapters: hasChapters,
    controlliSemplificati: isControlloSemplificato,
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      message = "Creazione modello non riuscita: i dati non sono corretti.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};

export const clonaModelliPersonalizzati = (gruppoId, brandId, ids) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/modelli/personalizzati/clonazione_multipla";
  const URL = BASE_URL + PATH;
  const requestBody = {
    gruppoId,
    brandId,
    modelliIds: ids,
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Clonazione modelli non riuscita: i dati non sono corretti.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};

// ONLY FOR MANUALE PERSONALIZZATO (NOT MODELLO):
export const pubblicaManualePersonalizzato = (manualeId) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/manuali/personalizzati/" + manualeId + "/pubblicazione";
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      message =
        "Pubblicazione del manuale non riuscita: i dati non sono corretti.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};
