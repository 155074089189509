import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import { NavigateBefore } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
  },
  title: {
    fontSize: "3rem",
    fontWeight: "bold",
    color: "#333",
  },
  description: {
    fontSize: "1.2rem",
    color: "#aaaaaa",
    marginBottom: "20px",
  },
  button: {
    backgroundColor: "#333",
    color: "white",
    margin: "100px 0",
    "&:hover": {
      backgroundColor: "#333",
    },
  },
}));

const PageNotFound = () => {
  const classes = useStyles();
  const history = useHistory();
  const isFirstPage = history.length <= 1;

  const handleGoHome = () => {
    if (isFirstPage) {
      window.location.replace("https://biorsaf.it/");
    } else {
      history.goBack();
    }
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Spiacenti, pagina non trovata.</h1>
      <h4 className={classes.description}>
        La pagina non esiste o è stata rimossa.
      </h4>
      <Button
        variant="contained"
        className={classes.button}
        startIcon={!isFirstPage && <NavigateBefore />}
        onClick={handleGoHome}
      >
        {isFirstPage ? "Vai alla Home di Biorsaf" : "Torna indietro"}
      </Button>
    </div>
  );
};

export default PageNotFound;
