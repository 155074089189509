// 1. Converti il colore selezionato da HEX a HSL.
// 2. Calcola i colori analoghi modificando il valore di Hue (ggiungendo e sottraendo 30 gradi).
// 3. Converti i colori HSL risultanti di nuovo in HEX per applicarli.

// Funzione per convertire HEX a HSL
export function hexToHsl(hex) {
  hex = hex.replace(/^#/, "");
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((hex) => hex + hex)
      .join("");
  }
  const r = parseInt(hex.substring(0, 2), 16) / 255;
  const g = parseInt(hex.substring(2, 4), 16) / 255;
  const b = parseInt(hex.substring(4, 6), 16) / 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0,
    s = 0,
    l = (max + min) / 2;
  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }
    h /= 6;
  }
  return [h * 360, s * 100, l * 100];
}

// Funzione per convertire HSL a HEX
export function hslToHex(h, s, l) {
  s /= 100;
  l /= 100;
  const k = (n) => (n + h / 30) % 12;
  const a = s * Math.min(l, 1 - l);
  const f = (n) =>
    Math.round(255 * (l - a * Math.max(Math.min(k(n) - 3, 9 - k(n), 1), -1)))
      .toString(16)
      .padStart(2, "0");
  return `#${f(0)}${f(8)}${f(4)}`;
}

// Funzione per calcolare i colori analoghi
export function calculateAnalogousColors(hex) {
  const [h, s, l] = hexToHsl(hex);
  const secondaryHue = (h + 30) % 360;
  const tertiaryHue = (h - 30 + 360) % 360;
  const secondaryColor = hslToHex(secondaryHue, s, l);
  const tertiaryColor = hslToHex(tertiaryHue, s, l);
  return [secondaryColor, tertiaryColor];
}
