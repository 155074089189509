import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.black,
    maxWidth: 300,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 8,
    boxShadow: theme.shadows[6],
  },
  arrow: {
    color: theme.palette.secondary.main,
  },
}))(Tooltip);

export default CustomTooltip;
