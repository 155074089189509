import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import PropTypes from "prop-types";

import FormModificaConsulente from "./FormModificaConsulente";
import RegistraConsulente from "./RegistraConsulente";
import EserciziXConsulenteComponent from "./EserciziXConsulenteComponent";

import { clearToken } from "../../../utils/storage";
import { getAbbonamenti } from "../../../utils/api/abbonamenti_api";
import {
  getConsulenti,
  updateConsulente,
} from "../../../utils/api/consulenti_api";
import { getGruppi, getGruppo } from "../../../utils/api/gruppi_api";

import theme from "../../../theme.js";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
    paddingTop: "26px",
    paddingBottom: "26px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  paper: {
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px " +
      theme.palette.primary.main,
  },
  tabsbar: {
    color: "black",
    boxShadow:
      "0px 1px 1px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
  },
  registrationMessage: {
    paddingTop: "10px",
    color: theme.palette.primary.main,
    paddingBottom: "10px",
  },
};

export default class DettaglioConsulente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      secondTabEnabled: false,
      showRegistrationForm: true,
      showRegistrationMessage: false,
      showChangesSavedMessage: false,
      consulente: {
        id: null,
        email: null,
        nome: null,
        cognome: null,
        telefono: null,
        activated: null,
        userId: null,
        validated: null,
        dataRegistrazione: null,
        terminiUsoAccettati: null,
        privacyPolicyAccettata: null,
        partitaIva: null,
        codiceSdi: null,
        pec: null,
        gruppoId: null,
        superconsulente: null,
      },
      notfound: true,
      nomeGruppo: "",
      abbonamenti: [],
      loading: true,
      errorDialogVisible: false,
      errorDialogMessage: "",
      tipologiaPagamento: null,
      saveButtonEnabled: false,
    };
  }

  componentDidMount() {
    if (this.props.consulenteId === "nuovo") {
      this.setState({
        secondTabEnabled: false,
        showRegistrationForm: true,
      });
    } else {
      const ID = Number.parseInt(this.props.consulenteId, 10);
      if (isNaN(ID)) {
        this.setState({
          secondTabEnabled: true,
          showRegistrationForm: false,
          loading: false,
          notfound: true,
        });
      } else {
        this.setState({
          secondTabEnabled: true,
          showRegistrationForm: false,
        });
        this.fetchRecord(ID);
      }
    }
  }

  handleInvalidToken = () => {
    this.setState({
      errorDialogVisible: true,
      errorDialogMessage:
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.",
    });
    clearToken();
    const { path } = this.props.theme;
    window.setTimeout(function () {
      window.location.href = "/" + path;
    }, 4000);
  };

  handleTabChange = (event, newValue) => {
    this.setState({
      tabValue: newValue,
    });
  };

  fetchRecord = (consulenteId) => {
    this.setState({
      loading: true,
    });
    getConsulenti(consulenteId, this.props.gruppoId, 0, 1, "id")
      .then((result) => {
        if (result.length > 0) {
          this.setState({
            consulente: result[0],
          });
          this.fetchAbbonamenti(consulenteId);
        } else {
          this.setState({
            loading: false,
            notfound: true,
          });
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
            notfound: true,
          });
        }
      });
  };

  fetchAbbonamenti = (consulenteId) => {
    getAbbonamenti(null, consulenteId, null, 0, 100, "id")
      .then((result) => {
        if (!this.props.superconsulenteView && !this.props.consulenteView) {
          this.setState({
            abbonamenti: result,
          });
          this.fetchGruppo();
        } else {
          this.setState({
            abbonamenti: result,
            loading: false,
            notfound: false,
          });
          this.fetchGruppoForConsulente(this.props.gruppoId);
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
          });
        }
      });
  };

  fetchGruppo = () => {
    getGruppi(this.state.consulente.gruppoId, null, 0, 1, "id")
      .then((result) => {
        let nomeGruppo = "";
        if (result.length > 0) {
          nomeGruppo = result[0].nome;
        }
        this.setState({
          loading: false,
          nomeGruppo,
          tipologiaPagamento: result[0].tipologiaPagamento,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
          });
        }
      });
  };

  fetchGruppoForConsulente = (gruppoId) => {
    getGruppo(gruppoId)
      .then((response) => {
        this.setState({
          loading: false,
          tipologiaPagamento: response.tipologiaPagamento,
          //costoUnitario: response.costoUnitario,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        }
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: error.message,
        });
      });
  };

  onFormSubmitted = () => {
    this.setState({
      loading: true,
    });
    let isAdmin = false;
    if (!this.props.superconsulenteView && !this.props.consulenteView) {
      isAdmin = true;
    }
    updateConsulente(isAdmin, this.state.consulente)
      .then((result) => {
        this.setState({
          loading: false,
          saveButtonEnabled: false,
          showChangesSavedMessage: true,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
          });
        }
      });
  };

  onRecordSaved = () => {
    this.setState({
      secondTabEnabled: false,
      showRegistrationMessage: true,
    });
  };

  onFormModified = (fieldName, value) => {
    if (!this.state.saveButtonEnabled) {
      this.setState({
        saveButtonEnabled: true,
      });
    }
    let consulente = this.state.consulente;
    consulente[fieldName] = value;
    this.setState({
      showRegistrationMessage: false,
      showChangesSavedMessage: false,
      consulente,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  render() {
    const showRegistrationForm = this.state.showRegistrationForm;
    const secondTabDisabled =
      !this.state.secondTabEnabled ||
      (this.props.consulenteView && !this.props.superconsulenteView);
    const title = showRegistrationForm
      ? "Nuovo consulente"
      : "Dettagli consulente";

    return (
      <div style={styles.mainContainer}>
        <Typography variant="h4">{title}</Typography>
        {this.props.consulenteView && !this.props.superconsulenteView ? (
          <Paper
            style={{
              marginTop: "26px",
              marginBottom: "26px",
              boxShadow:
                "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px " +
                this.props.theme.palette.primary.main,
            }}
          >
            <Box style={{ paddingTop: "20px" }}>
              <Typography
                variant="body1"
                style={{
                  color: "green",
                  visibility: this.state.showChangesSavedMessage
                    ? "inherit"
                    : "hidden",
                }}
              >
                Dati aggiornati con successo.
              </Typography>
            </Box>
            <FormModificaConsulente
              theme={this.props.theme}
              loading={this.state.loading}
              notfound={this.state.notfound}
              consulente={this.state.consulente}
              nomeGruppo={this.state.nomeGruppo}
              abbonamenti={this.state.abbonamenti}
              tipologiaPagamento={this.state.tipologiaPagamento}
              superconsulenteView={this.props.superconsulenteView}
              consulenteView={this.props.consulenteView}
              onFormModified={this.onFormModified}
              onFormSubmitted={this.onFormSubmitted}
              onErrorDialogClose={this.closeErrorDialog}
              saveButtonEnabled={this.state.saveButtonEnabled}
              showError={this.state.errorDialogVisible}
              errorMessage={this.state.errorDialogMessage}
            />
          </Paper>
        ) : (
          <Paper
            style={{
              marginTop: "26px",
              marginBottom: "26px",
              boxShadow:
                "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px " +
                this.props.theme.palette.primary.main,
            }}
          >
            <Tabs
              value={this.state.tabValue}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              variant="fullWidth"
              style={styles.tabsbar}
              //centered
            >
              <Tab label="Dati consulente" value={0} />
              <Tab
                label="Esercizi commerciali"
                value={1}
                disabled={secondTabDisabled}
                style={{
                  backgroundColor: secondTabDisabled
                    ? theme.palette.disabled.main
                    : "white",
                }}
              />
            </Tabs>
            <div hidden={this.state.tabValue !== 0}>
              <Box style={{ paddingTop: "20px" }}>
                {this.state.tabValue === 0 && showRegistrationForm ? (
                  <Fragment>
                    <Typography
                      variant="body1"
                      style={{
                        color: "green",
                        visibility: this.state.showRegistrationMessage
                          ? "inherit"
                          : "hidden",
                      }}
                    >
                      Registrazione effettuata con successo.
                    </Typography>
                    <RegistraConsulente
                      theme={this.props.theme}
                      gruppoId={this.props.gruppoId}
                      superconsulenteView={this.props.superconsulenteView}
                      onFormModified={this.onFormModified}
                      onFormSubmitted={this.onRecordSaved}
                    />
                  </Fragment>
                ) : null}
                {this.state.tabValue === 0 && !showRegistrationForm ? (
                  <Fragment>
                    <Typography
                      variant="body1"
                      style={{
                        color: "green",
                        visibility: this.state.showChangesSavedMessage
                          ? "inherit"
                          : "hidden",
                      }}
                    >
                      Dati aggiornati con successo.
                    </Typography>
                    <FormModificaConsulente
                      theme={this.props.theme}
                      tipologiaPagamento={this.state.tipologiaPagamento}
                      loading={this.state.loading}
                      consulente={this.state.consulente}
                      notfound={this.state.notfound}
                      nomeGruppo={this.state.nomeGruppo}
                      abbonamenti={this.state.abbonamenti}
                      superconsulenteView={this.props.superconsulenteView}
                      consulenteView={this.props.consulenteView}
                      onFormModified={this.onFormModified}
                      onFormSubmitted={this.onFormSubmitted}
                      onErrorDialogClose={this.closeErrorDialog}
                      saveButtonEnabled={this.state.saveButtonEnabled}
                      showError={this.state.errorDialogVisible}
                      errorMessage={this.state.errorDialogMessage}
                    />
                  </Fragment>
                ) : null}
              </Box>
            </div>
            <div hidden={this.state.tabValue !== 1}>
              <Box style={{ paddingTop: "44px" }}>
                {this.state.tabValue === 1 && (
                  <EserciziXConsulenteComponent
                    theme={this.props.theme}
                    consulente={this.state.consulente}
                  />
                )}
              </Box>
            </div>
          </Paper>
        )}
      </div>
    );
  }
}

DettaglioConsulente.propTypes = {
  consulenteId: PropTypes.string.isRequired,
  gruppoId: PropTypes.number,
  consulenteView: PropTypes.bool.isRequired,
  superconsulenteView: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
};
