import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import PropTypes from "prop-types";
import { Chip, Paper, Typography } from "@material-ui/core";
import {
  Archive,
  Assignment,
  AttachFile,
  Build,
  BusinessCenter,
  DateRange,
  Map,
  MenuBook,
  PlaylistAddCheck,
} from "@material-ui/icons";
import ActionButton from "../../../components/ActionButton";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "30px",
    margin: "10px 0",
    padding: "40px",
  },
  chipContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  chip: {
    margin: "4px",
    width: "240px",
    height: "40px",
    borderRadius: "20px",
    borderColor: "inherit",
    "& .MuiChip-label": {
      fontSize: "1rem",
      color: "black",
    },
    "& .MuiChip-icon": {
      transform: "scale(1.2)",
    },
  },
  clickedChip: {
    "& .MuiChip-label": {
      color: theme.palette.text.disabled,
    },
  },
}));

const normalizeLabel = (label) => label.toLowerCase().replace(/à/g, "a");

const mapBackendDataToChipData = (data) => {
  return [
    { icon: <MenuBook />, label: "Manuale", enabled: data.manuale },
    { icon: <DateRange />, label: "Calendario", enabled: data.calendario },
    {
      icon: <PlaylistAddCheck />,
      label: "Controlli",
      enabled: data.controlli,
    },
    { icon: <Assignment />, label: "Schede", enabled: data.schede },
    { icon: <AttachFile />, label: "Allegati", enabled: data.allegati },
    { icon: <Archive />, label: "Archivio", enabled: data.archivio },
    { icon: <Build />, label: "Attrezzature", enabled: data.attrezzature },
    { icon: <BusinessCenter />, label: "Attività", enabled: data.attivita },
    { icon: <Map />, label: "Tracciabilità", enabled: data.tracciabilita },
  ];
};

const ChipSection = ({ funzionalitaApp, onChipSave }) => {
  const classes = useStyles();
  const theme = useTheme();

  const chipData = mapBackendDataToChipData(funzionalitaApp);

  const inizializeClickedChips = () => {
    const initialClickedChips = {};
    Object.keys(funzionalitaApp).forEach((key) => {
      initialClickedChips[normalizeLabel(key)] = !funzionalitaApp[key];
    });
    return initialClickedChips;
  };

  const [clickedChips, setClickedChips] = useState(inizializeClickedChips);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    const isChanged = Object.keys(clickedChips).some(
      (key) => clickedChips[key] !== !funzionalitaApp[key]
    );
    setHasChanges(isChanged);
  }, [clickedChips, funzionalitaApp]);

  const handleChipClick = (label) => {
    const normalizedLabel = normalizeLabel(label);
    setClickedChips((prev) => ({
      ...prev,
      [normalizedLabel]: !prev[normalizedLabel],
    }));
  };

  const handleSave = () => {
    const updatedFunzionalitaApp = { ...funzionalitaApp };
    Object.keys(clickedChips).forEach((key) => {
      if (typeof updatedFunzionalitaApp[key] === "boolean") {
        updatedFunzionalitaApp[key] = !clickedChips[key];
      }
    });

    onChipSave(updatedFunzionalitaApp);
    setHasChanges(false);
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6">
        Attiva/disattiva i pulsanti grafici presenti nella dashboard dell'app:
      </Typography>
      <div className={classes.chipContainer}>
        {chipData.map((chip) => {
          const isClicked = clickedChips[normalizeLabel(chip.label)];
          return (
            <Chip
              variant="outlined"
              key={chip.label}
              icon={chip.icon}
              label={chip.label}
              clickable
              color="primary"
              onClick={() => {
                handleChipClick(chip.label);
              }}
              style={{
                backgroundColor: isClicked
                  ? theme.palette.disabled.main
                  : "inherit",
                color: isClicked
                  ? theme.palette.text.disabled
                  : theme.palette.primary.main,
                boxShadow: isClicked ? "none" : theme.shadows[8],
              }}
              className={`${classes.chip} ${
                isClicked ? classes.clickedChip : ""
              }`}
            />
          );
        })}
      </div>
      <ActionButton
        label="Salva pulsanti"
        onClick={handleSave}
        disabled={!hasChanges}
      />
    </Paper>
  );
};

ChipSection.propTypes = {
  funzionalitaApp: PropTypes.object.isRequired,
  onChipSave: PropTypes.func.isRequired,
};

export default ChipSection;
