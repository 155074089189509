import React from "react";
import { AppBar, Container, Toolbar, Typography, useTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import theme from "../theme.js";

export default function Footer() {
  const themeToUse = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <AppBar
        position="static"
        style={{ background: themeToUse.palette.primary.main }}
      >
        <Container>
          <Toolbar style={{display:"flex", flexDirection:"row",justifyContent:"space-between"}}>
          <div>
            <Typography
              variant="body1"
              style={{
                textAlign: "center",
                color: themeToUse.palette.text.main,
              }}
            >
              Copyright© BIORSAF SRL Innovativa
            </Typography>
            </div>
            <div>
            <a
              href="https://bsfood.biorsaf.it/condizioni-generali-uso-BS-FOOD.pdf"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: theme.palette.link.main }}
            >
              Condizioni D'uso
            </a>
            <span>&nbsp;&nbsp;</span>
            <a
              href="https://biorsaf.it/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: theme.palette.link.main }}
            >
              Privacy Policy
            </a>
            <span>&nbsp;&nbsp;</span>
            <a
              href="https://biorsaf.it/cookie-policy/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: theme.palette.link.main }}
            >
              Cookie policy
            </a>
            <span>&nbsp;&nbsp;</span>
            <a
              href="https://bsfood.biorsaf.it/Utilizzo-pannello-di-controllo-BSFOOD.pdf"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: theme.palette.link.main }}
            >
              Guida Piattaforma
            </a>
            <span>&nbsp;&nbsp;</span>
            <a
              href="https://bit.ly/bs-food-tickets"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: theme.palette.link.main }}
            >
              Segnalazioni
            </a>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}
