import React, { Component, Fragment } from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import SpinnerComponent from "../../../components/SpinnerComponent";
import ErrorDialog from "../../../components/ErrorDialog";
import FormModificaGruppo from "./FormModificaGruppo";
import TabellaConsulentiGruppo from "./TabellaConsulentiGruppo";
import TabellaModelliPiattaforma from "./TabellaModelliPiattaforma";
import TabellaModelliGruppo from "./TabellaModelliGruppo";
import DialogConfirmOperation from "../components/DialogConfirmOperation";
import GoToButton from "../components/GoToButton";
import {
  getGruppo,
  getGruppi,
  updateGruppo,
} from "../../../utils/api/gruppi_api";
import {
  getConsulenti,
  updateConsulente,
} from "../../../utils/api/consulenti_api";
import {
  getModelli,
  clonaModelliPersonalizzati,
  cancellaModello,
  updateModello,
} from "../../../utils/api/modelli_and_manuali_api";
import { clearToken } from "../../../utils/storage";

import PropTypes from "prop-types";
import TwoActionsAlertDialog from "../../../components/TwoActionsAlertDialog";

const styles = {
  root: {
    textAlign: "center",
    color: "black",
    paddingTop: "26px",
    paddingLeft: "4px",
    paddingRight: "4px",
    minHeight: "86vh",
  },
  texts: {
    marginBottom: "20px",
  },
};

export default class ModificaGruppoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      originalNome: "",
      originalCostoUnitario: null,
      gruppo: {
        id: null,
        nome: "",
        costoUnitario: null,
        brand: {
          id: null,
          nome: "",
        },
      },
      gruppi: [],
      consulenti: [],
      modelliPiattaforma: [],
      modelliGruppo: [],
      selectedModelli: [],
      isModalOpen: false,
      roleSuperconsulente: false,
      selectedConsulente: null,
      modalDescription: "",
      modalTitle: "",
      changeRoleSuccessful: false,
      changesNotSaved: false,
      errorDialogVisible: false,
      errorDialogTitle: null,
      errorDialogMessage: "",
      editTable: false,
      selectedModelloId: null,
      deleteSuccessful: false,
      statusChangeSuccessful: false,

      costoUnitarioChangeAlertVisible: false,
      showSuccessMessage: false,
      formChangesSaved: false,
    };
  }

  componentDidMount() {
    this.fetchGruppo();
  }

  handleInvalidToken = () => {
    const errorMessage =
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    this.openErrorDialog(errorMessage);
    clearToken();
    const { path } = this.props.theme;
    window.setTimeout(function () {
      window.location.href = "/" + path;
    }, 4000);
  };

  openErrorDialog = (errorMessage) => {
    this.setState({
      errorDialogTitle: null,
      errorDialogVisible: true,
      errorDialogMessage: errorMessage,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  // Using the ErrorDialog to display a message if operation is successful.
  displayMessage = (title, message) => {
    this.setState({
      errorDialogTitle: title,
      errorDialogVisible: true,
      errorDialogMessage: message,
    });
  };

  fetchGruppo = () => {
    let gruppoId = this.props.gruppoId;
    getGruppo(gruppoId)
      .then((result) => {
        this.setState({
          gruppo: result,
          originalNome: result.nome,
          originalCostoUnitario: result.costoUnitario,
        });
        this.fetchGruppi();
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  fetchGruppi = () => {
    getGruppi(null, null, 0, 1000, "id")
      .then((result) => {
        this.setState({
          gruppi: result,
        });
        this.fetchConsulenti();
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  fetchConsulenti = () => {
    const gruppoId = Number.parseInt(this.props.gruppoId);
    getConsulenti(null, gruppoId, 0, 2000, "id")
      .then((result) => {
        this.setState({
          consulenti: result,
        });
        this.fetchModelliPiattaforma();
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  fetchModelliPiattaforma = () => {
    getModelli(false, null, false, "PUBBLICATO", 0, 2000, "id")
      .then((result) => {
        this.setState({
          modelliPiattaforma: result,
        });
        this.fetchModelliGruppo();
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  fetchModelliGruppo = () => {
    const gruppoId = Number.parseInt(this.props.gruppoId);
    getModelli(false, gruppoId, false, null, 0, 3000, "id")
      .then((result) => {
        this.setState({
          modelliGruppo: result,
          loading: false,
        });
        if (this.state.changeRoleSuccessful) {
          this.setState({
            changeRoleSuccessful: false,
          });
          let message =
            "Il ruolo di superconsulente è stato assegnato all'utente selezionato.";
          if (!this.state.roleSuperconsulente) {
            message =
              "Il ruolo di superconsulente è stato tolto all'utente selezionato.";
          }
          this.displayMessage("Operazione effettuata", message);
        } else if (this.state.deleteSuccessful) {
          this.setState({
            deleteSuccessful: false,
          });
          let message = "Il modello è stato cancellato.";
          this.displayMessage("Operazione effettuata", message);
        } else if (this.state.statusChangeSuccessful) {
          this.setState({
            statusChangeSuccessful: false,
          });
          let message = "Il modello è stato pubblicato.";
          this.displayMessage("Operazione effettuata", message);
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  onDatiGruppoChanged = (event) => {
    const { name, value } = event.target;

    if (name === "costoUnitario") {
      const filteredValue = value.replace(/[^0-9.]/g, "");

      this.setState((prevState) => ({
        gruppo: {
          ...prevState.gruppo,
          [name]: filteredValue,
        },
        changesNotSaved: true,
      }));
    } else {
      this.setState((prevState) => ({
        gruppo: {
          ...prevState.gruppo,
          [name]: value,
        },
        changesNotSaved: true,
      }));
    }
  };

  saveChangesToGruppo = () => {
    if (+this.state.gruppo.costoUnitario !== this.state.originalCostoUnitario) {
      this.setState({
        costoUnitarioChangeAlertVisible: true,
      });
      return;
    }
    this.setState({
      loading: true,
    });
    updateGruppo(this.state.gruppo)
      .then((result) => {
        let gruppo = result;
        this.setState({
          gruppo,
          changesNotSaved: false,
          originalCostoUnitario: result.costoUnitario,
          originalNome: result.nome,
          showSuccessMessage: true,
          formChangesSaved: true,
        });
        this.fetchGruppo();
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  openModalSuperconsulente = (consulente, event) => {
    const roleSuperconsulente = event.target.value;
    let modalTitle = "Assegnamento del ruolo di superconsulente";
    let modalDescription =
      "Impostare il consulente " +
      consulente.email +
      " come superconsulente del gruppo " +
      this.state.gruppo.nome +
      "?";
    if (!roleSuperconsulente) {
      modalTitle = "Rimozione del ruolo di superconsulente";
      modalDescription =
        "Attenzione: se l'operazione verrà confermata, il consulente " +
        consulente.email +
        " perderà i privilegi di superconsulente e non potrà più accedere ai contenuti del gruppo," +
        " ma solo a quelli direttamente associati al consulente.";
    }
    let consulenti = this.state.consulenti;
    for (let i = 0; i < consulenti.length; i++) {
      if (consulenti[i].id === consulente.id) {
        consulenti[i].superconsulente = roleSuperconsulente;
        break;
      }
    }
    this.setState({
      roleSuperconsulente,
      consulenti,
      selectedConsulente: consulente,
      modalDescription,
      modalTitle,
      isModalOpen: true,
    });
  };

  closeModalWithoutChanges = () => {
    let consulenti = this.state.consulenti;
    for (let i = 0; i < consulenti.length; i++) {
      if (consulenti[i].id === this.state.selectedConsulente.id) {
        consulenti[i].superconsulente = !this.state.roleSuperconsulente;
        break;
      }
    }
    this.setState({
      consulenti,
      isModalOpen: false,
    });
  };

  changeRoleToConsulente = () => {
    this.setState({
      isModalOpen: false,
      loading: true,
    });
    let record = null;
    const consulenti = this.state.consulenti;
    for (let i = 0; i < consulenti.length; i++) {
      if (consulenti[i].id === this.state.selectedConsulente.id) {
        record = consulenti[i];
        break;
      }
    }
    updateConsulente(true, record)
      .then((result) => {
        this.setState({
          changeRoleSuccessful: true,
        });
        this.fetchConsulenti();
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        this.closeModalWithoutChanges();
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  handleChangedSelection = (selectedRecord, event) => {
    let selectedModelli = this.state.selectedModelli;
    if (event.target.checked) {
      selectedModelli.push(selectedRecord);
      this.setState({
        selectedModelli,
      });
    } else {
      let newarray = selectedModelli.filter(
        (modello) => modello.id !== selectedRecord.id
      );
      this.setState({
        selectedModelli: newarray,
      });
    }
  };

  cloneModelli = () => {
    this.setState({
      loading: true,
    });
    const selModelli = this.state.selectedModelli;
    let ids = [];
    for (let i = 0; i < selModelli.length; i++) {
      ids.push(selModelli[i].id);
    }
    clonaModelliPersonalizzati(
      this.state.gruppo.id,
      this.state.gruppo.brand.id,
      ids
    )
      .then((result) => {
        this.fetchModelliGruppo();
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  updateStatusModello = (modello, event) => {
    this.setState({
      loading: true,
    });
    modello.stato = "PUBBLICATO";
    updateModello(modello)
      .then((result) => {
        this.setState({
          statusChangeSuccessful: true,
        });
        this.fetchModelliGruppo();
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  removeModello = (modello, clickEvent) => {
    this.setState({
      loading: true,
    });
    cancellaModello(modello.id, true)
      .then((result) => {
        this.setState({
          deleteSuccessful: true,
        });
        this.fetchModelliGruppo();
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  changeEditFlag = (selectedRecord, event) => {
    let flag = this.state.editTable;
    this.setState({
      selectedModelloId: selectedRecord.id,
      editTable: !flag,
    });
  };

  handleEditedModello = (modello, fieldName, value) => {
    let modelliGruppo = this.state.modelliGruppo;
    for (let i = 0; i < modelliGruppo.length; i++) {
      if (modelliGruppo[i].id === modello.id) {
        modelliGruppo[i][fieldName] = value;
        break;
      }
    }
    this.setState({
      modelliGruppo,
      changesNotSaved: true,
    });
  };

  saveUpdatesToModello = (modello, event) => {
    this.changeEditFlag(modello, null);
    this.setState({
      loading: true,
    });
    updateModello(modello)
      .then((result) => {
        this.setState({
          changesNotSaved: false,
        });
        this.fetchModelliGruppo();
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  closeTwoActionsAlert = () => {
    this.setState((prevState) => ({
      costoUnitarioChangeAlertVisible: false,
      gruppo: {
        ...prevState.gruppo,
        nome: prevState.originalNome,
        costoUnitario: prevState.originalCostoUnitario,
      },
    }));
  };

  confirmDatiGruppoUpdate = () => {
    this.setState({
      loading: true,
      costoUnitarioChangeAlertVisible: false,
    });
    updateGruppo(this.state.gruppo)
      .then((result) => {
        let gruppo = result;

        this.setState({
          gruppo,
          changesNotSaved: false,
          originalNome: result.nome,
          originalCostoUnitario: result.costoUnitario,
          showSuccessMessage: true,
          formChangesSaved: true,
        });

        this.fetchGruppo();
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <Container maxWidth={false} style={styles.root}>
        <Typography variant="h4" style={styles.texts}>
          Modifica i dati del gruppo
        </Typography>
        {this.state.loading ? (
          <SpinnerComponent size={24} />
        ) : this.state.gruppo.id === null ? (
          <Typography
            variant="h6"
            style={{ paddingTop: "6px", paddingBottom: "6px" }}
          >
            Record non trovato
          </Typography>
        ) : (
          <Fragment>
            <FormModificaGruppo
              gruppo={this.state.gruppo}
              onChange={this.onDatiGruppoChanged}
              onSubmit={this.saveChangesToGruppo}
              disabled={this.state.formChangesSaved}
              showSuccessMessage={this.state.showSuccessMessage}
            />
            <TabellaConsulentiGruppo
              gruppoId={this.state.gruppo.id}
              consulenti={this.state.consulenti}
              onSelect={this.openModalSuperconsulente}
              disabled={false}
              changesNotSaved={this.state.changesNotSaved}
            />
            <Typography variant="h5" style={styles.texts}>
              Modelli base del gruppo
            </Typography>
            <TabellaModelliPiattaforma
              theme={this.props.theme}
              modelli={this.state.modelliPiattaforma}
              gruppi={this.state.gruppi}
              selectedModelli={this.state.selectedModelli}
              onSelectionChanged={this.handleChangedSelection}
              onSubmit={this.cloneModelli}
            />
            <Typography variant="h6" style={styles.texts}>
              Elenco dei modelli base del gruppo
            </Typography>
            <TabellaModelliGruppo
              theme={this.props.theme}
              modelli={this.state.modelliGruppo}
              selectedRecordId={this.state.selectedModelloId}
              edit={this.state.editTable}
              onStatoChanged={this.updateStatusModello}
              onEdit={this.changeEditFlag}
              onEditedCell={this.handleEditedModello}
              onSave={this.saveUpdatesToModello}
              onElimina={this.removeModello}
            />
            <Grid container justify="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ marginBottom: "10px" }}
              >
                <GoToButton
                  showAlert={this.state.changesNotSaved}
                  path={"/amministrazione/gruppi"}
                  label="Torna alla tabella dei gruppi"
                  disabled={false}
                  grayVersion={false}
                />
              </Grid>
            </Grid>
          </Fragment>
        )}
        <DialogConfirmOperation
          open={this.state.isModalOpen}
          title={this.state.modalTitle}
          description={this.state.modalDescription}
          cancelButtonLabel="Annulla"
          confirmButtonLabel="Conferma"
          onCancel={this.closeModalWithoutChanges}
          onConfirm={this.changeRoleToConsulente}
        />
        <ErrorDialog
          open={this.state.errorDialogVisible}
          title={this.state.errorDialogTitle}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
        <TwoActionsAlertDialog
          visible={this.state.costoUnitarioChangeAlertVisible}
          title="Attenzione!"
          message="La variazione del costo unitario comporterà la variazione di prezzo per l'utente finale, che verrà avvisato di conseguenza. Si desidera proseguire?"
          onIndietroClicked={this.closeTwoActionsAlert}
          onOkClicked={this.confirmDatiGruppoUpdate}
        />
      </Container>
    );
  }
}

ModificaGruppoPage.propTypes = {
  gruppoId: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
};
