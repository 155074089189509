import React, { Component } from "react";

import SpinnerComponent from "../../../components/SpinnerComponent";
import ErrorDialog from "../../../components/ErrorDialog";
import EditRecordButton from "../components/EditRecordButton";
import NewRecordButton from "../components/NewRecordButton";

import { clearToken } from "../../../utils/storage.js";
import {
  getCountGruppi,
  getCountGruppiFiltered,
  getGruppi,
} from "../../../utils/api/gruppi_api";
import BasicTable from "../../../components/BasicTable.js";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";

const styles = {
  root: {
    textAlign: "center",
    color: "black",
    paddingTop: "30px",
    minHeight: "100%",
  },
};

export default class GruppiTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gruppi: [],
      loading: true,
      errorDialogVisible: false,
      errorDialogTitle: null,
      errorDialogMessage: "",
      totalPages: 10,
      loadedPage: 0,
      pageSize: 10,
      id: "",
      nome: "",
    };
  }

  componentDidMount() {
    this.fetchGruppi(0);
  }

  openErrorDialog = (errorMessage) => {
    this.setState({
      errorDialogVisible: true,
      errorDialogMessage: errorMessage,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  handleInvalidToken = () => {
    const errorMessage =
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    this.openErrorDialog(errorMessage);
    clearToken();
    const { path } = this.props.theme;
    window.setTimeout(function () {
      window.location.href = `/${path}`;
    }, 4000);
  };

  fetchGruppi = (loadedPage) => {
    getCountGruppi()
      .then((countResult) => {
        getGruppi(
          this.state.id,
          this.state.nome,
          loadedPage,
          this.state.pageSize,
          "id"
        )
          .then((result) => {
            this.setState({
              gruppi: result,
              totalPages: Math.ceil(countResult / this.state.pageSize),
              loading: false,
            });
          })
          .catch((error) => {
            if (error.status === 403) {
              this.handleInvalidToken();
            } else {
              this.setState({
                loading: false,
              });
              this.openErrorDialog(error.message);
            }
          });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.openErrorDialog(error.message);
        }
      });
  };

  fetchGruppiForPageChange = (loadedPage) => {
    getGruppi(
      this.state.id,
      this.state.nome,
      loadedPage,
      this.state.pageSize,
      "id"
    )
      .then((result) => {
        this.setState({
          gruppi: result,
          loading: false,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.openErrorDialog(error.message);
        }
      });
  };

  fetchGruppiForResearch = () => {
    getCountGruppiFiltered(this.state.id, this.state.nome).then(
      (resultCount) => {
        getGruppi(this.state.id, this.state.nome, 0, 10, "id")
          .then((result) => {
            this.setState({
              gruppi: result,
              totalPages:
                resultCount > 0
                  ? Math.ceil(resultCount / this.state.pageSize)
                  : 1,
              loading: false,
            });
          })
          .catch((error) => {
            if (error.status === 403) {
              this.handleInvalidToken();
            } else {
              this.setState({
                loading: false,
              });
              this.openErrorDialog(error.message);
            }
          });
      }
    );
  };

  onPageChange = (pageIndex) => {
    this.setState(
      {
        loadedPage: pageIndex,
      },
      () => this.fetchGruppiForPageChange(this.state.loadedPage)
    );
  };

  render() {
    const columns = [
      {
        Header: "Numero",
        id: "numero",
        accessor: (row) => row,
        width: 120,
        filterable: false,
        Cell: (props) =>
          this.state.loadedPage === 0
            ? "" + props.index
            : this.state.loadedPage + "" + props.index,
      },
      {
        Header: "ID gruppo",
        id: "id",
        accessor: (d) => d.id,
        filterable: true,
        width: 120,
        Filter: (
          <TextField
            fullWidth
            variant="outlined"
            value={this.state.id}
            onChange={(e) => {
              let inputValue = e.target.value;
              let regex = /^[0-9]+$/;

              if (regex.test(inputValue) || inputValue === "") {
                this.setState({
                  id: inputValue,
                });
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      this.setState(
                        {
                          loadedPage: 0,
                        },
                        () => {
                          if (this.state.id === "" && this.state.nome === "") {
                            this.fetchGruppi(0);
                          } else {
                            this.fetchGruppiForResearch();
                          }
                        }
                      );
                    }}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Nome",
        id: "nome",
        accessor: (d) => d.nome,
        filterable: true,
        minWidth: 120,
        Filter: (
          <TextField
            variant="outlined"
            fullWidth
            value={this.state.nome}
            onChange={(e) => {
              this.setState({
                nome: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      this.setState(
                        {
                          loadedPage: 0,
                        },
                        () => {
                          if (this.state.id === "" && this.state.nome === "") {
                            this.fetchGruppi(0);
                          } else {
                            this.fetchGruppiForResearch();
                          }
                        }
                      );
                    }}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: "Brand",
        id: "brand",
        accessor: (d) => (d.brand === null ? " - " : d.brand.nome),
        minWidth: 90,
        filterable: false,
      },
      {
        Header: "Modifica",
        id: "modifica",
        accessor: (row) => {
          return <EditRecordButton recordId={row.id} disabled={false} />;
        },
        width: 80,
        filterable: false,
      },
    ];
    return (
      <div style={styles.root}>
        {this.state.loading ? (
          <SpinnerComponent size={24} />
        ) : (
          <div>
            <div
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                textAlign: "right",
              }}
            >
              <NewRecordButton disabled={false} label="Crea nuovo gruppo" />
            </div>
            <BasicTable
              columns={columns}
              data={this.state.gruppi}
              loading={false}
              filterable={false}
              resizable={true}
              sortable={false}
              defaultPageSize={this.state.pageSize}
              page={this.state.loadedPage}
              pages={this.state.totalPages}
              onPageChange={this.onPageChange}
            />
          </div>
        )}
        <ErrorDialog
          open={this.state.errorDialogVisible}
          title={this.state.errorDialogTitle}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
      </div>
    );
  }
}
