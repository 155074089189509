import React from "react";
import { TextField, Typography, InputAdornment } from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  roundedInput: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "30px",
      },
      "&:hover fieldset": {
        borderColor: "grey",
      },
      "&.Mui-focused fieldset": {
        borderColor: "grey",
      },
    },
  },
}));

const BrandNameInput = ({ isEditMode, brandName, handleBrandNameChange }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography>{`${
        isEditMode ? "Modifica" : "Inserisci"
      } nome del brand`}</Typography>
      <TextField
        className={classes.roundedInput}
        variant="outlined"
        value={brandName}
        onChange={handleBrandNameChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <EditOutlined />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

BrandNameInput.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  brandName: PropTypes.string.isRequired,
  handleBrandNameChange: PropTypes.func.isRequired,
};

export default BrandNameInput;
