import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import ActionButton from '../../../components/ActionButton';
import PropTypes from 'prop-types';

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          {" "}
          <CloseIcon />{" "}
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    minWidth: '500px',
    minHeight: '68px',
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2)
  }
}))(MuiDialogActions);


export default function DialogConfirmOperation(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.onCancel}
            aria-labelledby="confirm-operation-dialog-title"
        >
            <DialogTitle id="confirm-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.description}
                </DialogContentText>
                { props.descriptionExtra !== undefined || props.descriptionExtra !== null ?
                  <DialogContentText>
                    {props.descriptionExtra}
                  </DialogContentText>
                  :
                  null
                }
            </DialogContent>
            <DialogActions>
                <ActionButton
                    label={props.cancelButtonLabel}
                    onClick={props.onCancel}
                    disabled={false}
                    grayVersion={false}
                    secondaryButton
                />
                <ActionButton
                    label={props.confirmButtonLabel}
                    onClick={props.onConfirm}
                    disabled={false}
                    grayVersion={false}
                />
            </DialogActions>
        </Dialog>
    );
}

DialogConfirmOperation.propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    descriptionExtra: PropTypes.string,
    cancelButtonLabel: PropTypes.string.isRequired,
    confirmButtonLabel: PropTypes.string.isRequired,
}