import { retrieveToken } from "../storage";
import { wrapFetch, ApiError, wrapFetchWithDetailedErrors } from "./api";

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getRevisioniManuali = (
  id,
  hidden,
  gruppoId,
  consulenteId,
  page,
  size,
  sortby,
  esercizioId,
  esercizioNome,
  manualeId,
  manualeTitolo
) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/revisioni";
  let query = `?page=${page}&size=${size}&personalizzato.equals=true&sort=${sortby}&${sortby}.dir=ASC&hidden.equals=${hidden}`;

  if (id !== null) {
    query += "&id.equals=" + id;
  }
  if (consulenteId !== null) {
    query += "&consulenteId.equals=" + consulenteId;
  }
  if (gruppoId !== null && gruppoId !== -1) {
    query += "&gruppoId.equals=" + gruppoId;
  }
  if (esercizioId) {
    query += `&esercizioCommercialeId.equals=${esercizioId}`;
  }
  if (esercizioNome) {
    query += `&nomeEsercizio.contains=${encodeURIComponent(esercizioNome)}`;
  }
  if (manualeId) {
    query += `&manualeId.equals=${manualeId}`;
  }
  if (manualeTitolo) {
    query += `&titoloManuale.contains=${encodeURIComponent(manualeTitolo)}`;
  }
  const URL = BASE_URL + PATH + query;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};
export const getStatoFile = (revisioneId, stato) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/storage?revisioneId=" + revisioneId + "&tipo=" + stato;
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getStatoFileWithEsercizio = (revisioneId, stato, esercizio) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);

  // Controlla se lo stato è 'IN_ATTESA_PAGAMENTO' e imposta 'tipo' a 'BOZZA'
  const tipo = stato === "IN_ATTESA_PAGAMENTO" ? "BOZZA" : stato;

  const PATH = `/api/storage?revisioneId=${revisioneId}&tipo=${tipo}&esercizioId=${esercizio}`;
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getTotaleRevisioniManuali = (
  id,
  hidden,
  gruppoId,
  consulenteId,
  esercizioId,
  esercizioNome,
  manualeId,
  manualeTitolo
) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/revisioni/count";
  let query = `?personalizzato.equals=true&hidden.equals=${hidden}`;
  if (id !== null) {
    query += "&id.equals=" + id;
  }
  if (consulenteId !== null) {
    query += "&consulenteId.equals=" + consulenteId;
  }
  if (gruppoId !== null && gruppoId !== -1) {
    query += "&gruppoId.equals=" + gruppoId;
  }
  if (esercizioId) {
    query += `&esercizioCommercialeId.equals=${esercizioId}`;
  }
  if (esercizioNome) {
    query += `&nomeEsercizio.contains=${encodeURIComponent(esercizioNome)}`;
  }
  if (manualeId) {
    query += `&manualeId.equals=${manualeId}`;
  }
  if (manualeTitolo) {
    query += `&titoloManuale.contains=${encodeURIComponent(manualeTitolo)}`;
  }
  const URL = BASE_URL + PATH + query;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getRevisioneManuale = (revisioneId) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/revisioni/" + revisioneId;
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 404) {
      message = "Revisione manuale non trovata.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const updateRevisioneManuale = (revisione) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/revisioni";
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Errore salvataggio: i dati non sono corretti.";
    } else if (status === 404) {
      message = "Errore salvataggio: revisione manuale non trovata.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(revisione),
    }),
    errorFunction
  );
};

export const cancellaRevisione = (id, hidden) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/revisioni/cancella";
  const URL = BASE_URL + PATH;
  const requestBody = {
    id: id,
    hidden: hidden,
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      // Error 404 if ID does not correspond to an existing record:
      message = "Errore salvataggio: i dati non sono corretti.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};

// New API call for publishing a Manuale 'personalizzato'
export const pubblicaManuale = (manualeId) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/manuali/personalizzati/" + manualeId + "/pubblicazione";
  const URL = BASE_URL + PATH;
  const errorFunction = (status, serverMessage) => {
    let message = serverMessage || null;
    if (status === 403) {
      message =
        serverMessage ||
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message =
        serverMessage ||
        "Pubblicazione fallita: assicurarsi che l'esercizio commerciale collegato al manuale da pubblicare abbia accettato i termini d'uso e/o la privacy policy del servizio BS-FOOD.";
    } else if (status === 401) {
      message =
        serverMessage ||
        "Il servizio è in attesa del pagamento del canone annuale per l'esercizio commerciale selezionato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetchWithDetailedErrors(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};
