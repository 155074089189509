import { retrieveToken, getBrandId } from "../storage";
import { wrapFetch, ApiError } from "./api";

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getGruppi = (id, nome, page, size, sortby) => {
  const token = retrieveToken();
  const PATH = "/api/gruppi";
  let query =
    "?" +
    "page=" +
    page +
    "&size=" +
    size +
    "&sort=" +
    sortby +
    "&" +
    sortby +
    ".dir=ASC";
  if (id !== null) {
    query = query + "&id.equals=" + id;
  }
  if (nome !== null) {
    query += `&nome.contains=${encodeURIComponent(nome)}`;
  }
  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getCountGruppi = () => {
  const token = retrieveToken();
  const PATH = "/api/gruppi/count";
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getCountGruppiFiltered = (id, nome) => {
  const token = retrieveToken();
  const PATH = "/api/gruppi/count";
  let query = "?";
  if (id !== null) {
    query = query + "&id.equals=" + id;
  }
  if (nome !== null) {
    query += `&nome.contains=${encodeURIComponent(nome)}`;
  }
  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getGruppo = (gruppoId) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/gruppi/" + gruppoId;
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 404) {
      message =
        "Caricamento dati fallito: nessun gruppo corrispondente all'ID indicato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

//WARNING: this method contains a patch!
// If the brand obj in input and brandId is null or undefined,  the registered gruppo is associated to BSFOOD brand by default.
export const registerGruppo = (nome, brandObj) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/gruppi";
  const URL = BASE_URL + PATH;

  const BRANDID = getBrandId();
  let brand = {
    id: 60431,
    nome: "BSFOOD",
  };
  if (BRANDID !== null && BRANDID !== undefined) {
    brand = {
      id: BRANDID,
      nome: null,
    };
  }
  if (brandObj !== null && brandObj !== undefined) {
    brand = brandObj;
  }
  let requestBody = {
    nome: nome,
    brand,
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      //404 if the gruppoId does not correspond to a registered Gruppo
      message = "Registrazione non riuscita: dati non corretti.";
    } else if (status === 409) {
      message =
        "Registrazione non riuscita: esiste già un gruppo registrato con il nome indicato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};

export const createGruppo = (requestBody) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/gruppi";
  const URL = BASE_URL + PATH;

  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      //404 if the gruppoId does not correspond to a registered Gruppo
      message = "Registrazione non riuscita: dati non corretti.";
    } else if (status === 409) {
      message =
        "Registrazione non riuscita: esiste già un gruppo registrato con il nome indicato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};

export const updateGruppo = (requestBody) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/gruppi";
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Salvataggio non riuscito: i dati non sono corretti.";
    } else if (status === 409) {
      message =
        "Salvataggio non riuscito: esiste già un gruppo con lo stesso nome.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};
