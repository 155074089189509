import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PropTypes from "prop-types";
import { Checkbox } from "@material-ui/core";

import SpinnerComponent from "../../components/SpinnerComponent";
import ErrorDialog from "../../components/ErrorDialog";
import ActionButton from "../../components/ActionButton";

import { withStyles } from "@material-ui/styles";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
    padding: "10px",
  },
};

const styleToUse = (theme) => ({
  mainContainer: {
    textAlign: "center",
    color: "black",
    padding: "10px",
  },
  text: {
    color: theme.palette.primary.main,
  },
});

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkbox: true,
      data: {
        username: "",
        password: "",
      },
      validationErrors: {
        username: "",
        password: "",
      },
      errorDialogVisible: false,
      errorDialogMessage: "",
      errorDialogTitle: null,
      showPassword: false,
      loading: false,
    };
  }

  validateForm = () => {
    //Validate every property:
    let data = this.state.data;
    Object.keys(data).forEach((key) => {
      this.validateField(key, data[key]);
    });
  };
  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };
  validateField = (key, value) => {
    let validationErrors = this.state.validationErrors;
    switch (key) {
      case "username":
        validationErrors.username =
          value.length === 0 ? "Inserire lo username" : "";
        break;
      case "password":
        validationErrors.password =
          value.length < 8
            ? "La password deve contenere almeno 8 caratteri"
            : "";
        break;
      default:
        break;
    }
    this.setState({
      validationErrors,
    });
  };

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    let validationErrors = this.state.validationErrors;
    let data = this.state.data;
    data[name] = value;
    validationErrors[name] = "";

    this.setState({
      data,
      validationErrors,
    });
  };

  handleClickShowPassword = () => {
    let flag = !this.state.showPassword;
    this.setState({
      showPassword: flag,
    });
  };

  login = () => {
    this.validateForm();
    let isFormValid =
      this.state.validationErrors.username.length === 0 &&
      this.state.validationErrors.password.length === 0;
    if (isFormValid) {
      if (this.state.checkbox === true) {
        this.setState({
          loading: true,
        });
        this.props.onLoginButtonPressed(this.state.data);
      } else {
        const message =
          "Confermare di aver letto e accettato la privacy policy e i termini di servizio.";
        this.showErrorDialog("Avviso", message);
      }
    }
  };
  handlecheck = () => {
    this.setState({ checkbox: !this.state.checkbox });
  };
  showErrorDialog = (title, message) => {
    this.setState({
      errorDialogVisible: true,
      errorDialogTitle: title,
      errorDialogMessage: message,
    });
  };
  render() {
    const { classes, theme } = this.props;
    return (
      <div style={styles.mainContainer}>
        <Paper style={{ padding: "10px", minHeight: "400px" }}>
          <h2 className={classes.textToUse}>Login</h2>
          <form
            /* style={{display: 'flex', flexWrap: 'wrap'}} */ autoComplete="on"
          >
            <div style={{ margin: "10px" }}>
              <TextField
                label="Username"
                placeholder=""
                defaultValue={this.state.data.username}
                name="username"
                onChange={this.handleChange}
                variant="outlined"
                fullWidth
                helperText={this.state.validationErrors.username}
                error={
                  this.state.validationErrors.username.length > 0 ? true : false
                }
              />
            </div>
            <div style={{ margin: "10px" }}>
              <TextField
                label="Password"
                placeholder=""
                defaultValue={this.state.data.password}
                name="password"
                onChange={this.handleChange}
                variant="outlined"
                fullWidth
                type={this.state.showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Mostra password">
                        <IconButton
                          label="Mostra password"
                          onClick={this.handleClickShowPassword}
                        >
                          {this.state.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                helperText={this.state.validationErrors.password}
                error={
                  this.state.validationErrors.password.length > 0 ? true : false
                }
              />
            </div>
          </form>

          <Grid
            container
            spacing={8}
            direction="row"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12} sm={12} ml={12} style={{ color: "black" }}>
              <Checkbox
                defaultChecked
                onClick={this.handlecheck}
                required
                value={this.state.checkbox}
                style={{
                  color: theme.palette.primary.main,
                  "&.MuiChecked": {
                    color: theme.palette.primary.main,
                  },
                }}
              />
              Dichiaro di aver letto ed accettato la
              <span>&nbsp;&nbsp;</span>
              <a
                href="https://biorsaf.it/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy policy
              </a>
              <span>&nbsp;&nbsp;</span>e<span>&nbsp;&nbsp;</span>
              <a
                href="https://biorsaf.it/cookie-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                cookie policy
              </a>
            </Grid>
            <Grid item xs={12} sm={12} ml={12}>
              <label style={{ fontSize: "16px", widht: "100%" }}>
                Password dimenticata?
                <div style={{ position: "relative" }}>
                  <a
                    style={{
                      fontSize: "16px",
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "#183780",
                    }}
                    href={
                      theme.path !== ""
                        ? "/" + theme.path + "/recover_password"
                        : "/recover_password"
                    }
                  >
                    Clicca qui per recuperarla
                  </a>
                </div>
              </label>
            </Grid>
            <Grid item xs={12} sm={12} ml={12}>
              <ActionButton
                onClick={this.login}
                label="Accedi"
                disabled={this.state.loading}
                grayVersion={false}
              />
            </Grid>
            <Grid item xs={12} sm={12} ml={12}>
              {this.state.loading ? <SpinnerComponent size={24} /> : ""}
            </Grid>
          </Grid>
        </Paper>
        <ErrorDialog
          open={this.state.errorDialogVisible}
          title={this.state.errorDialogTitle}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
      </div>
    );
  }
}

LoginForm.propTypes = {
  onLoginButtonPressed: PropTypes.func.isRequired,
};
export default withStyles(styleToUse, { withTheme: true })(LoginForm);
