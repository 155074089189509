import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import SpinnerComponent from "../../../components/SpinnerComponent";
import ModalResetPsw from "../../../components/ModalResetPsw";
import EditRecordButton from "../components/EditRecordButton";
import ErrorDialog from "../../../components/ErrorDialog";
import NewRecordButton from "../components/NewRecordButton";

import UserActivationDialog from "../components/UserActivationDialog";
import SelezioneGruppo from "../components/SelezioneGruppo";

import { retrieveToken, clearToken } from "../../../utils/storage.js";
import {
  getResponsabiliSicurezza,
  getResponsabiliSicurezzaFiltered,
  getResponsabiliSicurezzaFilteredPerConsulente,
  getResponsabiliSicurezzaPerConsulente,
  getTotaleResponsabili,
  getTotaleResponsabiliFiltered,
  getTotaleResponsabiliPerConsulente,
  getTotaleResponsabiliPerConsulenteFiltered,
} from "../../../utils/api/responsabili_sicurezza_api";
import { getGruppi } from "../../../utils/api/gruppi_api";

import PropTypes from "prop-types";
import BasicTable from "../../../components/BasicTable.js";
import { InputAdornment, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
  },
};

export default class ResponsabiliSicurezzaTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      gruppoId: null,
      gruppi: [],
      loading: true,
      errorDialogVisible: false,
      errorDialogMessage: "",
      isResetPswDialogOpen: false,
      isUserActivationDialogOpen: false,
      isActivatingUser: false,
      selectedRecord: null,
      consulenteId: null,
      selectedEmail: "",
      openResetPswModal: false,
      totalPages: 10,
      loadedPage: 0,
      pageSize: 10,
      filter: {
        idResponsabile: "",
        gruppoId: "",
        email: "",
        nome: "",
        cognome: "",
        telefono: "",
      },
    };
  }

  componentDidMount() {
    let id = this.props.gruppoId;
    this.setState({
      gruppoId: id,
    });
    if (this.props.consulenteId === null) {
      // user is admin
      this.fetchGruppi();
    } else {
      this.fetchRecords(id);
    }
  }

  handleInvalidToken = () => {
    this.handleError(
      true,
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi."
    );
    clearToken();
    const { path } = this.props.theme;
    window.setTimeout(function () {
      window.location.href = `/${path}`;
    }, 4000);
  };

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  fetchRecords = (gruppoId) => {
    let consulenteId = this.props.consulenteId;
    if (consulenteId !== null && !this.props.superconsulenteView) {
      getTotaleResponsabiliPerConsulente(consulenteId)
        .then((totale) => {
          getResponsabiliSicurezzaPerConsulente(consulenteId, 0, 10, "id")
            .then((result) => {
              this.setState({
                records: result,
                loading: false,
                loadedPage: 0,
                totalPages:
                  Math.ceil(totale / 10) > 0 ? Math.ceil(totale / 10) : 1,
              });
            })
            .catch((error) => {
              if (error.status === 403) {
                this.handleInvalidToken();
              } else {
                this.setState({
                  loading: false,
                });
                this.handleError(true, error.message);
              }
            });
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
            });
            this.handleError(true, error.message);
          }
        });
    } else {
      // request executed by admin or superconsulente:
      getTotaleResponsabili(gruppoId)
        .then((countResult) =>
          getResponsabiliSicurezza(null, gruppoId, 0, 10, "id")
            .then((result) => {
              this.setState({
                records: result,
                loading: false,
                loadedPage: 0,
                totalPages:
                  Math.ceil(countResult / 10) > 0
                    ? Math.ceil(countResult / 10)
                    : 1,
                filter: {
                  idResponsabile: "",
                  gruppoId: "",
                  email: "",
                  nome: "",
                  cognome: "",
                  telefono: "",
                },
              });
            })
            .catch((error) => {
              if (error.status === 403) {
                this.handleInvalidToken();
              } else {
                this.setState({
                  loading: false,
                });
                this.handleError(true, error.message);
              }
            })
        )
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
            });
            this.handleError(true, error.message);
          }
        });
    }
  };

  fetchRecordForResearch = (loadedPage) => {
    const { filter } = this.state;
    let consulenteId = this.props.consulenteId;
    const gruppoId =
      this.props.consulenteId === null
        ? this.state.gruppoId !== -1
          ? this.state.gruppoId
          : filter.gruppoId
        : this.props.gruppoId;
    if (consulenteId !== null && !this.props.superconsulenteView) {
      getTotaleResponsabiliPerConsulenteFiltered(
        consulenteId,
        filter.email,
        filter.idResponsabile,
        filter.nome,
        filter.cognome,
        filter.telefono
      )
        .then((countResponsabile) => {
          getResponsabiliSicurezzaFilteredPerConsulente(
            consulenteId,
            filter.email,
            filter.idResponsabile,
            filter.nome,
            filter.cognome,
            filter.telefono,
            this.state.loadedPage,
            this.state.pageSize,
            "id"
          )
            .then((result) => {
              this.setState({
                records: result,
                totalPages:
                  Math.ceil(countResponsabile / 10) > 0
                    ? Math.ceil(countResponsabile / 10)
                    : 1,
                loading: false,
              });
            })
            .catch((error) => {
              if (error.status === 403) {
                this.handleInvalidToken();
              } else {
                this.setState({
                  loading: false,
                });
                this.handleError(true, error.message);
              }
            });
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
            });
            this.handleError(true, error.message);
          }
        });
    } else {
      //Amministratori e superconsulenti
      getTotaleResponsabiliFiltered(
        gruppoId,
        filter.idResponsabile,
        filter.email,
        filter.nome,
        filter.cognome,
        filter.telefono
      )
        .then((countResult) => {
          getResponsabiliSicurezzaFiltered(
            filter.idResponsabile,
            gruppoId,
            filter.email,
            filter.nome,
            filter.cognome,
            filter.telefono,
            this.state.loadedPage,
            this.state.pageSize,
            "id"
          ).then((response) => {
            this.setState({
              records: response,
              totalPages:
                Math.ceil(countResult / 10) > 0
                  ? Math.ceil(countResult / 10)
                  : 1,
              loading: false,
            });
          });
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
            });
            this.handleError(true, error.message);
          }
        });
    }
  };

  fetchRecordsForPageChange = (currentPage) => {
    let consulenteId = this.props.consulenteId;
    const { filter } = this.state;
    const gruppoId =
      this.props.consulenteId === null
        ? this.state.gruppoId !== -1
          ? this.state.gruppoId
          : filter.gruppoId
        : this.props.gruppoId;
    if (consulenteId !== null && !this.props.superconsulenteView) {
      getResponsabiliSicurezzaFilteredPerConsulente(
        consulenteId,
        filter.email,
        filter.idResponsabile,
        filter.nome,
        filter.cognome,
        filter.telefono,
        currentPage,
        10,
        "id"
      )
        .then((result) => {
          this.setState({
            records: result,
            loading: false,
          });
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
            });
            this.handleError(true, error.message);
          }
        });
    } else {
      // request executed by admin or superconsulente:
      getResponsabiliSicurezzaFiltered(
        filter.idResponsabile,
        gruppoId,
        filter.email,
        filter.nome,
        filter.cognome,
        filter.telefono,
        currentPage,
        this.state.pageSize,
        "id"
      )
        .then((response) => {
          this.setState({
            records: response,
            loading: false,
          });
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
            });
            this.handleError(true, error.message);
          }
        });
    }
  };

  onPageChange = (pageIndex) => {
    this.setState(
      {
        loadedPage: pageIndex,
      },
      () => this.fetchRecordsForPageChange(this.state.loadedPage)
    );
  };

  fetchGruppi = () => {
    this.setState({
      loading: true,
    });
    getGruppi(null, null, 0, 1000, "id")
      .then((result) => {
        let newarray = [{ id: -1, nome: "Tutti i gruppi" }];
        newarray.push(...result);
        let gruppoId = this.state.gruppoId;
        if (gruppoId === null) {
          gruppoId = -1;
        }
        this.setState({
          gruppi: newarray,
          gruppoId,
        });
        this.fetchRecords(this.state.gruppoId);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };

  updateUserStatus = () => {
    this.setState({
      loading: true,
    });
    let token = retrieveToken();
    if (token === null) {
      // If token was deleted, redirect to home page:
      this.handleInvalidToken();
    } else {
      let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
      let authtoken = "Bearer ".concat(token);
      let userid = this.state.selectedRecord["userId"];
      let path = "/api/utenti/" + userid + "/attiva";
      if (!this.state.isActivatingUser) {
        path = "/api/utenti/" + userid + "/disattiva";
      }
      fetch(ENDPOINT + path, {
        method: "POST",
        headers: {
          Authorization: authtoken,
          "Content-Type": "application/json",
        },
        withCredentials: true,
        credentials: "include",
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            return response;
          } else {
            if (status === 401 || status === 403) {
              let statusToString = "" + status;
              throw new Error(statusToString);
            } else {
              throw new Error(response.message);
            }
          }
        })
        .then((result) => {
          this.fetchRecords(this.state.gruppoId);
        })
        .catch((error) => {
          //Reset activated flag to origina value:
          this.resetUserActivatedFlag();
          //Display error:
          const msg = error.message;
          if (msg === "401" || msg === "403") {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
            });
            this.handleError(
              true,
              "Si è verificato un errore. Operazione non riuscita."
            );
          }
        });
    }
  };

  onResetPswButtonClicked = (email) => {
    this.setState({
      selectedEmail: email,
      openResetPswModal: true,
    });
  };

  onResetPswModalClosed = (isOperationCancelled) => {
    this.setState({
      openResetPswModal: false,
    });
  };

  onResetPswFailedForAuthError = () => {
    this.setState({
      openResetPswModal: false,
    });
    this.handleInvalidToken();
  };

  onStatusChanged = (record, event) => {
    let flag = event.target.value;
    const elementIndex = this.state.records.findIndex(
      (element) => element.id === record.id
    );
    let newArray = [...this.state.records];
    newArray[elementIndex] = {
      ...newArray[elementIndex],
      activated: !newArray[elementIndex].activated,
    };
    this.setState({
      records: newArray,
      selectedRecord: newArray[elementIndex],
      isUserActivationDialogOpen: true,
      isActivatingUser: flag,
    });
  };

  onStatusChangeConfirmed = (hasConfirmed) => {
    this.closeUserActivationDialog();
    // Proceed with POST only if user confirmed the operation:
    if (hasConfirmed) {
      this.updateUserStatus();
    } else {
      //otherwise reset activated flag to the original value:
      this.resetUserActivatedFlag();
    }
  };

  resetUserActivatedFlag = () => {
    const elementIndex = this.state.records.findIndex(
      (element) => element.id === this.state.selectedRecord.id
    );
    let newArray = [...this.state.records];
    newArray[elementIndex] = {
      ...newArray[elementIndex],
      activated: !newArray[elementIndex].activated,
    };
    this.setState({
      records: newArray,
    });
  };

  closeUserActivationDialog = () => {
    this.setState({
      isUserActivationDialogOpen: false,
    });
  };

  handleSelectedGruppo = (gruppoId) => {
    this.setState({
      gruppoId,
      loading: true,
    });
    this.fetchRecords(gruppoId);
  };

  generateFilterColumn = ({ id, placeholder, filterValue, onChange }) => {
    return {
      Header: placeholder,
      id: id,
      accessor: (d) => d[id],
      filterable: true,
      minWidth: 120,
      Filter: (
        <TextField
          variant="outlined"
          fullWidth
          value={filterValue}
          onChange={(e) => {
            const value = e.target.value;
            onChange(value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => {
                    this.setState(
                      {
                        loadedPage: 0,
                      },
                      () => {
                        this.fetchRecordForResearch(0);
                      }
                    );
                  }}
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ),
    };
  };

  getColumns = () => {
    let isSuperConsulente = this.props.superconsulenteView;
    let isAdmin =
      !this.props.superconsulenteView && this.props.consulenteId === null;
    if (isAdmin) {
      return [
        {
          Header: "Numero",
          id: "numero",
          accessor: (row) => row,
          width: 120,
          filterable: false,
          Cell: (props) =>
            this.state.loadedPage === 0
              ? "" + props.index
              : this.state.loadedPage + "" + props.index,
        },
        this.generateFilterColumn({
          id: "id",
          placeholder: "ID responsabile",
          filterValue: this.state.filter.idResponsabile,
          onChange: (value) => {
            if (/^\d*$/.test(value)) {
              this.setState((prevState) => ({
                filter: {
                  ...prevState.filter,
                  idResponsabile: value,
                },
              }));
            }
          },
        }),
        {
          id: "gruppoId",
          Header: "ID gruppo",
          accessor: (row) => row.gruppoId,
          filterable: false,
        },
        this.generateFilterColumn({
          id: "email",
          placeholder: "Email",
          filterValue: this.state.filter.email,
          onChange: (value) => {
            this.setState((prevState) => ({
              filter: {
                ...prevState.filter,
                email: value,
              },
            }));
          },
        }),
        this.generateFilterColumn({
          id: "nome",
          placeholder: "Nome",
          filterValue: this.state.filter.nome,
          onChange: (value) => {
            this.setState((prevState) => ({
              filter: {
                ...prevState.filter,
                nome: value,
              },
            }));
          },
        }),
        this.generateFilterColumn({
          id: "cognome",
          placeholder: "Cognome",
          filterValue: this.state.filter.cognome,
          onChange: (value) => {
            this.setState((prevState) => ({
              filter: {
                ...prevState.filter,
                cognome: value,
              },
            }));
          },
        }),
        this.generateFilterColumn({
          id: "telefono",
          placeholder: "Telefono",
          filterValue: this.state.filter.telefono,
          onChange: (value) => {
            this.setState((prevState) => ({
              filter: {
                ...prevState.filter,
                telefono: value,
              },
            }));
          },
        }),
        {
          Header: "Stato",
          id: "activated",
          accessor: (row) => row,
          width: 120,
          filterable: false,
          Cell: (props) => (
            <FormControl>
              <Select
                value={props.value.activated}
                name="activated"
                onChange={(event) => {
                  this.onStatusChanged(props.value, event);
                }}
              >
                <MenuItem value={true}>Attivo</MenuItem>
                <MenuItem value={false}>Non attivo</MenuItem>
              </Select>
            </FormControl>
          ),
        },
        {
          id: "editBtn",
          Header: "Modifica",
          accessor: (row) => row,
          width: 80,
          filterable: false,
          Cell: (props) => (
            <EditRecordButton recordId={props.value.id} disabled={false} />
          ),
        },
        {
          id: "resetPswBtn",
          Header: "Reset password",
          accessor: (row) => row,
          width: 130,
          filterable: false,
          Cell: (props) => (
            <IconButton
              style={{ color: this.props.theme.palette.primary.main }}
              aria-label="reset psw"
              size="small"
              onClick={() => {
                this.onResetPswButtonClicked(props.value.email);
              }}
            >
              <VpnKeyIcon />
            </IconButton>
          ),
        },
      ];
    } else if (isSuperConsulente) {
      return [
        {
          Header: "Numero",
          id: "numero",
          accessor: (row) => row,
          width: 120,
          filterable: false,
          Cell: (props) =>
            this.state.loadedPage === 0
              ? "" + props.index
              : this.state.loadedPage + "" + props.index,
        },
        this.generateFilterColumn({
          id: "id",
          placeholder: "ID responsabile",
          filterValue: this.state.filter.idResponsabile,
          onChange: (value) => {
            if (/^\d*$/.test(value)) {
              this.setState((prevState) => ({
                filter: {
                  ...prevState.filter,
                  idResponsabile: value,
                },
              }));
            }
          },
        }),
        this.generateFilterColumn({
          id: "email",
          placeholder: "Email",
          filterValue: this.state.filter.email,
          onChange: (value) => {
            this.setState((prevState) => ({
              filter: {
                ...prevState.filter,
                email: value,
              },
            }));
          },
        }),
        this.generateFilterColumn({
          id: "nome",
          placeholder: "Nome",
          filterValue: this.state.filter.nome,
          onChange: (value) => {
            this.setState((prevState) => ({
              filter: {
                ...prevState.filter,
                nome: value,
              },
            }));
          },
        }),
        this.generateFilterColumn({
          id: "cognome",
          placeholder: "Cognome",
          filterValue: this.state.filter.cognome,
          onChange: (value) => {
            this.setState((prevState) => ({
              filter: {
                ...prevState.filter,
                cognome: value,
              },
            }));
          },
        }),
        this.generateFilterColumn({
          id: "telefono",
          placeholder: "Telefono",
          filterValue: this.state.filter.telefono,
          onChange: (value) => {
            this.setState((prevState) => ({
              filter: {
                ...prevState.filter,
                telefono: value,
              },
            }));
          },
        }),
        {
          Header: "Stato",
          id: "activated",
          accessor: (row) => row,
          width: 120,
          filterable: false,
          Cell: (props) => (
            <FormControl>
              <Select
                value={props.value.activated}
                name="activated"
                onChange={(event) => {
                  this.onStatusChanged(props.value, event);
                }}
              >
                <MenuItem value={true}>Attivo</MenuItem>
                <MenuItem value={false}>Non attivo</MenuItem>
              </Select>
            </FormControl>
          ),
        },
        {
          id: "editBtn",
          Header: "Modifica",
          accessor: (row) => row,
          width: 80,
          filterable: false,
          Cell: (props) => (
            <EditRecordButton recordId={props.value.id} disabled={false} />
          ),
        },
        {
          id: "resetPswBtn",
          Header: "Reset password",
          accessor: (row) => row,
          width: 130,
          filterable: false,
          Cell: (props) => (
            <IconButton
              style={{ color: this.props.theme.palette.primary.main }}
              aria-label="reset psw"
              size="small"
              onClick={() => {
                this.onResetPswButtonClicked(props.value.email);
              }}
            >
              <VpnKeyIcon />
            </IconButton>
          ),
        },
      ];
    }
    return [
      {
        Header: "Numero",
        id: "numero",
        accessor: (row) => row,
        width: 120,
        filterable: false,
        Cell: (props) =>
          this.state.loadedPage === 0
            ? "" + props.index
            : this.state.loadedPage + "" + props.index,
      },
      this.generateFilterColumn({
        id: "id",
        placeholder: "ID responsabile",
        filterValue: this.state.filter.idResponsabile,
        onChange: (value) => {
          if (/^\d*$/.test(value)) {
            this.setState((prevState) => ({
              filter: {
                ...prevState.filter,
                idResponsabile: value,
              },
            }));
          }
        },
      }),
      this.generateFilterColumn({
        id: "email",
        placeholder: "Email",
        filterValue: this.state.filter.email,
        onChange: (value) => {
          this.setState((prevState) => ({
            filter: {
              ...prevState.filter,
              email: value,
            },
          }));
        },
      }),
      this.generateFilterColumn({
        id: "nome",
        placeholder: "Nome",
        filterValue: this.state.filter.nome,
        onChange: (value) => {
          this.setState((prevState) => ({
            filter: {
              ...prevState.filter,
              nome: value,
            },
          }));
        },
      }),
      this.generateFilterColumn({
        id: "cognome",
        placeholder: "Cognome",
        filterValue: this.state.filter.cognome,
        onChange: (value) => {
          this.setState((prevState) => ({
            filter: {
              ...prevState.filter,
              cognome: value,
            },
          }));
        },
      }),
      this.generateFilterColumn({
        id: "telefono",
        placeholder: "Telefono",
        filterValue: this.state.filter.telefono,
        onChange: (value) => {
          this.setState((prevState) => ({
            filter: {
              ...prevState.filter,
              telefono: value,
            },
          }));
        },
      }),
      {
        Header: "Stato",
        id: "activated",
        accessor: (row) => row,
        width: 120,
        filterable: false,
        Cell: (props) => (props.value.activated ? "ATTIVO" : "NON ATTIVO"),
      },
      {
        id: "editBtn",
        Header: "Modifica",
        accessor: (row) => row,
        width: 80,
        filterable: false,
        Cell: (props) => (
          <EditRecordButton recordId={props.value.id} disabled={false} />
        ),
      },
      {
        id: "resetPswBtn",
        Header: "Reset password",
        accessor: (row) => row,
        width: 130,
        filterable: false,
        Cell: (props) => (
          <IconButton
            style={{ color: this.props.theme.palette.primary.main }}
            aria-label="reset psw"
            size="small"
            onClick={() => {
              this.onResetPswButtonClicked(props.value.email);
            }}
          >
            <VpnKeyIcon />
          </IconButton>
        ),
      },
    ];
  };

  render() {
    const columns = this.getColumns();
    const isAdmin =
      this.props.consulenteId === null && !this.props.superconsulenteView;

    return (
      <div style={{ paddingTop: "30px", minHeight: "400px" }}>
        {this.state.loading ? (
          <SpinnerComponent size={24} />
        ) : (
          <div style={styles.mainContainer}>
            {isAdmin && (
              <SelezioneGruppo
                gruppoId={this.state.gruppoId}
                gruppi={this.state.gruppi}
                disabled={false}
                description="Selezionare il gruppo su cui filtrare i risultati:"
                onGruppoSelected={this.handleSelectedGruppo}
              />
            )}
            <div
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                textAlign: "right",
              }}
            >
              <NewRecordButton disabled={false} />
            </div>
            <BasicTable
              filterable={false}
              resizable={true}
              sortable={false}
              defaultPageSize={this.state.pageSize}
              page={this.state.loadedPage}
              pages={this.state.totalPages}
              onPageChange={this.onPageChange}
              data={this.state.records}
              columns={columns}
            />
          </div>
        )}
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
        <UserActivationDialog
          isOpen={this.state.isUserActivationDialogOpen}
          isActivatingUser={this.state.isActivatingUser}
          onButtonClicked={this.onStatusChangeConfirmed}
        />
        {this.state.openResetPswModal ? (
          <ModalResetPsw
            theme={this.props.theme}
            email={this.state.selectedEmail}
            open={this.state.openResetPswModal}
            onClose={this.onResetPswModalClosed}
            onAuthError={this.onResetPswFailedForAuthError}
          />
        ) : null}
      </div>
    );
  }
}

ResponsabiliSicurezzaTable.propTypes = {
  consulenteId: PropTypes.number,
  gruppoId: PropTypes.number,
  superconsulenteView: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
};
