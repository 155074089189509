import React from "react";
import { withStyles } from "@material-ui/styles";
import {
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import PropTypes from "prop-types";

const styles = (theme) => ({
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  modalSectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "94%",
  },
  valueWrapper: {
    display: "flex",
    gap: 10,
    margin: 10,
  },
  paperContainer: {
    display: "flex",
    justifyContent: "center",
    gap: 10,
    margin: 20,
    width: "100%",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: 10,
    flex: 1,
    padding: 30,
  },
  tooltipWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1),
  },
});

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.black,
    maxWidth: 300,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 8,
    boxShadow: theme.shadows[6],
  },
  arrow: {
    color: theme.palette.secondary.main,
  },
}))(Tooltip);

const ParametriSection = ({ classes, mode, state, handleInputChange }) => {
  const renderTPMSection = () => (
    <div className={classes.modalSectionContainer}>
      <div className={classes.flexColumn}>
        <div className={classes.valueWrapper}>
          <Typography>Valore minimo di conformità</Typography>
          <TextField
            name="TPM_Minimo"
            value={state.TPM_Minimo ?? ""}
            onChange={handleInputChange}
            size="small"
            type="number"
            inputProps={{ min: 0, max: 100, step: 0.1 }}
          />
          <Typography>%</Typography>
        </div>
        <div className={classes.valueWrapper}>
          <Typography>Valore massimo di conformità</Typography>
          <TextField
            name="TPM_Massimo"
            value={state.TPM_Massimo ?? ""}
            onChange={handleInputChange}
            size="small"
            type="number"
            inputProps={{ min: 0, max: 100, step: 0.1 }}
          />
          <Typography>%</Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 10,
            margin: 10,
          }}
        >
          <Typography>Valore Standard</Typography>
          <TextField
            name="TPM_Standard"
            value={state.TPM_Standard ?? ""}
            onChange={handleInputChange}
            type="number"
            size="small"
            inputProps={{ min: 0, max: 100, step: 0.1 }}
          />
          <Typography>%</Typography>
        </div>
      </div>
    </div>
  );

  const renderTemperaturaSection = () => (
    <div className={classes.modalSectionContainer}>
      <div className={classes.flexColumn}>
        <div className={classes.valueWrapper}>
          <Typography>Temperatura minima di conformità</Typography>
          <TextField
            name="temperaturaMinima"
            value={state.temperaturaMinima ?? ""}
            onChange={handleInputChange}
            size="small"
            type="number"
          />
          <Typography>°C</Typography>
        </div>
        <div className={classes.valueWrapper}>
          <Typography>Temperatura massima di conformità</Typography>
          <TextField
            name="temperaturaMassima"
            value={state.temperaturaMassima ?? ""}
            onChange={handleInputChange}
            size="small"
            type="number"
          />
          <Typography>°C</Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 10,
            margin: 10,
          }}
        >
          <Typography>Temperatura Standard</Typography>
          <TextField
            name="temperaturaConsigliata"
            value={state.temperaturaConsigliata ?? ""}
            onChange={handleInputChange}
            type="number"
            size="small"
          />
          <Typography>°C</Typography>
        </div>
      </div>
    </div>
  );

  const renderOlioFriggitriceSection = () => (
    <div className={classes.modalSectionContainer}>
      <div className={classes.paperContainer}>
        <Paper elevation={2} className={classes.paper}>
          <Typography style={{ fontWeight: "bold", marginBottom: 10 }}>
            TPM
          </Typography>
          <div className={classes.tooltipWrapper}>
            <Typography>
              Inserire i valori del range di tollerabilità:
            </Typography>
            <CustomTooltip
              title={
                <>
                  <Typography variant="caption">
                    Per valori compresi nel range di tollerabilità all'operatore
                    verrà chiesto di effettuare il controllo pomeridiano.
                  </Typography>
                  <br />
                  <Typography variant="caption">
                    <b>N.B.</b> La non conformità si attiva solo al superamento
                    del <u>limite massimo</u> indicato.
                  </Typography>
                </>
              }
              arrow
              placement="right"
            >
              <InfoOutlined fontSize="small" color="secondary" />
            </CustomTooltip>
          </div>
          <TextField
            fullWidth
            label="Limite minimo"
            name="TPM_Minimo"
            type="number"
            value={state.TPM_Minimo ?? ""}
            onChange={handleInputChange}
            inputProps={{ min: 0, max: 100, step: 0.1 }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <TextField
            fullWidth
            label="Limite massimo"
            name="TPM_Massimo"
            type="number"
            value={state.TPM_Massimo ?? ""}
            onChange={handleInputChange}
            inputProps={{ min: 0, max: 100, step: 0.1 }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Paper>
        <Paper elevation={2} className={classes.paper}>
          <Typography style={{ fontWeight: "bold", marginBottom: 10 }}>
            Temperatura
          </Typography>
          <TextField
            fullWidth
            label="Temperatura minima"
            name="temperaturaMinima"
            value={state.temperaturaMinima ?? ""}
            onChange={handleInputChange}
            size="small"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">°C</InputAdornment>,
            }}
          />
          <TextField
            fullWidth
            label="Temperatura massima"
            name="temperaturaMassima"
            value={state.temperaturaMassima ?? ""}
            onChange={handleInputChange}
            size="small"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">°C</InputAdornment>,
            }}
          />
          <TextField
            fullWidth
            label="Temperatura standard"
            name="temperaturaConsigliata"
            value={state.temperaturaConsigliata ?? ""}
            onChange={handleInputChange}
            type="number"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">°C</InputAdornment>,
            }}
          />
        </Paper>
      </div>
    </div>
  );

  switch (mode) {
    case "TPM":
      return renderTPMSection();
    case "CONTROLLO_TEMPERATURE":
      return renderTemperaturaSection();
    case "OLIO_FRIGGITRICE":
      return renderOlioFriggitriceSection();
    default:
      return null;
  }
};

ParametriSection.propTypes = {
  classes: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(ParametriSection);
