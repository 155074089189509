import { retrieveToken } from "../storage";
import { wrapFetch, ApiError } from "./api";

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getConsulenti = (consulenteId, gruppoId, page, size, sortby) => {
  const token = retrieveToken();
  const PATH = "/api/consulenti";
  let query =
    "?" +
    "page=" +
    page +
    "&size=" +
    size +
    "&sort=" +
    sortby +
    "&" +
    sortby +
    ".dir=ASC";
  if (consulenteId !== null) {
    query = query + "&id.equals=" + consulenteId;
  }
  if (gruppoId !== null && gruppoId !== -1) {
    query = query + "&gruppoId.equals=" + gruppoId;
  }
  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getConsulentiFiltered = (
  consulenteId,
  gruppoId,
  page,
  size,
  sortby,
  email,
  nome,
  cognome,
  telefono
) => {
  const token = retrieveToken();
  const PATH = "/api/consulenti";
  let query =
    "?" +
    "page=" +
    page +
    "&size=" +
    size +
    "&sort=" +
    sortby +
    "&" +
    sortby +
    ".dir=ASC";
  if (consulenteId !== null && consulenteId !== "") {
    query = query + "&id.equals=" + consulenteId;
  }
  if (gruppoId !== null && gruppoId !== -1) {
    query = query + "&gruppoId.equals=" + gruppoId;
  }
  if (email !== null && email !== "") {
    query += `&email.contains=${encodeURIComponent(email)}`;
  }
  if (nome !== null && nome !== "") {
    query += `&nome.contains=${encodeURIComponent(nome)}`;
  }
  if (cognome !== null && cognome !== "") {
    query += `&cognome.contains=${encodeURIComponent(cognome)}`;
  }
  if (telefono) {
    query += `&telefono.contains=${encodeURIComponent(telefono)}`;
  }
  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getTotaleConsulenti = (
  gruppoId,
  consulenteId,
  email,
  nome,
  cognome,
  telefono
) => {
  const token = retrieveToken();
  let PATH = "/api/consulenti/count";
  let hasQuery = false;

  if (gruppoId && gruppoId !== -1) {
    PATH += (hasQuery ? "&" : "?") + `gruppoId.equals=${gruppoId}`;
    hasQuery = true;
  }
  if (consulenteId) {
    PATH += (hasQuery ? "&" : "?") + `id.equals=${consulenteId}`;
    hasQuery = true;
  }
  if (email) {
    PATH +=
      (hasQuery ? "&" : "?") + `email.contains=${encodeURIComponent(email)}`;
    hasQuery = true;
  }
  if (nome) {
    PATH +=
      (hasQuery ? "&" : "?") + `nome.contains=${encodeURIComponent(nome)}`;
    hasQuery = true;
  }
  if (cognome) {
    PATH +=
      (hasQuery ? "&" : "?") +
      `cognome.contains=${encodeURIComponent(cognome)}`;
    hasQuery = true;
  }
  if (telefono) {
    PATH +=
      (hasQuery ? "&" : "?") +
      `telefono.contains=${encodeURIComponent(telefono)}`;
    hasQuery = true;
  }
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const registerConsulente = (isSuperconsulenteView, formData) => {
  if (isSuperconsulenteView) {
    return registerConsulenteWithoutRole(formData);
  } else {
    return registerConsulenteWithRole(formData);
  }
};

const registerConsulenteWithoutRole = (formData) => {
  const token = retrieveToken();
  const PATH = "/api/consulenti/registrazione";
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const requestBody = {
    email: formData.email,
    password: formData.password,
    nome: formData.nome,
    cognome: formData.cognome,
    telefono: formData.telefono,
    terminiUsoAccettati: true,
    privacyPolicyAccettata: true,
    codiceSdi: formData.codiceSdi,
    pec: formData.pec,
    partitaIva: formData.partitaIva,
    gruppoId: formData.gruppoId,
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      //404 if the gruppoId does not correspond to a registered Gruppo
      message = "Registrazione non riuscita: dati non corretti.";
    } else if (status === 409) {
      message =
        "Registrazione non riuscita: esiste già un utente registrato con lo stesso indirizzo email indicato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};

const registerConsulenteWithRole = (formData) => {
  const token = retrieveToken();
  const PATH = "/api/consulente";
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const requestBody = {
    email: formData.email,
    password: formData.password,
    nome: formData.nome,
    cognome: formData.cognome,
    telefono: formData.telefono,
    terminiUsoAccettati: true,
    privacyPolicyAccettata: true,
    codiceSdi: formData.codiceSdi,
    pec: formData.pec,
    partitaIva: formData.partitaIva,
    gruppoId: formData.gruppoId,
    superconsulente: formData.superconsulente,
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      //404 if the gruppoId does not correspond to a registered Gruppo
      message = "Registrazione non riuscita: dati non corretti.";
    } else if (status === 409) {
      message =
        "Registrazione non riuscita: esiste già un utente registrato con lo stesso indirizzo email indicato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};

export const updateConsulente = (isAdmin, requestBody) => {
  const token = retrieveToken();
  let PATH = "/api/consulenti";
  if (isAdmin) {
    PATH = "/api/consulente";
  }
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Errore salvataggio: i dati non sono corretti.";
    } else if (status === 404) {
      message = "Errore salvataggio: consulente non trovato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};
