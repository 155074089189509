import React, { Component } from "react";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@material-ui/core";

import SpinnerComponent from "../../../components/SpinnerComponent";
import ErrorDialog from "../../../components/ErrorDialog";
import EditRecordButton from "../components/EditRecordButton";
import NewRecordButton from "../components/NewRecordButton";
import BasicTable from "../../../components/BasicTable.js";
import SelezioneGruppo from "../components/SelezioneGruppo";
import ModalResponsabiliEsercizio from "../components/ModalResponsabiliEsercizio";

import { clearToken } from "../../../utils/storage.js";
import {
  getCountEserciziCommerciali,
  getEserciziCommerciali,
  getEserciziCommercialiFiltered,
} from "../../../utils/api/esercizi_commerciali_api";
import { getResponsabiliSicurezzaEsercizio } from "../../../utils/api/responsabili_sicurezza_api";
import { getGruppi } from "../../../utils/api/gruppi_api";
import { Lens, Search } from "@material-ui/icons";

import PropTypes from "prop-types";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
  },
  newRecordButtonWrapper: {
    paddingTop: "10px",
    paddingBottom: "10px",
    textAlign: "right",
  },
};

export default class EserciziCommercialiTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      esercizi: [],
      gruppoId: null,
      gruppi: [],
      gruppoInfo: null,
      loading: true,
      errorDialogVisible: false,
      errorDialogTitle: null,
      errorDialogMessage: "",
      isModalResponsabiliOpen: false,
      selectedEsercizioName: "",
      responsabiliEsercizio: [],
      loadingModalResponsabili: false,
      errorModalResponsabili: "",
      totalPages: 1,
      loadedPage: 0,
      pageSize: 10,
      filter: {
        esercizioId: "",
        gruppoId: "",
        consulenteId: "",
        ragioneSociale: "",
        partitaIva: "",
        telefono: "",
        cittaSede: "",
      },
    };
  }

  componentDidMount() {
    if (this.props.consulenteId === null) {
      //User is Admin
      this.fetchGruppi();
    } else {
      let id = this.props.gruppoId;
      this.setState({
        gruppoId: id,
      });
      this.getGruppoInfo(id);
      this.fetchEserciziCommerciali(id);
    }
  }

  handleInvalidToken = () => {
    this.handleError(
      true,
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi."
    );
    clearToken();
    const { path } = this.props.theme;
    window.setTimeout(function () {
      window.location.href = `/${path}`;
    }, 4000);
  };

  openErrorDialog = (errorMessage) => {
    this.setState({
      errorDialogTitle: null,
      errorDialogVisible: true,
      errorDialogMessage: errorMessage,
    });
  };

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  fetchGruppi = () => {
    getGruppi(null, null, 0, 1000, "id")
      .then((result) => {
        let newarray = [{ id: -1, nome: "Tutti i gruppi" }];
        newarray.push(...result);
        let gruppoId = this.state.gruppoId;
        if (gruppoId === null) {
          gruppoId = -1;
        }
        this.setState({
          gruppi: newarray,
          gruppoId,
        });
        this.fetchEserciziCommerciali(gruppoId);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };

  fetchEserciziCommerciali = (gruppoId) => {
    const isAdmin = this.props.consulenteId === null;
    const isConsulente =
      this.props.consulenteId !== null && !this.props.superconsulenteView;

    const consulenteId = isConsulente ? this.props.consulenteId : null;

    if (!isAdmin) {
      gruppoId = this.props.gruppoId;
    }

    this.setState({
      loading: true,
    });
    getCountEserciziCommerciali(gruppoId, consulenteId)
      .then((countResponse) => {
        return getEserciziCommerciali(
          gruppoId,
          consulenteId,
          null,
          0,
          this.state.pageSize,
          "id"
        ).then((result) => {
          this.setState({
            loadedPage: 0,
            esercizi: result,
            totalPages:
              countResponse !== 0
                ? Math.ceil(countResponse / this.state.pageSize)
                : 1,
          });
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      })
      .finally(() =>
        this.setState({
          loading: false,
        })
      );
  };

  getGruppoInfo = (gruppoId) => {
    /*getGruppo(gruppoId).then((response) => {
      this.setState({
        gruppoInfo:response,
      })
    }).catch((error) => {
      if (error.status === 403) {
       // this.handleInvalidToken();
      } else {
        this.handleError(true, error.message);
      }
    })*/
  };

  shouldStatoAbbonamentoShowUp = () => {
    const isAdmin = this.props.consulenteId === null;
    if (isAdmin) {
      return true;
    } else {
      let gruppoId = this.props.gruppoId;
      return true;
    }
  };

  fetchEserciziCommercialiForResearch = (loadedPage) => {
    const { filter, pageSize } = this.state;
    const isAdmin = this.props.consulenteId === null;
    const isConsulente =
      this.props.consulenteId !== null && !this.props.superconsulenteView;

    const consulenteId = isConsulente
      ? this.props.consulenteId
      : filter.consulenteId;

    let gruppoId = isAdmin
      ? this.state.gruppoId !== -1
        ? this.state.gruppoId
        : filter.gruppoId
      : this.props.gruppoId;

    getCountEserciziCommerciali(
      gruppoId,
      consulenteId,
      filter.esercizioId,
      filter.ragioneSociale,
      filter.partitaIva,
      filter.telefono,
      filter.cittaSede
    )
      .then((countResponse) => {
        return getEserciziCommercialiFiltered(
          gruppoId,
          consulenteId,
          null,
          loadedPage,
          pageSize,
          "id",
          null,
          null,
          filter.esercizioId,
          filter.ragioneSociale,
          filter.partitaIva,
          filter.telefono,
          filter.cittaSede
        ).then((response) =>
          this.setState({
            loadedPage: 0,
            esercizi: response,
            totalPages:
              countResponse !== 0 ? Math.ceil(countResponse / pageSize) : 1,
          })
        );
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  fetchEserciziCommercialiForPageChange = (loadedPage) => {
    const { filter, pageSize } = this.state;
    const isAdmin = this.props.consulenteId === null;
    const isConsulente =
      this.props.consulenteId !== null && !this.props.superconsulenteView;
    const gruppoId = isAdmin ? filter.gruppoId : this.props.gruppoId;
    const consulenteId = isConsulente
      ? this.props.consulenteId
      : filter.consulenteId;

    const effectiveGruppoId =
      this.state.gruppoId !== null && this.state.gruppoId !== -1
        ? this.state.gruppoId
        : gruppoId;

    getEserciziCommercialiFiltered(
      effectiveGruppoId,
      consulenteId,
      null,
      loadedPage,
      pageSize,
      "id",
      null,
      null,
      filter.esercizioId,
      filter.ragioneSociale,
      filter.partitaIva,
      filter.telefono,
      filter.cittaSede
    )
      .then((response) => this.setState({ esercizi: response }))
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  onPageChange = (pageIndex) => {
    this.setState({ loadedPage: pageIndex }, () =>
      this.fetchEserciziCommercialiForPageChange(this.state.loadedPage)
    );
  };

  compareDates = (b, a) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  };

  handleSelectedGruppo = (gruppoId) => {
    this.setState(
      {
        gruppoId,

        filter: {
          esercizioId: "",
          gruppoId: "",
          consulenteId: "",
          ragioneSociale: "",
          partitaIva: "",
          telefono: "",
          cittaSede: "",
        },
      },
      () => this.fetchEserciziCommerciali(gruppoId)
    );
  };

  generateFilterColumn = ({ id, placeholder, filterValue, onChange }) => {
    return {
      Header: placeholder,
      id: id,
      accessor: (d) => d[id],
      filterable: true,
      minWidth: 120,
      Filter: (
        <TextField
          variant="outlined"
          fullWidth
          value={filterValue}
          onChange={(e) => {
            const value = e.target.value;
            onChange(value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => this.fetchEserciziCommercialiForResearch(0)}
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ),
    };
  };

  getCommonColumns = () => {
    return [
      {
        Header: "Numero",
        id: "numero",
        accessor: (row) => row,
        minWidth: 120,
        filterable: false,
        Cell: (props) =>
          this.state.loadedPage === 0
            ? "" + props.index
            : this.state.loadedPage + "" + props.index,
      },
      this.generateFilterColumn({
        id: "id",
        placeholder: "ID esercizio",
        filterValue: this.state.filter.esercizioId,
        onChange: (value) => {
          if (/^\d*$/.test(value)) {
            this.setState((prevState) => ({
              filter: {
                ...prevState.filter,
                esercizioId: value,
              },
            }));
          }
        },
      }),
      {
        Header: "Responsabili",
        id: "responsabili",
        accessor: (row) => row,
        sortable: false,
        resizable: false,
        filterable: false,
        width: 120,
        Cell: (props) => (
          <Button
            type="button"
            onClick={(e) => {
              this.onVediButtonClicked(props.value, e);
            }}
            variant="contained"
            size="small"
            disabled={false}
            style={{
              color: this.props.theme.palette.text.main,
              padding: "0px",
              margin: "0px",
              backgroundColor: this.props.theme.palette.primary.main,
            }}
          >
            {"VEDI"}
          </Button>
        ),
      },
      this.generateFilterColumn({
        id: "ragioneSociale",
        placeholder: "Ragione sociale",
        filterValue: this.state.filter.ragioneSociale,
        onChange: (value) =>
          this.setState((prevState) => ({
            filter: {
              ...prevState.filter,
              ragioneSociale: value,
            },
          })),
      }),
      this.generateFilterColumn({
        id: "partitaIva",
        placeholder: "Partita IVA",
        filterValue: this.state.filter.partitaIva,
        onChange: (value) =>
          this.setState((prevState) => ({
            filter: {
              ...prevState.filter,
              partitaIva: value,
            },
          })),
      }),
      this.generateFilterColumn({
        id: "telefono",
        placeholder: "Telefono",
        filterValue: this.state.filter.telefono,
        onChange: (value) =>
          this.setState((prevState) => ({
            filter: {
              ...prevState.filter,
              telefono: value,
            },
          })),
      }),
      this.generateFilterColumn({
        id: "cittaSede",
        placeholder: "Sede",
        filterValue: this.state.filter.cittaSede,
        onChange: (value) =>
          this.setState((prevState) => ({
            filter: {
              ...prevState.filter,
              cittaSede: value,
            },
          })),
      }),
      {
        id: "dataFineAbbonamento",
        Header: "Stato Abbonamento",
        show: this.shouldStatoAbbonamentoShowUp() ? true : false,
        accessor: (row) => row.dataFineAbbonamento,
        sortable: false,
        filterable: false,
        Cell: (props) => {
          return props.row.dataFineAbbonamento === null ? (
            <Tooltip title="Abbonamento inattivo o non esistente">
              <Lens style={{ color: "#aaaaaa" }}></Lens>
            </Tooltip>
          ) : props.original.isSospeso === false ? (
            <Tooltip title="Abbonamento attivo">
              <Lens style={{ color: "#2ecc40" }}></Lens>
            </Tooltip>
          ) : this.compareDates(
              new Date(props.row.dataFineAbbonamento),
              new Date()
            ) > 30 ? (
            <Tooltip title="Abbonamento attivo">
              <Lens style={{ color: "#2ecc40" }}></Lens>
            </Tooltip>
          ) : this.compareDates(
              new Date(props.row.dataFineAbbonamento),
              new Date()
            ) <= 30 &&
            this.compareDates(
              new Date(props.row.dataFineAbbonamento),
              new Date()
            ) >= 10 ? (
            <Tooltip title="Abbonamento in scadenza tra meno di 30 giorni">
              <Lens style={{ color: "#ffc339" }}></Lens>
            </Tooltip>
          ) : this.compareDates(
              new Date(props.row.dataFineAbbonamento),
              new Date()
            ) < 10 &&
            this.compareDates(
              new Date(props.row.dataFineAbbonamento),
              new Date()
            ) > 0 ? (
            <Tooltip title="Abbonamento in scadenza tra meno di 10 giorni">
              <Lens style={{ color: "#FD6A02" }}></Lens>
            </Tooltip>
          ) : (
            <Tooltip title="Abbonamento scaduto">
              <Lens style={{ color: "#ff0000" }}></Lens>
            </Tooltip>
          );
        },
      },
      {
        id: "editBtn",
        Header: "Modifica",
        accessor: (row) => row,
        sortable: false,
        filterable: false,
        width: 80,
        Cell: (props) => (
          <EditRecordButton recordId={props.value.id} disabled={false} />
        ),
      },
    ];
  };

  getColumns = () => {
    let columns = this.getCommonColumns();
    const isAdmin =
      this.props.consulenteId === null && !this.props.superconsulenteView;
    const isSuperConsulente = this.props.superconsulenteView;

    if (isAdmin) {
      columns.splice(
        2,
        0,
        /*this.generateFilterColumn({
          id: "gruppoId",
          placeholder: "ID gruppo",
          filterValue: this.state.filter.gruppoId,
          onChange: (value) => {
            if (/^\d*$/.test(value)) {
              this.setState((prevState) => ({
                filter: {
                  ...prevState.filter,
                  gruppoId: value,
                },
              }));
            }
          },
        }),*/
        {
          id: "gruppoId",
          Header: "ID gruppo",
          accessor: (row) => row.gruppoId,
          filterable: false,
        },
        this.generateFilterColumn({
          id: "consulenteId",
          placeholder: "ID consulente",
          filterValue: this.state.filter.consulenteId,
          onChange: (value) => {
            if (/^\d*$/.test(value)) {
              this.setState((prevState) => ({
                filter: {
                  ...prevState.filter,
                  consulenteId: value,
                },
              }));
            }
          },
        })
      );
    } else if (isSuperConsulente) {
      columns.splice(
        2,
        0,
        this.generateFilterColumn({
          id: "consulenteId",
          placeholder: "ID consulente",
          filterValue: this.state.filter.consulenteId,
          onChange: (value) => {
            if (/^\d*$/.test(value)) {
              this.setState((prevState) => ({
                filter: {
                  ...prevState.filter,
                  consulenteId: value,
                },
              }));
            }
          },
        })
      );
    }

    return columns;
  };

  onVediButtonClicked = (esercizio, event) => {
    let nomeEsercizio = "";
    if (esercizio.nome !== null) {
      nomeEsercizio = esercizio.nome;
    }
    this.setState({
      selectedEsercizioName: nomeEsercizio,
      isModalResponsabiliOpen: true,
      loadingModalResponsabili: true,
    });

    getResponsabiliSicurezzaEsercizio(esercizio.id)
      .then((result) => {
        this.setState({
          selectedEsercizioName: nomeEsercizio,
          responsabiliEsercizio: result,
          loadingModalResponsabili: false,
          errorModalResponsabili: "",
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.setState({
            isModalResponsabiliOpen: false,
          });
          this.handleInvalidToken();
        } else {
          this.setState({
            loadingModalResponsabili: false,
            errorModalResponsabili: error.message,
          });
        }
      });
  };

  closeModalResponsabili = () => {
    this.setState({
      isModalResponsabiliOpen: false,
    });
  };

  render() {
    const isAdmin =
      this.props.consulenteId === null && !this.props.superconsulenteView;
    const columns = this.getColumns();

    return (
      <div style={{ paddingTop: "30px", minHeight: "400px" }}>
        {this.state.loading ? (
          <SpinnerComponent size={24} />
        ) : (
          <div style={styles.mainContainer}>
            {isAdmin && (
              <SelezioneGruppo
                gruppoId={this.state.gruppoId}
                gruppi={this.state.gruppi}
                disabled={false}
                description="Selezionare il gruppo su cui filtrare i risultati:"
                onGruppoSelected={this.handleSelectedGruppo}
              />
            )}
            <div style={styles.newRecordButtonWrapper}>
              <NewRecordButton disabled={false} />
            </div>
            <BasicTable
              data={this.state.esercizi}
              columns={columns}
              resizable={true}
              sortable={false}
              defaultPageSize={this.state.pageSize}
              page={this.state.loadedPage}
              pages={this.state.totalPages}
              onPageChange={this.onPageChange}
            />
          </div>
        )}
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
        <ModalResponsabiliEsercizio
          open={this.state.isModalResponsabiliOpen}
          loading={this.state.loadingModalResponsabili}
          onCloseModal={this.closeModalResponsabili}
          nomeEsercizio={this.state.selectedEsercizioName}
          responsabili={this.state.responsabiliEsercizio}
          errorLabel={this.state.errorModalResponsabili}
        />
      </div>
    );
  }
}

EserciziCommercialiTable.propTypes = {
  consulenteId: PropTypes.number,
  gruppoId: PropTypes.number,
  superconsulenteView: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
};
