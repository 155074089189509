import React, { Component } from "react";
import Modal from "@material-ui/core/Modal";
import {
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import ActionButton from "../../../components/ActionButton";
import { InfoOutlined } from "@material-ui/icons";

const styles = {
  modalcontainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "10%",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
    marginLeft: "20%",
    marginRight: "20%",
    //height: '85%'
    overflowY: "scroll",
    height: "400px",
    display: "flex",
  },
  miniModalContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
    marginLeft: "15%",
    marginRight: "15%",
    //height: '85%'
    overflowY: "scroll",
    height: "300px",
    display: "flex",
  },
  modalsectioncontainer: {
    flex: 1,
    display: "flex",
    //marginTop: 20,
    //marginBottom: 20,
    flexDirection: "column",
    justifyContent: "space-evely",
  },
  buttoncontainer: {
    justifyContent: "center",
    padding: "20px 0px",
  },
  modalTitle: {
    fontSize: 35,
    alignSelf: "center",
  },
  modalSectionTitle: {
    fontSize: 20,
    alignSelf: "center",
  },
};

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.black,
    maxWidth: 300,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 8,
    boxShadow: theme.shadows[6],
    fontSize: theme.typography.pxToRem(12),
  },
  arrow: {
    color: theme.palette.secondary.main,
  },
}))(Tooltip);

export default class ModalSelezioneModalitaModello extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipologiaItem: true,
      modalitaControllo: this.props.isControlliSelectionBlocked ? true : false,
    };
  }
  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.onClose}>
        <div style={styles.modalcontainer}>
          <div style={styles.modalsectioncontainer}>
            <div style={styles.titleContainer}>
              <Typography style={styles.modalTitle}>Opzioni manuale</Typography>
            </div>
            <Typography style={styles.modalSectionTitle}>
              Modalità di gestione dei controlli di non conformità
            </Typography>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <CustomTooltip
                arrow
                placement="right"
                title="Il manuale selezionato è generato da un altro manuale con i controlli in modalità Semplificata e dunque non è possibile cambiarlo in uno con i controlli in modalità Normale"
                disableHoverListener={!this.props.isControlliSelectionBlocked}
              >
                <Select
                  variant="outlined"
                  fullWidth
                  style={{ marginTop: "5%", marginBottom: "5%" }}
                  value={this.state.modalitaControllo}
                  onChange={(e) => {
                    this.setState({
                      modalitaControllo: e.target.value,
                    });
                  }}
                  disabled={this.props.isControlliSelectionBlocked}
                >
                  <MenuItem value={false}>Normale</MenuItem>
                  <MenuItem value={true}>Semplificata</MenuItem>
                </Select>
              </CustomTooltip>
              <CustomTooltip
                arrow
                placement="right"
                title={
                  <React.Fragment>
                    <b>{"Modalità Normale: "}</b>{" "}
                    {
                      "I controlli saranno dotati di limiti, cause e azioni correttive."
                    }
                    <br></br>
                    <b>{"Modalità Semplificata: "}</b>{" "}
                    {"I controlli avranno solo le azioni correttive."}
                  </React.Fragment>
                }
              >
                <IconButton
                  style={{
                    width: 30,
                    height: 30,
                    marginTop: "8%",
                    marginLeft: 10,
                  }}
                >
                  <InfoOutlined></InfoOutlined>
                </IconButton>
              </CustomTooltip>
            </div>
            <Typography style={styles.modalSectionTitle}>
              Modalità di formattazione del manuale
            </Typography>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <CustomTooltip
                arrow
                placement="right"
                title="Essendo il modello selezionato compilato dotato di capitoli, l'elemento che si sta creando deve esserne dotato a sua volta."
                disableHoverListener={!this.props.isChapterSelectionBlocked}
              >
                <Select
                  disabled={this.props.isChapterSelectionBlocked}
                  style={{ marginTop: "5%" }}
                  value={this.state.tipologiaItem}
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {
                    this.setState({
                      tipologiaItem: e.target.value,
                    });
                  }}
                >
                  <MenuItem value={true}>Con capitoli</MenuItem>
                  <MenuItem value={false}>Senza capitoli</MenuItem>
                </Select>
              </CustomTooltip>
              <CustomTooltip
                arrow
                placement="right"
                title={
                  <React.Fragment>
                    <b>{"Modalità con capitoli: "}</b>{" "}
                    {
                      "Il manuale sarà dotato, oltre ai paragrafi, di capitoli in cui racchiudere questi ultimi."
                    }
                    <br></br>
                    <b>{"Modalità senza capitoli: "}</b>{" "}
                    {"Il manuale sarà dotato dei soli paragrafi."}
                  </React.Fragment>
                }
              >
                <IconButton
                  style={{
                    width: 30,
                    height: 30,
                    marginTop: "8%",
                    marginLeft: 10,
                  }}
                >
                  <InfoOutlined></InfoOutlined>
                </IconButton>
              </CustomTooltip>
            </div>
            <div style={styles.buttoncontainer}>
              <ActionButton
                label="Indietro"
                onClick={() => {
                  this.props.onClose();
                }}
                disabled={false}
                grayVersion={false}
                showAlert={false}
                secondaryButton
              ></ActionButton>
              <ActionButton
                label="Prosegui"
                onClick={() => {
                  this.props.onProseguiClicked(
                    this.props.manualeBaseId,
                    this.props.gruppoId,
                    this.state.tipologiaItem,
                    this.props.isModello,
                    this.state.modalitaControllo
                  );
                }}
                disabled={false}
              ></ActionButton>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
