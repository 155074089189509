import MomentUtils from "@date-io/moment";
import { Button, MenuItem, Select, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Modal from "@material-ui/core/Modal";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import "moment/locale/it";
import PropTypes from "prop-types";
import React, { Component } from "react";
import DateFnsUtils from "@date-io/date-fns";
import theme from "../../../theme.js";
import itLocale from "date-fns/locale/it";
import OrarioStraordinarioComponent from "../components/OrarioStraordinarioComponent";

const dateFormat = "dd-MM-yyyy";
const styles = {
  modalcontainer: {
    flexDirection: "column",

    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
    marginLeft: "20%",
    marginRight: "20%",
    //height: '85%'
    overflowY: "scroll",
    height: "600px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "250px",
  },
  sectioncontainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "6px",
    //marginTop: '10px',
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  rowcontainer: {
    flexDirection: "row",
  },
  modalsectioncontainer: {
    flex: 1,
    display: "flex",
    //marginTop: 20,
    //marginBottom: 20,
    flexDirection: "column",
    justifyContent: "space-evely",
  },
  buttonContainer: {
    flex: 1,
    display: "flex",
    //marginTop: 20,
    //marginBottom: 20,
    flexDirection: "row",
    justifyContent: "center",
  },
  periodo: {
    flex: 1,
    display: "flex",
    flexDirection: "column",

    alignItems: "center",
    justifyContent: "space-evenly",
  },
};

export default class ModalGiorniChiusura extends Component {
  state = {
    periodo: false,
    giorniChiusura: {
      id: null,
      esercizioCommercialeId: this.props.esercizioId,
      dataInizio: moment().format(),
      dataFine: null,
      dataSpecifica: null,
      orarioApertura: null,
      orarioChiusura: null,
      interaGiornata: false,
      giorno: null,
      apertura: this.props.apertura ? true : false,
      ripetizioneSettimanale: null,
      tipo: null,
    },
    validationErrors: {
      dataInizio: "",
      dataFine: "",
      dataSpecifica: "",
      tipo: "",
      giorno: "",
      ripetizioneSettimanale: "",
    },
    giorniEsclusi: [],
  };

  closeModal = () => {
    this.setState({
      giorniChiusura: {
        id: null,
        esercizioCommercialeId: this.props.esercizioId,
        dataInizio: moment().format(),
        dataFine: null,
        dataSpecifica: null,
        ripetizioneSettimanale: null,
        apertura: this.props.apertura ? true : false,
        giorno: null,
        tipo: null,
      },
      giorniEsclusi: [],
    });
    this.props.handleClose();
  };

  validateForm = () => {
    let data = this.state.giorniChiusura;
    Object.keys(data).forEach((key) => {
      this.validateField(key, data[key]);
    });
    let valid = true;
    let validationErrors = this.state.validationErrors;
    Object.values(validationErrors).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  validateField = (key, value) => {
    let validationErrors = this.state.validationErrors;
    if (key.tipo === "RICORRENTE") {
      switch (key) {
        case "giorno":
          if (value.length === 0) {
            validationErrors.giorno = "Inserire giorno";
          }
          break;
        default:
          break;
      }
    } else if (key.tipo === "PERIODO") {
      switch (key) {
        case "dataInizio":
          if (value.length === 0) {
            validationErrors.dataInizio = "Inserire data inizio";
          }
          break;
        case "dataFine":
          if (value.length === 0) {
            validationErrors.dataFine = "Inserire data fine ";
          }
          break;
        default:
          break;
      }
    } else if (key.tipo === "SPECIFICO") {
      switch (key) {
        case "dataSpecifica":
          if (value.length === 0) {
            validationErrors.dataSpecifica = "Inserire data specifica";
          }
          break;
        default:
          break;
      }
    }

    this.setState({
      validationErrors,
    });
  };

  saveModal = () => {
    if (this.state.giorniChiusura.orarioApertura === undefined) {
      let giorniChiusuraTemp = this.state.giorniChiusura;
      giorniChiusuraTemp.orarioApertura = null;
      this.setState({
        giorniChiusuraTemp,
      });
    }
    if (this.state.giorniChiusura.orarioChiusura === undefined) {
      let giorniChiusuraTemp = this.state.giorniChiusura;
      giorniChiusuraTemp.orarioChiusura = null;
      this.setState({
        giorniChiusuraTemp,
      });
    }
    if (
      (this.state.giorniChiusura.tipo === "PERIODO" ||
        this.state.giorniChiusura.tipo === "RICORRENTE") &&
      this.state.giorniChiusura.dataFine === null
    ) {
      let temp = this.state.giorniChiusura;
      temp.dataFine = moment().format();
      this.setState({
        temp,
      });
    }
    if (
      !(
        moment(this.state.giorniChiusura.dataInizio).format() >
        moment(this.state.giorniChiusura.dataFine).format()
      )
    ) {
      if (
        moment(this.state.giorniChiusura.orarioApertura).format("HH:mm:ss") <
          moment(this.state.giorniChiusura.orarioChiusura).format("HH:mm:ss") ||
        this.state.giorniChiusura.orarioApertura === null ||
        this.state.giorniChiusura.orarioChiusura === null
      ) {
        let giorno =
          this.state.giorniChiusura.tipo === "PERIODO"
            ? {
                id: null,
                esercizioCommercialeId: this.props.esercizioId,
                dataInizio: moment(this.state.giorniChiusura.dataInizio),
                dataFine: moment(this.state.giorniChiusura.dataFine),
                dataSpecifica: this.state.giorniChiusura.dataSpecifica,
                ripetizioneSettimanale:
                  this.state.giorniChiusura.ripetizioneSettimanale,
                giorno: this.state.giorniChiusura.giorno,
                apertura: this.props.apertura ? true : false,
                tipo: this.state.giorniChiusura.tipo,
                orarioInizio: this.state.giorniChiusura.orarioApertura
                  ? moment(this.state.giorniChiusura.orarioApertura).format(
                      "HH:mm"
                    )
                  : null,
                orarioFine: this.state.giorniChiusura.orarioChiusura
                  ? moment(this.state.giorniChiusura.orarioChiusura).format(
                      "HH:mm"
                    )
                  : null,
              }
            : {
                id: null,
                esercizioCommercialeId: this.props.esercizioId,
                dataInizio: this.state.periodo
                  ? moment(this.state.giorniChiusura.dataInizio)
                  : null,
                dataFine: this.state.periodo
                  ? moment(this.state.giorniChiusura.dataFine)
                  : null,
                dataSpecifica: this.state.giorniChiusura.dataSpecifica,
                giorno: this.state.giorniChiusura.giorno,
                ripetizioneSettimanale:
                  this.state.giorniChiusura.ripetizioneSettimanale,
                apertura: this.props.apertura ? true : false,
                tipo: this.state.giorniChiusura.tipo,
                orarioInizio: this.state.giorniChiusura.orarioApertura
                  ? moment(this.state.giorniChiusura.orarioApertura).format(
                      "HH:mm"
                    )
                  : null,
                orarioFine: this.state.giorniChiusura.orarioChiusura
                  ? moment(this.state.giorniChiusura.orarioChiusura).format(
                      "HH:mm"
                    )
                  : null,
              };
        let array = this.props.giorniChiusura;
        if (this.state.giorniChiusura.interaGiornata === true) {
          giorno.orarioFine = null;
          giorno.orarioInizio = null;
        }
        array.push(giorno);
        if (this.state.giorniChiusura.interaGiornata === true) {
          this.setState({
            periodo: false,
            giorniChiusura: {
              id: null,
              esercizioCommercialeId: this.props.esercizioId,
              dataInizio: moment().format(),
              dataFine: null,
              dataSpecifica: null,
              ripetizioneSettimanale: null,
              giorno: null,
              tipo: null,
              apertura: this.props.apertura ? true : false,
              orarioInizio: null,
              orarioFine: null,
            },
          });
        } else {
          this.setState({
            periodo: false,

            giorniChiusura: {
              id: null,
              esercizioCommercialeId: this.props.esercizioId,
              dataInizio: moment().format(),
              dataFine: null,
              dataSpecifica: null,
              ripetizioneSettimanale: null,
              giorno: null,
              tipo: null,
              apertura: this.props.apertura ? true : false,
              orarioInizio: moment(
                this.state.giorniChiusura.orarioApertura
              ).format("HH:mm"),
              orarioFine: moment(
                this.state.giorniChiusura.orarioChiusura
              ).format("HH:mm"),
            },
          });
        }
        this.props.handleComplete();
        this.props.updateParentState(array);
      } else {
        alert("Orario per apertura o chiusura specifica non corretto");
      }
    } else {
      alert(
        "Errore, i valori delle date inserite non sono corretti! Riprovare!"
      );
    }
  };

  changeTimeTable = (props, date) => {
    let array = this.state.giorniEsclusi;
    array[props.index].dataSpecifica = moment(date).format();
    this.setState({ giorniEsclusi: array });
  };

  deleteelement = (cellInfo) => {
    let array = [...this.state.giorniEsclusi];
    array.splice(cellInfo.index, 1);

    this.setState({ giorniEsclusi: array });
  };

  onDateTimeChanged = (name, value) => {
    let date = value;
    this.handleChangeTime(name, date);
  };

  handleChangeTime = (name, value) => {
    if (this.state.saveDisabled) {
      this.setState({
        saveDisabled: false,
      });
    }

    let giorniChiusura = this.state.giorniChiusura;
    giorniChiusura[name] = value;

    this.setState({
      giorniChiusura,
    });
    if (
      this.state.giorniChiusura.dataInizio !== null &&
      this.state.giorniChiusura.dataFine !== null
    ) {
      if (
        !this.state.saveButtonEnabled &&
        this.state.giorniChiusura.tipo === "PERIODO"
      ) {
        this.setState({
          saveButtonEnabled: true,
        });
      }
      if (this.state.saveMessageVisible) {
        this.setState({
          saveMessageVisible: false,
        });
      }
    } else if (this.state.giorniChiusura.dataSpecifica !== null) {
      if (!this.state.saveButtonEnabled) {
        this.setState({
          saveButtonEnabled: true,
        });
      }
      if (this.state.saveMessageVisible) {
        this.setState({
          saveMessageVisible: false,
        });
      }
    }
  };

  handleDataInizio = (data) => {
    let giorniChiusuraTemp = this.state.giorniChiusura;
    giorniChiusuraTemp.dataInizio = data;
    this.setState({
      giorniChiusuraTemp,
    });
    if (this.state.giorniChiusura.tipo === "PERIODO") {
      this.setState({
        saveButtonEnabled: true,
      });
    }
  };

  handleDataFine = (data) => {
    let giorniChiusuraTemp = this.state.giorniChiusura;
    giorniChiusuraTemp.dataFine = data;
    this.setState({
      giorniChiusuraTemp,
    });
    if (this.state.giorniChiusura.tipo === "PERIODO") {
      this.setState({
        saveButtonEnabled: true,
      });
    }
  };

  handleOrarioInizio = (orario) => {
    let giorniChiusuraTemp = this.state.giorniChiusura;
    giorniChiusuraTemp.orarioApertura = orario;
    this.setState({
      giorniChiusuraTemp,
    });
  };

  handleOrarioFine = (orario) => {
    let giorniChiusuraTemp = this.state.giorniChiusura;
    giorniChiusuraTemp.orarioChiusura = orario;
    this.setState({
      giorniChiusuraTemp,
    });
  };

  handleInteraGiornata = (data) => {
    let giorniChiusuraTemp = this.state.giorniChiusura;
    giorniChiusuraTemp.interaGiornata = data;
    this.setState({
      giorniChiusuraTemp,
    });
  };

  handleChange = (event) => {
    if (!this.state.saveButtonEnabled && event.target.name === "giorno") {
      this.setState({
        saveButtonEnabled: true,
      });
    }
    if (this.state.saveMessageVisible) {
      this.setState({
        saveMessageVisible: false,
      });
    }
    let name = event.target.name;
    let value = event.target.value;
    let giorniChiusura = this.state.giorniChiusura;
    giorniChiusura[name] = value;

    this.setState({
      giorniChiusura,
    });
  };

  handleChangeTipo = (event) => {
    this.setState({
      saveButtonEnabled: false,
    });

    let value = event.target.value;
    let giorniChiusura = {
      id: null,
      esercizioCommercialeId: this.props.esercizioId,
      dataInizio: moment().format(),
      dataFine: null,
      dataSpecifica: null,
      ripetizioneSettimanale: null,
      giorno: null,
      tipo: value,
      apertura: this.props.apertura ? true : false,
      giorniEsclusi: [],
    };

    this.setState({
      giorniChiusura,
    });
  };

  render() {
    const disabledSubmitbutton =
      this.state.loading ||
      !this.state.saveButtonEnabled ||
      this.state.saveMessageVisible;

    return (
      <Modal
        open={this.props.modal}
        onClose={() => this.closeModal()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <MuiPickersUtilsProvider
          libInstance={moment}
          utils={MomentUtils}
          locale={"it"}
        >
          <div style={styles.modalcontainer}>
            <div style={styles.modalsectioncontainer}>
              <Typography
                variant="h6"
                style={{ paddingTop: "8px", paddingBottom: "8px" }}
              >
                {this.props.apertura
                  ? "Aggiunta giorno apertura"
                  : "Aggiunta giorno chiusura"}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  width: "250px",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                  paddingTop: "20px",
                  paddingBottom: "12px",
                }}
              >
                {" "}
                {this.props.apertura ? "Tipo apertura:" : "Tipo chiusura:"}
              </Typography>
              <Select
                value={this.state.tipo}
                name="tipo"
                onChange={this.handleChangeTipo}
              >
                <MenuItem value={"RICORRENTE"}>
                  {this.props.apertura
                    ? "Apertura ricorrente"
                    : "Chiusura ricorrente"}
                </MenuItem>
                <MenuItem value={"PERIODO"}>
                  {this.props.apertura
                    ? "Periodo apertura"
                    : "Periodo chiusura"}
                </MenuItem>
                <MenuItem value={"SPECIFICO"}>Giorno specifico</MenuItem>
              </Select>
              {this.state.giorniChiusura.tipo === "RICORRENTE" ? (
                <div>
                  <div
                    style={{
                      ...styles.modalsectioncontainer,
                      marginBottom: 20,
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        width: "250px",
                        paddingRight: "20px",
                        paddingLeft: "20px",
                        paddingTop: "20px",
                        paddingBottom: "12px",
                      }}
                    >
                      {this.props.apertura
                        ? "Giorno apertura:"
                        : "Giorno chiusura:"}
                    </Typography>

                    <Select
                      value={this.state.giorniChiusura.giorno}
                      name="giorno"
                      onChange={this.handleChange}
                      helperText={this.state.validationErrors.giorno}
                      error={
                        this.state.validationErrors.giorno.length > 0
                          ? true
                          : false
                      }
                    >
                      <MenuItem value={"MONDAY"}>Lunedì</MenuItem>
                      <MenuItem value={"TUESDAY"}>Martedì</MenuItem>
                      <MenuItem value={"WEDNESDAY"}>Mercoledì</MenuItem>
                      <MenuItem value={"THURSDAY"}>Giovedì</MenuItem>
                      <MenuItem value={"FRIDAY"}>Venerdì</MenuItem>
                      <MenuItem value={"SATURDAY"}>Sabato</MenuItem>
                      <MenuItem value={"SUNDAY"}>Domenica</MenuItem>
                    </Select>
                  </div>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Periodo:</FormLabel>
                    <RadioGroup
                      aria-label="periodo"
                      defaultValue="a"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        onClick={() => this.setState({ periodo: false })}
                        value={"a"}
                        control={<Radio />}
                        label="Sempre"
                      />
                      <div style={styles.rowcontainer}>
                        <FormControlLabel
                          onClick={() => this.setState({ periodo: true })}
                          value={"b"}
                          control={<Radio />}
                          label="Data intervallo"
                        />
                        {/*<MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>

                                                    <DatePicker
                                                        disabled={!this.state.periodo}
                                                        disablePast={true}
                                                        ampm={false}
                                                        value={this.state.giorniChiusura.dataInizio}
                                                        onChange={(date) => { this.onDateTimeChanged("dataInizio", date) }}
                                                        format={dateFormat}
                                                        label="Data inizio"
                                                        name='dataInizio'
                                                        helperText={this.state.validationErrors.dataInizio}
                                                        error={this.state.validationErrors.dataInizio.length > 0 ? true : false}
                                                        style={styles.textfield}
                                                    />
                                                    <DatePicker
                                                        disabled={!this.state.periodo}

                                                        disablePast={true}
                                                        ampm={false}
                                                        value={this.state.giorniChiusura.dataFine}
                                                        onChange={(date) => { this.onDateTimeChanged("dataFine", date) }}
                                                        format={dateFormat}
                                                        label="Data fine"
                                                        name='dataFine'
                                                        helperText={this.state.validationErrors.dataFine}
                                                        error={this.state.validationErrors.dataFine.length > 0 ? true : false}
                                                        style={styles.textfield}
                                                    />
                                                </MuiPickersUtilsProvider>*/}
                      </div>
                    </RadioGroup>
                  </FormControl>
                  <div
                    style={{
                      ...styles.modalsectioncontainer,
                      marginBottom: 20,
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        width: "250px",
                        paddingRight: "20px",
                        paddingLeft: "20px",
                        paddingTop: "20px",
                        paddingBottom: "12px",
                      }}
                    >
                      Frequenza settimanale:
                    </Typography>
                    <Select
                      value={this.state.giorniChiusura.ripetizioneSettimanale}
                      name="ripetizioneSettimanale"
                      onChange={this.handleChange}
                      helperText={
                        this.state.validationErrors.ripetizioneSettimanale
                      }
                      error={
                        this.state.validationErrors.ripetizioneSettimanale
                          .length > 0
                          ? true
                          : false
                      }
                    >
                      <MenuItem value={0}>Sempre</MenuItem>
                      <MenuItem value={1}>Primo del mese</MenuItem>
                      <MenuItem value={2}>Secondo del mese</MenuItem>
                      <MenuItem value={3}>Terzo del mese</MenuItem>
                      <MenuItem value={4}>Quarto del mese</MenuItem>
                      <MenuItem value={5}>Ultimo del mese</MenuItem>
                    </Select>
                    {!this.state.periodo ? (
                      <OrarioStraordinarioComponent
                        mode="ORARIO"
                        handleInteraGiornata={this.handleInteraGiornata}
                        handleDataInizio={this.handleDataInizio}
                        handleDataFine={this.handleDataFine}
                        handleOrarioInizio={this.handleOrarioInizio}
                        handleOrarioFine={this.handleOrarioFine}
                        aperto={this.state.giorniChiusura.apertura}
                      ></OrarioStraordinarioComponent>
                    ) : (
                      <OrarioStraordinarioComponent
                        mode="DATAEORARIO"
                        handleInteraGiornata={this.handleInteraGiornata}
                        handleDataInizio={this.handleDataInizio}
                        handleDataFine={this.handleDataFine}
                        handleOrarioInizio={this.handleOrarioInizio}
                        handleOrarioFine={this.handleOrarioFine}
                        aperto={this.state.giorniChiusura.apertura}
                      ></OrarioStraordinarioComponent>
                    )}
                  </div>
                </div>
              ) : null}
              {this.state.giorniChiusura.tipo === "PERIODO" ? (
                <div style={styles.periodo}>
                  <OrarioStraordinarioComponent
                    mode="PERIODO"
                    dataInizio={this.state.giorniChiusura.dataInizio}
                    handleInteraGiornata={this.handleInteraGiornata}
                    handleDataInizio={this.handleDataInizio}
                    handleDataFine={this.handleDataFine}
                    handleOrarioInizio={this.handleOrarioInizio}
                    handleOrarioFine={this.handleOrarioFine}
                    dataFine={this.state.giorniChiusura.dataFine}
                    aperto={this.state.giorniChiusura.apertura}
                  ></OrarioStraordinarioComponent>
                  {/*<MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>

                                        <DatePicker
                                            disablePast={true}

                                            ampm={false}
                                            value={this.state.giorniChiusura.dataInizio}
                                            onChange={(date) => { this.onDateTimeChanged("dataInizio", date) }}
                                            format={dateFormat}
                                            label="Data inizio"
                                            name='dataInizio'
                                            helperText={this.state.validationErrors.dataInizio}
                                            error={this.state.validationErrors.dataInizio.length > 0 ? true : false}
                                            style={styles.textfield}
                                        />
                                        <DatePicker
                                            disablePast={true}

                                            ampm={false}
                                            value={this.state.giorniChiusura.dataFine}
                                            onChange={(date) => { this.onDateTimeChanged("dataFine", date) }}
                                            format={dateFormat}
                                            label="Data fine"
                                            name='dataFine'
                                            helperText={this.state.validationErrors.dataFine}
                                            error={this.state.validationErrors.dataFine.length > 0 ? true : false}
                                            style={styles.textfield}
                                        />
                                    </MuiPickersUtilsProvider>*/}
                </div>
              ) : null}
              {this.state.giorniChiusura.tipo === "SPECIFICO" ? (
                <div>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={itLocale}
                  >
                    <DatePicker
                      disablePast={true}
                      ampm={false}
                      value={this.state.giorniChiusura.dataSpecifica}
                      onChange={(date) => {
                        this.onDateTimeChanged("dataSpecifica", date);
                      }}
                      format={dateFormat}
                      label="Data"
                      name="dataSpecifica"
                      helperText={this.state.validationErrors.dataSpecifica}
                      error={
                        this.state.validationErrors.dataSpecifica.length > 0
                          ? true
                          : false
                      }
                      style={styles.textfield}
                    />
                    <OrarioStraordinarioComponent
                      mode="SPECIFICO"
                      handleInteraGiornata={this.handleInteraGiornata}
                      handleDataInizio={this.handleDataInizio}
                      handleDataFine={this.handleDataFine}
                      handleOrarioInizio={this.handleOrarioInizio}
                      handleOrarioFine={this.handleOrarioFine}
                      aperto={this.state.giorniChiusura.apertura}
                    ></OrarioStraordinarioComponent>
                  </MuiPickersUtilsProvider>
                </div>
              ) : null}

              <div style={styles.buttonContainer}>
                <Button
                  type="button"
                  onClick={() => this.closeModal()}
                  variant="contained"
                  size="medium"
                  style={{
                    color: this.props.theme.palette.text.main,
                    margin: "10px",
                    backgroundColor: this.props.theme.palette.secondary.main,
                  }}
                >
                  CHIUDI
                </Button>
                <Button
                  type="button"
                  onClick={() => this.saveModal()}
                  variant="contained"
                  size="medium"
                  disabled={disabledSubmitbutton}
                  style={{
                    color: disabledSubmitbutton
                      ? theme.palette.text.disabled
                      : this.props.theme.palette.text.main,
                    margin: "10px",
                    backgroundColor: disabledSubmitbutton
                      ? theme.palette.disabled.main
                      : this.props.theme.palette.primary.main,
                  }}
                >
                  AGGIUNGI
                </Button>
              </div>
            </div>
          </div>
        </MuiPickersUtilsProvider>
      </Modal>
    );
  }
}

ModalGiorniChiusura.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleComplete: PropTypes.func.isRequired,
  esercizioCommercialeId: PropTypes.number,
  modal: PropTypes.bool.isRequired,
  isConsulente: PropTypes.bool.isRequired,
  updateParentState: PropTypes.func.isRequired,
  esercizioId: PropTypes.number,
  apertura: PropTypes.bool.isRequired,
};
