import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "6px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "20px",
    paddingBottom: "10px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "250px",
  },
  giornoChiusuraText: {
    color: theme.palette.text.disabled,
    width: "250px",
    paddingRight: "20px",
    paddingLeft: "20px",
    paddingTop: "20px",
    paddingBottom: "12px",
  },
  link: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}));

const FormAccettazioneEsercizio = ({
  record,
  handleSubmit,
  handleCheck,
  saveMessageVisible,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Grid container justify="center" alignItems="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.sectionContainer}
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ paddingBottom: "10px" }}
              >
                <TextField
                  label="ID esercizio"
                  disabled
                  value={record.id ?? ""}
                  InputProps={{
                    readOnly: true,
                  }}
                  className={classes.textfield}
                />
                <TextField
                  label="Nome"
                  value={record.nome ?? ""}
                  name="nome"
                  disabled
                  className={classes.textfield}
                />
                <TextField
                  label="Ragione sociale"
                  value={record.ragioneSociale ?? ""}
                  name="ragioneSociale"
                  disabled
                  className={classes.textfield}
                />
                <TextField
                  label="Partita IVA"
                  value={record.partitaIva ?? ""}
                  name="partitaIva"
                  disabled
                  className={classes.textfield}
                />
                <TextField
                  label="Codice ATECO"
                  disabled
                  value={record.codiceAteco ?? ""}
                  name="codiceAteco"
                  className={classes.textfield}
                />
                <TextField
                  label="Telefono"
                  value={record.telefono ?? ""}
                  name="telefono"
                  disabled
                  className={classes.textfield}
                />
                <TextField
                  label="Nome titolare"
                  value={record.nomeGestore ?? ""}
                  name="nomeGestore"
                  disabled
                  className={classes.textfield}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ paddingBottom: "10px" }}
              >
                <TextField
                  label="Cognome titolare"
                  value={record.cognomeGestore ?? ""}
                  name="cognomeGestore"
                  disabled
                  className={classes.textfield}
                />
                <TextField
                  label="Email titolare"
                  value={record.emailGestore ?? ""}
                  name="emailGestore"
                  disabled
                  className={classes.textfield}
                />
                <TextField
                  label="Telefono titolare"
                  value={record.telefonoGestore ?? ""}
                  name="telefonoGestore"
                  disabled
                  className={classes.textfield}
                />
                <TextField
                  label="Indirizzo"
                  value={record.indirizzoSede ?? ""}
                  name="indirizzoSede"
                  disabled
                  className={classes.textfield}
                />
                <TextField
                  label="Numero civico"
                  value={record.numeroSede ?? ""}
                  name="numeroSede"
                  disabled
                  className={classes.textfield}
                />
                <TextField
                  label="Città"
                  value={record.cittaSede ?? ""}
                  name="cittaSede"
                  disabled
                  className={classes.textfield}
                />
                <TextField
                  label="Provincia"
                  value={record.provinciaSede ?? ""}
                  name="provinciaSede"
                  disabled
                  className={classes.textfield}
                />
                <Typography
                  variant="body2"
                  className={classes.giornoChiusuraText}
                >
                  Giorno chiusura:
                </Typography>
                <FormControl>
                  <Select
                    disabled
                    value={record.giornoChiusura}
                    name="giornoChiusura"
                  >
                    <MenuItem value={""}>Non specificato</MenuItem>
                    <MenuItem value={"MONDAY"}>Lunedì</MenuItem>
                    <MenuItem value={"TUESDAY"}>Martedì</MenuItem>
                    <MenuItem value={"WEDNESDAY"}>Mercoledì</MenuItem>
                    <MenuItem value={"THURSDAY"}>Giovedì</MenuItem>
                    <MenuItem value={"FRIDAY"}>Venerdì</MenuItem>
                    <MenuItem value={"SATURDAY"}>Sabato</MenuItem>
                    <MenuItem value={"SUNDAY"}>Domenica</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justify="center" style={{ paddingTop: "10px" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ textAlign: "center" }}
        >
          <Typography
            variant="body1"
            style={{
              color: theme.palette.primary.main,
              visibility: saveMessageVisible ? "inherit" : "hidden",
            }}
          >
            Benvenuto in bsfood
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Checkbox
            checked={record.privacyPolicyAccettata}
            onChange={handleCheck}
            required
            color="primary"
          />
          <Box component="span">
            Dichiaro di aver letto e di accettare le
            <Link
              href="https://bsfood.biorsaf.it/condizioni-generali-uso-BS-FOOD.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              condizioni d'uso
            </Link>
            e la
            <Link
              href="https://biorsaf.it/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              privacy policy
            </Link>
            del servizio.
          </Box>
          <Button
            type="submit"
            onClick={handleSubmit}
            variant="contained"
            size="medium"
            disabled={!record.privacyPolicyAccettata}
            style={{
              color: record.privacyPolicyAccettata
                ? theme.palette.text.main
                : theme.palette.text.disabled,
              margin: "10px",
              backgroundColor: record.privacyPolicyAccettata
                ? theme.palette.primary.main
                : theme.palette.disabled.main,
            }}
          >
            Conferma
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

FormAccettazioneEsercizio.propTypes = {
  record: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCheck: PropTypes.func.isRequired,
  saveMessageVisible: PropTypes.bool.isRequired,
};

export default FormAccettazioneEsercizio;
