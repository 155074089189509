import React from "react";

import { withRouter } from "react-router-dom";

import { Fragment } from "react";

import Container from "@material-ui/core/Container";

import SpinnerComponent from "../../../components/SpinnerComponent";

import { Typography } from "@material-ui/core";
import { getStatoFile } from "../../../utils/api/revisioni_manuali_api";
import ErrorDialog from "../../../components/ErrorDialog";

class DownloadPDFPage extends React.Component {
  intervalId = 0;
  constructor(props) {
    super(props);
    this.state = {
      pdfloading: true,
      error: false,
      readyToDownload: false,
      errorDialogMessage: "",
      errorDialogVisible: false,
      revisioneMan: null,
    };
  }

  handleError = (showModal, errorMessage) => {
    this.setState({
      pdfloading: false,
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  componentDidMount() {
    this.downloadPdf(this.props.revisioneId);
  }
  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  checkStatus = (revisione, interval) => {
    if (!this.state.readyToDownload) {
      getStatoFile(revisione, "PUBBLICATO")
        .then((result) => {
          if (result.stato === "DONE") {
            this.setState({
              readyToDownload: true,
            });
          }
        })
        .catch((error) => {
          this.setState(
            {
              pdfloading: false,
              error: true,
            },
            () => {
              if (!this.state.readyToDownload) {
                clearInterval(interval);
                this.handleError(
                  true,
                  "Si è verificato un errore: download del PDF non riuscito."
                );
              }
            }
          );
        });
    }
  };

  downloadPdf = (revisione) => {
    console.log(revisione);

    this.setState({
      pdfloading: true,
      readyToDownload: false,
    });
    getStatoFile(revisione, "PUBBLICATO")
      .then((result) => {
        let counter = 0;
        this.intervalId = setInterval(() => {
          if (counter < 40) {
            this.checkStatus(revisione, this.intervalId);
            counter++;
          } else {
            clearInterval(this.intervalId);
            this.setState(
              {
                pdfloading: false,
              },
              () => {
                this.handleError(
                  true,
                  "Attenzione, timeout della connessione raggiunta"
                );
              }
            );
          }
          if (this.state.readyToDownload) {
            clearInterval(this.intervalId);
            this.setState(
              {
                pdfloading: false,
                //readyToDownload:false,
              },
              () => {
                const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;

                window.location.href =
                  ENDPOINT + "/api/v2/revisioni/pdf/" + revisione;
              }
            );
          }
        }, 3000);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState(
            {
              pdfloading: false,
            },
            () => {
              this.handleError(
                true,
                "Si è verificato un errore: download del PDF non riuscito."
              );
            }
          );
        }
      });
  };

  render() {
    return (
      <Fragment>
        <Container
          id="home_container"
          fixed
          style={{
            display: "flex",
            minWidth: "100%",
            minHeight: "85vh",
            flexDirection: "column",
            backgroundRepeat: "no-repeat",
            backgroundSize: "",
            backgroundAttachment: "fixed",
            backgroundPositionX:
              this.state.width > 1799
                ? "140%, -40%"
                : this.state.width > 1599
                ? "160%, -60%"
                : this.state.width > 1399
                ? "180%, -80%"
                : this.state.width > 1299
                ? "200%, -100%"
                : "220%, -120%",
          }}
        >
          {this.state.pdfloading ? (
            <Typography
              variant="h4"
              style={{ padding: "20px", fontSize: 20, color: "black",alignSelf:"center" }}
            >
              Download pdf in corso, attendere prego...
            </Typography>
          ) : (
            <Typography
              variant="h4"
              style={{ padding: "20px", fontSize: 20, color: "black",alignSelf:"center" }}
            >
              {!this.state.error
                ? "Download completato!"
                : "Errore esecuzione download, si prega di aggiornare la pagina per riprovare"}
            </Typography>
          )}
          {this.state.pdfloading ? (
            <SpinnerComponent size={36}></SpinnerComponent>
          ) : (
            <div></div>
          )}
          <ErrorDialog
            open={this.state.errorDialogVisible}
            message={this.state.errorDialogMessage}
            onCloseButtonClicked={this.closeErrorDialog}
          />
        </Container>
      </Fragment>
    );
  }
}

export default withRouter(DownloadPDFPage);
