import React from "react";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import ReactTable from "react-table";
// React-table library requires to import css as well:
import "react-table/react-table.css"; // eslint-disable-next-line
import ActionButton from "../../../components/ActionButton";
import {
  getCountEserciziCommerciali,
  getEserciziCommerciali,
  getEserciziCommercialiFiltered,
  getEsercizioCommerciale,
} from "../../../utils/api/esercizi_commerciali_api";
import theme from "../../../theme.js";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";

const styles = {
  modalcontainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px " +
      theme.palette.primary.main,
    marginLeft: "20%",
    marginRight: "20%",
    //height: '85%'
    overflowY: "scroll",
    height: "600px",
    display: "flex",
  },
  modalsectioncontainer: {
    flex: 1,
    display: "flex",
    //marginTop: 20,
    //marginBottom: 20,
    flexDirection: "column",
    justifyContent: "space-evely",
  },
  modalTitle: {
    fontSize: 30,
    width: "60%",
    alignSelf: "center",
  },
};
const SelectTable = selectTableHOC(ReactTable);

export default class ModalSelezioneEsercizioCommerciale extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colonneEsercizi: [],
      eserciziIdArray: [],
      selectedEsercizio: -1,
      elementiTotali: 0,
      loadedPage: 0,
      totalPages: 1,
      loading: true,
      esercizi: [],
      idToPassForFilter: "",
      emailToPassForFilter: "",
      nomeToPassForFilter: "",
    };
  }

  componentDidMount() {
    let a = 0;
    if (this.props.gruppoId != null) {
      getCountEserciziCommerciali(this.props.gruppoId, this.props.idConsulente)
        .then((result) => {
          a = result;
          this.setState({
            totalPages: Math.ceil(a / 10),
          });
        })
        .then((result) => {
          this.getEserciziForTable(0);
        })
        .catch((error) => {
          if (error.status === 403) {
            this.props.handleInvalidToken();
          } else {
            this.props.handleError(true, error.message);
          }
        });
    }
  }

  isNumber = (value) => {
    if (typeof value === "string") {
      return !isNaN(value);
    }
  };

  getEserciziForTableAfterLoading = (page) => {
    getEserciziCommerciali(
      this.props.gruppoId,
      this.props.idConsulente,
      null,
      page,
      10,
      "id"
    )
      .then((result) => {
        if (result != null) {
          let newArray = this.state.esercizi;
          newArray = result;
          this.setState({
            esercizi: newArray,
          });
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  getEserciziForTable = (page) => {
    getEserciziCommerciali(
      this.props.gruppoId,
      this.props.idConsulente,
      null,
      page,
      10,
      "id"
    )
      .then((result) => {
        if (result != null) {
          let newArray = this.state.esercizi;
          newArray = result;
          this.setState(
            {
              esercizi: newArray,
            },
            () => {
              let a = this.getEserciziColumn(newArray);
              this.setState({
                colonneEsercizi: a,
                loading: false,
              });
            }
          );
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };
  getEserciziColumn = (data) => {
    return [
      {
        Header: "Id",
        id: "IdEsercizio",
        filterable: false,
        //filterable:true,
        //width: 105,
        /*Filter: cellInfo => <TextField InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" onClick={() => {
                if(this.isNumber(this.state.idToPassForFilter)){
                if(this.state.idToPassForFilter.length > 0){
          let arrayOfResults = [];
          getEsercizioCommerciale(this.state.idToPassForFilter).then((response) => {
            arrayOfResults.push(response.esercizio)
            this.setState({esercizi:arrayOfResults})
          }).catch((error) => {
            if (error.status === 403) {
            this.props.handleError(true, "Errore, elemento non trovato");
          } else {
            this.props.handleError(true, error.message);
          }
          })
        }else{
          getEserciziCommerciali(this.props.gruppoId, this.props.idConsulente, null, null, null, "id").then((response) => {
            this.setState({esercizi:response})
          }).catch((error) => {
            if (error.status === 403) {
            this.props.handleInvalidToken();
          } else {
            this.props.handleError(true, error.message);
          }
          })
        }
              }else{
                alert("Attenzione, l'id deve avere un valore numerico")
              }}}><Search></Search></IconButton>
            </InputAdornment>
          ),
        }}  variant="standard" inputMode="numeric" value={this.state.idToPassForFilter} onInput={(event) => {
          this.setState({
            idToPassForFilter:event.target.value
          })
        }} onBlur={() => {
          if(this.state.idToPassForFilter.length === 0){
          getEserciziCommerciali(this.props.gruppoId, this.props.idConsulente,null,this.state.loadedPage,10,"id").then((response) => {
            this.setState({esercizi:response})
          }).catch((error) => {
            if (error.status === 403) {
            this.props.handleInvalidToken();
          } else {
            this.props.handleError(true, error.message);
          }
          })
        }}}></TextField>,*/
        accessor: (data) => data.id,
      },
      {
        Header: "Email",
        id: "EmailEsercizio",
        Filter: (cellInfo) => (
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      if (this.isNumber(this.state.idToPassForFilter)) {
                        if (this.state.emailToPassForFilter.length === 0) {
                          getEserciziCommercialiFiltered(
                            this.props.gruppoId,
                            this.props.idConsulente,
                            null,
                            this.state.loadedPage,
                            100,
                            "id",
                            this.state.emailToPassForFilter,
                            this.state.nomeToPassForFilter
                          )
                            .then((response) => {
                              if (this.state.eserciziIdArray.length === 0) {
                                this.setState({
                                  esercizi: response,
                                  selectedEsercizio: -1,
                                  eserciziIdArray: [],
                                });
                              } else {
                                let item = response.find(
                                  (element) =>
                                    element.id ===
                                    this.state.eserciziIdArray[0].id
                                );
                                if (item !== undefined) {
                                  this.setState({
                                    esercizi: response,
                                    selectedEsercizio: item.id,
                                    eserciziIdArray: this.state.eserciziIdArray,
                                  });
                                } else {
                                  this.setState({
                                    esercizi: response,
                                    selectedEsercizio: -1,
                                    eserciziIdArray: [],
                                  });
                                }
                              }
                            })
                            .catch((error) => {
                              if (error.status === 403) {
                                this.props.handleInvalidToken();
                              } else {
                                this.props.handleError(true, error.message);
                              }
                            });
                        } else {
                          getEserciziCommercialiFiltered(
                            this.props.gruppoId,
                            this.props.idConsulente,
                            null,
                            null,
                            null,
                            "id",
                            this.state.emailToPassForFilter,
                            this.state.nomeToPassForFilter
                          )
                            .then((response) => {
                              let numberOfPages = Math.ceil(
                                response.length / 10
                              );
                              if (this.state.eserciziIdArray.length === 0) {
                                this.setState({
                                  esercizi: response,
                                  selectedEsercizio: -1,
                                  eserciziIdArray: [],
                                  totalPages: numberOfPages,
                                });
                              } else {
                                let item = response.find(
                                  (element) =>
                                    element.id ===
                                    this.state.eserciziIdArray[0].id
                                );
                                if (item !== undefined) {
                                  this.setState({
                                    esercizi: response,
                                    selectedEsercizio: item.id,
                                    eserciziIdArray: this.state.eserciziIdArray,
                                    totalPages: numberOfPages,
                                  });
                                } else {
                                  this.setState({
                                    esercizi: response,
                                    selectedEsercizio: -1,
                                    eserciziIdArray: [],
                                    totalPages: numberOfPages,
                                  });
                                }
                              }
                            })
                            .catch((error) => {
                              if (error.status === 403) {
                                this.props.handleInvalidToken();
                              } else {
                                this.props.handleError(true, error.message);
                              }
                            });
                        }
                      } else {
                        alert("Attenzione, l'id deve avere un valore numerico");
                      }
                    }}
                    size="small"
                  >
                    <Search></Search>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="standard"
            value={this.state.emailToPassForFilter}
            onInput={(event) => {
              this.setState({
                emailToPassForFilter: event.target.value,
              });
            }}
            onBlur={() => {
              if (this.state.emailToPassForFilter.length === 0) {
                if (this.state.idToPassForFilter.length !== 0) {
                  if (this.isNumber(this.state.idToPassForFilter)) {
                    let arrayOfResults = [];
                    getEsercizioCommerciale(this.state.idToPassForFilter)
                      .then((response) => {
                        arrayOfResults.push(response.esercizio);
                        this.setState({ esercizi: arrayOfResults });
                      })
                      .catch((error) => {
                        if (error.status === 403) {
                          this.props.handleError(
                            true,
                            "Errore, elemento non trovato"
                          );
                        } else {
                          this.props.handleError(true, error.message);
                        }
                      });
                  } else {
                    alert(
                      "Attenzione, l'id passato deve avere un valore numerico"
                    );
                  }
                } else {
                  getEserciziCommercialiFiltered(
                    this.props.gruppoId,
                    this.props.idConsulente,
                    null,
                    this.state.loadedPage,
                    100,
                    "id",
                    this.state.emailToPassForFilter,
                    this.state.nomeToPassForFilter
                  )
                    .then((response) => {
                      getCountEserciziCommerciali(
                        this.props.gruppoId,
                        this.props.idConsulente
                      ).then((result) => {
                        let numberOfPages = Math.ceil(result / 10);
                        this.setState({
                          esercizi: response,
                          totalPages: numberOfPages,
                        });
                      });
                    })
                    .catch((error) => {
                      if (error.status === 403) {
                        this.props.handleInvalidToken();
                      } else {
                        this.props.handleError(true, error.message);
                      }
                    });
                }
              }
            }}
          ></TextField>
        ),
        //filterable:true,
        //width: 205,
        accessor: (data) => data.emailGestore,
      },
      {
        Header: "Nome",
        id: "NomeEsercizio",
        //width: 205,
        Filter: (cellInfo) => (
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      if (this.isNumber(this.state.idToPassForFilter)) {
                        if (this.state.nomeToPassForFilter.length === 0) {
                          getEserciziCommercialiFiltered(
                            this.props.gruppoId,
                            this.props.idConsulente,
                            null,
                            this.state.loadedPage,
                            10,
                            "id",
                            this.state.emailToPassForFilter,
                            this.state.nomeToPassForFilter
                          )
                            .then((response) => {
                              if (this.state.eserciziIdArray.length === 0) {
                                this.setState({
                                  esercizi: response,
                                  selectedEsercizio: -1,
                                  eserciziIdArray: [],
                                });
                              } else {
                                let item = response.find(
                                  (element) =>
                                    element.id ===
                                    this.state.eserciziIdArray[0].id
                                );
                                if (item !== undefined) {
                                  this.setState({
                                    esercizi: response,
                                    selectedEsercizio: item.id,
                                    eserciziIdArray: this.state.eserciziIdArray,
                                  });
                                } else {
                                  this.setState({
                                    esercizi: response,
                                    selectedEsercizio: -1,
                                    eserciziIdArray: [],
                                  });
                                }
                              }
                            })
                            .catch((error) => {
                              if (error.status === 403) {
                                this.props.handleInvalidToken();
                              } else {
                                this.props.handleError(true, error.message);
                              }
                            });
                        } else {
                          getEserciziCommercialiFiltered(
                            this.props.gruppoId,
                            this.props.idConsulente,
                            null,
                            null,
                            null,
                            "id",
                            this.state.emailToPassForFilter,
                            this.state.nomeToPassForFilter
                          )
                            .then((response) => {
                              let numberOfPages = Math.ceil(
                                response.length / 10
                              );
                              if (this.state.eserciziIdArray.length === 0) {
                                this.setState({
                                  esercizi: response,
                                  selectedEsercizio: -1,
                                  eserciziIdArray: [],
                                  totalPages: numberOfPages,
                                });
                              } else {
                                let item = response.find(
                                  (element) =>
                                    element.id ===
                                    this.state.eserciziIdArray[0].id
                                );
                                if (item !== undefined) {
                                  this.setState({
                                    esercizi: response,
                                    selectedEsercizio: item.id,
                                    eserciziIdArray: this.state.eserciziIdArray,
                                    totalPages: numberOfPages,
                                  });
                                } else {
                                  this.setState({
                                    esercizi: response,
                                    selectedEsercizio: -1,
                                    eserciziIdArray: [],
                                    totalPages: numberOfPages,
                                  });
                                }
                              }
                            })
                            .catch((error) => {
                              if (error.status === 403) {
                                this.props.handleInvalidToken();
                              } else {
                                this.props.handleError(true, error.message);
                              }
                            });
                        }
                      } else {
                        alert("Attenzione, l'id deve avere un valore numerico");
                      }
                    }}
                    size="small"
                  >
                    <Search></Search>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="standard"
            value={this.state.nomeToPassForFilter}
            onInput={(event) => {
              this.setState({
                nomeToPassForFilter: event.target.value,
              });
            }}
            onBlur={() => {
              if (this.state.nomeToPassForFilter.length === 0) {
                if (this.state.idToPassForFilter.length !== 0) {
                  if (this.isNumber(this.state.idToPassForFilter)) {
                    let arrayOfResults = [];
                    getEsercizioCommerciale(this.state.idToPassForFilter)
                      .then((response) => {
                        arrayOfResults.push(response.esercizio);
                        this.setState({ esercizi: arrayOfResults });
                      })
                      .catch((error) => {
                        if (error.status === 403) {
                          this.props.handleError(
                            true,
                            "Errore, elemento non trovato"
                          );
                        } else {
                          this.props.handleError(true, error.message);
                        }
                      });
                  } else {
                    alert("Attenzione,l'id deve avere un valore numerico");
                  }
                } else {
                  getEserciziCommercialiFiltered(
                    this.props.gruppoId,
                    this.props.idConsulente,
                    null,
                    this.state.loadedPage,
                    100,
                    "id",
                    this.state.emailToPassForFilter,
                    this.state.nomeToPassForFilter
                  )
                    .then((response) => {
                      getCountEserciziCommerciali(
                        this.props.gruppoId,
                        this.props.idConsulente
                      ).then((result) => {
                        let numberOfPages = Math.ceil(result / 10);
                        this.setState({
                          esercizi: response,
                          totalPages: numberOfPages,
                        });
                      });
                    })
                    .catch((error) => {
                      if (error.status === 403) {
                        this.props.handleInvalidToken();
                      } else {
                        this.props.handleError(true, error.message);
                      }
                    });
                }
              }
            }}
          ></TextField>
        ),
        accessor: (data) => data.nome,
      },
    ];
  };

  toggleSelection = (key, shift, row) => {
    this.setState({
      selectedEsercizio: key.split("select-")[1],
    });
    let eserciziIdArray = [...this.state.eserciziIdArray];
    const keyIndex = eserciziIdArray.indexOf(key);
    if (keyIndex >= 0) {
      eserciziIdArray = [
        ...eserciziIdArray.slice(0, keyIndex),
        ...eserciziIdArray.slice(keyIndex + 1),
      ];
    } else {
      eserciziIdArray = [];
      eserciziIdArray.push(row);
    }
    this.setState({ eserciziIdArray });
  };
  isSelected = (key) => {
    if (this.state.eserciziIdArray.some((e) => e.id === key)) {
      return true;
    }
  };

  render() {
    return (
      <Modal open={this.props.isModalOpen} onClose={this.props.closeModal}>
        <div style={styles.modalcontainer}>
          <div style={styles.modalsectioncontainer}>
            <Typography style={styles.modalTitle}>
              Selezionare l'esercizio da associare al nuovo manuale
            </Typography>
            <SelectTable
              selectType="radio"
              ref={(r) => {
                this.checkboxTable = r;
              }}
              toggleSelection={this.toggleSelection}
              isSelected={this.isSelected}
              keyField={"id"}
              filterable={true}
              resizable={true}
              showPageSizeOptions={false}
              showPageJump={false}
              showPaginationBottom={true}
              style={{ height: 400, width: "40vw", alignSelf: "center" }}
              defaultPageSize={10}
              loading={this.state.loading}
              pages={this.state.totalPages}
              onPageChange={(pageIndex) => {
                this.setState({
                  loadedPage: pageIndex,
                });
              }}
              manual
              onFetchData={(state, instance) => {
                this.setState({
                  loading: true,
                });
                let a = 0;
                if (this.props.gruppoId != null) {
                  getCountEserciziCommerciali(
                    this.props.gruppoId,
                    this.props.idConsulente
                  )
                    .then((result) => {
                      a = result;
                      this.setState({
                        totalPages: Math.ceil(a / state.pageSize),
                      });
                    })
                    .then((result) => {
                      getEserciziCommerciali(
                        this.props.gruppoId,
                        this.props.idConsulente,
                        null,
                        state.page,
                        state.pageSize,
                        "id"
                      ).then((result) => {
                        if (result != null) {
                          let newArray = this.state.esercizi;
                          newArray = result;
                          this.setState(
                            {
                              esercizi: newArray,
                            },
                            () => {
                              let a = this.getEserciziColumn(newArray);
                              this.setState({
                                colonneEsercizi: a,
                                loading: false,
                              });
                            }
                          );
                        }
                      });
                    })
                    .catch((error) => {
                      if (error.status === 403) {
                        this.props.handleInvalidToken();
                      } else {
                        this.props.handleError(true, error.message);
                      }
                    });
                  this.setState({
                    loading: false,
                  });
                }
              }}
              columns={this.state.colonneEsercizi}
              data={this.state.esercizi}
              previousText="Precedente"
              nextText="Successivo"
              noDataText="Nessun Esercizio Trovato"
              pageText="Pagina"
              ofText="di"
              rowsText="righe"
              pageJumpText="Vai a pagina"
              rowsSelectorText="righe per pagina"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <ActionButton
                onClick={this.props.closeModal}
                label="Annulla"
                secondaryButton
                disabled={false}
                grayVersion={false}
              />
              <ActionButton
                label="Prosegui"
                disabled={false}
                grayVersion={false}
                onClick={() => {
                  if (this.state.selectedEsercizio !== -1) {
                    this.props.openModalitaModal(
                      this.props.idConsulente,
                      this.state.selectedEsercizio
                    );
                  } else {
                    alert(
                      "Attenzione, occorre selezionare un esercizio commerciale"
                    );
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
