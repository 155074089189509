import React, { Fragment } from "react";
import BoxTitoloManuale from "./BoxTitoloManuale";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ActionButton from "../../../components/ActionButton";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  IconButton,
  Modal,
  Select,
  MenuItem,
  createMuiTheme,
  ThemeProvider,
  ListItem,
  Collapse,
  List,
  Divider,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import CustomTableForManuale from "./CustomTableForManuale";
import {
  clearToken,
  getChapterIndex,
  getParagraphIndex,
  setChapterIndex,
  setParagraphIndex,
} from "../../../utils/storage";
import CloseIcon from "@material-ui/icons/Close";
import Lock from "@material-ui/icons/Lock";
import {
  getContenutoParagrafo,
  getParagrafiManualePersonalizzato,
  postUploadImmaginiSottoparagrafi,
  putModificaParagrafiManualePersonalizzato,
  updateSottoparagrafi,
} from "../../../utils/api/paragrafi_api";
import {
  LockOpen,
  ArrowUpward,
  ArrowDownward,
  Edit,
  ExpandLess,
  ExpandMore,
} from "@material-ui/icons";
import NewSottoparagrafoTesto from "./NewSottoparagrafoTesto";
import NewSottoparagrafoTestoEImmagine from "./NewSottoparagrafoTestoEImmagine";
import GoBackButton from "../components/GoBackButton";
import ErrorDialog from "../../../components/ErrorDialog";
import SpinnerComponent from "../../../components/SpinnerComponent";
import { updateReparti } from "../../../utils/api/reparti";
import { updateLocaliManuale } from "../../../utils/api/locali_e_attrezzature_api";
import ModalChangeChapterName from "./ModalChangeChapterName";
import {
  getCapitoli,
  getCapitoliCount,
  putCapitoli,
} from "../../../utils/api/capitoli_api";
import { getManuale } from "../../../utils/api/modelli_and_manuali_api";
import { withRouter } from "react-router-dom";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
    padding: "26px 10px",
    minHeight: "90vh",
  },
  typo: {
    align: "center",
  },
  root: {
    flexGrow: 1,
    display: "flex",
  },
  buttonContainer: {},
  modalcontainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
    marginLeft: "20%",
    marginRight: "20%",
    //height: '85%'
    overflowY: "scroll",
    height: "600px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "250px",
  },
  sectioncontainer: {
    // border: '1px solid #e0e0e0',
    //borderRadius: '6px',
    //marginTop: '10px',
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  rowcontainer: {
    flexDirection: "row",
  },
  modalsectioncontainer: {
    flex: 1,
    display: "flex",
    //marginTop: 20,
    //marginBottom: 20,
    flexDirection: "column",
    justifyContent: "space-evely",
  },
  periodo: {
    flex: 1,
    display: "flex",
    flexDirection: "column",

    alignItems: "center",
    justifyContent: "space-evenly",
  },
  tableModalText: {
    marginTop: "2%",
    marginBottom: "2%",
  },
  notSelectedTab: {
    display: "flex",
    flexDirection: "row",
  },
  selectedTab: {
    display: "flex",
    flexDirection: "row",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function swapElements(arr, i1, i2) {
  let temp = arr[i1];

  arr[i1] = arr[i2];

  arr[i2] = temp;
}
function swapChapterElements(arr, i1, i2) {
  let temp = arr[i1];
  arr[i1] = arr[i2];

  arr[i2] = temp;
  arr[i1].paragraphData.map((item) => {
    item.chapterIndex = i1;
  });
  arr[i2].paragraphData.map((item) => {
    item.chapterIndex = i2;
  });
}
function swapTabElements(arr, i1, i2) {
  let valueOfFirst = arr[i1].value;
  let valueOfLast = arr[i2].value;

  let temp = arr[i1];

  arr[i1] = arr[i2];

  arr[i2] = temp;

  arr[i1].value = valueOfFirst;
  arr[i2].value = valueOfLast;
}

export const Component = withRouter(({ history, location }) => {});

class CreazioneNuovoManuale extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      idToSave: 0,
      idManuale: 0,
      currentTabValue: {
        label: "",
        value: "",
        idManuale: "",
        isSelected: true,
        isBlocked: true,
        obbligatorio: true,
      },
      tabListValues: [
        /*{
        name:"Chapter 1",
        isTabOpen:false,
        paragraphData:[{
          nomeTab:"Organigramma",
          value:0,
          isTabSelected:false,
        },{
          nomeTab:"Definizione Procedure Autocontrollo",
          value:1,
          isTabSelected:false,
        }]
        },
        {
          name:"Chapter 2",
          isTabOpen:false,
          paragraphData:[{
            nomeTab:"Descrizione Reparti",
            value:2,
            isTabSelected:false,
          },{
            nomeTab:"Definizione Reparti",
            value:3,
            isTabSelected:false,
          }]
          },*/
      ],
      loading: true,
      edit: false,
      model: [],
      manual: [],
      tabs: [
        /*{label:"Organigramma",value:"Organigramma",isSelected:true,isBlocked:true},{label:"Definizione Reparti",value:"Definizione_Reparti",isSelected:true,isBlocked:true},{label:"Descrizione Reparti",value:"Descrizione_Reparti",isSelected:true,isBlocked:true}/*,{label:"Attrezzatura",value:"Attrezzatura",isSelected:true,isBlocked:true},{label:"Definizione Procedure Autocontrollo",value:"Descrizione_Procedure_Autocontrollo",isSelected:true,isBlocked:true}*/
      ],
      isModalOpen: false,
      isSottoparagrafiModalOpen: false,
      firstRefresh: true,
      errorDialogVisible: false,
      errorDialogMessage: "",
      errorDialogTitle: null,
      isDialogOpen: false,
      tipoDiTabella: "Custom",
      tabelle: [],
      tabelleIndex: 1,
      chapterMode: undefined,
      colonneTabellaCustom: [],
      numberOfSottoparagrafo: 0,
      sottoParagrafi: [],
      sottoParagrafiData: [],
      sottoParagrafoIndex: 0,
      numberOfParagrafi: 0,
      selectedChapter: null,
      titoloSottoParagrafo: "Nuovo Sottoparagrafo",
      modalitaSottoparagrafo: "Testo",
      numberOfLines: 1,
      isChangeChapterNameModalOpen: false,
      modalTitle: "",
      editParagraphTitle: false,
      showTables: false,
      updatedReparti: null,
      updatedLocali: null,
      isConadModalOpen: false,
      manualeInfo: null,
      isTabChangePressed:false,
      isSaveButtonPressed:false,
    };
  }

  componentDidMount() {
    this.isConad();
    if (this.state.firstRefresh) {
      this.handleRefresh();
    }
  }
  handlePushBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  openConadHandlingModal = () => {
    this.setState({ isConadModalOpen: true });
  };

  closeConadHandlingModal = () => {
    this.setState({ isConadModalOpen: false });
  };

  isConad = () => {
    getManuale(this.props.manualeId)
      .then((manuale) => {
        if (manuale.gruppoId === 101) {
          this.openConadHandlingModal();
        } else {
          this.getParagrafiManualeInCostruzione();
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };

  handleRefresh = () => {
    this.setState({ firstRefresh: false });
  };
  sortByKey = (array) => {
    return array.sort(function (a, b) {
      var x = a["value"];
      var y = b["value"];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };

  getParagrafiManualeInCostruzione = () => {
    const manualeid = this.props.manualeId;
    let numberOfChapters = 0;
    let chapter = [];
    let chapterToPush = {};
    getCapitoliCount(manualeid)
      .then((result) => {
        numberOfChapters = result;
      })
      .then(() => {
        if (numberOfChapters === 0 && this.state.chapterMode === true) {
          chapterToPush = {
            name: "Nuovo Capitolo",
            id: null,
            isTabOpen: false,
            paragraphData: [],
          };
          const manualeid = this.props.manualeId;
          getParagrafiManualePersonalizzato(manualeid).then((result) => {
            let tabArray = [];
            for (let i = 0; i < result.length; i++) {
              tabArray.push({
                label: result[i].titolo,
                nomeTab: result[i].titolo,
                isTabSelected: false,
                id: result[i].id,
                stato: result[i].stato,
                value: result[i].numeroParagrafo,
                idManuale: result[i].manualeId,
                chapterIndex: 0,
                isSelected: true,
                isBlocked: result[i].bloccato,
                obbligatorio: result[i].obbligatorio,
              });
              chapterToPush.paragraphData = tabArray;
            }
            chapter.push(chapterToPush);
            this.setState(
              {
                tabs: tabArray,
                tabListValues: chapter,
                idToSave: result[0].id,
                idManuale: this.props.manualeId,
                numberOfParagrafi: tabArray.length,
                chapterMode: true,
              },
              () => {
                let chapterIndex = parseInt(getChapterIndex());
                let paragraphIndex = parseInt(getParagraphIndex());
                this.handleTabChangeNew(paragraphIndex, chapterIndex);
              }
            );
          });
        } else if (numberOfChapters > 0) {
          let tabArray = [];
          getCapitoli(manualeid).then((result) => {
            result.map((item, index) => {
              tabArray = [];
              chapterToPush = {
                name: item.capitolo.titolo,
                id: item.capitolo.id,
                isTabOpen: false,
                paragraphData: [],
              };
              for (let i = 0; i < item.paragrafi.length; i++) {
                tabArray.push({
                  label: item.paragrafi[i].titolo,
                  nomeTab: item.paragrafi[i].titolo,
                  isTabSelected: false,
                  id: item.paragrafi[i].id,
                  stato: item.paragrafi[i].stato,
                  value: item.paragrafi[i].numeroParagrafo,
                  idManuale: item.paragrafi[i].manualeId,
                  chapterIndex: index,
                  isSelected: true,
                  isBlocked: item.paragrafi[i].bloccato,
                  obbligatorio: item.paragrafi[i].obbligatorio,
                });
                chapterToPush.paragraphData = tabArray;
              }
              this.sortByKey(tabArray);
              chapter.push(chapterToPush);
            });
            this.setState(
              {
                tabs: tabArray,
                tabListValues: chapter,
                idToSave: result[0].id,
                idManuale: this.props.manualeId,
                numberOfParagrafi: tabArray.length,
                chapterMode: true,
              },
              () => {
                // let tabList = this.state.tabListValues;
                /*let chapterToOpen = 0;
                 */
                let chapterIndex = parseInt(getChapterIndex());
                /*for(let i = 0 ;i < tabList.length;i++){
            if(tabList[i].paragraphData.length > 0){
              console.log("Test");
              chapterIndex = i;
              break;
            }
          }*/
                let paragraphIndex = parseInt(getParagraphIndex());
                //this.handleTabChangeNew(0,chapterToOpen);
                let paragrafoElement =
                  this.state.tabListValues[chapterIndex].paragraphData.at(
                    paragraphIndex
                  );
                if (paragrafoElement === undefined) {
                  for (
                    let i = 0;
                    i <= this.state.tabListValues.length - 1;
                    i++
                  ) {
                    if (this.state.tabListValues[i].paragraphData.length > 0) {
                      this.handleTabChangeNew(0, i);
                    }
                  }
                } else {
                  this.handleTabChangeNew(paragraphIndex, chapterIndex);
                }
              }
            );
          });
        } else {
          let indexToChange = parseInt(getParagraphIndex());
          this.state.tabs.forEach((tab, index) => {
            if (tab.id === this.state.currentTabValue.id) {
              indexToChange = index;
            }
          });
          const manualeid = this.props.manualeId;
          getParagrafiManualePersonalizzato(manualeid)
            .then((result) => {
              let tabArray = [];
              for (let i = 0; i < result.length; i++) {
                tabArray.push({
                  label: result[i].titolo,
                  id: result[i].id,
                  stato: result[i].stato,
                  value: result[i].numeroParagrafo,
                  idManuale: result[i].manualeId,
                  isSelected: true,
                  isBlocked: result[i].bloccato,
                  obbligatorio: result[i].obbligatorio,
                });
              }
              this.sortByKey(tabArray);
              this.setState(
                {
                  tabs: tabArray,
                  idToSave: result[0].id,
                  idManuale: result[0].manualeId,
                  numberOfParagrafi: tabArray.length,
                  chapterMode: false,
                },
                () => {
                  this.handleTabChangeManually(indexToChange);
                }
              );
            })
            .catch((error) => {
              if (error.status === 403) {
                this.handleInvalidToken();
              } else {
                this.handleError(true, error.message);
              }
            });
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };
  getParagrafiManualeInCostruzioneAfterSaveForChapters = () => {
    const manualeid = this.props.manualeId;
    let numberOfChapters = 0;
    let chapter = [];
    let chapterToPush = {};
    getCapitoliCount(manualeid)
      .then((result) => {
        numberOfChapters = result;
      })
      .then(() => {
        if (numberOfChapters === 0 && this.state.chapterMode === true) {
          chapterToPush = {
            name: "Nuovo Capitolo",
            id: null,
            isTabOpen: false,
            paragraphData: [],
          };
          const manualeid = this.props.manualeId;
          getParagrafiManualePersonalizzato(manualeid).then((result) => {
            let tabArray = [];
            for (let i = 0; i < result.length; i++) {
              tabArray.push({
                label: result[i].titolo,
                nomeTab: result[i].titolo,
                isTabSelected: false,
                id: result[i].id,
                stato: result[i].stato,
                value: result[i].numeroParagrafo,
                idManuale: result[i].manualeId,
                chapterIndex: 0,
                isSelected: true,
                isBlocked: result[i].bloccato,
                obbligatorio: result[i].obbligatorio,
              });
              chapterToPush.paragraphData = tabArray;
            }
            chapter.push(chapterToPush);
            this.setState(
              {
                tabs: tabArray,
                tabListValues: chapter,
                idToSave: result[0].id,
                idManuale: this.props.manualeId,
                numberOfParagrafi: tabArray.length,
                chapterMode: true,
              },
              () => {
                this.handleTabChangeNew(0, 0);
              }
            );
          });
        } else if (numberOfChapters > 0) {
          let tabArray = [];
          getCapitoli(manualeid).then((result) => {
            result.map((item, index) => {
              tabArray = [];
              chapterToPush = {
                name: item.capitolo.titolo,
                id: item.capitolo.id,
                isTabOpen: this.state.tabListValues[index].isTabOpen,
                paragraphData: [],
              };
              for (let i = 0; i < item.paragrafi.length; i++) {
                tabArray.push({
                  label: item.paragrafi[i].titolo,
                  nomeTab: item.paragrafi[i].titolo,
                  isTabSelected: false,
                  id: item.paragrafi[i].id,
                  stato: item.paragrafi[i].stato,
                  value: item.paragrafi[i].numeroParagrafo,
                  idManuale: item.paragrafi[i].manualeId,
                  chapterIndex: index,
                  isSelected: true,
                  isBlocked: item.paragrafi[i].bloccato,
                  obbligatorio: item.paragrafi[i].obbligatorio,
                });
                chapterToPush.paragraphData = tabArray;
              }
              this.sortByKey(tabArray);
              chapter.push(chapterToPush);
            });
            this.setState(
              {
                tabs: tabArray,
                tabListValues: chapter,
                idToSave: result[0].id,
                idManuale: this.props.manualeId,
                numberOfParagrafi: tabArray.length,
                chapterMode: true,
              },
              () => {
                let tabList = this.state.tabListValues;
                let chapterToOpen = 0;
                for (let i = 0; i < tabList.length; i++) {
                  if (tabList[i].paragraphData.length > 0) {
                    chapterToOpen = i;
                    break;
                  }
                }
                let chapterElement =
                  this.state.tabListValues[
                    this.state.currentTabValue.chapterIndex
                  ];
                if (chapterElement === undefined) {
                  this.handleTabChangeNew(
                    this.state.currentTabValue.value - 1,
                    this.state.currentTabValue.chapterIndex - 1
                  );
                } else {
                  let paragrafoElement = this.state.tabListValues[
                    this.state.currentTabValue.chapterIndex
                  ].paragraphData.at(this.state.currentTabValue.value - 1);
                  if (paragrafoElement === undefined) {
                    if (
                      this.state.tabListValues[
                        this.state.currentTabValue.chapterIndex
                      ].paragraphData.length > 0
                    ) {
                      this.handleTabChangeNew(
                        this.state.currentTabValue.value - 2,
                        this.state.currentTabValue.chapterIndex
                      );
                    } else {
                      if (
                        this.state.tabListValues[
                          this.state.currentTabValue.chapterIndex + 1
                        ] !== undefined
                      ) {
                        let found = false;
                        for (
                          let i = 0;
                          i <= this.state.currentTabValue.chapterIndex - 1;
                          i++
                        ) {
                          if (
                            this.state.tabListValues[i].paragraphData.length > 0
                          ) {
                            found = true;
                            this.handleTabChangeNew(
                              this.state.tabListValues[i].paragraphData.length -
                                1,
                              i
                            );
                          }
                        }
                        if (!found) {
                          for (
                            let i = this.state.currentTabValue.chapterIndex + 1;
                            i < this.state.tabListValues.length;
                            i++
                          ) {
                            if (
                              this.state.tabListValues[i].paragraphData.length >
                              0
                            ) {
                              this.handleTabChangeNew(0, i);
                            }
                          }
                        }
                      } else {
                        let found = false;
                        for (
                          let i = 0;
                          i <= this.state.currentTabValue.chapterIndex - 1;
                          i++
                        ) {
                          if (
                            this.state.tabListValues[i].paragraphData.length > 0
                          ) {
                            found = true;
                            this.handleTabChangeNew(
                              this.state.tabListValues[i].paragraphData.length -
                                1,
                              i
                            );
                          }
                        }
                        if (!found) {
                          for (
                            let i = this.state.currentTabValue.chapterIndex + 1;
                            i < this.state.tabListValues.length;
                            i++
                          ) {
                            if (
                              this.state.tabListValues[i].paragraphData.length >
                              0
                            ) {
                              this.handleTabChangeNew(0, i);
                            }
                          }
                        }
                      }
                    }
                  } else {
                    this.handleTabChangeNew(
                      this.state.currentTabValue.value - 1,
                      this.state.currentTabValue.chapterIndex
                    );
                  }
                }
              }
            );
          });
        } else {
          let indexToChange = 0;
          this.state.tabs.forEach((tab, index) => {
            if (tab.id === this.state.currentTabValue.id) {
              indexToChange = index;
            }
          });
          const manualeid = this.props.manualeId;
          getParagrafiManualePersonalizzato(manualeid)
            .then((result) => {
              let tabArray = [];
              for (let i = 0; i < result.length; i++) {
                tabArray.push({
                  label: result[i].titolo,
                  id: result[i].id,
                  stato: result[i].stato,
                  value: result[i].numeroParagrafo,
                  idManuale: result[i].manualeId,
                  isSelected: true,
                  isBlocked: result[i].bloccato,
                  obbligatorio: result[i].obbligatorio,
                });
              }
              this.sortByKey(tabArray);
              this.setState(
                {
                  tabs: tabArray,
                  idToSave: result[0].id,
                  idManuale: result[0].manualeId,
                  numberOfParagrafi: tabArray.length,
                  chapterMode: false,
                },
                () => {
                  this.handleTabChangeManually(indexToChange);
                }
              );
            })
            .catch((error) => {
              if (error.status === 403) {
                this.handleInvalidToken();
              } else {
                this.handleError(true, error.message);
              }
            });
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };

  getParagrafiManualeInCostruzioneAfterSave = () => {
    let indexToChange = 0;
    this.state.tabs.forEach((tab, index) => {
      if (tab.id === this.state.currentTabValue.id) {
        indexToChange = index;
      }
    });
    const manualeid = this.props.manualeId;
    getParagrafiManualePersonalizzato(manualeid)
      .then((result) => {
        let tabArray = [];
        for (let i = 0; i < result.length; i++) {
          tabArray.push({
            label: result[i].titolo,
            id: result[i].id,
            stato: result[i].stato,
            value: result[i].numeroParagrafo,
            idManuale: result[i].manualeId,
            isSelected: true,
            isBlocked: result[i].bloccato,
            obbligatorio: result[i].obbligatorio,
          });
        }
        this.sortByKey(tabArray);
        this.setState(
          {
            tabs: tabArray,
            idToSave: result[0].id,
            idManuale: result[0].manualeId,
            numberOfParagrafi: tabArray.length,
            chapterMode: false,
          },
          () => {
            this.handleTabChangeManually(indexToChange);
          }
        );
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };
  updateParagrafiForChapterMode = (index) => {
    const capitoli = [...this.state.tabListValues];
    let idManuale = this.props.manualeId;
    let capitoliToPass = [];
    capitoli.map((item, index) => {
      let objectToPushInCapitolo = {};
      let paragrafi = [];
      let capitolo = {
        id: item.id,
        manualeId: idManuale,
        numeroCapitolo: item.numeroCapitolo,
        titolo: item.name,
        stato: null,
      };
      item.paragraphData.map((item2, index2) => {
        let paragrafo = {
          id: item2.id,
          manualeId: idManuale,
          capitoloId: item.id,
          numeroParagrafo: item2.value,
          titolo: item2.label,
          stato: item2.stato,
          obbligatorio: item2.obbligatorio,
          bloccato: item2.isBlocked,
        };
        paragrafi.push(paragrafo);
      });
      objectToPushInCapitolo = {
        capitolo: capitolo,
        paragrafi: paragrafi,
      };
      capitoliToPass.push(objectToPushInCapitolo);
    });

    let bodyToSave = {
      manualeId: idManuale,
      capitoli: capitoliToPass,
    };
    putCapitoli(bodyToSave)
      .then((result) => {
        this.getParagrafiManualeInCostruzioneAfterSaveForChapters();
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };

  updateParagrafi = () => {
    const paragrafi = [...this.state.tabs];
    const paragrafiToPass = [];
    paragrafi.map((item) => {
      paragrafiToPass.push({
        bloccato: item.isBlocked,
        id: item.id,
        manualeId: item.idManuale,
        numeroParagrafo: item.value,
        obbligatorio: item.obbligatorio,
        stato: item.stato,
        titolo: item.label,
      });
    });
    paragrafiToPass.map((item) => {});
    let requestBody = {
      manualeId: this.state.tabs.at(0).idManuale,
      paragrafi: paragrafiToPass,
    };
    putModificaParagrafiManualePersonalizzato(requestBody)
      .then((result) => {
        this.getParagrafiManualeInCostruzione();
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };
  updateParagrafiForTitolo = (titolo) => {
    const paragrafi = [...this.state.tabs];
    const paragrafiToPass = [];
    paragrafi.map((item) => {
      paragrafiToPass.push({
        bloccato: item.isBlocked,
        id: item.id,
        manualeId: item.idManuale,
        numeroParagrafo: item.value,
        obbligatorio: item.obbligatorio,
        stato: item.stato,
        titolo: item.label,
      });
    });
    paragrafiToPass.map((item) => {});
    let requestBody = {
      manualeId: this.state.tabs.at(0).idManuale,
      paragrafi: paragrafiToPass,
    };
    putModificaParagrafiManualePersonalizzato(requestBody)
      .then((result) => {
        this.getParagrafiManualeInCostruzione();
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };

  getSottoparagrafi = (id) => {
    getContenutoParagrafo(id)
      .then((result) => {
        this.setState({
          sottoParagrafiData: result.componenti,
          isTabChangePressed:false,
        });
      })
      .then((result) => {
        this.loadSottoparagrafi();
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };

  loadSottoparagrafi = () => {
    let sottoParagrafiArray = [];
    let numberToIncrease = this.state.numberOfSottoparagrafo;
    let sottoParagrafiData = [...this.state.sottoParagrafiData];
    sottoParagrafiData.map((item) => {
      if (item.componenti[0] === undefined) {
        if (item.tipoContenuto === "TABELLA_REPARTI") {
          sottoParagrafiArray.push({
            index: item.numeroComponente,
            value: (
              <CustomTableForManuale
                theme={this.props.theme}
                key={item.numeroComponente}
                aggiornaReparti={this.aggiornaReparti}
                aggiornaRepartiForResponsabili={
                  this.aggiornaRepartiForResponsabili
                }
                isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                mode={"Definizione_Reparto"}
                kind={this.props.mode}
                firstElement={true}
                handleError={this.handleError}
                titoloInizialeSottoparagrafo={item.titolo}
                handleInvalidToken={this.handleInvalidToken}
                idManuale={this.state.idManuale}
                modalTitle={item.titolo}
                numberOfRows={this.state.numberOfLines}
              ></CustomTableForManuale>
            ),
          });
        } else if (item.tipoContenuto === "TABELLA_LOCALI") {
          sottoParagrafiArray.push({
            index: item.numeroComponente,
            value: (
              <CustomTableForManuale
                theme={this.props.theme}
                key={item.numeroComponente}
                mode={"Descrizione_Reparto"}
                aggiornaLocali={this.aggiornaLocali}
                isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                handleError={this.handleError}
                firstElement={true}
                handleInvalidToken={this.handleInvalidToken}
                titoloInizialeSottoparagrafo={item.titolo}
                idManuale={this.state.idManuale}
                modalTitle={item.titolo}
                numberOfRows={this.state.numberOfLines}
              ></CustomTableForManuale>
            ),
          });
        } else if (item.tipoContenuto === "TABELLA_PROCEDURE") {
          sottoParagrafiArray.push({
            index: item.numeroComponente,
            value: (
              <CustomTableForManuale
                theme={this.props.theme}
                key={item.numeroComponente}
                idParagrafo={item.paragrafoId}
                isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                indexTab={item.numeroComponente}
                mode={"Definizione_Procedure_Autocontrollo"}
                bringDataToParent={this.bringDataToParentAutocontrollo}
                bringDataAfterExchange={
                  this.bringDataToParentAutocontrolloAfterExchange
                }
                bringTextToParent={this.bringTextToParentAutocontrollo}
                bringTitleToParent={this.bringTitleToParent}
                sottoParagrafiData={this.state.sottoParagrafiData}
                handleError={this.handleError}
                firstElement={true}
                itemScheda={item}
                handleInvalidToken={this.handleInvalidToken}
                titoloInizialeSottoparagrafo={item.titolo}
                idManuale={this.state.idManuale}
                modalTitle={item.titolo}
                numberOfRows={this.state.numberOfLines}
              ></CustomTableForManuale>
            ),
          });
        }
      } else if (item.tipoContenuto === "TABELLA_PROCEDURE") {
        sottoParagrafiArray.push({
          index: item.numeroComponente,
          value: (
            <CustomTableForManuale
              theme={this.props.theme}
              key={item.numeroComponente}
              indexTab={item.numeroComponente}
              isParagrafoBlocked={this.state.currentTabValue.isBlocked}
              bringTitleToParent={this.bringTitleToParent}
              idParagrafo={item.paragrafoId}
              mode={"Definizione_Procedure_Autocontrollo"}
              bringDataAfterExchange={
                this.bringDataToParentAutocontrolloAfterExchange
              }
              bringDataToParent={this.bringDataToParentAutocontrollo}
              bringTextToParent={this.bringTextToParentAutocontrollo}
              sottoParagrafiData={this.state.sottoParagrafiData}
              handleError={this.handleError}
              firstElement={true}
              itemScheda={item}
              handleInvalidToken={this.handleInvalidToken}
              rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
              titoloInizialeSottoparagrafo={item.titolo}
              idManuale={this.state.idManuale}
              modalTitle={item.titolo}
              numberOfRows={this.state.numberOfLines}
            ></CustomTableForManuale>
          ),
        });
      } else {
        if (item.componenti[0].tipo === "BLOCCO_TESTO") {
          if (item.numeroComponente === 0) {
            if (item.titolo === null) {
              sottoParagrafiArray.push({
                index: item.numeroComponente,
                value: (
                  <NewSottoparagrafoTestoEImmagine
                    theme={this.props.theme}
                    key={item.numeroComponente}
                    handleEditChange={this.handleEditChange}
                    isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                    sottoParagrafiData={this.state.sottoParagrafiData}
                    bringDataToParent={this.bringDataToParent}
                    deleteImage={this.deleteImageFromSottoparagrafo}
                    handleError={this.handleError}
                    handleInvalidToken={this.handleInvalidToken}
                    immaginiParagrafo={item.immagini}
                    bringTitleToParent={this.bringTitleToParent}
                    bringImageToParent={this.bringImageToParent}
                    testoSottoParagrafo={item.componenti[0].contenuto}
                    indexValue={item.numeroComponente}
                    firstElement={true}
                    titoloInizialeSottoparagrafo={
                      item.componenti[0].titolo === null
                        ? ""
                        : item.componenti[0].titolo
                    }
                    rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                    idImmagine={item.numeroComponente}
                    borderWidth={"100%"}
                    componentHeight={"50%"}
                    componentWidth={"50%"}
                    photoWidth={"100%"}
                    photoHeight={"100%"}
                  ></NewSottoparagrafoTestoEImmagine>
                ),
              });
            } else {
              sottoParagrafiArray.push({
                index: item.numeroComponente,
                value: (
                  <NewSottoparagrafoTestoEImmagine
                    theme={this.props.theme}
                    key={item.numeroComponente}
                    handleEditChange={this.handleEditChange}
                    isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                    sottoParagrafiData={this.state.sottoParagrafiData}
                    bringDataToParent={this.bringDataToParent}
                    deleteImage={this.deleteImageFromSottoparagrafo}
                    handleError={this.handleError}
                    handleInvalidToken={this.handleInvalidToken}
                    immaginiParagrafo={item.immagini}
                    bringTitleToParent={this.bringTitleToParent}
                    bringImageToParent={this.bringImageToParent}
                    testoSottoParagrafo={item.componenti[0].contenuto}
                    indexValue={item.numeroComponente}
                    firstElement={true}
                    titoloInizialeSottoparagrafo={
                      item.componenti[0].titolo === null
                        ? "Nuovo Sottoparagrafo"
                        : item.componenti[0].titolo
                    }
                    rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                    idImmagine={item.numeroComponente}
                    borderWidth={"100%"}
                    componentHeight={"50%"}
                    componentWidth={"50%"}
                    photoWidth={"100%"}
                    photoHeight={"100%"}
                  ></NewSottoparagrafoTestoEImmagine>
                ),
              });
            }
          } else {
            /*if(item.componenti[0].contenuto === ""){
                  sottoParagrafiArray.push({index:item.numeroComponente,value: <BoxTitoloManuale indexValue={item.numeroComponente} handleError={this.handleError} deleteImage={this.deleteImageFromSottoparagrafo} handleInvalidToken={this.handleInvalidToken} immaginiParagrafo={item.immagini} titoloInizialeSottoparagrafo={item.titolo === null ? "Nuovo Sottoparagrafo" : item.titolo} bringImageToParent={this.bringImageToParent} bringTitleToParent={this.bringTitleToParent}  rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} idImmagine={item.numeroComponente} borderWidth={"100%"} componentHeight={"50%"} componentWidth={"50%"} photoWidth={"100%"} photoHeight={"100%"}></BoxTitoloManuale>});
                }
                else if(item.immagini.length === 0){
                  sottoParagrafiArray.push({index:item.numeroComponente,value:<NewSottoparagrafoTesto bringDataToParent={this.bringDataToParent} bringTitleToParent={this.bringTitleToParent} indexValue={item.numeroComponente} testoSottoParagrafo={item.componenti[0].contenuto} componentHeight={"50%"} componentWidth={"50%"} rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} titoloInizialeSottoparagrafo={item.titolo === null ? "Nuovo Sottoparagrafo" : item.titolo}></NewSottoparagrafoTesto>})
                }
                else{*/
            sottoParagrafiArray.push({
              index: item.numeroComponente,
              value: (
                <NewSottoparagrafoTestoEImmagine
                  theme={this.props.theme}
                  key={item.numeroComponente}
                  handleEditChange={this.handleEditChange}
                  isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                  sottoParagrafiData={this.state.sottoParagrafiData}
                  moveUpward={this.handleSottoparagrafoMovementUpward}
                  moveDownward={this.handleSottoparagrafoMovementDownward}
                  indexValue={item.numeroComponente}
                  deleteImage={this.deleteImageFromSottoparagrafo}
                  handleError={this.handleError}
                  handleInvalidToken={this.handleInvalidToken}
                  immaginiParagrafo={item.immagini}
                  bringDataToParent={this.bringDataToParent}
                  bringTitleToParent={this.bringTitleToParent}
                  bringImageToParent={this.bringImageToParent}
                  testoSottoParagrafo={item.componenti[0].contenuto}
                  firstElement={false}
                  titoloInizialeSottoparagrafo={
                    item.titolo === null ? "Nuovo Sottoparagrafo" : item.titolo
                  }
                  rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                  idImmagine={item.numeroComponente}
                  borderWidth={"100%"}
                  componentHeight={"50%"}
                  componentWidth={"50%"}
                  photoWidth={"100%"}
                  photoHeight={"100%"}
                ></NewSottoparagrafoTestoEImmagine>
              ),
            });
            //}
          }
        }
        if (item.componenti[0].tipo === "TABELLA") {
          if (item.componenti[0].organigramma) {
            sottoParagrafiArray.push({
              index: item.numeroComponente,
              value: (
                <CustomTableForManuale
                  theme={this.props.theme}
                  key={item.numeroComponente}
                  isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                  firstElement={true}
                  sottoParagrafiData={this.state.sottoParagrafiData}
                  mode={"Organigramma"}
                  changeEditMode={this.handleEditChange}
                  bringDataToParentAutocontrolloOrganigramma={
                    this.bringDataToParentAutocontrolloOrganigramma
                  }
                  kind={this.props.mode}
                  idParagrafo={item.paragrafoId}
                  handleError={this.handleError}
                  handleInvalidToken={this.handleInvalidToken}
                  idManuale={this.state.idManuale}
                  modalTitle={item.titolo}
                  titoloInizialeSottoparagrafo={item.titolo}
                  indexTab={item.numeroComponente}
                  numberOfRows={this.state.numberOfLines}
                ></CustomTableForManuale>
              ),
            });
          } else {
            sottoParagrafiArray.push({
              index: item.numeroComponente,
              value: (
                <CustomTableForManuale
                  theme={this.props.theme}
                  key={item.numeroComponente}
                  isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                  mode={"Custom"}
                  updateCustomTable={this.aggiornaTabellaCustomHandler}
                  moveUpward={this.handleSottoparagrafoMovementUpward}
                  moveDownward={this.handleSottoparagrafoMovementDownward}
                  titoloParagrafo={item.titolo}
                  sottoParagrafiData={this.state.sottoParagrafiData}
                  idParagrafo={item.paragrafoId}
                  modalTitle={item.titolo}
                  titoloInizialeSottoparagrafo={item.titolo}
                  handleError={this.handleError}
                  bringTitleToParent={this.bringTitleToParent}
                  handleInvalidToken={this.handleInvalidToken}
                  idManuale={this.state.idManuale}
                  indexTab={this.state.sottoParagrafoIndex}
                  handleRimuoviTabella={this.handleRimuoviTabella}
                  rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                  columns={this.state.colonneTabellaCustom}
                  numberOfRows={this.state.numberOfLines}
                ></CustomTableForManuale>
              ),
            });
          }
        }
        if (item.componenti[0].tipo === "TABELLA_PERSONALIZZATA") {
          let contenuto = item.componenti[0].contenuto;
          let colonne = item.componenti[0].definizioniColonne;
          var array = JSON.parse("[" + colonne + "]");
          this.setState({
            colonneTabellaCustom: array[0],
          });
          let columnsToShow = [];
          array[0].forEach((item,index) => {
            columnsToShow.push({
              id:"Colonna"+index,
              header:item,
            })
          })
          sottoParagrafiArray.push({
            index: item.numeroComponente,
            value: (
              <CustomTableForManuale
                theme={this.props.theme}
                key={item.numeroComponente}
                isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                sottoParagrafiData={this.state.sottoParagrafiData}
                mode={"Custom"}
                updateCustomTable={this.aggiornaTabellaCustomHandler}
                moveUpward={this.handleSottoparagrafoMovementUpward}
                moveDownward={this.handleSottoparagrafoMovementDownward}
                modalTitle={item.titolo}
                titoloParagrafo={item.titolo}
                titoloInizialeSottoparagrafo={item.titolo}
                handleError={this.handleError}
                bringTitleToParent={this.bringTitleToParent}
                contenuto={contenuto}
                handleInvalidToken={this.handleInvalidToken}
                idManuale={this.state.idManuale}
                idParagrafo={item.paragrafoId}
                indexTab={item.numeroComponente}
                handleRimuoviTabella={this.handleRimuoviTabella}
                rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                columns={columnsToShow}
                numberOfRows={this.state.numberOfLines}
              ></CustomTableForManuale>
            ),
          });
        }
      }
    });
    numberToIncrease = sottoParagrafiArray.length;
    this.setState({
      sottoParagrafi: sottoParagrafiArray,
      numberOfSottoparagrafo: numberToIncrease,
      sottoParagrafoIndex: numberToIncrease,
      isSaveButtonPressed:false,
      colonneTabellaCustom: [],
    });
  };
  handleEditChange = () => {
    this.setState({
      edit: true,
    });
  };

  loadSottoparagrafiAfterSwap = () => {
    let sottoParagrafiArray = [];
    let numberToIncrease = this.state.numberOfSottoparagrafo;
    let sottoParagrafiData = [...this.state.sottoParagrafiData];
    sottoParagrafiData.map((item, index) => {
      if (item !== undefined) {
        if (item.componenti[0] === undefined) {
          if (item.tipoContenuto === "TABELLA_REPARTI") {
            sottoParagrafiArray.push({
              index: index,
              value: (
                <CustomTableForManuale
                  theme={this.props.theme}
                  aggiornaReparti={this.aggiornaReparti}
                  aggiornaRepartiForResponsabili={
                    this.aggiornaRepartiForResponsabili
                  }
                  isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                  mode={"Definizione_Reparto"}
                  kind={this.props.mode}
                  firstElement={true}
                  handleError={this.handleError}
                  titoloInizialeSottoparagrafo={item.titolo}
                  handleInvalidToken={this.handleInvalidToken}
                  idManuale={this.state.idManuale}
                  modalTitle={item.titolo}
                  numberOfRows={this.state.numberOfLines}
                />
              ),
            });
          } else if (item.tipoContenuto === "TABELLA_LOCALI") {
            sottoParagrafiArray.push({
              index: index,
              value: (
                <CustomTableForManuale
                  theme={this.props.theme}
                  aggiornaLocali={this.aggiornaLocali}
                  isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                  mode={"Descrizione_Reparto"}
                  handleError={this.handleError}
                  firstElement={true}
                  handleInvalidToken={this.handleInvalidToken}
                  titoloInizialeSottoparagrafo={item.titolo}
                  idManuale={this.state.idManuale}
                  modalTitle={item.titolo}
                  numberOfRows={this.state.numberOfLines}
                />
              ),
            });
          } else if (item.tipoContenuto === "TABELLA_PROCEDURE") {
            sottoParagrafiArray.push({
              index: index,
              value: (
                <CustomTableForManuale
                  theme={this.props.theme}
                  isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                  idParagrafo={item.paragrafoId}
                  indexTab={index}
                  mode={"Definizione_Procedure_Autocontrollo"}
                  bringDataToParent={this.bringDataToParentAutocontrollo}
                  bringDataAfterExchange={
                    this.bringDataToParentAutocontrolloAfterExchange
                  }
                  bringTextToParent={this.bringTextToParentAutocontrollo}
                  bringTitleToParent={this.bringTitleToParent}
                  sottoParagrafiData={this.state.sottoParagrafiData}
                  handleError={this.handleError}
                  firstElement={true}
                  itemScheda={item}
                  handleInvalidToken={this.handleInvalidToken}
                  titoloInizialeSottoparagrafo={item.titolo}
                  idManuale={this.state.idManuale}
                  modalTitle={item.titolo}
                  numberOfRows={this.state.numberOfLines}
                />
              ),
            });
          }
        } else if (item.tipoContenuto === "TABELLA_PROCEDURE") {
          sottoParagrafiArray.push({
            index: index,
            value: (
              <CustomTableForManuale
                theme={this.props.theme}
                isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                indexTab={index}
                bringTitleToParent={this.bringTitleToParent}
                idParagrafo={item.paragrafoId}
                mode={"Definizione_Procedure_Autocontrollo"}
                bringDataAfterExchange={
                  this.bringDataToParentAutocontrolloAfterExchange
                }
                bringDataToParent={this.bringDataToParentAutocontrollo}
                bringTextToParent={this.bringTextToParentAutocontrollo}
                sottoParagrafiData={this.state.sottoParagrafiData}
                handleError={this.handleError}
                firstElement={true}
                itemScheda={item}
                handleInvalidToken={this.handleInvalidToken}
                rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                titoloInizialeSottoparagrafo={item.titolo}
                idManuale={this.state.idManuale}
                modalTitle={item.titolo}
                numberOfRows={this.state.numberOfLines}
              />
            ),
          });
        } else {
          if (item.componenti[0].tipo === "BLOCCO_TESTO") {
            if (index === 0) {
              if (item.titolo === null) {
                sottoParagrafiArray.push({
                  index: index,
                  value: (
                    <NewSottoparagrafoTestoEImmagine
                      theme={this.props.theme}
                      isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                      sottoParagrafiData={this.state.sottoParagrafiData}
                      bringDataToParent={this.bringDataToParent}
                      deleteImage={this.deleteImageFromSottoparagrafo}
                      handleError={this.handleError}
                      handleInvalidToken={this.handleInvalidToken}
                      handleEditChange={this.handleEditChange}
                      immaginiParagrafo={item.immagini}
                      bringTitleToParent={this.bringTitleToParent}
                      bringImageToParent={this.bringImageToParent}
                      testoSottoParagrafo={item.componenti[0].contenuto}
                      indexValue={index}
                      firstElement={true}
                      titoloInizialeSottoparagrafo={
                        item.componenti[0].titolo === null
                          ? ""
                          : item.componenti[0].titolo
                      }
                      rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                      idImmagine={index}
                      borderWidth={"100%"}
                      componentHeight={"50%"}
                      componentWidth={"50%"}
                      photoWidth={"100%"}
                      photoHeight={"100%"}
                    />
                  ),
                });
              } else {
                sottoParagrafiArray.push({
                  index: index,
                  value: (
                    <NewSottoparagrafoTestoEImmagine
                      theme={this.props.theme}
                      handleEditChange={this.handleEditChange}
                      isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                      sottoParagrafiData={this.state.sottoParagrafiData}
                      bringDataToParent={this.bringDataToParent}
                      deleteImage={this.deleteImageFromSottoparagrafo}
                      handleError={this.handleError}
                      handleInvalidToken={this.handleInvalidToken}
                      immaginiParagrafo={item.immagini}
                      bringTitleToParent={this.bringTitleToParent}
                      bringImageToParent={this.bringImageToParent}
                      testoSottoParagrafo={item.componenti[0].contenuto}
                      indexValue={index}
                      firstElement={true}
                      titoloInizialeSottoparagrafo={
                        item.componenti[0].titolo === null
                          ? "Nuovo Sottoparagrafo"
                          : item.componenti[0].titolo
                      }
                      rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                      idImmagine={index}
                      borderWidth={"100%"}
                      componentHeight={"50%"}
                      componentWidth={"50%"}
                      photoWidth={"100%"}
                      photoHeight={"100%"}
                    />
                  ),
                });
              }
            } else {
              /*if(item.componenti[0].contenuto === ""){
                  sottoParagrafiArray.push({index:item.numeroComponente,value: <BoxTitoloManuale indexValue={item.numeroComponente} handleError={this.handleError} deleteImage={this.deleteImageFromSottoparagrafo} handleInvalidToken={this.handleInvalidToken} immaginiParagrafo={item.immagini} titoloInizialeSottoparagrafo={item.titolo === null ? "Nuovo Sottoparagrafo" : item.titolo} bringImageToParent={this.bringImageToParent} bringTitleToParent={this.bringTitleToParent}  rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} idImmagine={item.numeroComponente} borderWidth={"100%"} componentHeight={"50%"} componentWidth={"50%"} photoWidth={"100%"} photoHeight={"100%"}></BoxTitoloManuale>});
                }
                else if(item.immagini.length === 0){
                  sottoParagrafiArray.push({index:item.numeroComponente,value:<NewSottoparagrafoTesto bringDataToParent={this.bringDataToParent} bringTitleToParent={this.bringTitleToParent} indexValue={item.numeroComponente} testoSottoParagrafo={item.componenti[0].contenuto} componentHeight={"50%"} componentWidth={"50%"} rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} titoloInizialeSottoparagrafo={item.titolo === null ? "Nuovo Sottoparagrafo" : item.titolo}></NewSottoparagrafoTesto>})
                }
                else{*/
              sottoParagrafiArray.push({
                index: index,
                value: (
                  <NewSottoparagrafoTestoEImmagine
                    theme={this.props.theme}
                    handleEditChange={this.handleEditChange}
                    isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                    sottoParagrafiData={this.state.sottoParagrafiData}
                    moveUpward={this.handleSottoparagrafoMovementUpward}
                    moveDownward={this.handleSottoparagrafoMovementDownward}
                    indexValue={index}
                    deleteImage={this.deleteImageFromSottoparagrafo}
                    handleError={this.handleError}
                    handleInvalidToken={this.handleInvalidToken}
                    immaginiParagrafo={item.immagini}
                    bringDataToParent={this.bringDataToParent}
                    bringTitleToParent={this.bringTitleToParent}
                    bringImageToParent={this.bringImageToParent}
                    testoSottoParagrafo={item.componenti[0].contenuto}
                    firstElement={false}
                    titoloInizialeSottoparagrafo={
                      item.titolo === null
                        ? "Nuovo Sottoparagrafo"
                        : item.titolo
                    }
                    rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                    idImmagine={index}
                    borderWidth={"100%"}
                    componentHeight={"50%"}
                    componentWidth={"50%"}
                    photoWidth={"100%"}
                    photoHeight={"100%"}
                  ></NewSottoparagrafoTestoEImmagine>
                ),
              });
              //}
            }
          }
          if (item.componenti[0].tipo === "TABELLA") {
            if (item.componenti[0].organigramma) {
              sottoParagrafiArray.push({
                index: index,
                value: (
                  <CustomTableForManuale
                    theme={this.props.theme}
                    isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                    firstElement={true}
                    sottoParagrafiData={this.state.sottoParagrafiData}
                    mode={"Organigramma"}
                    changeEditMode={this.handleEditChange}
                    kind={this.props.mode}
                    idParagrafo={item.paragrafoId}
                    handleError={this.handleError}
                    bringDataToParentAutocontrolloOrganigramma={
                      this.bringDataToParentAutocontrolloOrganigramma
                    }
                    handleInvalidToken={this.handleInvalidToken}
                    indexTab={index}
                    idManuale={this.state.idManuale}
                    modalTitle={item.titolo}
                    titoloInizialeSottoparagrafo={item.titolo}
                    numberOfRows={this.state.numberOfLines}
                  ></CustomTableForManuale>
                ),
              });
            } else {
              sottoParagrafiArray.push({
                index: index,
                value: (
                  <CustomTableForManuale
                    theme={this.props.theme}
                    isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                    mode={"Custom"}
                    updateCustomTable={this.aggiornaTabellaCustomHandler}
                    moveUpward={this.handleSottoparagrafoMovementUpward}
                    moveDownward={this.handleSottoparagrafoMovementDownward}
                    titoloParagrafo={item.titolo}
                    sottoParagrafiData={this.state.sottoParagrafiData}
                    idParagrafo={item.paragrafoId}
                    modalTitle={item.titolo}
                    titoloInizialeSottoparagrafo={item.titolo}
                    handleError={this.handleError}
                    bringTitleToParent={this.bringTitleToParent}
                    handleInvalidToken={this.handleInvalidToken}
                    idManuale={this.state.idManuale}
                    indexTab={index}
                    handleRimuoviTabella={this.handleRimuoviTabella}
                    rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                    columns={this.state.colonneTabellaCustom}
                    numberOfRows={this.state.numberOfLines}
                  ></CustomTableForManuale>
                ),
              });
            }
          }
          if (item.componenti[0].tipo === "TABELLA_PERSONALIZZATA") {
            let contenuto = item.componenti[0].contenuto;
            let colonne = item.componenti[0].definizioniColonne;
            var array = JSON.parse("[" + colonne + "]");
            this.setState({
              colonneTabellaCustom: array[0],
            });
            let columnsToShow = [];
            array[0].forEach((item,index) => {
            columnsToShow.push({
                id:"Colonna"+index,
                header:item,
              })
            })
            sottoParagrafiArray.push({
              index: index,
              value: (
                <CustomTableForManuale
                  theme={this.props.theme}
                  isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                  sottoParagrafiData={this.state.sottoParagrafiData}
                  mode={"Custom"}
                  updateCustomTable={this.aggiornaTabellaCustomHandler}
                  moveUpward={this.handleSottoparagrafoMovementUpward}
                  moveDownward={this.handleSottoparagrafoMovementDownward}
                  modalTitle={item.titolo}
                  titoloParagrafo={item.titolo}
                  titoloInizialeSottoparagrafo={item.titolo}
                  handleError={this.handleError}
                  bringTitleToParent={this.bringTitleToParent}
                  contenuto={contenuto}
                  handleInvalidToken={this.handleInvalidToken}
                  idManuale={this.state.idManuale}
                  idParagrafo={item.paragrafoId}
                  indexTab={index}
                  handleRimuoviTabella={this.handleRimuoviTabella}
                  rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                  columns={columnsToShow}
                  numberOfRows={this.state.numberOfLines}
                ></CustomTableForManuale>
              ),
            });
          }
        }
      }
      item.numeroComponente = index;
    });
    numberToIncrease = sottoParagrafiArray.length;
    this.setState(
      {
        sottoParagrafi: [...sottoParagrafiArray],
        numberOfSottoparagrafo: numberToIncrease,
        sottoParagrafoIndex: numberToIncrease,
        sottoParagrafiData: sottoParagrafiData,
        colonneTabellaCustom: [],
      },
      () => {}
    );
  };

  handleTabChange = (event, newValue) => {
    if(this.state.isTabChangePressed){
     // alert("Attenzione, occorre attendere la fine del caricamento per effettuare il cambio della tab");
    }else{
    this.setState({
      isTabChangePressed:true,
    })
    if (!Array.isArray(event._dispatchInstances)) {
      setParagraphIndex(newValue);
      this.setState({
        loading: true,
        editParagraphTitle: false,
      });
      if (this.state.edit === false) {
        this.setState({
          tabValue: newValue,
        });
        this.setState({
          showTables: false,
          sottoParagrafi: [],
          tabelle: [],
        });
        let nameToPass = "";
        let tablesArray = [];
        let sottoParagrafiArray = [];
        let numberToIncrease = this.state.numberOfSottoparagrafo;
        let paragrafoElement = this.state.tabs.at(newValue);
        if (
          paragrafoElement.obbligatorio &&
          paragrafoElement.label === "Organigramma"
        ) {
          nameToPass = "Organigramma";
          this.getSottoparagrafi(paragrafoElement.id);
          /*sottoParagrafiArray.push({index:0,value:<NewSottoparagrafoTestoEImmagine indexValue={this.state.sottoParagrafoIndex} firstElement={true} titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo} rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} idImmagine={numberToIncrease} borderWidth={"100%"} componentHeight={"50%"} componentWidth={"50%"} photoWidth={"100%"} photoHeight={"100%"}></NewSottoparagrafoTestoEImmagine>})
            sottoParagrafiArray.push({index:1,value:<CustomTableForManuale mode={"Organigramma"} kind={this.props.mode} handleError={this.handleError} handleInvalidToken={this.handleInvalidToken} idManuale={this.state.idManuale} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>});*/
          /*let newIndexSottoparagrafo = this.state.sottoParagrafoIndex;
            newIndexSottoparagrafo += 2;
            this.setState({
              sottoParagrafoIndex:newIndexSottoparagrafo,
            })
            numberToIncrease += 1;*/
          this.setState({
            modalTitle: "Organigramma",
            // tabelle:tablesArray,
            // sottoParagrafi:sottoParagrafiArray,
            // numberOfSottoparagrafo:numberToIncrease,
            currentTabValue: paragrafoElement,
          });
        } else if (
          paragrafoElement.obbligatorio &&
          paragrafoElement.label === "Definizione dei reparti"
        ) {
          nameToPass = "Definizione Reparto";
          this.getSottoparagrafi(paragrafoElement.id);
          /*sottoParagrafiArray.push({index:0,value:<NewSottoparagrafoTestoEImmagine indexValue={this.state.sottoParagrafoIndex} firstElement={true} titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo} rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} idImmagine={numberToIncrease} borderWidth={"100%"} componentHeight={"50%"} componentWidth={"50%"} photoWidth={"100%"} photoHeight={"100%"}></NewSottoparagrafoTestoEImmagine>})
            sottoParagrafiArray.push({index:1,value:<CustomTableForManuale mode={"Definizione_Reparto"} kind={this.props.mode} handleError={this.handleError} handleInvalidToken={this.handleInvalidToken} idManuale={this.state.idManuale} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>});
            let newIndexSottoparagrafo = this.state.sottoParagrafoIndex;
            newIndexSottoparagrafo += 2;
            this.setState({
              sottoParagrafoIndex:newIndexSottoparagrafo
            })
            numberToIncrease += 1;*/
          this.setState({
            modalTitle: "Definizione Reparti",
            // tabelle:tablesArray,
            // sottoParagrafi:sottoParagrafiArray,
            // numberOfSottoparagrafo:numberToIncrease,
            currentTabValue: paragrafoElement,
          });
        } else if (
          paragrafoElement.obbligatorio &&
          paragrafoElement.label === "Descrizione dei reparti"
        ) {
          nameToPass = "Descrizione Reparto";
          this.getSottoparagrafi(paragrafoElement.id);
          /*sottoParagrafiArray.push({index:0,value:<NewSottoparagrafoTestoEImmagine indexValue={this.state.sottoParagrafoIndex} firstElement={true} titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo} rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} idImmagine={numberToIncrease} borderWidth={"100%"} componentHeight={"50%"} componentWidth={"50%"} photoWidth={"100%"} photoHeight={"100%"}></NewSottoparagrafoTestoEImmagine>})
            sottoParagrafiArray.push({index:1,value:<CustomTableForManuale mode={"Descrizione_Reparto"} handleError={this.handleError} handleInvalidToken={this.handleInvalidToken} idManuale={this.state.idManuale} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>});
            let newIndexSottoparagrafo = this.state.sottoParagrafoIndex;
            newIndexSottoparagrafo += 2;
            this.setState({
              sottoParagrafoIndex:newIndexSottoparagrafo
            })
            numberToIncrease += 1;*/
          this.setState({
            modalTitle: "Descrizione Reparti",
            //tabelle:tablesArray,
            //sottoParagrafi:sottoParagrafiArray,
            //numberOfSottoparagrafo:numberToIncrease,
            currentTabValue: paragrafoElement,
          });
        } else if (
          paragrafoElement.obbligatorio &&
          paragrafoElement.label ===
            "Definizione delle procedure di autocontrollo"
        ) {
          nameToPass = "Descrizione Procedure Autocontrollo";
          this.getSottoparagrafi(paragrafoElement.id);
          /*sottoParagrafiArray.push({index:0,value:<NewSottoparagrafoTestoEImmagine indexValue={this.state.sottoParagrafoIndex} firstElement={true} titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo} rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} idImmagine={numberToIncrease} borderWidth={"100%"} componentHeight={"50%"} componentWidth={"50%"} photoWidth={"100%"} photoHeight={"100%"}></NewSottoparagrafoTestoEImmagine>})
            sottoParagrafiArray.push({index:1,value:<CustomTableForManuale mode={"Definizione_Procedure_Autocontrollo"} handleError={this.handleError} handleInvalidToken={this.handleInvalidToken} idManuale={this.state.idManuale} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>});
            let newIndexSottoparagrafo = this.state.sottoParagrafoIndex;
            newIndexSottoparagrafo += 2;
            this.setState({
              sottoParagrafoIndex:newIndexSottoparagrafo,
            })
            numberToIncrease += 1;*/
          this.setState({
            modalTitle: "Definizione Procedure Autocontrollo",
            // tabelle:tablesArray,
            //sottoParagrafi:sottoParagrafiArray,
            //numberOfSottoparagrafo:numberToIncrease,
            currentTabValue: paragrafoElement,
          });
        } else {
          /*else if(newValue === "Attrezzatura"){
            this.setState({
              modalTitle: "Attrezzatura",
            });
          }*/
          this.setState(
            {
              modalTitle: "Paragrafo " + paragrafoElement.valueOfParagraph,
              currentTabValue: paragrafoElement,
            },
            () => {
              this.getSottoparagrafi(this.state.currentTabValue.id);
            }
          );
        }
        this.setState({
          loading: false,
          colonneTabellaCustom: [],
        });
      } else {
        let confirmation = window.confirm(
          "Modifiche non salvate, cambiare paragrafo?"
        );
        if (confirmation) {
          this.setState({
            edit: false,
            tabValue: newValue,
          });
          this.setState({
            showTables: false,
            sottoParagrafi: [],
            tabelle: [],
          });
          let nameToPass = "";
          let tablesArray = [];
          let sottoParagrafiArray = [];
          let numberToIncrease = this.state.numberOfSottoparagrafo;
          let paragrafoElement = this.state.tabs.at(newValue);
          if (
            paragrafoElement.obbligatorio &&
            paragrafoElement.label === "Organigramma"
          ) {
            nameToPass = "Organigramma";
            this.getSottoparagrafi(paragrafoElement.id);
            /*sottoParagrafiArray.push({index:0,value:<NewSottoparagrafoTestoEImmagine indexValue={this.state.sottoParagrafoIndex} firstElement={true} titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo} rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} idImmagine={numberToIncrease} borderWidth={"100%"} componentHeight={"50%"} componentWidth={"50%"} photoWidth={"100%"} photoHeight={"100%"}></NewSottoparagrafoTestoEImmagine>})
              sottoParagrafiArray.push({index:1,value:<CustomTableForManuale mode={"Organigramma"} kind={this.props.mode} handleError={this.handleError} handleInvalidToken={this.handleInvalidToken} idManuale={this.state.idManuale} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>});*/
            /*let newIndexSottoparagrafo = this.state.sottoParagrafoIndex;
              newIndexSottoparagrafo += 2;
              this.setState({
                sottoParagrafoIndex:newIndexSottoparagrafo,
              })
              numberToIncrease += 1;*/
            this.setState({
              modalTitle: "Organigramma",
              // tabelle:tablesArray,
              // sottoParagrafi:sottoParagrafiArray,
              // numberOfSottoparagrafo:numberToIncrease,
              currentTabValue: paragrafoElement,
            });
          } else if (
            paragrafoElement.obbligatorio &&
            paragrafoElement.label === "Definizione dei reparti"
          ) {
            nameToPass = "Definizione Reparto";
            this.getSottoparagrafi(paragrafoElement.id);
            /*sottoParagrafiArray.push({index:0,value:<NewSottoparagrafoTestoEImmagine indexValue={this.state.sottoParagrafoIndex} firstElement={true} titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo} rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} idImmagine={numberToIncrease} borderWidth={"100%"} componentHeight={"50%"} componentWidth={"50%"} photoWidth={"100%"} photoHeight={"100%"}></NewSottoparagrafoTestoEImmagine>})
              sottoParagrafiArray.push({index:1,value:<CustomTableForManuale mode={"Definizione_Reparto"} kind={this.props.mode} handleError={this.handleError} handleInvalidToken={this.handleInvalidToken} idManuale={this.state.idManuale} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>});
              let newIndexSottoparagrafo = this.state.sottoParagrafoIndex;
              newIndexSottoparagrafo += 2;
              this.setState({
                sottoParagrafoIndex:newIndexSottoparagrafo
              })
              numberToIncrease += 1;*/
            this.setState({
              modalTitle: "Definizione Reparti",
              // tabelle:tablesArray,
              // sottoParagrafi:sottoParagrafiArray,
              // numberOfSottoparagrafo:numberToIncrease,
              currentTabValue: paragrafoElement,
            });
          } else if (
            paragrafoElement.obbligatorio &&
            paragrafoElement.label === "Descrizione dei reparti"
          ) {
            nameToPass = "Descrizione Reparto";
            this.getSottoparagrafi(paragrafoElement.id);
            /*sottoParagrafiArray.push({index:0,value:<NewSottoparagrafoTestoEImmagine indexValue={this.state.sottoParagrafoIndex} firstElement={true} titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo} rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} idImmagine={numberToIncrease} borderWidth={"100%"} componentHeight={"50%"} componentWidth={"50%"} photoWidth={"100%"} photoHeight={"100%"}></NewSottoparagrafoTestoEImmagine>})
              sottoParagrafiArray.push({index:1,value:<CustomTableForManuale mode={"Descrizione_Reparto"} handleError={this.handleError} handleInvalidToken={this.handleInvalidToken} idManuale={this.state.idManuale} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>});
              let newIndexSottoparagrafo = this.state.sottoParagrafoIndex;
              newIndexSottoparagrafo += 2;
              this.setState({
                sottoParagrafoIndex:newIndexSottoparagrafo
              })
              numberToIncrease += 1;*/
            this.setState({
              modalTitle: "Descrizione Reparti",
              //tabelle:tablesArray,
              //sottoParagrafi:sottoParagrafiArray,
              //numberOfSottoparagrafo:numberToIncrease,
              currentTabValue: paragrafoElement,
            });
          } else if (
            paragrafoElement.obbligatorio &&
            paragrafoElement.label ===
              "Definizione delle procedure di autocontrollo"
          ) {
            nameToPass = "Descrizione Procedure Autocontrollo";
            this.getSottoparagrafi(paragrafoElement.id);
            /*sottoParagrafiArray.push({index:0,value:<NewSottoparagrafoTestoEImmagine indexValue={this.state.sottoParagrafoIndex} firstElement={true} titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo} rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} idImmagine={numberToIncrease} borderWidth={"100%"} componentHeight={"50%"} componentWidth={"50%"} photoWidth={"100%"} photoHeight={"100%"}></NewSottoparagrafoTestoEImmagine>})
              sottoParagrafiArray.push({index:1,value:<CustomTableForManuale mode={"Definizione_Procedure_Autocontrollo"} handleError={this.handleError} handleInvalidToken={this.handleInvalidToken} idManuale={this.state.idManuale} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>});
              let newIndexSottoparagrafo = this.state.sottoParagrafoIndex;
              newIndexSottoparagrafo += 2;
              this.setState({
                sottoParagrafoIndex:newIndexSottoparagrafo,
              })
              numberToIncrease += 1;*/
            this.setState({
              modalTitle: "Definizione Procedure Autocontrollo",
              // tabelle:tablesArray,
              //sottoParagrafi:sottoParagrafiArray,
              //numberOfSottoparagrafo:numberToIncrease,
              currentTabValue: paragrafoElement,
            });
          } else {
            /*else if(newValue === "Attrezzatura"){
              this.setState({
                modalTitle: "Attrezzatura",
              });
            }*/
            this.setState(
              {
                modalTitle: "Paragrafo " + paragrafoElement.valueOfParagraph,
                currentTabValue: paragrafoElement,
              },
              () => {
                this.getSottoparagrafi(paragrafoElement.id);
              }
            );
          }
          this.setState({
            loading: false,
            colonneTabellaCustom: [],
          });
        } else {
          this.setState({ loading: false });
        }
      }
    }
  }
  };

  handleTabChangeNew = (newValueItem, chapter) => {
    if(this.state.isTabChangePressed){
      //alert("Attenzione, occorre attendere la fine del caricamento per effettuare il cambio della tab");
    }else{
    this.setState({
      isTabChangePressed:true,
    })
    let newValue = newValueItem;
    let tabList = this.state.tabListValues;

    tabList.map((item, index) => {
      item.paragraphData.map((item2, index2) => {
        item2.isTabSelected = false;
      });
    });
    tabList.map((item, index) => {
      if (index === chapter) {
        item.paragraphData.map((item2, index2) => {
          if (index2 === newValueItem) {
            item2.isTabSelected = !item2.isTabSelected;
            item.isTabOpen = true;
          }
        });
      }
    });
    this.setState({
      loading: true,
      editParagraphTitle: false,
    });
    this.setState({
      tabValue: newValue,
      edit: false,
    });
    this.setState({
      showTables: false,
      sottoParagrafi: [],
      tabelle: [],
    });
    let nameToPass = "";
    let tablesArray = [];
    let sottoParagrafiArray = [];
    let numberToIncrease = this.state.numberOfSottoparagrafo;
    let paragrafoElement =
      this.state.tabListValues[chapter].paragraphData.at(newValueItem);
    if (
      paragrafoElement.obbligatorio &&
      paragrafoElement.label === "Organigramma"
    ) {
      nameToPass = "Organigramma";
      this.getSottoparagrafi(paragrafoElement.id);
      /*sottoParagrafiArray.push({index:0,value:<NewSottoparagrafoTestoEImmagine indexValue={this.state.sottoParagrafoIndex} firstElement={true} titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo} rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} idImmagine={numberToIncrease} borderWidth={"100%"} componentHeight={"50%"} componentWidth={"50%"} photoWidth={"100%"} photoHeight={"100%"}></NewSottoparagrafoTestoEImmagine>})
          sottoParagrafiArray.push({index:1,value:<CustomTableForManuale mode={"Organigramma"} kind={this.props.mode} handleError={this.handleError} handleInvalidToken={this.handleInvalidToken} idManuale={this.state.idManuale} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>});*/
      /*let newIndexSottoparagrafo = this.state.sottoParagrafoIndex;
          newIndexSottoparagrafo += 2;
          this.setState({
            sottoParagrafoIndex:newIndexSottoparagrafo,
          })
          numberToIncrease += 1;*/
      this.setState({
        modalTitle: "Organigramma",
        // tabelle:tablesArray,
        // sottoParagrafi:sottoParagrafiArray,
        // numberOfSottoparagrafo:numberToIncrease,
        currentTabValue: paragrafoElement,
      });
    } else if (
      paragrafoElement.obbligatorio &&
      paragrafoElement.label === "Definizione dei reparti"
    ) {
      nameToPass = "Definizione Reparto";
      this.getSottoparagrafi(paragrafoElement.id);
      /*sottoParagrafiArray.push({index:0,value:<NewSottoparagrafoTestoEImmagine indexValue={this.state.sottoParagrafoIndex} firstElement={true} titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo} rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} idImmagine={numberToIncrease} borderWidth={"100%"} componentHeight={"50%"} componentWidth={"50%"} photoWidth={"100%"} photoHeight={"100%"}></NewSottoparagrafoTestoEImmagine>})
          sottoParagrafiArray.push({index:1,value:<CustomTableForManuale mode={"Definizione_Reparto"} kind={this.props.mode} handleError={this.handleError} handleInvalidToken={this.handleInvalidToken} idManuale={this.state.idManuale} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>});
          let newIndexSottoparagrafo = this.state.sottoParagrafoIndex;
          newIndexSottoparagrafo += 2;
          this.setState({
            sottoParagrafoIndex:newIndexSottoparagrafo
          })
          numberToIncrease += 1;*/
      this.setState({
        modalTitle: "Definizione Reparti",
        // tabelle:tablesArray,
        // sottoParagrafi:sottoParagrafiArray,
        // numberOfSottoparagrafo:numberToIncrease,
        currentTabValue: paragrafoElement,
      });
    } else if (
      paragrafoElement.obbligatorio &&
      paragrafoElement.label === "Descrizione dei reparti"
    ) {
      nameToPass = "Descrizione Reparto";
      this.getSottoparagrafi(paragrafoElement.id);
      /*sottoParagrafiArray.push({index:0,value:<NewSottoparagrafoTestoEImmagine indexValue={this.state.sottoParagrafoIndex} firstElement={true} titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo} rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} idImmagine={numberToIncrease} borderWidth={"100%"} componentHeight={"50%"} componentWidth={"50%"} photoWidth={"100%"} photoHeight={"100%"}></NewSottoparagrafoTestoEImmagine>})
          sottoParagrafiArray.push({index:1,value:<CustomTableForManuale mode={"Descrizione_Reparto"} handleError={this.handleError} handleInvalidToken={this.handleInvalidToken} idManuale={this.state.idManuale} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>});
          let newIndexSottoparagrafo = this.state.sottoParagrafoIndex;
          newIndexSottoparagrafo += 2;
          this.setState({
            sottoParagrafoIndex:newIndexSottoparagrafo
          })
          numberToIncrease += 1;*/
      this.setState({
        modalTitle: "Descrizione Reparti",
        //tabelle:tablesArray,
        //sottoParagrafi:sottoParagrafiArray,
        //numberOfSottoparagrafo:numberToIncrease,
        currentTabValue: paragrafoElement,
      });
    } else if (
      paragrafoElement.obbligatorio &&
      paragrafoElement.label === "Definizione delle procedure di autocontrollo"
    ) {
      nameToPass = "Descrizione Procedure Autocontrollo";
      this.getSottoparagrafi(paragrafoElement.id);
      /*sottoParagrafiArray.push({index:0,value:<NewSottoparagrafoTestoEImmagine indexValue={this.state.sottoParagrafoIndex} firstElement={true} titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo} rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} idImmagine={numberToIncrease} borderWidth={"100%"} componentHeight={"50%"} componentWidth={"50%"} photoWidth={"100%"} photoHeight={"100%"}></NewSottoparagrafoTestoEImmagine>})
          sottoParagrafiArray.push({index:1,value:<CustomTableForManuale mode={"Definizione_Procedure_Autocontrollo"} handleError={this.handleError} handleInvalidToken={this.handleInvalidToken} idManuale={this.state.idManuale} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>});
          let newIndexSottoparagrafo = this.state.sottoParagrafoIndex;
          newIndexSottoparagrafo += 2;
          this.setState({
            sottoParagrafoIndex:newIndexSottoparagrafo,
          })
          numberToIncrease += 1;*/
      this.setState({
        modalTitle: "Definizione Procedure Autocontrollo",
        // tabelle:tablesArray,
        //sottoParagrafi:sottoParagrafiArray,
        //numberOfSottoparagrafo:numberToIncrease,
        currentTabValue: paragrafoElement,
      });
    } else {
      /*else if(newValue === "Attrezzatura"){
          this.setState({
            modalTitle: "Attrezzatura",
          });
        }*/
      this.setState(
        {
          modalTitle: "Paragrafo " + paragrafoElement.valueOfParagraph,
          currentTabValue: paragrafoElement,
        },
        () => {
          this.getSottoparagrafi(paragrafoElement.id);
        }
      );
    }
    this.setState(
      {
        loading: false,
        colonneTabellaCustom: [],
      },
      () => {
        setChapterIndex(chapter);
        setParagraphIndex(newValueItem);
      }
    );}
  };

  handleTabChangeManually = (newValue) => {
    let tabList = [...this.state.tabListValues];
    this.setState({
      loading: true,
    });
    this.setState({
      tabValue: newValue,
      edit: false,
    });
    this.setState({
      showTables: false,
      sottoParagrafi: [],
      tabelle: [],
    });
    let nameToPass = "";
    let tablesArray = [];
    let sottoParagrafiArray = [];
    let numberToIncrease = this.state.numberOfSottoparagrafo;
    let paragrafoElement = this.state.tabs.at(newValue);
    if (
      paragrafoElement.obbligatorio &&
      paragrafoElement.label === "Organigramma"
    ) {
      nameToPass = "Organigramma";
      this.getSottoparagrafi(paragrafoElement.id);
      /*sottoParagrafiArray.push({index:0,value:<NewSottoparagrafoTestoEImmagine indexValue={this.state.sottoParagrafoIndex} firstElement={true} titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo} rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} idImmagine={numberToIncrease} borderWidth={"100%"} componentHeight={"50%"} componentWidth={"50%"} photoWidth={"100%"} photoHeight={"100%"}></NewSottoparagrafoTestoEImmagine>})
          sottoParagrafiArray.push({index:1,value:<CustomTableForManuale mode={"Organigramma"} kind={this.props.mode} handleError={this.handleError} handleInvalidToken={this.handleInvalidToken} idManuale={this.state.idManuale} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>});*/
      /*let newIndexSottoparagrafo = this.state.sottoParagrafoIndex;
          newIndexSottoparagrafo += 2;
          this.setState({
            sottoParagrafoIndex:newIndexSottoparagrafo,
          })
          numberToIncrease += 1;*/
      this.setState({
        modalTitle: "Organigramma",
        // tabelle:tablesArray,
        // sottoParagrafi:sottoParagrafiArray,
        // numberOfSottoparagrafo:numberToIncrease,
        currentTabValue: paragrafoElement,
      });
    } else if (
      paragrafoElement.obbligatorio &&
      paragrafoElement.label === "Definizione dei reparti"
    ) {
      nameToPass = "Definizione Reparto";
      this.getSottoparagrafi(paragrafoElement.id);
      /*sottoParagrafiArray.push({index:0,value:<NewSottoparagrafoTestoEImmagine indexValue={this.state.sottoParagrafoIndex} firstElement={true} titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo} rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} idImmagine={numberToIncrease} borderWidth={"100%"} componentHeight={"50%"} componentWidth={"50%"} photoWidth={"100%"} photoHeight={"100%"}></NewSottoparagrafoTestoEImmagine>})
          sottoParagrafiArray.push({index:1,value:<CustomTableForManuale mode={"Definizione_Reparto"} kind={this.props.mode} handleError={this.handleError} handleInvalidToken={this.handleInvalidToken} idManuale={this.state.idManuale} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>});
          let newIndexSottoparagrafo = this.state.sottoParagrafoIndex;
          newIndexSottoparagrafo += 2;
          this.setState({
            sottoParagrafoIndex:newIndexSottoparagrafo
          })
          numberToIncrease += 1;*/
      this.setState({
        modalTitle: "Definizione Reparti",
        // tabelle:tablesArray,
        // sottoParagrafi:sottoParagrafiArray,
        // numberOfSottoparagrafo:numberToIncrease,
        currentTabValue: paragrafoElement,
      });
    } else if (
      paragrafoElement.obbligatorio &&
      paragrafoElement.label === "Descrizione dei reparti"
    ) {
      nameToPass = "Descrizione Reparto";
      this.getSottoparagrafi(paragrafoElement.id);
      /*sottoParagrafiArray.push({index:0,value:<NewSottoparagrafoTestoEImmagine indexValue={this.state.sottoParagrafoIndex} firstElement={true} titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo} rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} idImmagine={numberToIncrease} borderWidth={"100%"} componentHeight={"50%"} componentWidth={"50%"} photoWidth={"100%"} photoHeight={"100%"}></NewSottoparagrafoTestoEImmagine>})
          sottoParagrafiArray.push({index:1,value:<CustomTableForManuale mode={"Descrizione_Reparto"} handleError={this.handleError} handleInvalidToken={this.handleInvalidToken} idManuale={this.state.idManuale} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>});
          let newIndexSottoparagrafo = this.state.sottoParagrafoIndex;
          newIndexSottoparagrafo += 2;
          this.setState({
            sottoParagrafoIndex:newIndexSottoparagrafo
          })
          numberToIncrease += 1;*/
      this.setState({
        modalTitle: "Descrizione Reparti",
        //tabelle:tablesArray,
        //sottoParagrafi:sottoParagrafiArray,
        //numberOfSottoparagrafo:numberToIncrease,
        currentTabValue: paragrafoElement,
      });
    } else if (
      paragrafoElement.obbligatorio &&
      paragrafoElement.label === "Definizione delle procedure di autocontrollo"
    ) {
      nameToPass = "Descrizione Procedure Autocontrollo";
      this.getSottoparagrafi(paragrafoElement.id);
      /*sottoParagrafiArray.push({index:0,value:<NewSottoparagrafoTestoEImmagine indexValue={this.state.sottoParagrafoIndex} firstElement={true} titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo} rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} idImmagine={numberToIncrease} borderWidth={"100%"} componentHeight={"50%"} componentWidth={"50%"} photoWidth={"100%"} photoHeight={"100%"}></NewSottoparagrafoTestoEImmagine>})
          sottoParagrafiArray.push({index:1,value:<CustomTableForManuale mode={"Definizione_Procedure_Autocontrollo"} handleError={this.handleError} handleInvalidToken={this.handleInvalidToken} idManuale={this.state.idManuale} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>});
          let newIndexSottoparagrafo = this.state.sottoParagrafoIndex;
          newIndexSottoparagrafo += 2;
          this.setState({
            sottoParagrafoIndex:newIndexSottoparagrafo,
          })
          numberToIncrease += 1;*/
      this.setState({
        modalTitle: "Definizione Procedure Autocontrollo",
        // tabelle:tablesArray,
        //sottoParagrafi:sottoParagrafiArray,
        //numberOfSottoparagrafo:numberToIncrease,
        currentTabValue: paragrafoElement,
      });
    } else {
      /*else if(newValue === "Attrezzatura"){
          this.setState({
            modalTitle: "Attrezzatura",
          });
        }*/
      this.setState(
        {
          modalTitle: "Paragrafo " + paragrafoElement.valueOfParagraph,
          currentTabValue: paragrafoElement,
        },
        () => {
          this.getSottoparagrafi(paragrafoElement.id);
        }
      );
    }
    this.setState({
      loading: false,
      colonneTabellaCustom: [],
    });
  };

  handleChangeTipo = (event) => {
    this.setState({
      tipoDiTabella: event.target.value,
    });
  };
  handleNumbersOfLines = (event) => {
    this.setState({
      numberOfLines: event.target.value,
    });
  };
  aggiornaTabellaCustomHandler = (recordData) => {
    this.setState({
      edit: true,
    });
  };

  aggiornaReparti = (repartiData, index) => {
    this.setState(
      {
        updatedReparti: repartiData,
        edit: true,
      },
      () => {}
    );
  };
  aggiornaRepartiForResponsabili = (repartiData, index) => {
    this.setState(
      {
        updatedReparti: repartiData,
        // edit: true,
      },
      () => {}
    );
  };
  launchRepartiUpdate = (reparti) => {
    updateReparti(this.state.idManuale, reparti)
      .then((result) => {
        //window.location.reload(true);
        this.setState({
          updatedReparti: null,
        });
        if (this.state.chapterMode) {
          this.handleTabChangeNew(
            parseInt(getParagraphIndex()),
            parseInt(getChapterIndex())
          );
        } else {
          this.handleTabChangeManually(this.state.tabValue);
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
        this.setState({
          isSaveButtonPressed:false,
        })
      });
  };
  aggiornaLocali = (records) => {
    let recordsToChange = [...records];
    /*recordsToChange.map(item => {
          delete item['nomeReparto']
          delete item['repartiToSelect']
        })*/
    this.setState(
      {
        updatedLocali: recordsToChange,
        edit: true,
      },
      () => {}
    );
  };
  launchLocaliUpdate = (locali) => {
    let recordsToSend = [...locali];
    recordsToSend.map((item) => {
      delete item["nomeReparto"];
      delete item["repartiToSelect"];
    });
    let body = {
      manualeId: this.state.idManuale,
      locali: recordsToSend,
    };
    updateLocaliManuale(body)
      .then((result) => {
        this.setState({
          updatedLocali: null,
        });
        //this.getLocaliForTabella();
        //window.location.reload(true);
        if (!this.state.chapterMode) {
          this.handleTabChangeManually(this.state.tabValue);
        } else {
          this.handleTabChangeNew(
            parseInt(getParagraphIndex()),
            parseInt(getChapterIndex())
          );
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
        this.setState({
          isSaveButtonPressed:false,
        })
      });
  };

  bringDataToParent = (data, index) => {
    let newSubparagraphArray = [...this.state.sottoParagrafiData];
    let elementToChange = newSubparagraphArray.filter(
      (item) => item.numeroComponente === index
    );
    elementToChange[0].componenti[0].contenuto = data;
    this.setState(
      {
        sottoParagrafiData: newSubparagraphArray,
      },
      () => {
        this.handleEditChange();
      }
    );
  };
  bringDataToParentAutocontrollo = (data, index) => {
    let newSubparagraphArray = [...this.state.sottoParagrafiData];
    let elementToChange = newSubparagraphArray.filter(
      (item) => item.numeroComponente === index
    );
    elementToChange[0].componenti = data.componenti[1].componenti;
    this.setState({
      sottoParagrafiData: newSubparagraphArray,
    });
  };
  bringDataToParentAutocontrolloAfterExchange = (data, index) => {
    let newSubparagraphArray = [...this.state.sottoParagrafiData];
    let elementToChange = newSubparagraphArray.filter(
      (item) => item.numeroComponente === index
    );
    elementToChange[0].componenti = data;
    this.setState({
      sottoParagrafiData: newSubparagraphArray,
    });
  };
  bringDataToParentAutocontrolloOrganigramma = (data, index) => {
    let newSubparagraphArray = [...this.state.sottoParagrafiData];
    let elementToChange = newSubparagraphArray.filter(
      (item) => item.numeroComponente === index
    );
    elementToChange[0].componenti = data;
    this.setState({
      sottoParagrafiData: newSubparagraphArray,
    });
  };
  bringTextToParentAutocontrollo = (data, index, indexElement) => {
    let newSubparagraphArray = [...this.state.sottoParagrafiData];
    let elementToChange = newSubparagraphArray.filter(
      (item) => item.numeroComponente === index
    );
    elementToChange = data;
    this.setState({
      sottoParagrafiData: elementToChange,
    });
  };
  bringTitleToParent = (title, index) => {
    let newSubparagraphArray = [...this.state.sottoParagrafiData];
    let elementToChange = newSubparagraphArray.filter(
      (item) => item.numeroComponente === index
    );
    elementToChange[0].titolo = title;
    this.setState(
      {
        sottoParagrafiData: newSubparagraphArray,
      },
      () => {
        this.handleEditChange();
      }
    );
  };
  bringImageToParent = (image, names, index) => {
    let newSubparagraphArray = [...this.state.sottoParagrafiData];
    let elementToChange = newSubparagraphArray.filter(
      (item) => item.numeroComponente === index
    );
    postUploadImmaginiSottoparagrafi(image[image.length - 1])
      .then((result) => {
        elementToChange[0].immagini.push(result);
        this.setState({
          sottoParagrafiData: newSubparagraphArray,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };
  deleteImageFromSottoparagrafo = (immagini, index) => {
    let newSubparagraphArray = [...this.state.sottoParagrafiData];
    newSubparagraphArray[index].immagini = immagini;
    this.setState({
      sottoParagrafiData: newSubparagraphArray,
    });
  };

  handleAggiuntaTable = () => {
    if (this.state.colonneTabellaCustom.length > 0) {
      let tablesArray = [...this.state.sottoParagrafi];
      //let currentParagraph = this.state.tabs.at(this.state.currentTabValue);
      let currentParagraph = this.state.currentTabValue;
      var nameToPass = "Tabella Personalizzata";
      let subParagraphDataArray = [...this.state.sottoParagrafiData];
      let columnsToInsert = [...this.state.colonneTabellaCustom];
      let columnsNames = [];
      columnsToInsert.forEach((element) => {
        columnsNames.push(element.header);
      });
      let logicalArrayOfColumns = "";
      let data = [];
      let logicalArrayOfData = [];
      logicalArrayOfColumns = JSON.stringify(columnsNames);
      logicalArrayOfData = JSON.stringify(data);
      if (this.state.tipoDiTabella === "Custom") {
        let newSubparagraphArrayData = {
          tipo: "SOTTOPARAGRAFO",
          id: null,
          paragrafoId: currentParagraph.id,
          parentComponentId: null,
          numeroComponente: this.state.sottoParagrafoIndex,
          titolo: "Tabella Personalizzata",
          numeroSottoparagrafo: null,
          tipoContenuto: null,
          componenti: [
            {
              tipo: "TABELLA_PERSONALIZZATA",
              id: null,
              paragrafoId: currentParagraph.id,
              parentComponentId: null,
              numeroComponente: this.state.sottoParagrafoIndex,
              definizioniColonne: logicalArrayOfColumns,
              contenuto: logicalArrayOfData,
            },
          ],
          immagini: [],
        };
        this.setState(
          {
            sottoParagrafiData: subParagraphDataArray,
          },
          () => {}
        );
        tablesArray.push({
          index: this.state.sottoParagrafoIndex,
          value: (
            <CustomTableForManuale
              theme={this.props.theme}
              key={this.state.sottoParagrafoIndex}
              isParagrafoBlocked={this.state.currentTabValue.isBlocked}
              mode={"Custom"}
              updateCustomTable={this.aggiornaTabellaCustomHandler}
              moveUpward={this.handleSottoparagrafoMovementUpward}
              moveDownward={this.handleSottoparagrafoMovementDownward}
              titoloParagrafo={
                this.state.currentTabValue.label
                  ? this.state.currentTabValue.label
                  : "Nuovo Paragrafo"
              }
              idParagrafo={null}
              sottoParagrafiData={subParagraphDataArray}
              modalTitle={nameToPass}
              titoloInizialeSottoparagrafo={nameToPass}
              handleError={this.handleError}
              handleInvalidToken={this.handleInvalidToken}
              rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
              bringTitleToParent={this.bringTitleToParent}
              idManuale={this.state.idManuale}
              indexTab={this.state.sottoParagrafoIndex}
              handleRimuoviTabella={this.handleRimuoviTabella}
              columns={this.state.colonneTabellaCustom}
              numberOfRows={this.state.numberOfLines}
            ></CustomTableForManuale>
          ),
        });
        subParagraphDataArray.push(newSubparagraphArrayData);

        let newIndex = this.state.sottoParagrafoIndex;
        newIndex += 1;
        this.setState({
          sottoParagrafoIndex: newIndex,
        });
      }
      /*else if(this.state.tipoDiTabella === "Organigramma"){
          nameToPass = "Organigramma";
          tablesArray.push(<CustomTableForManuale mode={"Organigramma"} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>);
        }
        else if(this.state.tipoDiTabella === "Definizione_Reparto"){
          nameToPass = "Definizione Reparto";
          tablesArray.push(<CustomTableForManuale mode={"Definizione_Reparto"} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>);
        }
        else if(this.state.tipoDiTabella === "Descrizione_Reparto"){
          nameToPass = "Descrizione Reparto";
          tablesArray.push(<CustomTableForManuale mode={"Descrizione_Reparto"} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>);
        }
        else if(this.state.tipoDiTabella === "Definizione_Procedure_Autocontrollo"){
          nameToPass = "Descrizione Procedure Autocontrollo"
          tablesArray.push(<CustomTableForManuale mode={"Definizione_Procedure_Autocontrollo"} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>);
        }*/
      this.setState({
        sottoParagrafi: tablesArray,
        colonneTabellaCustom: [],
        showTables: true,
        isModalOpen: false,
        edit: true,
      });
    } else {
      alert("Attenzione, occorre aggiungere almeno una colonna");
    }
  };
  handleAggiuntaSottoparagrafi = () => {
    if (
      !this.state.currentTabValue.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let numberToIncrease = this.state.numberOfSottoparagrafo;
      let subParagraphArray = [...this.state.sottoParagrafi];
      let currentParagraph = this.state.currentTabValue;
      let subParagraphDataArray = [...this.state.sottoParagrafiData];
      if (this.state.modalitaSottoparagrafo === "Immagine") {
        let newSubparagraphArrayData = {
          tipo: "SOTTOPARAGRAFO",
          id: null,
          paragrafoId: currentParagraph.id,
          parentComponentId: null,
          numeroComponente: this.state.sottoParagrafoIndex,
          titolo: null,
          numeroSottoparagrafo: null,
          tipoContenuto: null,
          componenti: [
            {
              tipo: "BLOCCO_TESTO",
              id: null,
              paragrafoId: currentParagraph.id,
              parentComponentId: null,
              numeroComponente: this.state.sottoParagrafoIndex,
              titolo: "",
              contenuto: "",
            },
          ],
          immagini: [],
        };
        subParagraphArray.push({
          index: this.state.sottoParagrafoIndex,
          value: (
            <BoxTitoloManuale
              isParagrafoBlocked={this.state.currentTabValue.isBlocked}
              deleteImage={this.deleteImageFromSottoparagrafo}
              indexValue={this.state.sottoParagrafoIndex}
              titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo}
              handleError={this.handleError}
              handleInvalidToken={this.handleInvalidToken}
              bringTitleToParent={this.bringTitleToParent}
              bringImageToParent={this.bringImageToParent}
              rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
              idImmagine={numberToIncrease}
              borderWidth={"100%"}
              componentHeight={"50%"}
              componentWidth={"50%"}
              photoWidth={"100%"}
              photoHeight={"100%"}
            ></BoxTitoloManuale>
          ),
        });
        subParagraphDataArray.push(newSubparagraphArrayData);
        let newIndex = this.state.sottoParagrafoIndex;
        newIndex += 1;
        this.setState({
          sottoParagrafoIndex: newIndex,
        });
      }
      if (this.state.modalitaSottoparagrafo === "Testo") {
        let newSubparagraphArrayData = {
          tipo: "SOTTOPARAGRAFO",
          id: null,
          paragrafoId: currentParagraph.id,
          parentComponentId: null,
          numeroComponente: this.state.sottoParagrafoIndex,
          titolo: null,
          numeroSottoparagrafo: null,
          tipoContenuto: null,
          componenti: [
            {
              tipo: "BLOCCO_TESTO",
              id: null,
              paragrafoId: currentParagraph.id,
              parentComponentId: null,
              numeroComponente: this.state.sottoParagrafoIndex,
              titolo: "",
              contenuto: "",
            },
          ],
          immagini: [],
        };
        subParagraphDataArray.push(newSubparagraphArrayData);
        subParagraphArray.push({
          index: this.state.sottoParagrafoIndex,
          value: (
            <NewSottoparagrafoTesto
              isParagrafoBlocked={this.state.currentTabValue.isBlocked}
              bringDataToParent={this.bringDataToParent}
              bringTitleToParent={this.bringTitleToParent}
              indexValue={this.state.sottoParagrafoIndex}
              componentHeight={"50%"}
              componentWidth={"50%"}
              rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
              titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo}
            ></NewSottoparagrafoTesto>
          ),
        });
        let newIndex = this.state.sottoParagrafoIndex;
        newIndex += 1;
        this.setState({
          sottoParagrafoIndex: newIndex,
        });
      }
      if (this.state.modalitaSottoparagrafo === "ImmagineAndTesto") {
        let newSubparagraphArrayData = {
          tipo: "SOTTOPARAGRAFO",
          id: null,
          paragrafoId: currentParagraph.id,
          parentComponentId: null,
          numeroComponente: this.state.sottoParagrafoIndex,
          titolo: null,
          numeroSottoparagrafo: null,
          tipoContenuto: null,
          componenti: [
            {
              tipo: "BLOCCO_TESTO",
              id: null,
              paragrafoId: currentParagraph.id,
              parentComponentId: null,
              numeroComponente: this.state.sottoParagrafoIndex,
              titolo: "",
              contenuto: "",
            },
          ],
          immagini: [],
        };
        subParagraphDataArray.push(newSubparagraphArrayData);
        this.setState({
          sottoParagrafiData: subParagraphDataArray,
        });
        subParagraphArray.push({
          index: this.state.sottoParagrafoIndex,
          value: (
            <NewSottoparagrafoTestoEImmagine
              theme={this.props.theme}
              key={this.state.sottoParagrafoIndex}
              handleEditChange={this.handleEditChange}
              isParagrafoBlocked={this.state.currentTabValue.isBlocked}
              sottoParagrafiData={subParagraphDataArray}
              immaginiParagrafo={[]}
              deleteImage={this.deleteImageFromSottoparagrafo}
              moveUpward={this.handleSottoparagrafoMovementUpward}
              moveDownward={this.handleSottoparagrafoMovementDownward}
              firstElement={false}
              indexValue={this.state.sottoParagrafoIndex}
              handleError={this.handleError}
              handleInvalidToken={this.handleInvalidToken}
              bringDataToParent={this.bringDataToParent}
              bringTitleToParent={this.bringTitleToParent}
              bringImageToParent={this.bringImageToParent}
              titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo}
              testoSottoParagrafo={""}
              rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
              idImmagine={numberToIncrease}
              borderWidth={"100%"}
              componentHeight={"50%"}
              componentWidth={"50%"}
              photoWidth={"100%"}
              photoHeight={"100%"}
            ></NewSottoparagrafoTestoEImmagine>
          ),
        });
        let newIndex = this.state.sottoParagrafoIndex;
        newIndex += 1;
        this.setState({
          sottoParagrafoIndex: newIndex,
        });
      }
      numberToIncrease += 1;
      this.setState({
        numberOfSottoparagrafo: numberToIncrease,

        sottoParagrafi: subParagraphArray,
        isSottoparagrafiModalOpen: false,
        edit: true,
        titoloSottoParagrafo: "Nuovo Sottoparagrafo",
      });
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };
  handleRimuoviSottoparagrafo = (indexValue) => {
    if (
      !this.state.currentTabValue.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let subparagraphArray = [...this.state.sottoParagrafi];
      let subParagraphDataArray = [...this.state.sottoParagrafiData];
      let newSubparagraphArray = subparagraphArray.filter(
        (subparagraphArray) => subparagraphArray.index !== indexValue
      );
      let newSubparagraphArrayData = subParagraphDataArray.filter(
        (subParagraphDataArray) =>
          subParagraphDataArray.numeroComponente !== indexValue
      );
      let newIndex = this.state.sottoParagrafoIndex;
      newIndex -= 1;
      this.setState({
        sottoParagrafoIndex: newIndex,
      });
      this.setState(
        {
          sottoParagrafi: newSubparagraphArray,
          sottoParagrafiData: newSubparagraphArrayData,
          edit: true,
        },
        () => {
          //this.loadSottoparagrafi();
        }
      );
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };
  handleRimuoviTabella = (indexValue) => {
    if (
      !this.state.currentTabValue.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let subparagraphArray = [...this.state.sottoParagrafi];
      let subParagraphDataArray = [...this.state.sottoParagrafiData];
      let newSubparagraphArray = subparagraphArray.filter(
        (subparagraphArray) => subparagraphArray.index !== indexValue
      );
      let newSubparagraphArrayData = subParagraphDataArray.filter(
        (subParagraphDataArray) =>
          subParagraphDataArray.numeroComponente !== indexValue
      );
      let newIndex = this.state.sottoParagrafoIndex;
      newIndex -= 1;
      this.setState({
        sottoParagrafoIndex: newIndex,
      });
      this.setState({
        sottoParagrafi: newSubparagraphArray,
        sottoParagrafiData: newSubparagraphArrayData,
        edit: true,
      });
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };
  handleSubparagraphTitleText = (event) => {
    this.setState({
      titoloSottoParagrafo: event.target.value,
      edit: true,
    });
  };
  handleChangeModalitaSottoparagrafo = (event) => {
    this.setState({
      modalitaSottoparagrafo: event.target.value,
    });
  };
  handleSottoparagrafoMovementUpward = (sottoParagrafoIndex) => {
    if (
      !this.state.currentTabValue.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let newArray = [...this.state.sottoParagrafiData];
      if (sottoParagrafoIndex > 1) {
        swapElements(newArray, sottoParagrafoIndex, sottoParagrafoIndex - 1);
        this.setState(
          {
            sottoParagrafiData: [...newArray],
          },
          () => {
            this.loadSottoparagrafiAfterSwap();
          }
        );
        /*if(sottoParagrafoIndex > 0){
          let newArray = this.state.tabs;
          swapElements(newArray,sottoParagrafoIndex,sottoParagrafoIndex-1);
        }*/
      }
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };
  handleSottoparagrafoMovementDownward = (sottoParagrafoIndex) => {
    if (
      !this.state.currentTabValue.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let newArray = [...this.state.sottoParagrafiData];
      if (sottoParagrafoIndex < this.state.sottoParagrafi.length - 1) {
        swapElements(newArray, sottoParagrafoIndex, sottoParagrafoIndex + 1);
        /**/
        this.setState(
          {
            sottoParagrafiData: [...newArray],
          },
          () => {
            this.loadSottoparagrafiAfterSwap();
          }
        );
      }
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  handleInvalidToken = () => {
    this.handleError(
      true,
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi."
    );
    clearToken();
    let path = this.props.theme.path;
    window.setTimeout(function () {
      window.location.href = "/" + path;
    }, 4000);
  };
  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };
  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };
  openModalToChangeChapterName = (chapterData) => {
    let chapterToSave = { ...chapterData };
    this.setState({
      isChangeChapterNameModalOpen: true,
      selectedChapter: chapterToSave,
    });
  };
  closeModalToChangeChapterName = () => {
    this.setState({
      isChangeChapterNameModalOpen: false,
    });
  };
  saveChapterName = (chapter) => {
    let chapters = [...this.state.tabListValues];
    let index = chapters.findIndex((item) => item.id === chapter.id);
    chapters[index].name = chapter.name;
    this.setState(
      {
        tabListValues: chapters,
      },
      () => {
        this.updateParagrafiForChapterMode();
        this.closeModalToChangeChapterName();
      }
    );
  };

  render() {
    const { tabs } = this.state;
    return (
      <div style={styles.mainContainer}>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {/*(!this.state.currentTabValue.obbligatorio) ? (<Typography style={styles.typo} variant="h4">
              {this.state.currentTabValue.label}
            </Typography>) : (
              <Typography style={styles.typo} variant="h4">
              {this.state.modalTitle}
              </Typography>
            )*/}
            <Typography style={styles.typo} variant="h4">
              Nuovo manuale personalizzato
            </Typography>
          </div>
          {!this.state.loading ? (
            <div style={styles.root}>
              <ThemeProvider
                theme={createMuiTheme(
                  {
                    overrides: {
                      MuiTabs: {
                        indicator: {
                          right: "97.8%",
                          backgroundColor: "black",
                        },
                      },
                      MuiTab: {
                        root: {
                          maxWidth: 550,
                          marginLeft: 10,
                        },
                      },
                    },
                  },
                  this.props.theme
                )}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {
                    this.state.chapterMode ? (
                      <List style={{ width: "25vw" }}>
                        {this.state.tabListValues.map((value, index) => {
                          return (
                            <Fragment key={index}>
                              <ListItem
                                button
                                key={index}
                                onClick={(event) => {
                                  if (
                                    !Array.isArray(event._dispatchInstances)
                                  ) {
                                    let array = [...this.state.tabListValues];
                                    array[index].isTabOpen =
                                      !array[index].isTabOpen;
                                    this.setState({
                                      tabListValues: array,
                                    });
                                  }
                                }}
                              >
                                <Typography style={{ flex: 1 }}>
                                  {value.name}
                                </Typography>
                                <IconButton
                                  onClick={() => {
                                    if (this.state.edit) {
                                      let confirmation = window.confirm(
                                        "Modifiche non salvate, continuare?"
                                      );
                                      if (confirmation) {
                                        this.openModalToChangeChapterName(
                                          value
                                        );
                                      }
                                    } else {
                                      this.openModalToChangeChapterName(value);
                                    }
                                  }}
                                >
                                  <Edit></Edit>
                                </IconButton>
                                <IconButton
                                  size="medium"
                                  onClick={() => {
                                    if (this.state.edit) {
                                      let confirmation = window.confirm(
                                        "Modifiche non salvate, continuare?"
                                      );
                                      if (confirmation) {
                                        let array = this.state.tabListValues;
                                        if (
                                          array[index].paragraphData.length ===
                                          0
                                        ) {
                                          array.splice(index, 1);
                                          this.setState(
                                            {
                                              tabListValues: array,
                                            },
                                            () => {
                                              this.updateParagrafiForChapterMode();
                                            }
                                          );
                                        } else {
                                          alert(
                                            "Non è possibile eliminare un capitolo con paragrafi associati. Spostare i paragrafi in un altro capitolo e riprovare."
                                          );
                                        }
                                      }
                                    } else {
                                      let array = this.state.tabListValues;
                                      if (
                                        array[index].paragraphData.length === 0
                                      ) {
                                        array.splice(index, 1);
                                        this.setState(
                                          {
                                            tabListValues: array,
                                          },
                                          () => {
                                            this.updateParagrafiForChapterMode();
                                          }
                                        );
                                      } else {
                                        alert(
                                          "Non è possibile eliminare un capitolo con paragrafi associati. Spostare i paragrafi in un altro capitolo e riprovare."
                                        );
                                      }
                                    }
                                  }}
                                >
                                  <CloseIcon></CloseIcon>
                                </IconButton>
                                <IconButton
                                  onClick={(event) => {
                                    if (this.state.edit) {
                                      let confirmation = window.confirm(
                                        "Modifiche non salvate, continuare?"
                                      );
                                      if (confirmation) {
                                        if (index > 0) {
                                          let array = [
                                            ...this.state.tabListValues,
                                          ];
                                          swapChapterElements(
                                            array,
                                            index,
                                            index - 1
                                          );
                                          this.setState(
                                            {
                                              tabListValues: array,
                                            },
                                            () => {
                                              this.updateParagrafiForChapterMode();
                                            }
                                          );
                                        }
                                      }
                                    } else {
                                      if (index > 0) {
                                        let array = [
                                          ...this.state.tabListValues,
                                        ];
                                        swapChapterElements(
                                          array,
                                          index,
                                          index - 1
                                        );
                                        this.setState(
                                          {
                                            tabListValues: array,
                                          },
                                          () => {
                                            this.updateParagrafiForChapterMode();
                                          }
                                        );
                                      }
                                    }
                                  }}
                                >
                                  <ArrowUpward></ArrowUpward>
                                </IconButton>
                                <IconButton
                                  onClick={(event) => {
                                    if (this.state.edit) {
                                      let confirmation = window.confirm(
                                        "Modifiche non salvate, continuare?"
                                      );
                                      if (confirmation) {
                                        let array = [
                                          ...this.state.tabListValues,
                                        ];
                                        if (index < array.length - 1) {
                                          swapChapterElements(
                                            array,
                                            index,
                                            index + 1
                                          );
                                          this.setState(
                                            {
                                              tabListValues: array,
                                            },
                                            () => {
                                              this.updateParagrafiForChapterMode();
                                            }
                                          );
                                        }
                                      }
                                    } else {
                                      let array = [...this.state.tabListValues];
                                      if (index < array.length - 1) {
                                        swapChapterElements(
                                          array,
                                          index,
                                          index + 1
                                        );
                                        this.setState(
                                          {
                                            tabListValues: array,
                                          },
                                          () => {
                                            this.updateParagrafiForChapterMode();
                                          }
                                        );
                                      }
                                    }
                                  }}
                                >
                                  <ArrowDownward></ArrowDownward>
                                </IconButton>
                                {value.isTabOpen ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </ListItem>
                              <Collapse in={value.isTabOpen}>
                                <List
                                  style={{ width: "20vw", marginLeft: "5%" }}
                                >
                                  {value.paragraphData.map((item, value) => {
                                    return (
                                      <Fragment key={value}>
                                        <ListItem
                                          divider
                                          button
                                          onClick={(event) => {
                                            if (
                                              !Array.isArray(
                                                event._dispatchInstances
                                              )
                                            ) {
                                              if (this.state.edit) {
                                                let confirmation =
                                                  window.confirm(
                                                    "Modifiche non salvate, cambiare paragrafo?"
                                                  );
                                                if (confirmation) {
                                                  this.handleTabChangeNew(
                                                    value,
                                                    index
                                                  );
                                                }
                                              } else {
                                                this.handleTabChangeNew(
                                                  value,
                                                  index
                                                );
                                              }
                                            }
                                          }}
                                          style={
                                            item.isTabSelected
                                              ? styles.selectedTab
                                              : styles.notSelectedTab
                                          }
                                        >
                                          {item.isTabSelected ? (
                                            <Divider
                                              variant="fullWidth"
                                              orientation="vertical"
                                              style={{
                                                backgroundColor:
                                                  this.props.theme.palette
                                                    .primary.main,
                                                width: 2,
                                                marginRight: 10,
                                              }}
                                              flexItem
                                            ></Divider>
                                          ) : null}
                                          {item.isBlocked ? (
                                            <IconButton
                                              size="small"
                                              onClick={() => {
                                                if (
                                                  localStorage.getItem(
                                                    "superconsulente"
                                                  ) === "true" ||
                                                  localStorage.getItem(
                                                    "consulenteId"
                                                  ) === "null" ||
                                                  localStorage.getItem(
                                                    "consulenteId"
                                                  ) === null
                                                ) {
                                                  if (!this.state.chapterMode) {
                                                    let tabsToChange =
                                                      this.state.tabs;
                                                    var indexToFind =
                                                      tabsToChange
                                                        .map((e) => e.value)
                                                        .indexOf(item.value);
                                                    let itemToChange = item;
                                                    itemToChange.isBlocked =
                                                      !itemToChange.isBlocked;
                                                    tabsToChange[indexToFind] =
                                                      itemToChange;
                                                    this.setState({
                                                      tabs: tabsToChange,
                                                    });
                                                  } else {
                                                    if (this.state.edit) {
                                                      let confirmation =
                                                        window.confirm(
                                                          "Modifiche non salvate, continuare?"
                                                        );
                                                      if (confirmation) {
                                                        let objectToChange = [
                                                          ...this.state
                                                            .tabListValues,
                                                        ];
                                                        objectToChange[
                                                          index
                                                        ].paragraphData.find(
                                                          (e) => {
                                                            if (
                                                              e.value ===
                                                              item.value
                                                            ) {
                                                              e.isBlocked =
                                                                !e.isBlocked;
                                                            }
                                                          }
                                                        );
                                                        this.setState(
                                                          {
                                                            tabListValues:
                                                              objectToChange,
                                                          },
                                                          () => {
                                                            this.updateParagrafiForChapterMode();
                                                          }
                                                        );
                                                      }
                                                    } else {
                                                      let objectToChange = [
                                                        ...this.state
                                                          .tabListValues,
                                                      ];
                                                      objectToChange[
                                                        index
                                                      ].paragraphData.find(
                                                        (e) => {
                                                          if (
                                                            e.value ===
                                                            item.value
                                                          ) {
                                                            e.isBlocked =
                                                              !e.isBlocked;
                                                          }
                                                        }
                                                      );
                                                      this.setState(
                                                        {
                                                          tabListValues:
                                                            objectToChange,
                                                        },
                                                        () => {
                                                          this.updateParagrafiForChapterMode();
                                                        }
                                                      );
                                                    }
                                                  }
                                                } else {
                                                  alert(
                                                    "Operazione permessa solo ai superconsulenti o admin!"
                                                  );
                                                }
                                              }}
                                              disabled={
                                                localStorage.getItem(
                                                  "superconsulente"
                                                ) !== "true" &&
                                                localStorage.getItem(
                                                  "consulenteId"
                                                ) !== "null" &&
                                                localStorage.getItem(
                                                  "consulenteId"
                                                ) !== null
                                              }
                                            >
                                              <Lock></Lock>
                                            </IconButton>
                                          ) : (
                                            <IconButton
                                              size="small"
                                              onClick={() => {
                                                if (
                                                  localStorage.getItem(
                                                    "superconsulente"
                                                  ) === "true" ||
                                                  localStorage.getItem(
                                                    "consulenteId"
                                                  ) === "null" ||
                                                  localStorage.getItem(
                                                    "consulenteId"
                                                  ) === null
                                                ) {
                                                  if (!this.state.chapterMode) {
                                                    let tabsToChange =
                                                      this.state.tabs;
                                                    var indexToFind =
                                                      tabsToChange
                                                        .map((e) => e.value)
                                                        .indexOf(item.value);
                                                    let itemToChange = item;
                                                    itemToChange.isBlocked =
                                                      !itemToChange.isBlocked;
                                                    tabsToChange[indexToFind] =
                                                      itemToChange;
                                                    this.setState({
                                                      tabs: tabsToChange,
                                                    });
                                                  } else {
                                                    if (this.state.edit) {
                                                      let confirmation =
                                                        window.confirm(
                                                          "Modifiche non salvate, continuare?"
                                                        );
                                                      if (confirmation) {
                                                        let objectToChange = [
                                                          ...this.state
                                                            .tabListValues,
                                                        ];
                                                        objectToChange[
                                                          index
                                                        ].paragraphData.find(
                                                          (e) => {
                                                            if (
                                                              e.value ===
                                                              item.value
                                                            ) {
                                                              e.isBlocked =
                                                                !e.isBlocked;
                                                            }
                                                          }
                                                        );
                                                        this.setState(
                                                          {
                                                            tabListValues:
                                                              objectToChange,
                                                          },
                                                          () => {
                                                            this.updateParagrafiForChapterMode();
                                                          }
                                                        );
                                                      }
                                                    } else {
                                                      let objectToChange = [
                                                        ...this.state
                                                          .tabListValues,
                                                      ];
                                                      objectToChange[
                                                        index
                                                      ].paragraphData.find(
                                                        (e) => {
                                                          if (
                                                            e.value ===
                                                            item.value
                                                          ) {
                                                            e.isBlocked =
                                                              !e.isBlocked;
                                                          }
                                                        }
                                                      );
                                                      this.setState(
                                                        {
                                                          tabListValues:
                                                            objectToChange,
                                                        },
                                                        () => {
                                                          this.updateParagrafiForChapterMode();
                                                        }
                                                      );
                                                    }
                                                  }
                                                } else {
                                                  alert(
                                                    "Operazione permessa solo ai superconsulenti o admin!"
                                                  );
                                                }
                                              }}
                                              disabled={
                                                localStorage.getItem(
                                                  "superconsulente"
                                                ) !== "true" &&
                                                localStorage.getItem(
                                                  "consulenteId"
                                                ) !== "null" &&
                                                localStorage.getItem(
                                                  "consulenteId"
                                                ) !== null
                                              }
                                            >
                                              <LockOpen></LockOpen>
                                            </IconButton>
                                          )}
                                          {!item.obbligatorio ? (
                                            <IconButton
                                              size="small"
                                              onClick={(event) => {
                                                if (
                                                  (localStorage.getItem(
                                                    "superconsulente"
                                                  ) === "true" ||
                                                    localStorage.getItem(
                                                      "consulenteId"
                                                    ) === "null" ||
                                                    localStorage.getItem(
                                                      "consulenteId"
                                                    ) === null) &&
                                                  item.isBlocked
                                                ) {
                                                  let array =
                                                    this.state.tabListValues;
                                                  array[
                                                    index
                                                  ].paragraphData.splice(
                                                    value,
                                                    1
                                                  );
                                                  this.setState(
                                                    {
                                                      tabListValues: array,
                                                    },
                                                    () => {
                                                      this.updateParagrafiForChapterMode();
                                                    }
                                                  );
                                                } else if (!item.isBlocked) {
                                                  let array =
                                                    this.state.tabListValues;
                                                  array[
                                                    index
                                                  ].paragraphData.splice(
                                                    value,
                                                    1
                                                  );
                                                  this.setState(
                                                    {
                                                      tabListValues: array,
                                                    },
                                                    () => {
                                                      this.updateParagrafiForChapterMode();
                                                    }
                                                  );
                                                } else {
                                                  alert(
                                                    "Operazione permessa solo ai superconsulenti o admin!"
                                                  );
                                                }
                                              }}
                                            >
                                              <CloseIcon></CloseIcon>
                                            </IconButton>
                                          ) : null}
                                          <IconButton
                                            size="small"
                                            onClick={() => {
                                              if (this.state.edit) {
                                                let confirmation =
                                                  window.confirm(
                                                    "Modifiche non salvate, continuare?"
                                                  );
                                                if (confirmation) {
                                                  let arrayOfChapters = [
                                                    ...this.state.tabListValues,
                                                  ];
                                                  let arrayToUpdate =
                                                    arrayOfChapters[index]
                                                      .paragraphData;
                                                  if (value > 0) {
                                                    swapTabElements(
                                                      arrayToUpdate,
                                                      value,
                                                      value - 1
                                                    );
                                                    this.setState(
                                                      {
                                                        tabListValues:
                                                          arrayOfChapters,
                                                      },
                                                      () => {
                                                        this.updateParagrafiForChapterMode();
                                                      }
                                                    );
                                                  } else if (index > 0) {
                                                    arrayToUpdate.splice(
                                                      value,
                                                      1
                                                    );
                                                    arrayOfChapters[
                                                      index - 1
                                                    ].paragraphData.push(item);
                                                    arrayOfChapters[
                                                      index - 1
                                                    ].paragraphData[
                                                      arrayOfChapters[index - 1]
                                                        .paragraphData.length -
                                                        1
                                                    ].chapterIndex = index - 1;
                                                    arrayOfChapters[
                                                      index - 1
                                                    ].paragraphData.map(
                                                      (item, index) => {
                                                        item.value = index + 1;
                                                      }
                                                    );
                                                    arrayOfChapters[
                                                      index
                                                    ].paragraphData.map(
                                                      (item, index) => {
                                                        item.value = index + 1;
                                                      }
                                                    );
                                                    this.setState(
                                                      {
                                                        tabListValues:
                                                          arrayOfChapters,
                                                      },
                                                      () => {
                                                        this.updateParagrafiForChapterMode();
                                                      }
                                                    );
                                                  } else {
                                                  }
                                                }
                                              } else {
                                                let arrayOfChapters = [
                                                  ...this.state.tabListValues,
                                                ];
                                                let arrayToUpdate =
                                                  arrayOfChapters[index]
                                                    .paragraphData;
                                                if (value > 0) {
                                                  swapTabElements(
                                                    arrayToUpdate,
                                                    value,
                                                    value - 1
                                                  );
                                                  this.setState(
                                                    {
                                                      tabListValues:
                                                        arrayOfChapters,
                                                    },
                                                    () => {
                                                      this.updateParagrafiForChapterMode();
                                                    }
                                                  );
                                                } else if (index > 0) {
                                                  arrayToUpdate.splice(
                                                    value,
                                                    1
                                                  );
                                                  arrayOfChapters[
                                                    index - 1
                                                  ].paragraphData.push(item);
                                                  arrayOfChapters[
                                                    index - 1
                                                  ].paragraphData[
                                                    arrayOfChapters[index - 1]
                                                      .paragraphData.length - 1
                                                  ].chapterIndex = index - 1;
                                                  arrayOfChapters[
                                                    index - 1
                                                  ].paragraphData.map(
                                                    (item, index) => {
                                                      item.value = index + 1;
                                                    }
                                                  );
                                                  arrayOfChapters[
                                                    index
                                                  ].paragraphData.map(
                                                    (item, index) => {
                                                      item.value = index + 1;
                                                    }
                                                  );
                                                  this.setState(
                                                    {
                                                      tabListValues:
                                                        arrayOfChapters,
                                                    },
                                                    () => {
                                                      this.updateParagrafiForChapterMode();
                                                    }
                                                  );
                                                } else {
                                                }
                                              }
                                            }}
                                          >
                                            <ArrowUpward></ArrowUpward>
                                          </IconButton>
                                          <IconButton
                                            size="small"
                                            onClick={() => {
                                              if (this.state.edit) {
                                                let confirmation =
                                                  window.confirm(
                                                    "Modifiche non salvate, continuare?"
                                                  );
                                                if (confirmation) {
                                                  let arrayOfChapters = [
                                                    ...this.state.tabListValues,
                                                  ];
                                                  let arrayToUpdate =
                                                    arrayOfChapters[index]
                                                      .paragraphData;
                                                  if (
                                                    value <
                                                    arrayToUpdate.length - 1
                                                  ) {
                                                    swapTabElements(
                                                      arrayToUpdate,
                                                      value,
                                                      value + 1
                                                    );
                                                    this.setState(
                                                      {
                                                        tabListValues:
                                                          arrayOfChapters,
                                                      },
                                                      () => {
                                                        this.updateParagrafiForChapterMode();
                                                      }
                                                    );
                                                  } else if (
                                                    index <
                                                    arrayOfChapters.length - 1
                                                  ) {
                                                    arrayToUpdate.splice(
                                                      value,
                                                      1
                                                    );
                                                    arrayOfChapters[
                                                      index + 1
                                                    ].paragraphData.unshift(
                                                      item
                                                    );
                                                    arrayOfChapters[
                                                      index + 1
                                                    ].paragraphData[0].chapterIndex =
                                                      index + 1;
                                                    arrayOfChapters[
                                                      index + 1
                                                    ].paragraphData.map(
                                                      (item, index) => {
                                                        item.value = index + 1;
                                                      }
                                                    );
                                                    arrayOfChapters[
                                                      index
                                                    ].paragraphData.map(
                                                      (item, index) => {
                                                        item.value = index + 1;
                                                      }
                                                    );
                                                    this.setState(
                                                      {
                                                        tabListValues:
                                                          arrayOfChapters,
                                                      },
                                                      () => {
                                                        this.updateParagrafiForChapterMode();
                                                      }
                                                    );
                                                  } else {
                                                  }
                                                }
                                              } else {
                                                let arrayOfChapters = [
                                                  ...this.state.tabListValues,
                                                ];
                                                let arrayToUpdate =
                                                  arrayOfChapters[index]
                                                    .paragraphData;
                                                if (
                                                  value <
                                                  arrayToUpdate.length - 1
                                                ) {
                                                  swapTabElements(
                                                    arrayToUpdate,
                                                    value,
                                                    value + 1
                                                  );
                                                  this.setState(
                                                    {
                                                      tabListValues:
                                                        arrayOfChapters,
                                                    },
                                                    () => {
                                                      this.updateParagrafiForChapterMode();
                                                    }
                                                  );
                                                } else if (
                                                  index <
                                                  arrayOfChapters.length - 1
                                                ) {
                                                  arrayToUpdate.splice(
                                                    value,
                                                    1
                                                  );
                                                  arrayOfChapters[
                                                    index + 1
                                                  ].paragraphData.unshift(item);
                                                  arrayOfChapters[
                                                    index + 1
                                                  ].paragraphData[0].chapterIndex =
                                                    index + 1;
                                                  arrayOfChapters[
                                                    index + 1
                                                  ].paragraphData.map(
                                                    (item, index) => {
                                                      item.value = index + 1;
                                                    }
                                                  );
                                                  arrayOfChapters[
                                                    index
                                                  ].paragraphData.map(
                                                    (item, index) => {
                                                      item.value = index + 1;
                                                    }
                                                  );
                                                  this.setState(
                                                    {
                                                      tabListValues:
                                                        arrayOfChapters,
                                                    },
                                                    () => {
                                                      this.updateParagrafiForChapterMode();
                                                    }
                                                  );
                                                } else {
                                                }
                                              }
                                            }}
                                          >
                                            <ArrowDownward></ArrowDownward>
                                          </IconButton>
                                          <Typography
                                            style={{ flex: 1, marginLeft: 15 }}
                                          >
                                            {item.nomeTab}
                                          </Typography>
                                        </ListItem>
                                      </Fragment>
                                    );
                                  })}
                                  <ListItem>
                                    <ActionButton
                                      disabled={false}
                                      grayVersion={false}
                                      label="Aggiungi Paragrafo"
                                      onClick={() => {
                                        // if (
                                        //                   localStorage.getItem(
                                        //                     "superconsulente"
                                        //                   ) === "true" ||
                                        //                   localStorage.getItem("consulenteId") ===
                                        //                     "null" ||
                                        //                   localStorage.getItem("consulenteId") ===
                                        //                     null
                                        //                 ){
                                        if (this.state.edit) {
                                          let confirmation = window.confirm(
                                            "Modifiche non salvate, continuare?"
                                          );
                                          if (confirmation) {
                                            let arrayOfChaptersToUpdate = [
                                              ...this.state.tabListValues,
                                            ];
                                            let arrayToUpdate =
                                              arrayOfChaptersToUpdate[index]
                                                .paragraphData;
                                            arrayToUpdate.push({
                                              id: null,
                                              idManuale: this.state.idManuale,
                                              isBlocked: false,
                                              isSelected: false,
                                              label: "Nuovo paragrafo",
                                              obbligatorio: false,
                                              chapterIndex: index,
                                              stato: "BOZZA",
                                              value: null,
                                              nomeTab: "Nuovo paragrafo",
                                              isTabSelected: false,
                                            });
                                            this.setState(
                                              {
                                                tabListValues:
                                                  arrayOfChaptersToUpdate,
                                              },
                                              () => {
                                                this.updateParagrafiForChapterMode(
                                                  index
                                                );
                                              }
                                            );
                                          }
                                        } else {
                                          let arrayOfChaptersToUpdate = [
                                            ...this.state.tabListValues,
                                          ];
                                          let arrayToUpdate =
                                            arrayOfChaptersToUpdate[index]
                                              .paragraphData;
                                          arrayToUpdate.push({
                                            id: null,
                                            idManuale: this.state.idManuale,
                                            isBlocked: false,
                                            isSelected: false,
                                            label: "Nuovo paragrafo",
                                            obbligatorio: false,
                                            chapterIndex: index,
                                            stato: "BOZZA",
                                            value: null,
                                            nomeTab: "Nuovo paragrafo",
                                            isTabSelected: false,
                                          });
                                          this.setState(
                                            {
                                              tabListValues:
                                                arrayOfChaptersToUpdate,
                                            },
                                            () => {
                                              this.updateParagrafiForChapterMode(
                                                index
                                              );
                                            }
                                          );
                                        }
                                        // }else{
                                        //   alert("Operazione permessa solo ai superconsulenti o admin!")
                                        // }

                                        /*let arrayToCheck = [...this.state.tabs];
                    let onlyNumbers = arrayToCheck.filter(
                      (element) => typeof element.value === "number"
                    );
                    let valueToSave = 1;
                    if (onlyNumbers.length !== 0) {
                      let secondToLastTab =
                        onlyNumbers[onlyNumbers.length - 1].value;
                      valueToSave = secondToLastTab + 1;
                      var numberOfParagraph = this.state.numberOfParagrafi;
                      numberOfParagraph += 1;
                    }
                    let tabToPush = {
                      id: null,
                      idManuale: this.state.idManuale,
                      isBlocked: false,
                      isSelected: false,
                      label: "Nuovo paragrafo",
                      obbligatorio: false,
                      stato: "BOZZA",
                      value: null,
                    };
                    arrayToCheck.push(tabToPush);
                    //arrayToCheck.push({label:'Nuovo Paragrafo',valueOfParagraph:numberOfParagraph, value:valueToSave,isSelected:true,idManuale:this.state.idManuale,stato:"BOZZA",isBlocked:false,obbligatorio:false});
                    this.setState(
                      {
                        tabs: arrayToCheck,
                        numberOfParagrafi: numberOfParagraph,
                      },
                      () => {
                        this.updateParagrafi();
                      }
                    );*/
                                      }}
                                    ></ActionButton>
                                  </ListItem>
                                </List>
                              </Collapse>
                            </Fragment>
                          );
                        })}
                      </List>
                    ) : (
                      <Tabs
                        variant="scrollable"
                        orientation="vertical"
                        value={this.state.tabValue}
                        onChange={this.handleTabChange}
                        scrollButtons={"off"}
                      >
                        {tabs.map((item, index) => {
                          return item.obbligatorio ? (
                            <Tab
                              key={item.value}
                              label={
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignSelf: "flex-start",
                                  }}
                                >
                                  {!item.isBlocked ? (
                                    <IconButton
                                      onClick={(event) => {
                                        if (
                                          localStorage.getItem(
                                            "superconsulente"
                                          ) === "true" ||
                                          localStorage.getItem(
                                            "consulenteId"
                                          ) === "null" ||
                                          localStorage.getItem(
                                            "consulenteId"
                                          ) === null
                                        ) {
                                          let tabsToChange = [
                                            ...this.state.tabs,
                                          ];
                                          var indexToFind = tabsToChange
                                            .map((e) => e.value)
                                            .indexOf(item.value);
                                          let itemToChange = item;
                                          itemToChange.isBlocked =
                                            !itemToChange.isBlocked;
                                          tabsToChange[indexToFind] =
                                            itemToChange;
                                          this.setState({
                                            tabs: tabsToChange,
                                          });
                                        } else {
                                          alert(
                                            "Operazione permessa solo ai superconsulenti o admin!"
                                          );
                                        }
                                      }}
                                      disabled={
                                        localStorage.getItem(
                                          "superconsulente"
                                        ) !== "true" &&
                                        localStorage.getItem("consulenteId") !==
                                          "null" &&
                                        localStorage.getItem("consulenteId") !==
                                          null
                                      }
                                    >
                                      <LockOpen></LockOpen>
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      onClick={() => {
                                        if (
                                          localStorage.getItem(
                                            "superconsulente"
                                          ) === "true" ||
                                          localStorage.getItem(
                                            "consulenteId"
                                          ) === "null" ||
                                          localStorage.getItem(
                                            "consulenteId"
                                          ) === null
                                        ) {
                                          let tabsToChange = this.state.tabs;
                                          var indexToFind = tabsToChange
                                            .map((e) => e.value)
                                            .indexOf(item.value);
                                          let itemToChange = item;
                                          itemToChange.isBlocked =
                                            !itemToChange.isBlocked;
                                          tabsToChange[indexToFind] =
                                            itemToChange;
                                          this.setState({
                                            tabs: tabsToChange,
                                          });
                                        } else {
                                          alert(
                                            "Operazione permessa solo ai superconsulenti o admin!"
                                          );
                                        }
                                      }}
                                      disabled={
                                        localStorage.getItem(
                                          "superconsulente"
                                        ) !== "true" &&
                                        localStorage.getItem("consulenteId") !==
                                          "null" &&
                                        localStorage.getItem("consulenteId") !==
                                          null
                                      }
                                    >
                                      <Lock></Lock>
                                    </IconButton>
                                  )}

                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      let indexToChange =
                                        this.state.tabs.findIndex(
                                          (o) => o.value === item.value
                                        );
                                      if (indexToChange > 0) {
                                        let newArray = [...this.state.tabs];
                                        swapTabElements(
                                          newArray,
                                          indexToChange,
                                          indexToChange - 1
                                        );
                                        this.setState(
                                          {
                                            tabs: newArray,
                                          },
                                          () => {
                                            this.handleTabChangeManually(
                                              indexToChange
                                            );
                                          }
                                        );
                                      }
                                    }}
                                  >
                                    <ArrowUpward></ArrowUpward>
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      let indexToChange =
                                        this.state.tabs.findIndex(
                                          (o) => o.value === item.value
                                        );
                                      if (
                                        indexToChange <
                                        this.state.tabs.length - 1
                                      ) {
                                        let newArray = [...this.state.tabs];
                                        swapTabElements(
                                          newArray,
                                          indexToChange,
                                          indexToChange + 1
                                        );
                                        this.setState(
                                          {
                                            tabs: newArray,
                                          },
                                          () => {
                                            this.handleTabChangeManually(
                                              indexToChange
                                            );
                                          }
                                        );
                                      }
                                    }}
                                  >
                                    <ArrowDownward></ArrowDownward>
                                  </IconButton>
                                  {item.label ===
                                  "Definizione delle Procedure di Autocontrollo" ? (
                                    <div style={{ margin: "2px" }}>
                                      {item.label}
                                    </div>
                                  ) : (
                                    <Typography
                                      style={{
                                        margin: "15px",
                                        fontSize: "0.9em",
                                      }}
                                    >
                                      {item.label}
                                    </Typography>
                                  )}
                                </div>
                              }
                            ></Tab>
                          ) : (
                            <Tab
                              key={item.value}
                              label={
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignSelf: "flex-start",
                                  }}
                                >
                                  {!item.isBlocked ? (
                                    <IconButton
                                      disabled={
                                        localStorage.getItem(
                                          "superconsulente"
                                        ) !== "true" &&
                                        localStorage.getItem("consulenteId") !==
                                          "null" &&
                                        localStorage.getItem("consulenteId") !==
                                          null
                                      }
                                      onClick={() => {
                                        if (
                                          localStorage.getItem(
                                            "superconsulente"
                                          ) === "true" ||
                                          localStorage.getItem(
                                            "consulenteId"
                                          ) === "null" ||
                                          localStorage.getItem(
                                            "consulenteId"
                                          ) === null
                                        ) {
                                          let tabsToChange = this.state.tabs;
                                          var indexToFind = tabsToChange
                                            .map((e) => e.value)
                                            .indexOf(item.value);
                                          let itemToChange = item;
                                          itemToChange.isBlocked =
                                            !itemToChange.isBlocked;
                                          tabsToChange[indexToFind] =
                                            itemToChange;
                                          this.setState({
                                            tabs: tabsToChange,
                                          });
                                        } else {
                                          alert(
                                            "Operazione permessa solo ai superconsulenti o admin!"
                                          );
                                        }
                                      }}
                                    >
                                      <LockOpen></LockOpen>
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      disabled={
                                        localStorage.getItem(
                                          "superconsulente"
                                        ) !== "true" &&
                                        localStorage.getItem("consulenteId") !==
                                          "null" &&
                                        localStorage.getItem("consulenteId") !==
                                          null
                                      }
                                      onClick={() => {
                                        if (
                                          localStorage.getItem(
                                            "superconsulente"
                                          ) === "true" ||
                                          localStorage.getItem(
                                            "consulenteId"
                                          ) === "null" ||
                                          localStorage.getItem(
                                            "consulenteId"
                                          ) === null
                                        ) {
                                          let tabsToChange = this.state.tabs;
                                          var indexToFind = tabsToChange
                                            .map((e) => e.value)
                                            .indexOf(item.value);
                                          let itemToChange = item;
                                          itemToChange.isBlocked =
                                            !itemToChange.isBlocked;
                                          tabsToChange[indexToFind] =
                                            itemToChange;
                                          this.setState({
                                            tabs: tabsToChange,
                                          });
                                        } else {
                                          alert(
                                            "Operazione permessa solo ai superconsulenti o admin!"
                                          );
                                        }
                                      }}
                                    >
                                      <Lock></Lock>
                                    </IconButton>
                                  )}
                                  <IconButton
                                    style={{}}
                                    size="small"
                                    onClick={() => {
                                      let indexToChange =
                                        this.state.tabs.findIndex(
                                          (o) => o.value === item.value
                                        );
                                      if (indexToChange > 0) {
                                        let newArray = [...this.state.tabs];
                                        swapTabElements(
                                          newArray,
                                          indexToChange,
                                          indexToChange - 1
                                        );
                                        this.setState(
                                          { tabs: newArray },
                                          () => {
                                            this.handleTabChangeManually(
                                              indexToChange
                                            );
                                          }
                                        );
                                      }
                                    }}
                                  >
                                    <ArrowUpward></ArrowUpward>
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      let indexToChange =
                                        this.state.tabs.findIndex(
                                          (o) => o.value === item.value
                                        );
                                      if (
                                        indexToChange <
                                        this.state.tabs.length - 1
                                      ) {
                                        let newArray = [...this.state.tabs];
                                        swapTabElements(
                                          newArray,
                                          indexToChange,
                                          indexToChange + 1
                                        );
                                        this.setState(
                                          { tabs: newArray },
                                          () => {
                                            this.handleTabChangeManually(
                                              indexToChange
                                            );
                                          }
                                        );
                                      }
                                    }}
                                  >
                                    <ArrowDownward></ArrowDownward>
                                  </IconButton>
                                  <IconButton
                                    onClick={() => {
                                      var newArrayValue = item.value;
                                      var objectToErase =
                                        this.state.currentTabValue;
                                      var newArray = this.state.tabs;
                                      if (
                                        (localStorage.getItem(
                                          "superconsulente"
                                        ) === "true" ||
                                          localStorage.getItem(
                                            "consulenteId"
                                          ) === "null" ||
                                          localStorage.getItem(
                                            "consulenteId"
                                          ) === null) &&
                                        item.isBlocked
                                      ) {
                                        if (
                                          newArrayValue.obbligatorio !== true
                                        ) {
                                          newArray.map((item) => {
                                            if (item.value === newArrayValue) {
                                              setChapterIndex(0);
                                              setParagraphIndex(0);
                                            }
                                          });
                                          let arrayToSave = newArray.filter(
                                            (item) =>
                                              item.value !== newArrayValue
                                          );
                                          this.setState(
                                            {
                                              tabs: arrayToSave,
                                              numberOfParagrafi:
                                                this.state.numberOfParagrafi -
                                                1,
                                            },
                                            function () {
                                              this.updateParagrafi();
                                            }
                                          );
                                        }
                                      } else if (!item.isBlocked) {
                                        if (
                                          newArrayValue.obbligatorio !== true
                                        ) {
                                          newArray.map((item) => {
                                            if (item.value === newArrayValue) {
                                              setChapterIndex(0);
                                              setParagraphIndex(0);
                                            }
                                          });
                                          let arrayToSave = newArray.filter(
                                            (item) =>
                                              item.value !== newArrayValue
                                          );
                                          this.setState(
                                            {
                                              tabs: arrayToSave,
                                              numberOfParagrafi:
                                                this.state.numberOfParagrafi -
                                                1,
                                            },
                                            function () {
                                              this.updateParagrafi();
                                            }
                                          );
                                        }
                                      } else {
                                        alert(
                                          "Attenzione, operazione di cancellazione permessa solo ai superconsulenti!"
                                        );
                                      }
                                    }}
                                  >
                                    <CloseIcon></CloseIcon>
                                  </IconButton>
                                  <Typography
                                    style={{
                                      margin: "15px",
                                      fontSize: "0.9em",
                                    }}
                                  >
                                    {item.label}
                                  </Typography>
                                </div>
                              }
                            ></Tab>
                          );
                        })}
                        <ActionButton
                          label="Aggiungi Paragrafo"
                          disabled={false}
                          grayVersion={false}
                          onClick={() => {
                            let arrayToCheck = [...this.state.tabs];
                            let onlyNumbers = arrayToCheck.filter(
                              (element) => typeof element.value === "number"
                            );
                            let valueToSave = 1;
                            if (onlyNumbers.length !== 0) {
                              let secondToLastTab =
                                onlyNumbers[onlyNumbers.length - 1].value;
                              valueToSave = secondToLastTab + 1;
                              var numberOfParagraph =
                                this.state.numberOfParagrafi;
                              numberOfParagraph += 1;
                            }
                            let tabToPush = {
                              id: null,
                              idManuale: this.state.idManuale,
                              isBlocked: false,
                              isSelected: false,
                              label: "Nuovo paragrafo",
                              obbligatorio: false,
                              stato: "BOZZA",
                              value: null,
                            };
                            arrayToCheck.push(tabToPush);
                            //arrayToCheck.push({label:'Nuovo Paragrafo',valueOfParagraph:numberOfParagraph, value:valueToSave,isSelected:true,idManuale:this.state.idManuale,stato:"BOZZA",isBlocked:false,obbligatorio:false});
                            this.setState(
                              {
                                tabs: arrayToCheck,
                                numberOfParagrafi: numberOfParagraph,
                              },
                              () => {
                                this.updateParagrafi();
                              }
                            );
                          }}
                        ></ActionButton>
                      </Tabs>
                    )
                    /*<MenuList style={{width:"25vw"}}>
              <MenuItem button key={1} style={styles.notSelectedTab} onClick={() => this.setState({
                isListCollapsed:!this.state.isListCollapsed
                })}>
                <Typography style={{flex:1}}>Test</Typography>
                {this.state.isListCollapsed ? <ExpandLess /> : <ExpandMore />}
              </MenuItem>
              <Collapse
                in={this.state.isListCollapsed}
                >
                  <MenuList style={{width:"20vw",marginLeft:"5%"}}>
                    <MenuItem style={styles.notSelectedTab}><Typography style={{flex:1}}>Test3</Typography></MenuItem>
                    <MenuItem style={styles.selectedTab}><Typography style={{flex:1}}>Test4</Typography></MenuItem>
                  </MenuList>
                </Collapse>
              </MenuList>
              <MenuList>
              <MenuItem button key={1} style={{display:"flex",flexDirection:"row"}} onClick={() => this.setState({
                isList2Collapsed:!this.state.isList2Collapsed
                })}>
                <Typography style={{flex:1}}>Test 2</Typography>
                {this.state.isList2Collapsed ? <ExpandLess /> : <ExpandMore />}
              </MenuItem>
              <Collapse
                in={this.state.isList2Collapsed}
                >
                  <MenuList style={{width:"20vw",marginLeft:"5%"}}>
                    <MenuItem button><Typography style={{flex:1}}>Test5</Typography></MenuItem>
                    <MenuItem button><Typography style={{flex:1}}>Test6</Typography></MenuItem>
                  </MenuList>
                </Collapse>
              </MenuList>*/
                  }

                  {/*<Tabs
                  variant="scrollable"
                  orientation="vertical"
                  value={this.state.tabValue}
                  onChange={this.handleTabChange}
                  scrollButtons={false}
                >
                  
                    tabs.map((item, index) => {
                      return item.obbligatorio ? (
                        <Tab
                          key={item.value}
                          label={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignSelf: "flex-start",
                              }}
                            >
                              {!item.isBlocked ? (
                                <IconButton
                                  onClick={(event) => {
                                    if (
                                      localStorage.getItem(
                                        "superconsulente"
                                      ) === "true" ||
                                      localStorage.getItem("consulenteId") ===
                                        "null" ||
                                      localStorage.getItem("consulenteId") ===
                                        null
                                    ) {
                                      let tabsToChange = [...this.state.tabs];
                                      var indexToFind = tabsToChange
                                        .map((e) => e.value)
                                        .indexOf(item.value);
                                      let itemToChange = item;
                                      itemToChange.isBlocked =
                                        !itemToChange.isBlocked;
                                      tabsToChange[indexToFind] = itemToChange;
                                      this.setState({
                                        tabs: tabsToChange,
                                      });
                                    } else {
                                      alert(
                                        "Operazione permessa solo ai superconsulenti o admin!"
                                      );
                                    }
                                  }}
                                  disabled={
                                    localStorage.getItem("superconsulente") !==
                                      "true" &&
                                    localStorage.getItem("consulenteId") !==
                                      "null" &&
                                    localStorage.getItem("consulenteId") !==
                                      null
                                  }
                                >
                                  <LockOpen></LockOpen>
                                </IconButton>
                              ) : (
                                <IconButton
                                  onClick={() => {
                                    if (
                                      localStorage.getItem(
                                        "superconsulente"
                                      ) === "true" ||
                                      localStorage.getItem("consulenteId") ===
                                        "null" ||
                                      localStorage.getItem("consulenteId") ===
                                        null
                                    ) {
                                      let tabsToChange = this.state.tabs;
                                      var indexToFind = tabsToChange
                                        .map((e) => e.value)
                                        .indexOf(item.value);
                                      let itemToChange = item;
                                      itemToChange.isBlocked =
                                        !itemToChange.isBlocked;
                                      tabsToChange[indexToFind] = itemToChange;
                                      this.setState({
                                        tabs: tabsToChange,
                                      });
                                    } else {
                                      alert(
                                        "Operazione permessa solo ai superconsulenti o admin!"
                                      );
                                    }
                                  }}
                                  disabled={
                                    localStorage.getItem("superconsulente") !==
                                      "true" &&
                                    localStorage.getItem("consulenteId") !==
                                      "null" &&
                                    localStorage.getItem("consulenteId") !==
                                      null
                                  }
                                >
                                  <Lock></Lock>
                                </IconButton>
                              )}

                              <IconButton
                                size="small"
                                onClick={() => {
                                  let indexToChange = this.state.tabs.findIndex(
                                    (o) => o.value === item.value
                                  );
                                  if (indexToChange > 0) {
                                    let newArray = [...this.state.tabs];
                                    swapTabElements(
                                      newArray,
                                      indexToChange,
                                      indexToChange - 1
                                    );
                                    this.setState(
                                      {
                                        tabs: newArray,
                                      },
                                      () => {
                                        this.handleTabChangeManually(
                                          indexToChange
                                        );
                                      }
                                    );
                                  }
                                }}
                              >
                                <ArrowUpward></ArrowUpward>
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  let indexToChange = this.state.tabs.findIndex(
                                    (o) => o.value === item.value
                                  );
                                  if (
                                    indexToChange <
                                    this.state.tabs.length - 1
                                  ) {
                                    let newArray = [...this.state.tabs];
                                    swapTabElements(
                                      newArray,
                                      indexToChange,
                                      indexToChange + 1
                                    );
                                    this.setState(
                                      {
                                        tabs: newArray,
                                      },
                                      () => {
                                        this.handleTabChangeManually(
                                          indexToChange
                                        );
                                      }
                                    );
                                  }
                                }}
                              >
                                <ArrowDownward></ArrowDownward>
                              </IconButton>
                              {item.label ===
                              "Definizione delle Procedure di Autocontrollo" ? (
                                <div style={{ margin: "2px" }}>
                                  {item.label}
                                </div>
                              ) : (
                                <Typography
                                  style={{ margin: "15px", fontSize: "0.9em" }}
                                >
                                  {item.label}
                                </Typography>
                              )}
                            </div>
                          }
                        ></Tab>
                      ) : (
                        <Tab
                          key={item.value}
                          label={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignSelf: "flex-start",
                              }}
                            >
                              {!item.isBlocked ? (
                                <IconButton
                                  disabled={
                                    localStorage.getItem("superconsulente") !==
                                      "true" &&
                                    localStorage.getItem("consulenteId") !==
                                      "null" &&
                                    localStorage.getItem("consulenteId") !==
                                      null
                                  }
                                  onClick={() => {
                                    if (
                                      localStorage.getItem(
                                        "superconsulente"
                                      ) === "true" ||
                                      localStorage.getItem("consulenteId") ===
                                        "null" ||
                                      localStorage.getItem("consulenteId") ===
                                        null
                                    ) {
                                      let tabsToChange = this.state.tabs;
                                      var indexToFind = tabsToChange
                                        .map((e) => e.value)
                                        .indexOf(item.value);
                                      let itemToChange = item;
                                      itemToChange.isBlocked =
                                        !itemToChange.isBlocked;
                                      tabsToChange[indexToFind] = itemToChange;
                                      this.setState({
                                        tabs: tabsToChange,
                                      });
                                    } else {
                                      alert(
                                        "Operazione permessa solo ai superconsulenti o admin!"
                                      );
                                    }
                                  }}
                                >
                                  <LockOpen></LockOpen>
                                </IconButton>
                              ) : (
                                <IconButton
                                  disabled={
                                    localStorage.getItem("superconsulente") !==
                                      "true" &&
                                    localStorage.getItem("consulenteId") !==
                                      "null" &&
                                    localStorage.getItem("consulenteId") !==
                                      null
                                  }
                                  onClick={() => {
                                    if (
                                      localStorage.getItem(
                                        "superconsulente"
                                      ) === "true" ||
                                      localStorage.getItem("consulenteId") ===
                                        "null" ||
                                      localStorage.getItem("consulenteId") ===
                                        null
                                    ) {
                                      let tabsToChange = this.state.tabs;
                                      var indexToFind = tabsToChange
                                        .map((e) => e.value)
                                        .indexOf(item.value);
                                      let itemToChange = item;
                                      itemToChange.isBlocked =
                                        !itemToChange.isBlocked;
                                      tabsToChange[indexToFind] = itemToChange;
                                      this.setState({
                                        tabs: tabsToChange,
                                      });
                                    } else {
                                      alert(
                                        "Operazione permessa solo ai superconsulenti o admin!"
                                      );
                                    }
                                  }}
                                >
                                  <Lock></Lock>
                                </IconButton>
                              )}
                              <IconButton
                                style={
                                  {
                                   
                                  }
                                }
                                size="small"
                                onClick={() => {
                                  let indexToChange = this.state.tabs.findIndex(
                                    (o) => o.value === item.value
                                  );
                                  if (indexToChange > 0) {
                                    let newArray = [...this.state.tabs];
                                    swapTabElements(
                                      newArray,
                                      indexToChange,
                                      indexToChange - 1
                                    );
                                    this.setState({ tabs: newArray }, () => {
                                      this.handleTabChangeManually(
                                        indexToChange
                                      );
                                    });
                                  }
                                }}
                              >
                                <ArrowUpward></ArrowUpward>
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  let indexToChange = this.state.tabs.findIndex(
                                    (o) => o.value === item.value
                                  );
                                  if (
                                    indexToChange <
                                    this.state.tabs.length - 1
                                  ) {
                                    let newArray = [...this.state.tabs];
                                    swapTabElements(
                                      newArray,
                                      indexToChange,
                                      indexToChange + 1
                                    );
                                    this.setState({ tabs: newArray }, () => {
                                      this.handleTabChangeManually(
                                        indexToChange
                                      );
                                    });
                                  }
                                }}
                              >
                                <ArrowDownward></ArrowDownward>
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  var newArrayValue = item.value;
                                  var objectToErase =
                                    this.state.currentTabValue;
                                  var newArray = this.state.tabs;
                                  if (
                                    (localStorage.getItem("superconsulente") ===
                                      "true" ||
                                      localStorage.getItem("consulenteId") ===
                                        "null" ||
                                      localStorage.getItem("consulenteId") ===
                                        null) &&
                                    item.isBlocked
                                  ) {
                                    if (newArrayValue.obbligatorio !== true) {
                                      let arrayToSave = newArray.filter(
                                        (item) => item.value !== newArrayValue
                                      );
                                      this.setState(
                                        {
                                          tabs: arrayToSave,
                                          numberOfParagrafi:
                                            this.state.numberOfParagrafi - 1,
                                        },
                                        function () {
                                          this.updateParagrafi();
                                        }
                                      );
                                    }
                                  } else if (!item.isBlocked) {
                                    if (newArrayValue.obbligatorio !== true) {
                                      let arrayToSave = newArray.filter(
                                        (item) => item.value !== newArrayValue
                                      );
                                      this.setState(
                                        {
                                          tabs: arrayToSave,
                                          numberOfParagrafi:
                                            this.state.numberOfParagrafi - 1,
                                        },
                                        function () {
                                          this.updateParagrafi();
                                        }
                                      );
                                    }
                                  } else {
                                    alert(
                                      "Attenzione, operazione di cancellazione permessa solo ai superconsulenti!"
                                    );
                                  }
                                }}
                              >
                                <CloseIcon></CloseIcon>
                              </IconButton>
                              <Typography
                                style={{ margin: "15px", fontSize: "0.9em" }}
                              >
                                {item.label}
                              </Typography>
                            </div>
                          }
                        ></Tab>
                      );
                    })*/
                  /*if(item.isBlocked === false)
                  return <Tab label={<div style={{display:"flex",flexDirection:"row",alignSelf:'flex-start'}}><LockOpen style={{marginTop:'10px',marginLeft:'10px'}}></LockOpen> <IconButton style={{marginLeft:'5px',marginRight:'0.5px'}} size="small" onClick={() => {
              let indexToChange = this.state.tabs.findIndex(o => o.value === item.value);
              if(indexToChange > 0){
                let newArray = this.state.tabs;
                console.log(newArray);
                swapElements(newArray,indexToChange,indexToChange-1);
              }
            }}><ArrowUpward></ArrowUpward></IconButton><IconButton size="small" onClick={() => {
              let indexToChange = this.state.tabs.findIndex(o => o.value === item.value);
              if(indexToChange < this.state.tabs.length-1){
                let newArray = this.state.tabs;
                console.log(newArray);
                swapElements(newArray,indexToChange,indexToChange+1);
              }
            }}><ArrowDownward></ArrowDownward></IconButton>
            <IconButton onClick={() => {
                  var newArrayValue = item.value;
                  var newArray = this.state.tabs;
                  console.log("Array Actual value: ");
                  console.log(newArrayValue);
                  console.log("Old Array: ");
                  console.log(newArray);
                  if(newArrayValue > 0){
                    let arrayToSave = newArray.filter(newArray => newArray.value !== newArrayValue);
                   console.log("I will save this array");
                      console.log(arrayToSave);
                    this.setState({
                  tabs:arrayToSave
                })
                this.handleTabChangeManually("Organigramma");
                }
            }}><CloseIcon></CloseIcon></IconButton><div style={{marginTop: "12px"}}>{item.label}</div></div>} value={item.value}></Tab>
            else if(item.value === "Organigramma"){
                    return <Tab label = {<div style={{display:"flex",flexDirection:"row",alignSelf:"flex-start"}} ><Lock color="disabled" style = { {marginLeft:'10px',marginTop:0} }></Lock><IconButton size="small" style={{marginLeft:'5px',marginRight:'5px'}} onClick={() => {
              let indexToChange = this.state.tabs.findIndex(o => o.value === item.value);
              if(indexToChange > 0){
                let newArray = this.state.tabs;
                console.log(newArray);
                swapElements(newArray,indexToChange,indexToChange-1);
              }
            }}><ArrowUpward></ArrowUpward></IconButton><IconButton  style={{marginLeft:-4.5,}} size="small" onClick={() => {
              let indexToChange = this.state.tabs.findIndex(o => o.value === item.value);
              if(indexToChange < this.state.tabs.length-1){
                let newArray = this.state.tabs;
                console.log(newArray);
                swapElements(newArray,indexToChange,indexToChange+1);
              }
            }}><ArrowDownward></ArrowDownward></IconButton><div style={{padding:3}}>Organigramma</div></div>} value={"Organigramma"}></Tab>
                  }
                  else if(item.value === "Definizione_Reparti"){
                    return <Tab label = {<div style={{display:"flex",flexDirection:"row",alignSelf:"flex-start"}} ><Lock color="disabled" style = { {marginLeft:'10px',marginTop:0} }></Lock><IconButton style={{marginRight: 5,marginLeft:5}} size="small" onClick={() => {
              let indexToChange = this.state.tabs.findIndex(o => o.value === item.value);
              if(indexToChange > 0){
                let newArray = this.state.tabs;
                console.log(newArray);
                swapElements(newArray,indexToChange,indexToChange-1);
              }
            }}><ArrowUpward></ArrowUpward></IconButton><IconButton size="small" style={{marginLeft:-4.5,}} onClick={() => {
              let indexToChange = this.state.tabs.findIndex(o => o.value === item.value);
              if(indexToChange < this.state.tabs.length-1){
                let newArray = this.state.tabs;
                console.log(newArray);
                swapElements(newArray,indexToChange,indexToChange+1);
              }
            }}><ArrowDownward></ArrowDownward></IconButton><div style={{padding:"3px"}}>Definizione Reparti</div></div>} value={"Definizione_Reparti"}></Tab>
                  }
                  else if(item.value === "Descrizione_Reparti"){
                    return  <Tab label = {<div style={{display:"flex",flexDirection:"row",alignSelf:"flex-start"}} ><Lock color="disabled" style = { {marginLeft:'10px',marginTop:0} }></Lock><IconButton style={{marginRight: 5,marginLeft:5}} size="small" onClick={() => {
              let indexToChange = this.state.tabs.findIndex(o => o.value === item.value);
              if(indexToChange > 0){
                let newArray = this.state.tabs;
                console.log(newArray);
                swapElements(newArray,indexToChange,indexToChange-1);
              }
            }}><ArrowUpward></ArrowUpward></IconButton><IconButton style={{marginLeft:-4.5,}} size="small" onClick={() => {
              let indexToChange = this.state.tabs.findIndex(o => o.value === item.value);
              if(indexToChange < this.state.tabs.length-1){
                let newArray = this.state.tabs;
                console.log(newArray);
                swapElements(newArray,indexToChange,indexToChange+1);
              }
            }}><ArrowDownward></ArrowDownward></IconButton><div style={{padding:"3px"}}>Descrizione Reparti</div></div>} value={"Descrizione_Reparti"}></Tab>
                  }
                  else if(item.value === "Attrezzatura"){
                    return  <Tab label = {<div style={{display:"flex",flexDirection:"row",alignSelf:"flex-start"}} ><Lock color="disabled" style = { {paddingLeft:'10px'} }></Lock><IconButton style={{marginRight: 5,marginLeft: 5}} size="small" onClick={() => {
              let indexToChange = this.state.tabs.findIndex(o => o.value === item.value);
              if(indexToChange > 0){
                let newArray = this.state.tabs;
                console.log(newArray);
                swapElements(newArray,indexToChange,indexToChange-1);
              }
            }}><ArrowUpward></ArrowUpward></IconButton><IconButton style={{marginLeft: -4.5}} size="small" onClick={() => {
              let indexToChange = this.state.tabs.findIndex(o => o.value === item.value);
              if(indexToChange < this.state.tabs.length-1){
                let newArray = this.state.tabs;
                console.log(newArray);
                swapElements(newArray,indexToChange,indexToChange+1);
              }
            }}><ArrowDownward></ArrowDownward></IconButton><div style={{marginLeft:5,marginTop:1}}>Attrezzatura</div></div>} value={"Attrezzatura"}></Tab>
            }
                  else if(item.value === "Descrizione_Procedure_Autocontrollo"){
                    return <Tab label = {<div style={{display:"flex",flexDirection:"row",alignSelf:"flex-start"}} ><Lock color="disabled" style = { {paddingLeft: '10px'} }></Lock><IconButton style={{marginRight: 5,marginLeft: 5}} size="small" onClick={() => {
              let indexToChange = this.state.tabs.findIndex(o => o.value === item.value);
              if(indexToChange > 0){
                let newArray = this.state.tabs;
                console.log(newArray);
                swapElements(newArray,indexToChange,indexToChange-1);
              }
            }}><ArrowUpward></ArrowUpward></IconButton><IconButton style={{marginLeft: -4.5}} size="small" onClick={() => {
              let indexToChange = this.state.tabs.findIndex(o => o.value === item.value);
              if(indexToChange < this.state.tabs.length-1){
                let newArray = this.state.tabs;
                console.log(newArray);
                swapElements(newArray,indexToChange,indexToChange+1);
              }
            }}><ArrowDownward></ArrowDownward></IconButton><div style={{marginTop:2,marginLeft:3}}>Descrizione Procedure Autocontrollo</div></div>} value={"Definizione_Procedure_Autocontrollo"}></Tab>}
        })}
                
               
                <ActionButton label="Aggiungi Paragrafo" onClick={() => {
                  let arrayToCheck = this.state.tabs;
                  console.log("tabs array so far:");
                  console.log(this.state.tabs);
                  let onlyNumbers = arrayToCheck.filter(
                    element => typeof element.value === 'number'
                  )
                  let valueToSave = 1;
                  if(onlyNumbers.length !== 0 ){
                  let secondToLastTab = onlyNumbers[onlyNumbers.length-1].value;
                  valueToSave = secondToLastTab+1;
                  }
                  this.setState({
                    tabs: [...this.state.tabs,{label:'Paragrafo '+valueToSave, value:valueToSave,isSelected:true,isBlocked:false}]
                  })
                }}></ActionButton>
                 
                </Tabs>*/}
                  {this.state.chapterMode && (
                    <ActionButton
                      disabled={false}
                      grayVersion={false}
                      label="Aggiungi Capitolo"
                      onClick={() => {
                        if (this.state.edit) {
                          let confirmation = window.confirm(
                            "Modifiche non salvate, continuare?"
                          );
                          if (confirmation) {
                            let arrayToCheck = [...this.state.tabListValues];
                            if (arrayToCheck.length === 0) {
                              let oldParagraphs = [...this.state.tabs];
                              let paragraphToInsert = [];
                              oldParagraphs.map((item, index) => {
                                paragraphToInsert.push({
                                  label: oldParagraphs[index].label,
                                  nomeTab: oldParagraphs[index].label,
                                  isTabSelected:
                                    this.state.currentTabValue.value - 1 ===
                                    index
                                      ? true
                                      : false,
                                  id: oldParagraphs[index].id,
                                  stato: oldParagraphs[index].stato,
                                  value: oldParagraphs[index].value,
                                  idManuale: oldParagraphs[index].idManuale,
                                  chapterIndex: 0,
                                  isSelected: true,
                                  isBlocked: oldParagraphs[index].isBlocked,
                                  obbligatorio:
                                    oldParagraphs[index].obbligatorio,
                                });
                              });
                              arrayToCheck.push({
                                id: null,
                                name: "Nuovo capitolo",
                                isTabOpen: false,
                                numeroCapitolo: arrayToCheck.length + 1,
                                paragraphData: paragraphToInsert,
                              });
                            } else {
                              arrayToCheck.push({
                                id: null,
                                name: "Nuovo capitolo",
                                isTabOpen: false,
                                numeroCapitolo: arrayToCheck.length + 1,
                                paragraphData: [],
                              });
                            }
                            this.setState(
                              {
                                tabListValues: arrayToCheck,
                                chapterMode: true,
                              },
                              () => {
                                this.updateParagrafiForChapterMode();
                              }
                            );
                          }
                        } else {
                          let arrayToCheck = [...this.state.tabListValues];
                          if (arrayToCheck.length === 0) {
                            let oldParagraphs = [...this.state.tabs];
                            let paragraphToInsert = [];
                            oldParagraphs.map((item, index) => {
                              paragraphToInsert.push({
                                label: oldParagraphs[index].label,
                                nomeTab: oldParagraphs[index].label,
                                isTabSelected:
                                  this.state.currentTabValue.value - 1 === index
                                    ? true
                                    : false,
                                id: oldParagraphs[index].id,
                                stato: oldParagraphs[index].stato,
                                value: oldParagraphs[index].value,
                                idManuale: oldParagraphs[index].idManuale,
                                chapterIndex: 0,
                                isSelected: true,
                                isBlocked: oldParagraphs[index].isBlocked,
                                obbligatorio: oldParagraphs[index].obbligatorio,
                              });
                            });
                            arrayToCheck.push({
                              id: null,
                              name: "Nuovo capitolo",
                              isTabOpen: false,
                              numeroCapitolo: arrayToCheck.length + 1,
                              paragraphData: paragraphToInsert,
                            });
                          } else {
                            arrayToCheck.push({
                              id: null,
                              name: "Nuovo capitolo",
                              isTabOpen: false,
                              numeroCapitolo: arrayToCheck.length + 1,
                              paragraphData: [],
                            });
                          }
                          this.setState(
                            {
                              tabListValues: arrayToCheck,
                              chapterMode: true,
                            },
                            () => {
                              this.updateParagrafiForChapterMode();
                            }
                          );
                        }
                        /*let arrayToCheck = [...this.state.tabs];
                    let onlyNumbers = arrayToCheck.filter(
                      (element) => typeof element.value === "number"
                    );
                    let valueToSave = 1;
                    if (onlyNumbers.length !== 0) {
                      let secondToLastTab =
                        onlyNumbers[onlyNumbers.length - 1].value;
                      valueToSave = secondToLastTab + 1;
                      var numberOfParagraph = this.state.numberOfParagrafi;
                      numberOfParagraph += 1;
                    }
                    let tabToPush = {
                      id: null,
                      idManuale: this.state.idManuale,
                      isBlocked: false,
                      isSelected: false,
                      label: "Nuovo paragrafo",
                      obbligatorio: false,
                      stato: "BOZZA",
                      value: null,
                    };
                    arrayToCheck.push(tabToPush);
                    //arrayToCheck.push({label:'Nuovo Paragrafo',valueOfParagraph:numberOfParagraph, value:valueToSave,isSelected:true,idManuale:this.state.idManuale,stato:"BOZZA",isBlocked:false,obbligatorio:false});
                    this.setState(
                      {
                        tabs: arrayToCheck,
                        numberOfParagrafi: numberOfParagraph,
                      },
                      () => {
                        this.updateParagrafi();
                      }
                    );*/
                      }}
                    ></ActionButton>
                  )}
                </div>
              </ThemeProvider>
              <div
                style={{
                  flex: 1 /*border:"3px solid lightGrey",borderRadius:"10px",padding:30*/,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  {!this.state.currentTabValue.obbligatorio ? (
                    <TextField
                      style={{ width: "50vw" }}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{
                        min: 0,
                        style: {
                          textAlign: "center",
                          fontSize: 30,
                          verticalAlign: "middle",
                        },
                      }}
                      onInput={(e) => {
                        if (this.state.chapterMode) {
                          let newLabel = this.state.currentTabValue;
                          newLabel.label = e.target.value;
                          let arrayOfChapters = [...this.state.tabListValues];
                          let indexToUse = arrayOfChapters[
                            this.state.currentTabValue.chapterIndex
                          ].paragraphData.findIndex(
                            (item) => item.id === newLabel.id
                          );
                          arrayOfChapters[
                            this.state.currentTabValue.chapterIndex
                          ].paragraphData[indexToUse].nomeTab = newLabel.label;
                          //arrayOfChapters[this.state.currentTabValue.chapterIndex].nomeTab = newLabel.label;
                          this.setState({
                            currentTabValue: newLabel,
                            tabListValues: arrayOfChapters,
                          });
                        } else {
                          let newLabel = this.state.currentTabValue;
                          newLabel.label = e.target.value;
                          let arrayOfParagraphs = [...this.state.tabs];
                          let indexToUse = arrayOfParagraphs.findIndex(
                            (item) => item.id === newLabel.id
                          );
                          arrayOfParagraphs[indexToUse].nomeTab =
                            newLabel.label;
                          this.setState({
                            currentTabValue: newLabel,
                            tabs: arrayOfParagraphs,
                            edit: true,
                          });
                        }
                      }}
                      disabled={!this.state.editParagraphTitle}
                      value={this.state.currentTabValue.label}
                    />
                  ) : (
                    <TextField
                      disabled={!this.state.editParagraphTitle}
                      style={{ width: "50vw" }}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{
                        min: 0,
                        style: {
                          textAlign: "center",
                          fontSize: 30,
                          verticalAlign: "middle",
                        },
                      }}
                      onInput={(e) => {
                        if (this.state.chapterMode) {
                          let newLabel = this.state.currentTabValue;
                          newLabel.label = e.target.value;
                          let arrayOfChapters = [...this.state.tabListValues];
                          let indexToUse = arrayOfChapters[
                            this.state.currentTabValue.chapterIndex
                          ].paragraphData.findIndex(
                            (item) => item.id === newLabel.id
                          );
                          arrayOfChapters[
                            this.state.currentTabValue.chapterIndex
                          ].paragraphData[indexToUse].nomeTab = newLabel.label;
                          //arrayOfChapters[this.state.currentTabValue.chapterIndex].nomeTab = newLabel.label;
                          this.setState({
                            currentTabValue: newLabel,
                            tabListValues: arrayOfChapters,
                            edit: true,
                          });
                        } else {
                          let newLabel = this.state.currentTabValue;
                          newLabel.label = e.target.value;
                          let arrayOfParagraphs = [...this.state.tabs];
                          let indexToUse = arrayOfParagraphs.findIndex(
                            (item) => item.id === newLabel.id
                          );
                          arrayOfParagraphs[indexToUse].nomeTab =
                            newLabel.label;
                          this.setState({
                            currentTabValue: newLabel,
                            tabs: arrayOfParagraphs,
                            edit: true,
                          });
                        }
                      }}
                      value={this.state.currentTabValue.label}
                    />
                  )}
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (
                        !this.state.currentTabValue.isBlocked ||
                        localStorage.getItem("superconsulente") === "true" ||
                        localStorage.getItem("consulenteId") === "null"
                      ) {
                        this.setState({
                          editParagraphTitle: !this.state.editParagraphTitle,
                        });
                      } else {
                        alert(
                          "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
                        );
                      }
                    }}
                    style={{}}
                  >
                    <Edit
                      htmlColor={
                        this.state.editParagraphTitle
                          ? this.props.theme.palette.primary.main
                          : "#707070"
                      }
                    />
                  </IconButton>
                  {!this.state.currentTabValue.obbligatorio && (
                    <IconButton
                      size="small"
                      style={{}}
                      onClick={() => {
                        var newArrayValue = this.state.tabValue;
                        var objectToErase = this.state.currentTabValue;
                        var newArray = this.state.tabs;
                        if (
                          !this.state.currentTabValue.isBlocked ||
                          localStorage.getItem("superconsulente") === "true" ||
                          localStorage.getItem("consulenteId") === "null"
                        ) {
                          if (!this.state.chapterMode) {
                            if (newArrayValue === objectToErase.value - 1) {
                              setChapterIndex(0);
                              setParagraphIndex(0);
                            }
                            if (newArrayValue.obbligatorio !== true) {
                              let arrayToSave = newArray.splice(
                                newArrayValue,
                                1
                              );
                              this.setState(
                                {
                                  tabs: newArray,
                                  numberOfParagrafi:
                                    this.state.numberOfParagrafi - 1,
                                },
                                function () {
                                  this.updateParagrafi();
                                }
                              );
                            } else if (!this.state.currentTabValue.isBlocked) {
                              if (newArrayValue.obbligatorio !== true) {
                                let arrayToSave = newArray.splice(
                                  newArrayValue,
                                  1
                                );
                                this.setState(
                                  {
                                    tabs: newArray,
                                    numberOfParagrafi:
                                      this.state.numberOfParagrafi - 1,
                                  },
                                  function () {
                                    this.updateParagrafi();
                                  }
                                );
                              }
                            } else {
                              alert(
                                "Attenzione, operazione di cancellazione permessa solo ai superconsulenti!"
                              );
                            }
                          } else {
                            let array = this.state.tabListValues;
                            let currentTabValue = this.state.currentTabValue;
                            array[
                              currentTabValue.chapterIndex
                            ].paragraphData.splice(
                              currentTabValue.value - 1,
                              1
                            );
                            this.setState(
                              {
                                tabListValues: array,
                              },
                              () => {
                                this.updateParagrafiForChapterMode();
                              }
                            );
                          }
                        } else {
                          alert(
                            "Attenzione, operazione di cancellazione permessa solo ai superconsulenti!"
                          );
                        }
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </div>
                {this.state.sottoParagrafi.length > 0 && (
                  <div
                    style={{
                      border: "3px solid grey",
                      marginLeft: 30,
                      marginTop: 30,
                      marginRight: 30,
                      padding: 30,
                      borderRadius: 6,
                    }}
                  >
                    <Box
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {!this.state.loading ? (
                        this.state.sottoParagrafi.map((item) => {
                          return item.value;
                        })
                      ) : (
                        <SpinnerComponent size={24} />
                      )}
                    </Box>
                  </div>
                )}
                {!this.state.loading ? (
                  <Box p={1} style={styles.buttonContainer}>
                    {this.state.tabValue > -1 ? (
                      this.state.sottoParagrafi.length > 0 ? (
                        <Fragment>
                          <ActionButton
                            disabled={false}
                            grayVersion={false}
                            label="Aggiungi sottoparagrafo"
                            onClick={() => {
                              this.setState(
                                {
                                  modalitaSottoparagrafo: "ImmagineAndTesto",
                                },
                                () => {
                                  this.handleAggiuntaSottoparagrafi();
                                }
                              );
                            }}
                          />
                          <ActionButton
                            disabled={false}
                            grayVersion={false}
                            label="Aggiungi tabella"
                            onClick={() => {
                              if (
                                !this.state.currentTabValue.isBlocked ||
                                localStorage.getItem("superconsulente") ===
                                  "true" ||
                                localStorage.getItem("consulenteId") === "null"
                              ) {
                                this.setState({
                                  isModalOpen: !this.state.isModalOpen,
                                });
                              } else {
                                alert(
                                  "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
                                );
                              }
                            }}
                          />
                        </Fragment>
                      ) : (
                        <SpinnerComponent size={24} />
                      )
                    ) : null}
                  </Box>
                ) : null}
              </div>
            </div>
          ) : (
            <SpinnerComponent size={24} />
          )}
          {!this.state.loading ? (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <GoBackButton label="Indietro" />
              <ActionButton
                grayVersion={false}
                disabled={this.state.isSaveButtonPressed}
                label="Salva Modifiche"
                onClick={() => {
                  this.setState({
                    isSaveButtonPressed:true,
                  })
                  if (this.state.chapterMode === false) {
                    const paragrafi = this.state.tabs;
                    let currentParagraph = this.state.currentTabValue;
                    if (currentParagraph.label !== "") {
                      const paragrafiToPass = [];
                      paragrafi.map((item) => {
                        paragrafiToPass.push({
                          bloccato: item.isBlocked,
                          id: item.id,
                          manualeId: item.idManuale,
                          numeroParagrafo: item.value,
                          obbligatorio: item.obbligatorio,
                          stato: item.stato,
                          titolo: item.label,
                        });
                      });
                      paragrafiToPass.map((item) => {});
                      let requestBody = {
                        manualeId: this.state.tabs.at(0).idManuale,
                        paragrafi: paragrafiToPass,
                      };
                      let componentiToPassForSottoparagrafi = [
                        ...this.state.sottoParagrafiData,
                      ];
                      componentiToPassForSottoparagrafi.map((item) => {
                        item.immagini.map((item2, index) => {
                          item2.posizione = index;
                        });
                      });
                      let bodyToPassForSottoparagrafi = {
                        id: currentParagraph.id,
                        manualeId: currentParagraph.idManuale,
                        titolo: currentParagraph.label,
                        componenti: componentiToPassForSottoparagrafi,
                      };
                      putModificaParagrafiManualePersonalizzato(requestBody)
                        .then((result) => {})
                        .then((result) => {
                          updateSottoparagrafi(bodyToPassForSottoparagrafi)
                            .then((result) => {
                              if (this.state.updatedReparti !== null) {
                                this.launchRepartiUpdate(
                                  this.state.updatedReparti
                                );
                              } else if (this.state.updatedLocali !== null) {
                                if (
                                  this.state.updatedLocali.some(
                                    (item) => item.nome === ""
                                  )
                                ) {
                                  alert(
                                    "Attenzione, uno dei locali è privo di nome"
                                  );
                                } else {
                                  this.launchLocaliUpdate(
                                    this.state.updatedLocali
                                  );
                                }
                              } else {
                                //window.location.reload(true);
                                this.getParagrafiManualeInCostruzioneAfterSave();
                              }
                            })
                            .catch((error) => {});
                        })
                        .catch((error) => {
                          if (error.status === 403) {
                            this.handleInvalidToken();
                          } else {
                            this.handleError(true, error.message);
                          }
                          this.setState({
                              isSaveButtonPressed:false,
                          })
                        });
                    } else {
                      alert("Attenzione, selezionare un paragrafo da salvare!");
                      this.setState({
                              isSaveButtonPressed:false,
                      })
                    }
                  } else {
                    const capitoli = [...this.state.tabListValues];
                    let idManuale = this.props.manualeId;
                    let capitoliToPass = [];
                    capitoli.map((item, index) => {
                      let objectToPushInCapitolo = {};
                      let paragrafi = [];
                      let capitolo = {
                        id: item.id,
                        manualeId: idManuale,
                        numeroCapitolo: item.numeroCapitolo,
                        titolo: item.name,
                        stato: null,
                      };
                      item.paragraphData.map((item2, index2) => {
                        let paragrafo = {
                          id: item2.id,
                          manualeId: idManuale,
                          capitoloId: item.id,
                          numeroParagrafo: item2.value,
                          titolo: item2.label,
                          stato: item2.stato,
                          obbligatorio: item2.obbligatorio,
                          bloccato: item2.isBlocked,
                        };
                        paragrafi.push(paragrafo);
                      });
                      objectToPushInCapitolo = {
                        capitolo: capitolo,
                        paragrafi: paragrafi,
                      };
                      capitoliToPass.push(objectToPushInCapitolo);
                    });

                    let bodyToSave = {
                      manualeId: idManuale,
                      capitoli: capitoliToPass,
                    };
                    putCapitoli(bodyToSave)
                      .then((result) => {
                        let currentParagraph = this.state.currentTabValue;
                        let componentiToPassForSottoparagrafi = [
                          ...this.state.sottoParagrafiData,
                        ];
                        let bodyToPassForSottoparagrafi = {
                          id: currentParagraph.id,
                          manualeId: currentParagraph.idManuale,
                          titolo: currentParagraph.label,
                          componenti: componentiToPassForSottoparagrafi,
                        };
                        componentiToPassForSottoparagrafi.map((item) => {
                          item.immagini.map((item2, index) => {
                            item2.posizione = index;
                          });
                        });
                        updateSottoparagrafi(bodyToPassForSottoparagrafi)
                          .then((result) => {
                            if (this.state.updatedReparti !== null) {
                              this.launchRepartiUpdate(
                                this.state.updatedReparti
                              );
                            } else if (this.state.updatedLocali !== null) {
                              if (
                                this.state.updatedLocali.some(
                                  (item) => item.nome === ""
                                )
                              ) {
                                alert(
                                  "Attenzione, uno dei locali è privo di nome"
                                );
                              } else {
                                this.launchLocaliUpdate(
                                  this.state.updatedLocali
                                );
                              }
                            } else {
                              //window.location.reload(true);
                              this.getParagrafiManualeInCostruzioneAfterSaveForChapters();
                            }
                          })
                          .catch((error) => {
                            if (error.status === 403) {
                              this.handleInvalidToken();
                            } else {
                              this.handleError(true, error.message);
                            }
                            this.setState({
                              isSaveButtonPressed:false,
                            })
                          });
                      })
                      .catch((error) => {
                        if (error.status === 403) {
                          this.handleInvalidToken();
                        } else {
                          this.handleError(true, error.message);
                        }
                        this.setState({
                              isSaveButtonPressed:false,
                        })
                      });
                  } /**/
                }}
              />
            </div>
          ) : null}

          {/*<SezioneSottoparagrafiCustom
                                                            componentiParagrafo={this.state.manual.componenti}
                                                            firstCustomSottoparagrafoIndex={firstCustomSottoparagrafoIndex}
                                                            disabled={false}
                                                            numeroParagrafo={this.state.manual.numeroParagrafo}
                                                            onAdd={this.addSottoparagrafo}
                                                            onMove={this.moveSottoparagrafo}
                                                            onTextChanged={this.handleTextChanged}
                                                            onDelete={this.deleteSottoparagrafo}
                                                        />*/}
        </div>
        <Modal
          open={this.state.isModalOpen}
          onClose={() => {
            this.setState({
              isModalOpen: !this.state.isModalOpen,
              colonneTabellaCustom: [],
            });
          }}
        >
          <div style={styles.modalcontainer}>
            <div style={styles.modalsectioncontainer}>
              <Typography
                variant="h6"
                style={{ paddingTop: "8px", paddingBottom: "8px" }}
              >
                {"Creazione tabella per la sezione " +
                  this.state.currentTabValue.label}
              </Typography>
              {/*<Typography variant="h6" style={{ paddingTop: '8px', paddingBottom: '8px' }} >
                                Tipologia Di Tabella
                        </Typography>
                        <Select
                        style={{alignSelf:"center",width:500}}
                        value={this.state.tipoDiTabella}
                              name="tipoDiTabella"
                              onChange={this.handleChangeTipo}
                        >
                          <MenuItem value={'Custom'}>Personalizzato</MenuItem>
                        </Select>*/}
              <Typography variant="h6" style={styles.tableModalText}>
                Cliccare su "Aggiungi colonna" per aggiungere una colonna alla
                tabella
              </Typography>
              <Box style={{ marginBottom: "2%" }}>
                {this.state.colonneTabellaCustom.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Typography>Nome colonna: </Typography>
                      <TextField
                        style={{ marginLeft: 10, marginTop: -2, width: "50%" }}
                        onChange={(e) => {
                          let columnName = e.target.value;
                          if (columnName === "") {
                            columnName = " ";
                          }
                          let array = this.state.colonneTabellaCustom;
                          array[index].header = columnName;
                          this.setState({
                            colonneTabellaCustom: array,
                          });
                        }}
                        value={item.header}
                      />
                      <IconButton
                        size="small"
                        style={{ marginTop: 0 }}
                        onClick={() => {
                          let columnArray = [
                            ...this.state.colonneTabellaCustom,
                          ];
                          columnArray.splice(index, 1);
                          this.setState(
                            {
                              colonneTabellaCustom: columnArray,
                            },
                            () => {}
                          );
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  );
                })}
              </Box>
              <ActionButton
                grayVersion={false}
                disabled={false}
                label={"Aggiungi colonna"}
                onClick={() => {
                  if (this.state.colonneTabellaCustom.length < 16) {
                    let updateColumn = [...this.state.colonneTabellaCustom];
                    let valueToSave = this.state.colonneTabellaCustom.length;
                    updateColumn.push({
                      header: "Colonna " + valueToSave++,
                      id: "Colonna" + valueToSave++,
                    });
                    this.setState({
                      colonneTabellaCustom: updateColumn,
                    });
                  } else {
                    alert("Errore, sono state inserite più di 16 colonne");
                  }
                }}
              />

              <Typography
                variant="h6"
                style={{ paddingTop: "10%", paddingBottom: "8px" }}
              ></Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ActionButton
                grayVersion={false}
                disabled={false}
                onClick={this.handleAggiuntaTable}
                label="Aggiungi Tabella"
              />
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.isSottoparagrafiModalOpen}
          onClose={() => {
            this.setState({
              isSottoparagrafiModalOpen: !this.state.isSottoparagrafiModalOpen,
            });
          }}
        >
          <div style={styles.modalcontainer}>
            <div style={styles.modalsectioncontainer}>
              <Typography
                variant="h6"
                style={{ paddingTop: "8px", paddingBottom: "8px" }}
              >
                {"Creazione sottoparagrafo in " + this.state.modalTitle}
              </Typography>
              <Typography
                variant="h6"
                style={{ paddingTop: "8px", paddingBottom: "8px" }}
              >
                Titolo sottoparagrafo
              </Typography>
              <TextField
                defaultValue={"Nuovo Sottoparagrafo"}
                onChange={this.handleSubparagraphTitleText}
                style={{ alignSelf: "center", width: 500 }}
              />
              <Typography
                variant="h6"
                style={{ paddingTop: "8px", paddingBottom: "8px" }}
              >
                Tipo di sottoparagrafo
              </Typography>
              <Select
                style={{ alignSelf: "center", width: 500 }}
                value={this.state.modalitaSottoparagrafo}
                name="modalitaSottoparagrafo"
                onChange={this.handleChangeModalitaSottoparagrafo}
              >
                <MenuItem value={"Testo"}>Testo</MenuItem>
                <MenuItem value={"Immagine"}>Immagine</MenuItem>
                <MenuItem value={"ImmagineAndTesto"}>Testo e Immagine</MenuItem>
              </Select>
              <ActionButton
                grayVersion={false}
                disabled={false}
                onClick={this.handleAggiuntaSottoparagrafi}
                label="Aggiungi Sottoparagrafo"
              />
            </div>
          </div>
        </Modal>
        {this.state.isChangeChapterNameModalOpen ? (
          <ModalChangeChapterName
            isChangeChapterNameModalOpen={
              this.state.isChangeChapterNameModalOpen
            }
            closeModalToChangeChapterName={this.closeModalToChangeChapterName}
            saveChapterName={this.saveChapterName}
            chapter={this.state.selectedChapter}
          />
        ) : null}
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
        {this.state.isConadModalOpen && (
          <ErrorDialog
            open={this.state.isConadModalOpen}
            message={
              this.props.mode === "manuale"
                ? "Attenzione: questo manuale può essere modificato soltanto attraverso la web app dedicata http://conad-backoffice.biorsaf.it/"
                : "Attenzione: questo modello può essere modificato soltanto attraverso la web app dedicata http://conad-backoffice.biorsaf.it/"
            }
            onCloseButtonClicked={() => {
              this.handlePushBack();
            }}
          />
        )}
      </div>
    );
  }
}
export default withRouter(CreazioneNuovoManuale);
