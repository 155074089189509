import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  div_spinner: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  spinner: {
    color: theme.palette.primary.main,
  },
}));

export default function SpinnerComponent(props) {
  const classes = useStyles();

  return (
    <div className={classes.div_spinner}>
      <CircularProgress
        disableShrink
        className={classes.spinner}
        size={props.size}
      />
    </div>
  );
}

SpinnerComponent.propTypes = {
  size: PropTypes.number.isRequired,
};
