import React from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { withStyles } from "@material-ui/styles";
import SelectSearch from "react-select-search";
import Moment from "moment";
import validator from "validator";
import PropTypes from "prop-types";

import ErrorDialog from "../../../components/ErrorDialog";
import IndietroButton from "../../../components/IndietroButton";
import SpinnerComponent from "../../../components/SpinnerComponent";
import GiorniChiusuraEsercizio from "./GiorniChiusuraEsercizio";
import FormPagamento from "./FormPagamento";

import { clearToken } from "../../../utils/storage";
import { getConsulenti } from "../../../utils/api/consulenti_api";
import { registerEsercizioWithResponsabile } from "../../../utils/api/esercizi_commerciali_api";
import { getGruppo } from "../../../utils/api/gruppi_api";

const styles = (theme) => ({
  mainContainer: {
    textAlign: "center",
    color: "black",
    paddingTop: "10px",
  },
  sectionContainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "6px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  gridContainer: {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingBottom: "10px",
  },
  fieldscontainer: {
    marginTop: "10px",
    marginBottom: "10px",
    padding: "10px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "250px",
  },
});

class NuovoEsercizioConsulente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {
        esercizio: {
          id: null,
          nome: "",
          ragioneSociale: "",
          partitaIva: null,
          codiceAteco: null,
          telefono: null,
          nomeGestore: null,
          cognomeGestore: null,
          emailGestore: null,
          telefonoGestore: null,
          indirizzoSede: null,
          numeroSede: null,
          cittaSede: null,
          provinciaSede: null,
          consulenteId: null,
          codiceSdi: null,
          pec: null,
          terminiUsoAccettati: false,
          privacyPolicyAccettata: false,
          gruppoId: null,
          responsabileSicurezza: {
            email: "",
            password: "",
            confirmPassword: "",
            nome: null,
            cognome: null,
            telefono: null,
            terminiUsoAccettati: false,
            privacyPolicyAccettata: false,
            gruppoId: null,
          },
        },
        storicoPricing: {
          moltiplicatore: 1,
          anniValidita: 1,
          valoreIva: "",
          imponibile: "",
          totaleLordo: "",
          modalita: "PAYPAL",
          consulenteId: this.props.consulenteId,
          esercizioCommercialeId: "",
          dataAggiornamento: "",
        },
        giorniChiusura: [],
      },
      validationErrors: {
        nome: "",
        ragioneSociale: "",
        email: "",
        password: "",
        confirmPassword: "",
        privacyPolicyAccettata: "",
        emailGestore: "",
      },
      costoUnitario: "",
      tipologiaPagamento: "",
      consulenti: [],
      showPassword1: false,
      disabledButtons: false,
      showPassword2: false,
      errorDialogVisible: false,
      errorDialogMessage: "",
      errorDialogTitle: null,
      loading: true,
      saveButtonEnabled: false,
      saveMessageVisible: false,
    };
  }

  componentDidMount() {
    let record = this.state.record;
    record.esercizio.consulenteId = this.props.consulenteId;
    record.esercizio.gruppoId = this.props.gruppoId;
    record.esercizio.responsabileSicurezza.gruppoId = this.props.gruppoId;
    if (this.props.superconsulente) {
      this.setState({
        record,
      });
      this.fetchConsulenti(this.props.gruppoId);
    } else {
      this.setState({
        record,
        loading: false,
      });
    }
    this.fetchGruppo();
  }

  handleInvalidToken = () => {
    this.setState({
      errorDialogVisible: true,
      errorDialogMessage:
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.",
    });
    clearToken();
    const { path } = this.props.theme;
    window.setTimeout(function () {
      window.location.href = "/" + path;
    }, 4000);
  };

  fetchConsulenti = (gruppoId) => {
    getConsulenti(null, gruppoId, 0, 1000, "id")
      .then((result) => {
        this.setState({
          loading: false,
          consulenti: result,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
          });
        }
      });
  };

  fetchGruppo = () => {
    getGruppo(this.props.gruppoId).then((response) => {
      this.setState({
        costoUnitario: response.costoUnitario,
        tipologiaPagamento: response.tipologiaPagamento,
      });
    });
  };

  updateParentState = (data) => {
    let record = this.state.record;
    record.giorniChiusura = data;
    this.setState({
      record: record,
      saveButtonEnabled: true,
    });
  };

  validateForm = () => {
    let data = this.state.record.esercizio;
    Object.keys(data).forEach((key) => {
      this.validateField(key, data[key]);
    });
    let responsabile = this.state.record.esercizio.responsabileSicurezza;
    Object.keys(responsabile).forEach((key) => {
      this.validateFieldResponsabileSicurezza(key, responsabile[key]);
    });
    //Return false if there are validation errors:
    let valid = true;
    let validationErrors = this.state.validationErrors;
    Object.values(validationErrors).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  handleChangeAbbonamento = (value, fielName) => {
    if (!this.state.saveButtonEnabled) {
      this.setState({
        saveButtonEnabled: true,
      });
    }
    let data = this.state.record;
    data.storicoPricing[fielName] = value;
    this.setState({
      record: data,
    });
  };

  validateField = (key, value) => {
    let validationErrors = this.state.validationErrors;
    switch (key) {
      case "nome":
        if (value === null || value.length === 0) {
          validationErrors.nome = "Inserire nome";
        } else {
          validationErrors.nome = "";
        }
        break;
      case "ragioneSociale":
        if (value === null || value.length === 0) {
          validationErrors.ragioneSociale = "Inserire ragione sociale";
        } else {
          validationErrors.ragioneSociale = "";
        }
        break;
      case "emailGestore":
        if (value === null || value.length === 0) {
          validationErrors.emailGestore =
            "Inserire indirizzo email del titolare";
        } else {
          if (validator.isEmail(value)) {
            validationErrors.emailGestore = "";
          } else {
            validationErrors.emailGestore =
              "L'indirizzo email inserito non è valido";
          }
        }
        break;
      default:
        break;
    }
    this.setState({
      validationErrors,
    });
  };

  validateFieldResponsabileSicurezza = (key, value) => {
    let insertedPsw =
      this.state.record.esercizio.responsabileSicurezza.password;
    let validationErrors = this.state.validationErrors;
    switch (key) {
      case "email":
        if (value.length === 0) {
          validationErrors.email = "Inserire l'indirizzo email";
        } else {
          if (validator.isEmail(value)) {
            validationErrors.email = "";
          } else {
            validationErrors.email = "L'indirizzo email inserito non è valido";
          }
        }
        break;
      case "password":
        if (value.length >= 8) {
          validationErrors.password = "";
        } else {
          validationErrors.password =
            "Inserire una password di almeno 8 caratteri";
        }
        break;
      case "confirmPassword":
        if (value.length === 0) {
          validationErrors.confirmPassword = "Ripetere la password";
        } else {
          if (value === insertedPsw) {
            validationErrors.confirmPassword = "";
          } else {
            validationErrors.confirmPassword =
              "Le due password non corrispondono";
          }
        }
        break;

      default:
        break;
    }
    this.setState({
      validationErrors,
    });
  };

  onItemSelected = (value) => {
    if (!this.state.saveButtonEnabled) {
      this.setState({
        saveButtonEnabled: true,
      });
    }
    const consulenti = this.state.consulenti;
    let selectedConsulente = null;
    for (let i = 0; i < consulenti.length; i++) {
      if (consulenti[i].id === value) {
        selectedConsulente = consulenti[i];
        break;
      }
    }
    if (selectedConsulente !== null) {
      let record = this.state.record;
      record.esercizio.consulenteId = selectedConsulente.id;
      this.setState({
        record,
      });
    }
  };

  handleChange = (event) => {
    if (!this.state.saveButtonEnabled) {
      this.setState({
        saveButtonEnabled: true,
      });
    }
    let validationErrors = this.state.validationErrors;
    let name = event.target.name;
    let value = event.target.value;
    let data = this.state.record;
    data.esercizio[name] = value;
    //Reset validation :
    if (name === "nome" || name === "ragioneSociale") {
      validationErrors[name] = "";
    }
    this.setState({
      data,
      validationErrors,
    });
  };

  handleChangeResponsabileSicurezza = (event) => {
    if (!this.state.saveButtonEnabled) {
      this.setState({
        saveButtonEnabled: true,
      });
    }
    let name = event.target.name;
    let value = event.target.value;
    let validationErrors = this.state.validationErrors;
    let data = this.state.record.esercizio.responsabileSicurezza;
    data[name] = value;
    //Reset validation :
    if (name === "email" || name === "password" || name === "confirmPassword") {
      validationErrors[name] = "";
    }
    this.setState({
      data,
      validationErrors,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.validateForm()) return;

    const data = {
      ...this.state.record,
      storicoPricing:
        this.state.tipologiaPagamento === "BIORSAF"
          ? {
              ...this.state.record.storicoPricing,
              dataAggiornamento: null,
            }
          : null,
    };
    this.setState({
      loading: true,
    });

    registerEsercizioWithResponsabile(data)
      .then((_result) => {
        this.setState({
          saveButtonEnabled: false,
          disabledButtons: true,
          saveMessageVisible: true,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            errorDialogVisible: true,
            errorDialogMessage: error.message,
          });
        }
      })
      .finally(() => this.setState({ loading: false }));
  };

  handleClickShowPassword1 = () => {
    let flag = this.state.showPassword1;
    this.setState({
      showPassword1: !flag,
    });
  };

  handleClickShowPassword2 = () => {
    let flag = this.state.showPassword2;
    this.setState({
      showPassword2: !flag,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  render() {
    const { classes } = this.props;
    Moment.locale("it-IT");
    const disabledSubmitbutton =
      this.state.loading ||
      !this.state.saveButtonEnabled ||
      this.state.saveMessageVisible;
    let textFieldsDisabled = this.state.saveMessageVisible;

    return (
      <div className={classes.mainContainer}>
        <Typography variant="h4">Nuovo esercizio commerciale</Typography>
        <Grid
          container
          justify="center"
          style={{ marginTop: "26px", marginBottom: "26px" }}
        >
          <div style={{ minHeight: "500px" }}>
            {this.state.loading ? (
              <SpinnerComponent size={24} />
            ) : (
              <form autoComplete="off" onSubmit={this.handleSubmit}>
                <Container
                  style={{ width: "100%", paddingLeft: 0, paddingRight: 0 }}
                >
                  <Grid container justify="center" alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className={classes.sectionContainer}
                    >
                      <Typography
                        variant="h6"
                        style={{ paddingTop: "6px", paddingBottom: "6px" }}
                      >
                        Dati esercizio commerciale
                      </Typography>
                      <Grid container className={classes.fieldscontainer}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            className={classes.gridContainer}
                          >
                            <TextField
                              label="Nome*"
                              value={
                                this.state.record.esercizio.nome === null
                                  ? ""
                                  : this.state.record.esercizio.nome
                              }
                              name="nome"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                              helperText={this.state.validationErrors.nome}
                              error={
                                this.state.validationErrors.nome.length > 0
                              }
                            />
                            <TextField
                              label="Ragione sociale*"
                              value={
                                this.state.record.esercizio.ragioneSociale ===
                                null
                                  ? ""
                                  : this.state.record.esercizio.ragioneSociale
                              }
                              name="ragioneSociale"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                              helperText={
                                this.state.validationErrors.ragioneSociale
                              }
                              error={
                                this.state.validationErrors.ragioneSociale
                                  .length > 0
                              }
                            />
                            <TextField
                              label="Partita IVA"
                              value={
                                this.state.record.esercizio.partitaIva === null
                                  ? ""
                                  : this.state.record.esercizio.partitaIva
                              }
                              name="partitaIva"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="Codice ATECO"
                              value={
                                this.state.record.esercizio.codiceAteco === null
                                  ? ""
                                  : this.state.record.esercizio.codiceAteco
                              }
                              name="codiceAteco"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="Telefono"
                              value={
                                this.state.record.esercizio.telefono === null
                                  ? ""
                                  : this.state.record.esercizio.telefono
                              }
                              name="telefono"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="Nome titolare"
                              value={
                                this.state.record.esercizio.nomeGestore === null
                                  ? ""
                                  : this.state.record.esercizio.nomeGestore
                              }
                              name="nomeGestore"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="Cognome titolare"
                              value={
                                this.state.record.esercizio.cognomeGestore ===
                                null
                                  ? ""
                                  : this.state.record.esercizio.cognomeGestore
                              }
                              name="cognomeGestore"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="Email titolare*"
                              value={
                                this.state.record.esercizio.emailGestore ===
                                null
                                  ? ""
                                  : this.state.record.esercizio.emailGestore
                              }
                              name="emailGestore"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                              helperText={
                                this.state.validationErrors.emailGestore
                              }
                              error={
                                this.state.validationErrors.emailGestore
                                  .length > 0
                              }
                            />

                            <TextField
                              label="Telefono titolare"
                              value={
                                this.state.record.esercizio.telefonoGestore ===
                                null
                                  ? ""
                                  : this.state.record.esercizio.telefonoGestore
                              }
                              name="telefonoGestore"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            className={classes.gridContainer}
                          >
                            <TextField
                              label="Indirizzo"
                              value={
                                this.state.record.esercizio.indirizzoSede ===
                                null
                                  ? ""
                                  : this.state.record.esercizio.indirizzoSede
                              }
                              name="indirizzoSede"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="Numero civico"
                              value={
                                this.state.record.esercizio.numeroSede === null
                                  ? ""
                                  : this.state.record.esercizio.numeroSede
                              }
                              name="numeroSede"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="Città"
                              value={
                                this.state.record.esercizio.cittaSede === null
                                  ? ""
                                  : this.state.record.esercizio.cittaSede
                              }
                              name="cittaSede"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="Provincia"
                              value={
                                this.state.record.esercizio.provinciaSede ===
                                null
                                  ? ""
                                  : this.state.record.esercizio.provinciaSede
                              }
                              name="provinciaSede"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="Codice SDI"
                              defaultValue={
                                this.state.record.esercizio.codiceSdi === null
                                  ? ""
                                  : this.state.record.esercizio.codiceSdi
                              }
                              name="codiceSdi"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="PEC"
                              defaultValue={
                                this.state.record.esercizio.pec === null
                                  ? ""
                                  : this.state.record.esercizio.pec
                              }
                              name="pec"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {this.props.superconsulente ? (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={classes.sectionContainer}
                      >
                        <Typography
                          variant="h6"
                          style={{ paddingTop: "6px", paddingBottom: "6px" }}
                        >
                          Selezionare il consulente associato al nuovo
                          esercizio:
                        </Typography>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          style={{ paddingBottom: "10px" }}
                        >
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <SelectSearch
                              disabled={textFieldsDisabled}
                              options={this.state.consulenti.map(
                                ({ id, email }) => ({ value: id, name: email })
                              )}
                              onChange={this.onItemSelected}
                              value={this.state.record.esercizio.consulenteId}
                              placeholder="Selezionare un consulente"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null}

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className={classes.sectionContainer}
                    >
                      <Typography
                        variant="h6"
                        style={{ paddingTop: "6px", paddingBottom: "6px" }}
                      >
                        Aggiungi responsabile sicurezza associato al nuovo
                        esercizio
                      </Typography>
                      <Grid container className={classes.fieldscontainer}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            className={classes.gridContainer}
                          >
                            <TextField
                              label="Email*"
                              value={
                                this.state.record.esercizio
                                  .responsabileSicurezza.email === null
                                  ? ""
                                  : this.state.record.esercizio
                                      .responsabileSicurezza.email
                              }
                              name="email"
                              onChange={this.handleChangeResponsabileSicurezza}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                              helperText={this.state.validationErrors.email}
                              error={
                                this.state.validationErrors.email.length > 0
                              }
                            />
                            <TextField
                              label="Password*"
                              value={
                                this.state.record.esercizio
                                  .responsabileSicurezza.password === null
                                  ? ""
                                  : this.state.record.esercizio
                                      .responsabileSicurezza.password
                              }
                              name="password"
                              onChange={this.handleChangeResponsabileSicurezza}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                              helperText={this.state.validationErrors.password}
                              error={
                                this.state.validationErrors.password.length > 0
                              }
                              type={
                                this.state.showPassword1 ? "text" : "password"
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Tooltip title="Mostra password">
                                      <IconButton
                                        label="Mostra password"
                                        onClick={this.handleClickShowPassword1}
                                      >
                                        {this.state.showPassword1 ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </Tooltip>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              label="Ripeti password*"
                              value={
                                this.state.record.esercizio
                                  .responsabileSicurezza.confirmPassword
                              }
                              name="confirmPassword"
                              onChange={this.handleChangeResponsabileSicurezza}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                              helperText={
                                this.state.validationErrors.confirmPassword
                              }
                              error={
                                this.state.validationErrors.password.length > 0
                              }
                              type={
                                this.state.showPassword2 ? "text" : "password"
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Tooltip title="Mostra password">
                                      <IconButton
                                        label="Mostra password"
                                        onClick={this.handleClickShowPassword2}
                                      >
                                        {this.state.showPassword2 ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </Tooltip>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            className={classes.gridContainer}
                          >
                            <TextField
                              label="Nome"
                              value={
                                this.state.record.esercizio
                                  .responsabileSicurezza.nome === null
                                  ? ""
                                  : this.state.record.esercizio
                                      .responsabileSicurezza.nome
                              }
                              name="nome"
                              onChange={this.handleChangeResponsabileSicurezza}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="Cognome"
                              value={
                                this.state.record.esercizio
                                  .responsabileSicurezza.cognome === null
                                  ? ""
                                  : this.state.record.esercizio
                                      .responsabileSicurezza.cognome
                              }
                              name="cognome"
                              onChange={this.handleChangeResponsabileSicurezza}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="Telefono"
                              value={
                                this.state.record.esercizio
                                  .responsabileSicurezza.telefono === null
                                  ? ""
                                  : this.state.record.esercizio
                                      .responsabileSicurezza.telefono
                              }
                              name="telefono"
                              onChange={this.handleChangeResponsabileSicurezza}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
                {this.state.tipologiaPagamento === "BIORSAF" && (
                  <FormPagamento
                    abbonamentoData={this.state.record.storicoPricing}
                    handleChangeAbbonamento={this.handleChangeAbbonamento}
                    costoUnitario={this.state.costoUnitario}
                  />
                )}
                <GiorniChiusuraEsercizio
                  esercizioId={this.state.record.esercizio.id}
                  giorniChiusura={this.state.record.giorniChiusura}
                  updateParentState={this.updateParentState}
                  disabledButtons={this.state.disabledButtons}
                  theme={this.props.theme}
                />
                <Grid container justify="center" style={{ paddingTop: "10px" }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ textAlign: "center" }}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        color: "green",
                        visibility: this.state.saveMessageVisible
                          ? "inherit"
                          : "hidden",
                      }}
                    >
                      Dati registrati con successo.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ textAlign: "right" }}
                  >
                    <IndietroButton isDisabled={this.state.loading} />
                    <Button
                      type="submit"
                      onClick={this.handleSubmit}
                      variant="contained"
                      size="medium"
                      disabled={disabledSubmitbutton}
                      style={{
                        color: disabledSubmitbutton
                          ? this.props.theme.palette.text.disabled
                          : this.props.theme.palette.text.main,
                        margin: "10px",
                        backgroundColor: disabledSubmitbutton
                          ? this.props.theme.palette.disabled.main
                          : this.props.theme.palette.primary.main,
                      }}
                    >
                      Salva
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </div>
        </Grid>

        <ErrorDialog
          open={this.state.errorDialogVisible}
          title={this.state.errorDialogTitle}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
      </div>
    );
  }
}

NuovoEsercizioConsulente.propTypes = {
  consulenteId: PropTypes.number.isRequired,
  gruppoId: PropTypes.number.isRequired,
  superconsulente: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles)(NuovoEsercizioConsulente);
