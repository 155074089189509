import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, useTheme } from "@material-ui/core";

export default function AquistaButton(props) {
  let history = useHistory();
  let themeToUse = useTheme();
  return (
    <Button
      type="button"
      variant="contained"
      onClick={() => {
        if(props.isEdited){
          alert("Attenzione: occorre salvare le modifiche prima di procedere")
        }
        else{
          history.push(`/abbonamenti?id=${props.abbonamentoId}`);
        }
      }}
      size="medium"
      style={{
        color: themeToUse.palette.text.main,
        backgroundColor:props.disabled ? "#e0e0e0" : themeToUse.palette.primary.main,
      }}
      disabled={props.disabled}
    >
      Acquista
    </Button>
  );
}

AquistaButton.propTypes = {
  abbonamentoId: PropTypes.number.isRequired,
};
