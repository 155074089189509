import React from "react";
import theme from "../../../theme.js";
import CloseIcon from "@material-ui/icons/Close";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import AlertDialog from "../../../components/AlertDialog.js";
import { getImmaginiSottoparagrafo } from "../../../utils/api/paragrafi_api.js";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
    paddingBottom: "1%",
  },
  header: {
    textAlign: "left",
    fontSize: 30,
    marginLeft: "0%",
    display: "flex",
    flexDirection: "row",
  },
  imageContainer: {
    width: 300,
    height: 400,
    display: "flex",
    margin: "auto",
    flexWrap: "wrap",
  },
  buttonContainer: {},
  divider: {},
};

class BoxTitoloManuale extends React.Component {
  /*constructor(props){
        super(props);
      this.state = {
      tabValue: this.initialTab(),
      loading: true,
      edit: false,
      model: [],
      manual: [],
      errorDialogVisible: false,
      errorDialogMessage: "",
      errorDialogTitle: null,
      isDialogOpen: false,
    };
    this.updateEdit = this.updateEdit.bind(this);
    }*/
  constructor(props) {
    super(props);
    this.state = {
      currentFile: "",
      previewImage: "",
      dialogVisible: false,
      idImmagine: props.idImmagine,
      borderWidth: props.borderWidth,
      componentHeight: props.componentHeight,
      componentWidth: props.componentWidth,
      photoWidth: props.photoWidth,
      isTitleEditable: false,
      photoHeight: props.photoHeight,
      titoloInizialeSottoparagrafo: props.titoloInizialeSottoparagrafo,
      idImages: props.immaginiParagrafo,
      imagesNames: [],
      images: [],
    };
  }
  selectFile(event) {
    this.setState({
      currentFile: event.target.files[0],
      previewImage: URL.createObjectURL(event.target.files[0]),
    });
    console.log(this.state.images.length);
  }
  upload() {
    console.log("I have been called");
  }

  componentDidMount() {
    console.log(this.state.titoloInizialeSottoparagrafo);
    console.log(
      "This is the value that i will use to delete this element: " +
        this.props.indexValue
    );
    console.log("imagesId: ");
    console.log(this.state.idImages);
    this.loadImages(this.state.idImages);
  }

  loadImages = (arrayOfImagesId) => {
    console.log("Gotta download them now: ");
    console.log(arrayOfImagesId);
    let newArrayOfImages = [...this.state.images];
    if (arrayOfImagesId !== undefined) {
      arrayOfImagesId.map((item) => {
        getImmaginiSottoparagrafo(item)
          .then((result) => {
            newArrayOfImages.push(result.url);
            this.setState(
              {
                images: newArrayOfImages,
              },
              () => {
                console.log("Images downloaded:");
                console.log(this.state.images);
              }
            );
          })
          .catch((error) => {
            if (error.status === 403) {
              this.props.handleInvalidToken();
            } else {
              this.props.handleError(true, error.message);
            }
          });
      });
    }
  };

  render() {
    const { images } = this.state;
    return (
      <div
        style={{
          margin: 35,
          marginLeft: "25%",
          textAlign: "center",
          color: "black",
          paddingBottom: "1%",
          height: this.props.componentHeight,
          width: this.props.componentWidth,
        }}
      >
        <div style={styles.header}>
          <TextField
            InputProps={{ disableUnderline: true, style: { fontSize: 30 } }}
            InputLabelProps={{ style: { fontSize: 30 } }}
            disabled={!this.state.isTitleEditable}
            onInput={(e) => {
              this.setState({
                titoloInizialeSottoparagrafo: e.target.value,
              });
            }}
            onBlur={() => {
              this.props.bringTitleToParent(
                this.state.titoloInizialeSottoparagrafo,
                this.props.indexValue
              );
            }}
            value={this.state.titoloInizialeSottoparagrafo}
          ></TextField>
          <IconButton>
            <Edit
              onClick={() => {
                this.setState({
                  isTitleEditable: !this.state.isTitleEditable,
                });
              }}
            ></Edit>
          </IconButton>
          <IconButton
            onClick={() => {
              this.props.rimuoviSottoparagrafo(this.props.indexValue);
            }}
          >
            <CloseIcon></CloseIcon>
          </IconButton>
        </div>
        <div
          style={{
            border: "1px solid black",
            width: this.state.borderWidth,
            marginLeft: "1%",
          }}
        ></div>
        {!images && (
          <div style={{ display: "flex", gap: "1%", marginLeft: "1%" }}>
            {/*previewImage !== '' ? (
                  <img src={previewImage} alt="" style={{marginTop:"1%",maxWidth:this.props.photoWidth,maxHeight:this.props.photoHeight}}/>
                ):(
                  <img src={previewImage} alt="" style={{maxWidth:this.props.photoWidth,maxHeight:this.props.photoHeight}}/>
                )*/}
          </div>
        )}
        {images.length > 0 && (
          <Grid container spacing={2} mx={"1%"} style={{ marginTop: 1 }} xs={6}>
            {images.map((img) => {
              return (
                <Grid item style={{ display: "flex" }}>
                  <img
                    src={img}
                    alt=""
                    style={{
                      maxWidth: this.props.photoWidth,
                      maxHeight: this.props.photoHeight,
                    }}
                  />
                  <div>
                    <IconButton
                      onClick={() => {
                        console.log(img);
                        let newArray = [...this.state.images];
                        let arrayOfImagesId = [...this.state.idImages];
                        let index = newArray.indexOf(img);
                        if (index !== -1) {
                          newArray.splice(index, 1);
                          this.setState({
                            images: newArray,
                          });
                        }
                        this.props.deleteImage(
                          arrayOfImagesId.at(index),
                          this.props.indexValue
                        );
                      }}
                    >
                      <CloseIcon></CloseIcon>
                    </IconButton>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        )}
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={(event) => {
            if (this.state.images.length < 4) {
              let arrayOfImagesToPass = [
                ...this.state.images,
                event.target.files[0],
              ];
              console.log(arrayOfImagesToPass);
              let arrayOfNamesToPass = [
                ...this.state.imagesNames,
                event.target.files[0].name,
              ];
              this.setState({
                currentFile: event.target.files[0],
                previewImage: URL.createObjectURL(event.target.files[0]),
                images: [
                  ...this.state.images,
                  URL.createObjectURL(event.target.files[0]),
                ],
                imagesNames: [
                  ...this.state.imagesNames,
                  event.target.files[0].name,
                ],
              });
              this.props.bringImageToParent(
                arrayOfImagesToPass,
                arrayOfNamesToPass,
                this.props.indexValue
              );
            } else {
              this.setState({
                dialogVisible: true,
              });
            }
            //console.log(this.state.images.length);
            //console.log("preview image: "+this.state.previewImage);
          }}
          id={this.props.idImmagine}
        />
        <Typography align="left">
          <label htmlFor={this.props.idImmagine}>
            <Button
              variant="contained"
              style={{
                color: theme.palette.secondary.main,
                backgroundColor: theme.palette.primary.main,
                marginBottom: "1%",
                marginLeft: "1%",
                marginTop: "1%",
              }}
              component="span"
              onClick={this.upload}
            >
              Scegli file
            </Button>
          </label>
        </Typography>
        <div
          style={{
            border: "1px solid black",
            width: this.state.borderWidth,
            marginBottom: "1%",
            marginLeft: "1%",
          }}
        ></div>
        <AlertDialog
          isOpen={this.state.dialogVisible}
          title="Errore inserimento immagine"
          message="Non è possibile inserire più di 4 immagini!"
          onButtonClicked={() => {
            this.setState({
              dialogVisible: false,
            });
          }}
        />
      </div>
    );
  }
}
export default BoxTitoloManuale;
