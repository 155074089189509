import React, { useState } from "react";
import PropTypes from "prop-types";
import { ChromePicker } from "react-color";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  colorPickerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  colorBoxWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  colorBlock: {
    width: "150px",
    height: "35px",
  },
  highlightedBorder: {
    border: `2px solid ${theme.palette.common.black}`,
  },
}));

const ColorPickerToggle = ({ selectedColor, onColorChange }) => {
  const classes = useStyles();

  const [visiblePicker, setVisiblePicker] = useState("primary");

  const handleChromePickerVisibility = (picker) => {
    setVisiblePicker(picker);
  };

  return (
    <div className={classes.colorPickerContainer}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {visiblePicker && (
          <ChromePicker
            color={selectedColor[visiblePicker]}
            onChangeComplete={(color) => onColorChange(visiblePicker, color)}
            disableAlpha
          />
        )}
      </div>
      <div className={classes.colorBoxWrapper}>
        <Box
          title="Colore primario"
          className={`${classes.colorBlock} ${
            visiblePicker === "primary" ? classes.highlightedBorder : ""
          }`}
          bgcolor={selectedColor.primary}
          onClick={() => handleChromePickerVisibility("primary")}
        />
        <Box
          title="Colore secondario"
          className={`${classes.colorBlock} ${
            visiblePicker === "secondary" ? classes.highlightedBorder : ""
          }`}
          bgcolor={selectedColor.secondary}
          onClick={() => handleChromePickerVisibility("secondary")}
        />
      </div>
    </div>
  );
};

ColorPickerToggle.propTypes = {
  selectedColor: PropTypes.objectOf(PropTypes.string),
  onColorChange: PropTypes.func.isRequired,
};

export default ColorPickerToggle;
