import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Container, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import ActionButton from "../../../components/ActionButton";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "20px",
    marginBottom: "20px",
    minHeight: "296px",
  },
  gridContainer: {
    paddingTop: "10px",
  },
  gridElement: {
    padding: "10px 0px",
    maxWidth: "600px",
  },
  paper: {
    padding: "12px",
    boxShadow: theme.shadows[5],
  },
  image: {
    height: 146,
    margin: "auto",
    display: "block",
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

const LogoHeaderTemplate = ({ logoHeader, uploadLogoHeader }) => {
  const theme = useTheme();
  const classes = useStyles();

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("Nessun file selezionato.");
  const [uploadDisabled, setUploadDisabled] = useState(true);

  const selectFile = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      setFile(files[0]);
      setFileName(files[0].name);
      setUploadDisabled(false);
    }
  };

  return (
    <Container maxWidth="md" className={classes.root}>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.gridContainer}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.gridElement}
        >
          <Paper className={classes.paper}>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.image}
            >
              {logoHeader ? (
                <img
                  src={logoHeader}
                  alt="logo header"
                  className={classes.img}
                />
              ) : (
                <div>Carica logo...</div>
              )}
            </Grid>
          </Paper>
        </Grid>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <div>
              <input
                id="fileLogoInput"
                type="file"
                name="file"
                onChange={selectFile}
                style={{ display: "none" }}
              />
              <label htmlFor="fileLogoInput">
                <Button
                  variant="contained"
                  size="medium"
                  style={{
                    color: theme.palette.text.main,
                    backgroundColor: theme.palette.primary.main,
                    margin: "10px",
                  }}
                  component="span"
                >
                  Seleziona file
                </Button>
              </label>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Typography variant="subtitle2">{fileName}</Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <ActionButton
              label="Inserisci immagine"
              disabled={uploadDisabled}
              onClick={() => uploadLogoHeader(file)}
              grayVersion={false}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

LogoHeaderTemplate.propTypes = {
  logoHeader: PropTypes.string.isRequired,
  uploadLogoHeader: PropTypes.func.isRequired,
};

export default LogoHeaderTemplate;
