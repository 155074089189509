import React from "react";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import PostAddIcon from "@material-ui/icons/PostAdd";
import HighlightOff from "@material-ui/icons/HighlightOff";
import RestoreIcon from "@material-ui/icons/Restore";

import LoadingOverlay from "react-loading-overlay";

import SpinnerComponent from "../../../components/SpinnerComponent";
import ErrorDialog from "../../../components/ErrorDialog";
import SelezioneGruppo from "../components/SelezioneGruppo";
import ActionButton from "../../../components/ActionButton";
import ModalSelezioneConsulente from "../components/ModalSelezioneConsulente";
import ModalSelezioneEsercizioCommerciale from "../components/ModalSelezioneEsercizioCommerciale";
import DettaglioManualeButton from "../components/DettaglioManualeButton";

import {
  clearToken,
  setChapterIndex,
  setParagraphIndex,
} from "../../../utils/storage";
import {
  getRevisioniManuali,
  cancellaRevisione,
  getStatoFileWithEsercizio,
  getTotaleRevisioniManuali,
} from "../../../utils/api/revisioni_manuali_api";
import { getGruppi } from "../../../utils/api/gruppi_api";
import { getCapitoliCount } from "../../../utils/api/capitoli_api";
import {
  creaManualePersonalizzato,
  getFlagControlliSemplificatiManualeFromId,
} from "../../../utils/api/modelli_and_manuali_api";

import { withRouter } from "react-router-dom";

import moment from "moment";
import "moment/locale/it";

import PropTypes from "prop-types";
import ModalSelezioneModalita from "../components/ModalSelezioneModalita";
import ModalSelezioneGruppo from "../components/ModalSelezioneGruppo";
import LoadingModal from "../components/LoadingModal";
import BasicTable from "../../../components/BasicTable.js";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
  },
};

const dateFormat = "DD-MM-YYYY HH:mm";

export const Component = withRouter(({ history, location }) => {});

class ManualiPersonalizzatiTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      manuali: [],
      gruppoId: null,
      selectedGruppoId: null,
      consulenteId: null,
      esercizioId: null,
      gruppi: [],
      loading: true,
      pdfloading: false,
      errorDialogVisible: false,
      errorDialogMessage: "",
      manualeBaseId: null,
      showHiddenManuali: false,
      showModalSelezioneConsulente: false,
      showModalSelezioneEsercizio: false,
      showModalSelezioneModalita: false,
      showModalSelezioneGruppo: false,
      isChapterSelectionBlocked: false,
      isControlliSelectionBlocked: false,
      origin: "Manuale",
      loadingModal: false,
      totalPages: 1,
      loadedPage: 0,
      pageSize: 10,
      filter: {
        gruppoId: -1,
        consulenteId: "",
        esercizioId: "",
        esercizioNome: "",
        manualeId: "",
        manualeTitolo: "",
      },
    };
  }

  componentDidMount() {
    let gruppoId = this.props.gruppoId;
    this.setState({
      gruppoId,
    });
    if (this.props.consulenteId === null) {
      // user is admin
      this.fetchGruppi();
    } else {
      this.fetchManuali(gruppoId, this.state.showHiddenManuali);
    }
  }

  handleInvalidToken = () => {
    this.handleError(
      true,
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi."
    );
    clearToken();
    const { path } = this.props.theme;
    window.setTimeout(function () {
      window.location.href = `/${path}`;
    }, 4000);
  };

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  fetchGruppi = () => {
    getGruppi(null, null, 0, 3000, "id")
      .then((result) => {
        let newarray = [{ id: -1, nome: "Tutti i gruppi" }];
        newarray.push(...result);
        let gruppoId = this.state.gruppoId;
        if (gruppoId === null) {
          gruppoId = -1;
        }
        this.setState({
          gruppi: newarray,
          gruppoId,
        });
        this.fetchManuali(gruppoId, this.state.showHiddenManuali);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };

  fetchManuali = (gruppoId, hidden) => {
    let consulenteId = null;
    if (this.props.consulenteId !== null && !this.props.superconsulenteView) {
      consulenteId = this.props.consulenteId; // Admin and superconsulente do not filter manuali by consulenteId
    }

    getTotaleRevisioniManuali(null, hidden, gruppoId, consulenteId)
      .then((countResponse) => {
        getRevisioniManuali(
          null,
          hidden,
          gruppoId,
          consulenteId,
          0,
          this.state.pageSize,
          "id"
        ).then((result) => {
          this.setState({
            loadedPage: 0,
            loading: false,
            manuali: result,
            totalPages:
              countResponse !== 0
                ? Math.ceil(countResponse / this.state.pageSize)
                : 1,
          });
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };

  fetchManualiForResearch = (loadedPage) => {
    const { filter, pageSize } = this.state;
    const isAdmin = this.props.consulenteId === null;
    const isConsulente =
      this.props.consulenteId !== null && !this.props.superconsulenteView;

    const consulenteId = isConsulente
      ? this.props.consulenteId
      : filter.consulenteId;

    let gruppoId = isAdmin
      ? this.state.gruppoId !== -1
        ? this.state.gruppoId
        : filter.gruppoId
      : this.props.gruppoId;

    getTotaleRevisioniManuali(
      null,
      this.state.showHiddenManuali,
      gruppoId,
      consulenteId,
      filter.esercizioId,
      filter.esercizioNome,
      filter.manualeId,
      filter.manualeTitolo
    )
      .then((countResponse) => {
        return getRevisioniManuali(
          null,
          this.state.showHiddenManuali,
          gruppoId,
          consulenteId,
          loadedPage,
          pageSize,
          "id",
          filter.esercizioId,
          filter.esercizioNome,
          filter.manualeId,
          filter.manualeTitolo
        ).then((response) =>
          this.setState({
            loadedPage: 0,
            manuali: response,
            totalPages:
              countResponse !== 0 ? Math.ceil(countResponse / pageSize) : 1,
          })
        );
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };

  fetchManualiForPageChange = (loadedPage) => {
    const { filter, pageSize } = this.state;
    const isAdmin = this.props.consulenteId === null;
    const isConsulente =
      this.props.consulenteId !== null && !this.props.superconsulenteView;

    // const gruppoId = isAdmin ? filter.gruppoId : this.props.gruppoId;
    const consulenteId = isConsulente
      ? this.props.consulenteId
      : filter.consulenteId;

    const effectiveGruppoId = isAdmin ? filter.gruppoId : this.props.gruppoId;
    /*this.state.gruppoId !== null && this.state.gruppoId !== -1
        ? this.state.gruppoId
        : gruppoId;*/

    getRevisioniManuali(
      null,
      this.state.showHiddenManuali,
      effectiveGruppoId,
      consulenteId,
      loadedPage,
      pageSize,
      "id",
      filter.esercizioId,
      filter.esercizioNome,
      filter.manualeId,
      filter.manualeTitolo
    )
      .then((response) => this.setState({ manuali: response }))
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };

  onPageChange = (pageIndex) => {
    this.setState({ loadedPage: pageIndex }, () =>
      this.fetchManualiForPageChange(this.state.loadedPage)
    );
  };

  checkStatus = (revisione, interval) => {
    if (!this.state.readyToDownload) {
      getStatoFileWithEsercizio(
        revisione.id,
        revisione.stato,
        revisione.esercizioCommercialeId
      )
        .then((result) => {
          if (result.stato === "DONE") {
            this.setState({
              readyToDownload: true,
            });
          }
        })
        .catch((error) => {
          this.setState(
            {
              pdfloading: false,
            },
            () => {
              if (!this.state.readyToDownload) {
                clearInterval(this.intervalId);
                this.handleError(
                  true,
                  "Si è verificato un errore: download del PDF non riuscito."
                );
              }
            }
          );
        });
    }
  };

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  downloadPdf = (revisione) => {
    this.setState({
      pdfloading: true,
      readyToDownload: false,
    });
    getStatoFileWithEsercizio(
      revisione.id,
      revisione.stato,
      revisione.esercizioCommercialeId
    )
      .then((result) => {
        if (result.stato === "PROGRESS") {
          let counter = 0;
          this.intervalId = setInterval(() => {
            if (counter < 40) {
              this.checkStatus(revisione, this.intervalId);
              counter++;
            } else {
              clearInterval(this.intervalId);
              this.setState(
                {
                  pdfloading: false,
                },
                () => {
                  this.handleError(
                    true,
                    "Attenzione, timeout della connessione raggiunta"
                  );
                }
              );
            }
            if (this.state.readyToDownload) {
              clearInterval(this.intervalId);
              this.setState(
                {
                  pdfloading: false,
                  //readyToDownload:false,
                },
                () => {
                  const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
                  if (
                    revisione.stato === "BOZZA" ||
                    revisione.stato === "IN_ATTESA_PAGAMENTO"
                  ) {
                    window.location.href =
                      ENDPOINT +
                      "/api/v2/revisioni/pdf_anteprima/" +
                      revisione.id;
                  } else {
                    window.location.href =
                      ENDPOINT + "/api/v2/revisioni/pdf/" + revisione.id;
                  }
                }
              );
            }
          }, 3000);
        } else {
          this.setState(
            {
              pdfloading: false,
            },
            () => {
              const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
              if (
                revisione.stato === "BOZZA" ||
                revisione.stato === "IN_ATTESA_PAGAMENTO"
              ) {
                window.location.href =
                  ENDPOINT + "/api/v2/revisioni/pdf_anteprima/" + revisione.id;
              } else {
                window.location.href =
                  ENDPOINT + "/api/v2/revisioni/pdf/" + revisione.id;
              }
            }
          );
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState(
            {
              pdfloading: false,
            },
            () => {
              this.handleError(
                true,
                "Si è verificato un errore: download del PDF non riuscito."
              );
            }
          );
        }
      });
  };

  onCancellaOrRipristinaButtonClicked = (revisione, e) => {
    this.setState({
      loading: false,
    });
    let hidden = !this.state.showHiddenManuali;
    cancellaRevisione(revisione.id, hidden)
      .then((result) => {
        this.fetchManuali(this.state.gruppoId, this.state.showHiddenManuali);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };

  handleSelectedGruppo = (gruppoId) => {
    this.setState({
      //gruppoId,
      loading: true,
      filter: {
        gruppoId: gruppoId,
        consulenteId: "",
        esercizioId: "",
        esercizioNome: "",
        manualeId: "",
        manualeTitolo: "",
      },
    });
    this.fetchManuali(gruppoId, this.state.showHiddenManuali);
  };

  onHiddenManualiSelection = (e) => {
    let hidden = e.target.value;
    const isAdmin = this.props.consulenteId === null;
    this.setState(
      {
        showHiddenManuali: hidden,
        loading: true,
        filter: {
          gruppoId: isAdmin ? this.state.filter.gruppoId : this.state.gruppoId,
          consulenteId: "",
          esercizioId: "",
          esercizioNome: "",
          manualeId: "",
          manualeTitolo: "",
        },
      },
      () => {
        this.fetchManuali(this.state.filter.gruppoId, hidden);
      }
    );
  };

  onCreaDaManualeButtonClicked = (manuale, event) => {
    let manualeBaseId = manuale === null ? null : manuale.id;
    const isAdmin = this.props.consulenteId === null;
    const isSuperconsulente = this.props.superconsulenteView;
    if (isAdmin) {
      this.setState({
        gruppoId: manuale.gruppoId,
        manualeBaseId,
        showModalSelezioneConsulente: true,
      });
    } else if (isSuperconsulente) {
      this.setState({
        loading: true,
        manualeBaseId,
        showModalSelezioneConsulente: true,
      });
    } else {
      // If user is not admin nor superconsulente:
      let consulenteId = this.props.consulenteId;
      this.setState({
        loading: true,
        consulenteId,
        manualeBaseId: manualeBaseId,
        showModalSelezioneEsercizio: true,
      });
    }
  };

  onCreaManualeButtonClicked = (manuale, event) => {
    let manualeBaseId = manuale === null ? null : manuale.id;
    const isAdmin = this.props.consulenteId === null;
    const isSuperconsulente = this.props.superconsulenteView;
    if (isSuperconsulente || isAdmin) {
      this.setState({
        loading: true,
        manualeBaseId,
        gruppoId: isSuperconsulente ? this.props.gruppoId : -1,
        showModalSelezioneConsulente: true,
      });
    } else {
      // If user is not admin nor superconsulente:
      let consulenteId = this.props.consulenteId;
      this.setState({
        loading: true,
        consulenteId,
        manualeBaseId: manualeBaseId,
        showModalSelezioneEsercizio: true,
      });
    }
  };

  onCloseModalConsulenti = () => {
    this.setState({
      loading: false,
      showModalSelezioneConsulente: false,
    });
  };

  onOpenModalEserciziCommerciali = (consulenteId) => {
    this.setState({
      consulenteId,
      showModalSelezioneConsulente: false,
      showModalSelezioneEsercizio: true,
    });
  };

  onCloseModalEserciziCommerciali = () => {
    this.setState({
      loading: false,
      showModalSelezioneEsercizio: false,
    });
  };

  onProseguiButtonModalitaClicked = (
    modalita,
    esercizioId,
    isControlloSemplificato
  ) => {
    const { history } = this.props;
    this.setState(
      {
        showModalSelezioneModalita: false,
        loadingModal: true,
      },
      () => {
        creaManualePersonalizzato(
          esercizioId,
          this.state.manualeBaseId,
          this.state.consulenteId,
          modalita,
          isControlloSemplificato
        )
          .then((result) => {
            setChapterIndex(0);
            setParagraphIndex(0);
            if (modalita === false)
              history.push(
                "/amministrazione/manuali/nuovomanuale/" +
                  result.manuale.id +
                  "/manuale/false"
              );
            else {
              history.push(
                "/amministrazione/manuali/nuovomanuale/" +
                  result.manuale.id +
                  "/manuale/true"
              );
            }
          })
          .catch((error) => {
            if (error.status === 403) {
              this.handleInvalidToken();
            } else {
              this.setState({
                loadingModal: false,
              });
              this.handleError(true, error.message);
            }
          });
      }
    );
  };

  onOpenModalSelezioneModalita = (consulenteId, esercizioId) => {
    // const { history } = this.props;
    if (this.state.manualeBaseId === null) {
      this.setState({
        consulenteId,
        esercizioId,
        showModalSelezioneEsercizio: false,
        showModalSelezioneModalita: true,
        isChapterSelectionBlocked: false,
        isControlliSelectionBlocked: false,
      });
    } else {
      getCapitoliCount(this.state.manualeBaseId)
        .then((result) => {
          getFlagControlliSemplificatiManualeFromId(this.state.manualeBaseId)
            .then((flag) => {
              if (result === 0) {
                this.setState({
                  consulenteId,
                  esercizioId,
                  showModalSelezioneEsercizio: false,
                  showModalSelezioneModalita: true,
                  isChapterSelectionBlocked: false,
                  isControlliSelectionBlocked: flag,
                });
              } else {
                this.setState(
                  {
                    consulenteId,
                    esercizioId,
                    showModalSelezioneEsercizio: false,
                    showModalSelezioneModalita: true,
                    isChapterSelectionBlocked: true,
                    isControlliSelectionBlocked: flag,
                    //loadingModal: true,
                  },
                  () => {}
                  /* creaManualePersonalizzato(
                    esercizioId,
                    this.state.manualeBaseId,
                    consulenteId,
                    false
                  )
                    .then((result) => {
                      setChapterIndex(0);
                      setParagraphIndex(0);
                      history.push(
                        "/amministrazione/manuali/nuovomanuale/" +
                          result.manuale.id +
                          "/manuale/" +
                          true
                      );
                    })
                    .catch((error) => {
                      if (error.status === 403) {
                        this.handleInvalidToken();
                      } else {
                        this.setState({
                          loading: false,
                          loadingModal: false,
                        });
                        this.handleError(true, error.message);
                      }
                    })*/
                );
              }
            })
            .catch((error) => {
              if (error.status === 403) {
                this.handleInvalidToken();
              } else {
                this.setState({
                  loading: false,
                });
                this.handleError(true, error.message);
              }
            }); //inserire qui la GET del flag del controllo del manuale base
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
            });
            this.handleError(true, error.message);
          }
        });
    }
  };

  onCloseModalModalita = () => {
    this.setState({
      loading: false,
      showModalSelezioneModalita: false,
    });
  };

  onCancelGruppoSelection = () => {
    let currentGruppoId = this.state.gruppoId;
    this.setState({
      showModalSelezioneGruppo: false,
      loading: false,
      selectedGruppoId: currentGruppoId,
    });
  };

  saveSelectedGruppoForModello = (gruppoId) => {
    this.setState({
      selectedGruppoId: gruppoId,
      gruppoId: gruppoId,
    });
  };

  onCreaButtonInModalClicked = (origin) => {
    this.setState({
      showModalSelezioneConsulente: true,
      showModalSelezioneGruppo: false,
    });
  };

  generateFilterColumn = ({
    id,
    placeholder,
    filterValue,
    onChange,
    customAccessor,
  }) => {
    const accessor = customAccessor || ((d) => d[id]);

    return {
      Header: placeholder,
      id: id,
      accessor: accessor,
      filterable: true,
      minWidth: 120,
      Filter: (
        <TextField
          variant="outlined"
          fullWidth
          value={filterValue}
          onChange={(e) => {
            const value = e.target.value;
            onChange(value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => this.fetchManualiForResearch(0)}
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ),
    };
  };

  getCommonColumns = () => {
    return [
      {
        Header: "Numero",
        id: "numero",
        accessor: (row) => row,
        width: 120,
        filterable: false,
        Cell: (props) =>
          this.state.loadedPage === 0
            ? "" + props.index
            : this.state.loadedPage + "" + props.index,
      },
      this.generateFilterColumn({
        id: "id",
        placeholder: "ID manuale",
        filterValue: this.state.filter.manualeId,
        customAccessor: (d) => d.manuale.id,
        onChange: (value) => {
          if (/^\d*$/.test(value)) {
            this.setState((prevState) => ({
              filter: {
                ...prevState.filter,
                manualeId: value,
              },
            }));
          }
        },
      }),
      {
        Header: "ID manuale base",
        id: "manualeBaseId",
        filterable: false,
        accessor: (d) =>
          d.manuale.manualeBaseId === null ? " - " : d.manuale.manualeBaseId,
        width: 124,
      },
      this.generateFilterColumn({
        id: "esercizioCommercialeId",
        placeholder: "ID esercizio",
        filterValue: this.state.filter.esercizioId,
        onChange: (value) => {
          if (/^\d*$/.test(value)) {
            this.setState((prevState) => ({
              filter: {
                ...prevState.filter,
                esercizioId: value,
              },
            }));
          }
        },
      }),
      this.generateFilterColumn({
        id: "esercizioCommercialeNome",
        placeholder: "Nome esercizio",
        filterValue: this.state.filter.esercizioNome,
        onChange: (value) =>
          this.setState((prevState) => ({
            filter: {
              ...prevState.filter,
              esercizioNome: value,
            },
          })),
      }),
      this.generateFilterColumn({
        id: "titolo",
        placeholder: "Titolo",
        filterValue: this.state.filter.manualeTitolo,
        customAccessor: (d) => d.manuale.titolo,
        onChange: (value) =>
          this.setState((prevState) => ({
            filter: {
              ...prevState.filter,
              manualeTitolo: value,
            },
          })),
      }),
      {
        Header: "Motivo Revisione",
        id: "motivoRevisione",
        accessor: (d) => d.motivoRevisione,
        filterable: false,
        minWidth: 150,
      },
      {
        Header: "Stato pubblicazione",
        id: "stato",
        accessor: (d) => d.stato.replace(/_/g, " "),
        filterable: false,
        minWidth: 180,
      },
      {
        Header: "Stato calendario",
        id: "statoCalendario",
        accessor: (d) => d.statoLogDescrizione,
        filterable: false,
        minWidth: 130,
        Cell: (props) => <div>{props.row.statoCalendario}</div>,
      },
      {
        Header: "Indice revisione",
        id: "indiceRevisione",
        accessor: (d) => d.indiceRevisione,
        filterable: false,
        minWidth: 80,
      },
      {
        Header: "Data creazione",
        id: "dataCreazione",
        accessor: (d) => moment(d.manuale.dataCreazione).format(dateFormat),
        filterable: false,
        width: 160,
      },
      {
        Header: "Data aggiornamento manuale",
        id: "dataUltimoAggiornamento",
        accessor: (d) =>
          moment(d.manuale.dataUltimoAggiornamento).format(dateFormat),
        filterable: false,
        width: 224,
      },
      {
        Header: "Data aggiornamento revisione",
        id: "dataRevisione",
        accessor: (d) => moment(d.dataRevisione).format(dateFormat),
        filterable: false,
        width: 224,
      },
      {
        id: "creaManualeBtn",
        Header: "Crea manuale da",
        show: this.state.showHiddenManuali ? false : true,
        accessor: (row) => row,
        filterable: false,
        width: 130,
        Cell: (props) => (
          <IconButton
            size="small"
            disabled={props.value.stato !== "PUBBLICATO"}
            style={{
              color:
                props.value.stato !== "PUBBLICATO"
                  ? this.props.theme.palette.disabled.main
                  : this.props.theme.palette.primary.main,
            }}
            onClick={(e) => {
              this.onCreaDaManualeButtonClicked(props.value.manuale, e);
            }}
          >
            <PostAddIcon />
          </IconButton>
        ),
      },
      {
        id: "modificaButton",
        Header: "Modifica",
        show: this.state.showHiddenManuali ? false : true,
        filterable: false,
        width: 80,
        accessor: (row) => row,
        Cell: (props) => (
          <DettaglioManualeButton
            revisioneManualeId={props.value.id}
            disabled={props.value.stato === "PUBBLICATO"}
          />
        ),
      },
      {
        id: "downloadPdfButton",
        Header: "Scarica",
        show: this.state.showHiddenManuali ? false : true,
        filterable: false,
        width: 80,
        accessor: (row) => row,
        Cell: (props) => (
          <IconButton
            style={{ color: this.props.theme.palette.primary.main }}
            size="small"
            onClick={() => this.downloadPdf(props.value)}
            disabled={false}
          >
            <CloudDownloadIcon />
          </IconButton>
        ),
      },
      {
        id: "cancellaButton",
        Header: this.state.showHiddenManuali ? "Ripristina" : "Elimina",
        accessor: (row) => row,
        filterable: false,
        width: 80,
        Cell: (props) => (
          <IconButton
            style={{ color: this.props.theme.palette.primary.main }}
            size="small"
            onClick={(e) => {
              this.onCancellaOrRipristinaButtonClicked(props.value, e);
            }}
          >
            {this.state.showHiddenManuali ? <RestoreIcon /> : <HighlightOff />}
          </IconButton>
        ),
      },
    ];
  };

  getColumns = () => {
    let columns = this.getCommonColumns();
    let isAdmin =
      !this.props.superconsulenteView && this.props.consulenteId === null;
    let isSuperConsulente = this.props.superconsulenteView;

    if (isAdmin) {
      columns.splice(
        3,
        0,
        {
          id: "gruppoId",
          Header: "ID gruppo",
          accessor: (row) => row.manuale.gruppoId,
          filterable: false,
        },
        this.generateFilterColumn({
          id: "consulenteId",
          placeholder: "ID consulente",
          filterValue: this.state.filter.consulenteId,
          onChange: (value) => {
            if (/^\d*$/.test(value)) {
              this.setState((prevState) => ({
                filter: {
                  ...prevState.filter,
                  consulenteId: value,
                },
              }));
            }
          },
        })
      );
    } else if (isSuperConsulente) {
      columns.splice(
        3,
        0,
        this.generateFilterColumn({
          id: "consulenteId",
          placeholder: "ID consulente",
          filterValue: this.state.filter.consulenteId,
          onChange: (value) => {
            if (/^\d*$/.test(value)) {
              this.setState((prevState) => ({
                filter: {
                  ...prevState.filter,
                  consulenteId: value,
                },
              }));
            }
          },
        })
      );
    }
    return columns;
  };

  render() {
    const columns = this.getColumns();
    const isAdmin = this.props.consulenteId === null;

    return (
      <div style={{ paddingTop: "30px", minHeight: "400px" }}>
        <LoadingOverlay
          active={this.state.pdfloading}
          spinner
          text="Caricamento pdf in corso ..."
        >
          {this.state.loading ? (
            <SpinnerComponent size={24} />
          ) : (
            <div style={styles.mainContainer}>
              {isAdmin ? (
                <SelezioneGruppo
                  gruppoId={this.state.filter.gruppoId}
                  gruppi={this.state.gruppi}
                  disabled={false}
                  description="Selezionare il gruppo su cui filtrare i risultati:"
                  onGruppoSelected={this.handleSelectedGruppo}
                />
              ) : null}
              <div style={{ marginBottom: "30px" }}>
                <Select
                  value={this.state.showHiddenManuali}
                  name="activated"
                  onChange={(e) => {
                    this.onHiddenManualiSelection(e);
                  }}
                >
                  <MenuItem value={false}>Manuali non cancellati</MenuItem>
                  <MenuItem value={true}>Manuali cancellati</MenuItem>
                </Select>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <ActionButton
                  label="CREA MANUALE PERSONALIZZATO"
                  disabled={this.state.loading}
                  onClick={(e) => {
                    this.onCreaManualeButtonClicked(null, e);
                  }}
                  grayVersion={false}
                />
              </div>
              <BasicTable
                data={this.state.manuali}
                columns={columns}
                filterable={false}
                resizable={true}
                sortable={false}
                defaultPageSize={this.state.pageSize}
                page={this.state.loadedPage}
                pages={this.state.totalPages}
                onPageChange={this.onPageChange}
              />
            </div>
          )}
        </LoadingOverlay>
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
        {this.state.showModalSelezioneConsulente && (
          <ModalSelezioneConsulente
            isModalOpen={this.state.showModalSelezioneConsulente}
            openEserciziModal={this.onOpenModalEserciziCommerciali}
            gruppoId={this.state.gruppoId}
            handleError={this.handleError}
            handleInvalidToken={this.handleInvalidToken}
            closeModal={this.onCloseModalConsulenti}
          />
        )}
        {this.state.showModalSelezioneEsercizio && (
          <ModalSelezioneEsercizioCommerciale
            idConsulente={this.state.consulenteId}
            gruppoId={this.state.gruppoId}
            manualeBaseId={this.state.manualeBaseId}
            isModalOpen={this.state.showModalSelezioneEsercizio}
            handleError={this.handleError}
            handleInvalidToken={this.handleInvalidToken}
            closeModal={this.onCloseModalEserciziCommerciali}
            openModalitaModal={this.onOpenModalSelezioneModalita}
          />
        )}
        {this.state.showModalSelezioneModalita && (
          <ModalSelezioneModalita
            idConsulente={this.state.consulenteId}
            selectedEsercizio={this.state.esercizioId}
            gruppoId={this.state.gruppoId}
            manualeBaseId={this.state.manualeBaseId}
            open={this.state.showModalSelezioneModalita}
            handleError={this.handleError}
            handleInvalidToken={this.handleInvalidToken}
            onClose={this.onCloseModalModalita}
            onProseguiButton={this.onProseguiButtonModalitaClicked}
            isChapterSelectionBlocked={this.state.isChapterSelectionBlocked}
            isControlliSelectionBlocked={this.state.isControlliSelectionBlocked}
          />
        )}
        {this.state.showModalSelezioneGruppo && (
          <ModalSelezioneGruppo
            gruppoId={this.state.gruppoId}
            gruppi={this.state.gruppi}
            open={this.state.showModalSelezioneGruppo}
            onCancel={this.onCancelGruppoSelection}
            onGruppoSelected={this.saveSelectedGruppoForModello}
            onCreaButtonClicked={this.onCreaButtonInModalClicked}
            origin={this.state.origin}
          />
        )}
        {this.state.loadingModal && (
          <LoadingModal
            open={this.state.loadingModal}
            testo={"Generazione manuale in corso..."}
          />
        )}
      </div>
    );
  }
}

ManualiPersonalizzatiTable.propTypes = {
  consulenteId: PropTypes.number,
  gruppoId: PropTypes.number,
  superconsulenteView: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(ManualiPersonalizzatiTable);
