import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ErrorDialog from "../components/ErrorDialog";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    textAlign: "center",
    padding: theme.spacing(4),
  },
}));

const ConfermaRegistrazione = ({ activationKey }) => {
  const classes = useStyles();

  const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
  const url = ENDPOINT + `/api/account/conferma_registrazione/${activationKey}`;

  const [errorDialog, setErrorDialog] = useState({
    isVisible: false,
    title: "",
    message: "",
  });

  useEffect(() => {
    let isMounted = true;
    let debounceTimer;

    const fetchConfirmation = () => {
      fetch(url, {
        method: "GET",
      })
        .then((response) => {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 404) {
            throw new Error(response.status.toString());
          } else {
            throw new Error(`HTTP error ${response.status}`);
          }
        })
        .catch((error) => {
          if (isMounted) {
            if (error.message === "404") {
              setErrorDialog({
                isVisible: true,
                title: "Attenzione",
                message: "Registrazione già confermata.",
              });
            } else if (error.message === "Failed to fetch") {
              setErrorDialog({
                isVisible: true,
                title: "Errore",
                message:
                  "Servizio temporaneamente non disponibile. Riprovare più tardi.",
              });
            } else {
              setErrorDialog({
                isVisible: true,
                title: "Errore",
                message: `Si è verifivato un errore: ${error.message}`,
              });
            }
          }
        });
    };

    debounceTimer = setTimeout(fetchConfirmation, 300);

    return () => {
      isMounted = false;
      clearTimeout(debounceTimer);
    };
  }, [url]);

  const onCloseErrorDialog = () =>
    setErrorDialog((prev) => ({ ...prev, isVisible: false }));

  return (
    <div className={classes.mainContainer}>
      <Typography variant="h4">
        Registrazione confermata con successo.
      </Typography>

      <ErrorDialog
        open={errorDialog.isVisible}
        title={errorDialog.title}
        message={errorDialog.message}
        onCloseButtonClicked={onCloseErrorDialog}
      />
    </div>
  );
};

ConfermaRegistrazione.propTypes = {
  activationKey: PropTypes.string.isRequired,
};

export default ConfermaRegistrazione;
