import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import PropTypes from "prop-types";

import FormRegistraResponsabilePerConsulente from "./FormRegistraResponsabilePerConsulente";
import FormRegistraResponsabileSicurezza from "./FormRegistraResponsabileSicurezza";
import FormModificaResponsabileSicurezza from "./FormModificaResponsabileSicurezza";
import EsercizioXResponsabileComponent from "./EsercizioXResponsabileComponent";

import theme from "../../../theme.js";
import { withStyles } from "@material-ui/styles";

const styleToUse = (theme) => ({
  mainContainer: {
    textAlign: "center",
    color: "black",
    paddingTop: "26px",
    paddingBottom: "26px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  paper: {
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px " +
      theme.palette.primary.main,
  },
  tabsbar: {
    color: "black",
    boxShadow:
      "0px 1px 1px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
  },
  registrationMessage: {
    paddingTop: "10px",
    color: theme.palette.primary.main,
    paddingBottom: "10px",
  },
});

class DettaglioResponsabileSicurezza extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      secondTabEnabled: true,
      showRegistrationForm: true,
      showRegistrationMessage: false,
      showChangesSavedMessage: false,
    };
  }

  componentDidMount() {
    if (this.props.responsabileId === "nuovo") {
      this.setState({
        secondTabEnabled: false,
        showRegistrationForm: true,
      });
    } else {
      this.setState({
        secondTabEnabled: true,
        showRegistrationForm: false,
      });
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      tabValue: newValue,
    });
  };

  onChangesSaved = () => {
    //this.props.onClose();
    this.setState({
      showChangesSavedMessage: true,
    });
  };

  onRecordSaved = () => {
    this.setState({
      secondTabEnabled: false,
      showRegistrationMessage: true,
    });
  };

  onFormModified = () => {
    this.setState({
      showRegistrationMessage: false,
      showChangesSavedMessage: false,
    });
  };

  render() {
    const isConsulente =
      this.props.consulenteView && !this.props.superconsulenteView;
    const showRegistrationForm = this.state.showRegistrationForm;
    const secondTabDisabled = !this.state.secondTabEnabled;
    const title = showRegistrationForm
      ? "Nuovo responsabile sicurezza"
      : "Dettagli responsabile sicurezza";
    const { classes } = this.props;
    return (
      <div className={classes.mainContainer}>
        <Typography variant="h4">{title}</Typography>
        {/* { isConsulente ? 
                <Paper style={styles.paper} >
                    <Box style={{ paddingTop:'20px' }} >
                        <Typography 
                            variant="body1" 
                            style={{
                                color: theme.palette.primary.main,
                                visibility: this.state.showChangesSavedMessage ? 'inherit' : 'hidden'
                            }}
                        >
                        Dati aggiornati con successo.
                        </Typography>
                    </Box>
                    { showRegistrationForm ? 
                        <Fragment>
                            <Typography 
                                variant="body1" 
                                style={{
                                    color: theme.palette.primary.main,
                                    visibility: this.state.showRegistrationMessage ? 'inherit' : 'hidden'
                                }}
                            >
                            Registrazione effettuata con successo.
                            </Typography>
                            <FormRegistraResponsabilePerConsulente 
                                gruppoId={this.props.gruppoId}
                                consulenteId={this.props.consulenteId}
                                onFormModified={this.onFormModified} 
                                onFormSubmitted={this.onRecordSaved} 
                            />
                        </Fragment>
                    :
                        <FormModificaResponsabileSicurezza 
                            responsabileId={this.props.responsabileId}
                            consulenteId={this.props.consulenteId}
                            gruppoId={this.props.gruppoId}
                            superconsulenteView={this.props.superconsulenteView}
                            consulenteView={this.props.consulenteView}
                            onFormModified={this.onFormModified} 
                            onFormSubmitted={this.onChangesSaved}
                        />
                    }
                </Paper>
                : */}
        <Paper className={classes.paper}>
          <Tabs
            value={this.state.tabValue}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            variant="fullWidth"
            className={classes.tabsbar}
            //centered
          >
            <Tab label="Dati responsabile sicurezza" value={0} />
            <Tab
              label="Esercizi commerciali"
              value={1}
              disabled={secondTabDisabled}
              style={{
                backgroundColor: secondTabDisabled
                  ? theme.palette.disabled.main
                  : "white",
              }}
            />
          </Tabs>
          <div hidden={this.state.tabValue !== 0}>
            <Box style={{ paddingTop: "20px" }}>
              {this.state.tabValue === 0 && showRegistrationForm ? (
                <Fragment>
                  <Typography
                    variant="body1"
                    style={{
                      color: "green",
                      visibility: this.state.showRegistrationMessage
                        ? "inherit"
                        : "hidden",
                    }}
                  >
                    Registrazione effettuata con successo.
                  </Typography>
                  {isConsulente ? (
                    <FormRegistraResponsabilePerConsulente
                      theme={this.props.theme}
                      gruppoId={this.props.gruppoId}
                      consulenteId={this.props.consulenteId}
                      onFormModified={this.onFormModified}
                      onFormSubmitted={this.onRecordSaved}
                    />
                  ) : (
                    <FormRegistraResponsabileSicurezza
                      theme={this.props.theme}
                      gruppoId={this.props.gruppoId}
                      consulenteView={this.props.consulenteView}
                      superconsulenteView={this.props.superconsulenteView}
                      onFormModified={this.onFormModified}
                      onFormSubmitted={this.onRecordSaved}
                    />
                  )}
                </Fragment>
              ) : null}
              {this.state.tabValue === 0 && !showRegistrationForm ? (
                <Fragment>
                  <Typography
                    variant="body1"
                    style={{
                      color: "green",
                      visibility: this.state.showChangesSavedMessage
                        ? "inherit"
                        : "hidden",
                    }}
                  >
                    Dati aggiornati con successo.
                  </Typography>
                  <FormModificaResponsabileSicurezza
                    theme={this.props.theme}
                    responsabileId={this.props.responsabileId}
                    consulenteId={this.props.consulenteId}
                    gruppoId={this.props.gruppoId}
                    superconsulenteView={this.props.superconsulenteView}
                    consulenteView={this.props.consulenteView}
                    onFormModified={this.onFormModified}
                    onFormSubmitted={this.onChangesSaved}
                  />
                </Fragment>
              ) : null}
            </Box>
          </div>
          <div hidden={this.state.tabValue !== 1}>
            <Box style={{ paddingTop: "44px" }}>
              {this.state.tabValue === 1 && (
                <EsercizioXResponsabileComponent
                  theme={this.props.theme}
                  responsabileId={this.props.responsabileId}
                  consulenteId={this.props.consulenteId}
                  gruppoId={this.props.gruppoId}
                  consulenteView={this.props.consulenteView}
                  superconsulenteView={this.props.superconsulenteView}
                />
              )}
            </Box>
          </div>
        </Paper>
        {/* } */}
      </div>
    );
  }
}

DettaglioResponsabileSicurezza.propTypes = {
  responsabileId: PropTypes.string.isRequired,
  consulenteId: PropTypes.number,
  gruppoId: PropTypes.number,
  consulenteView: PropTypes.bool.isRequired,
  superconsulenteView: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
};
export default withStyles(styleToUse, { withTheme: true })(
  DettaglioResponsabileSicurezza
);
