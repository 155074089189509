import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import GoToButton from "../components/GoToButton";
import CustomTable from "../components/CustomTable";
import EditRecordButton from "../components/EditRecordButton";

import PropTypes from 'prop-types';

const styles = {
    root: {
        //border: '1px solid #e0e0e0',
        //borderRadius: '6px',
        margin: '30px 0px',
        padding: '10px 10px',
    },
    buttonDiv: {
        paddingTop:'10px', 
        paddingBottom:'10px', 
        textAlign:'right'
    }, 
    formcontrol: {
        marginBottom: '30px',
        width: '120px',
    },
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: '146px',
            width: '120px',
        },
    },
};

export default function TabellaConsulentiGruppo(props) {

    const columns = [
        {
            Header: 'ID',
            id: 'id',
            accessor: d => d.id,
            width: 80,
        },
        {
            Header: 'Email',
            id: 'email',
            accessor: d => d.email,
            minWidth: 90,
        },
        {
            Header: 'Nome',
            id: 'nome',
            accessor: d => d.nome,
            minWidth: 90,
        },
        {
            Header: 'Cognome',
            id: 'cognome',
            accessor: d => d.cognome,
            minWidth: 90,
        },
        {
            Header: 'Telefono',
            id: 'telefono',
            accessor: d => d.telefono,
            minWidth: 90,
        },
        {
            Header: 'Registrazione confermata',
            id: 'validated',
            accessor: d => d.validated ? "Sì" : "No",
            filterable: false,
            width: 224,
        },
        {
            Header: 'Superconsulente',
            id: 'superconsulente',
            accessor: row => row,
            sortable: false,
            filterable: false,
            resizable: false,
            width: 148,
            Cell: propCell =>
            <FormControl
                style={styles.formcontrol}
            >
                <Select
                  value={propCell.value.superconsulente}
                  name="superconsulente"
                  onChange={ (event) => { props.onSelect(propCell.value, event) }}
                  MenuProps={MenuProps}
                >
                    <MenuItem value={true}>Sì</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                </Select>
            </FormControl>
        },
        {
            id: 'editConsulenteBtn',
            Header: 'Modifica',
            accessor: row => row,
            sortable: false,
            filterable: false,
            resizable: false,
            width: 80,
            Cell: propsCell =>
                <EditRecordButton 
                    recordId={propsCell.value.id} 
                    disabled={false}
                    path={"/amministrazione/consulenti/" + propsCell.value.id}
                />
          },
    ];

    return (
        <Container maxWidth={false} style={styles.root}>
            <Typography variant="h5" >
                Elenco dei consulenti appartenenti al gruppo
            </Typography>
            <div style={styles.buttonDiv}>
                <GoToButton
                    showAlert={props.changesNotSaved} 
                    path={"/amministrazione/gruppi/" + props.gruppoId + "/consulenti/nuovo"}
                    label="Registra nuovo consulente"
                    disabled={false}
                    grayVersion={false}
                />
            </div>
            <CustomTable 
                columns={columns}
                records={props.consulenti}
                loading={false}
                filterable={true}
                resizable={true}
                sortable={true}
                defaultPageSize={5}
            />
        </Container>
    );
}

TabellaConsulentiGruppo.propTypes = {
    gruppoId: PropTypes.number.isRequired,
    consulenti: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    changesNotSaved: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
}