import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "16px 32px",
    marginTop: theme.spacing(2),
  },
  formLabel: {
    marginBottom: "20px",
    color: theme.palette.primary.main,
  },
}));

const CheckboxSection = ({
  notificheAggregate,
  multiselect,
  idOperatoreObbligatorio,
  onChange,
  disabledForm,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Grid container direction="column">
        <FormControl>
          <FormLabel className={classes.formLabel}>
            Impostazioni App mobile
          </FormLabel>
          <FormGroup>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Grid container direction="column">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="notificheAggregate"
                        checked={notificheAggregate}
                        onChange={onChange}
                        color="primary"
                        disabled={disabledForm}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Invio di notifiche push aggregate
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="multiselect"
                        checked={multiselect}
                        onChange={onChange}
                        color="primary"
                        disabled={disabledForm}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Multiselect sui controlli da validare
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container direction="column">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="idOperatoreObbligatorio"
                        checked={idOperatoreObbligatorio}
                        onChange={onChange}
                        color="primary"
                        disabled={disabledForm}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        ID operatore obbligatorio per effettuare controlli
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </FormGroup>
        </FormControl>
      </Grid>
    </Paper>
  );
};

CheckboxSection.propTypes = {
  notificheAggregate: PropTypes.bool.isRequired,
  multiselect: PropTypes.bool.isRequired,
  idOperatoreObbligatorio: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabledForm: PropTypes.bool.isRequired,
};

export default CheckboxSection;
