import React, { useState } from "react";
import { Button, Container, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import ActionButton from "../../../components/ActionButton";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "20px",
    marginBottom: "20px",
    minHeight: "296px",
  },
  gridContainer: {
    paddingTop: "10px",
  },
  gridElement: {
    padding: "10px 0px",
    maxWidth: "600px",
  },
  paper: {
    padding: "12px",
    boxShadow: theme.shadows[5],
  },
  image: {
    //width: 146,
    height: 146,
    margin: "auto",
    display: "block",
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

export default function LoghiTemplate({
  loghiIds,
  accept,
  onSubmit,
  disabled,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("Nessun file selezionato.");
  const [uploadDisabled, setUploadDisabled] = useState(true);

  const selectFile = (event) => {
    let files = event.target.files;
    if (files.length > 0) {
      setFile(files[0]);
      setFileName(files[0].name);
      setUploadDisabled(false);
    } else {
      setFile(null);
      setFileName("Nessun file selezionato.");
      setUploadDisabled(true);
    }
  };

  return (
    <Container maxWidth="md" className={classes.root}>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.gridContainer}
      >
        {loghiIds.map((id, index) => {
          return (
            <Grid
              key={index}
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.gridElement}
            >
              <Paper className={classes.paper}>
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    <div className={classes.image}>
                      {id ? (
                        <img
                          className={classes.img}
                          alt="logo_brand"
                          src={id}
                        />
                      ) : (
                        <div>Carica il tuo logo...</div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          );
        })}
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <div>
              <input
                id="fileInput"
                type="file"
                name="file"
                accept={accept}
                //  accept="image/*"
                style={{ display: "none" }}
                onChange={selectFile}
                // disabled={this.props.disabled || this.props.loading}
              />
              <label htmlFor="fileInput">
                <Button
                  variant="contained"
                  size="medium"
                  style={{
                    color: theme.palette.text.main,
                    backgroundColor: disabled
                      ? theme.palette.disabled.main
                      : theme.palette.primary.main,
                    margin: "10px",
                  }}
                  component="span"
                >
                  Seleziona file
                </Button>
              </label>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Typography variant="subtitle2" className={classes.text}>
              {fileName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <ActionButton
              label="Inserisci immagine"
              disabled={uploadDisabled}
              onClick={(e) => {
                onSubmit(file, e);
              }}
              grayVersion={false}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

LoghiTemplate.propTypes = {
  loghiIds: PropTypes.arrayOf(PropTypes.string),
  onSubmit: PropTypes.func.isRequired,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
};

LoghiTemplate.defaultProps = {
  accept: "image/*",
};
