import React from "react";
import {
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

import ActionButton from "../../../components/ActionButton";

import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #e0e0e0",
    borderRadius: "6px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  texts: {
    marginBottom: "20px",
  },
  sectionContainer: {
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "320px",
  },
  successMessage: {
    textAlign: "center",
    color: theme.palette.success.main,
  },
}));

export default function FormModificaGruppo(props) {
  const classes = useStyles();
  return (
    <Container maxWidth="md" className={classes.root}>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.sectionContainer}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="h5" className={classes.texts}>
            Dati del gruppo
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ paddingBottom: "10px" }}
              >
                <TextField
                  label="ID gruppo"
                  name="id"
                  disabled
                  value={props.gruppo.id === null ? "" : props.gruppo.id}
                  InputProps={{
                    readOnly: true,
                  }}
                  className={classes.textfield}
                />
                <TextField
                  label="Nome del brand"
                  disabled
                  value={props.gruppo.brand.nome}
                  InputProps={{
                    readOnly: true,
                  }}
                  className={classes.textfield}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ paddingBottom: "10px" }}
              >
                <TextField
                  label="Nome gruppo"
                  name="nome"
                  value={props.gruppo.nome}
                  onChange={props.onChange}
                  disabled={props.disabled}
                  className={classes.textfield}
                />
              </Grid>
              <TextField
                label="Costo unitario base"
                name="costoUnitario"
                value={props.gruppo.costoUnitario || ""}
                onChange={props.onChange}
                disabled={props.disabled}
                className={classes.textfield}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={4} />
        <Grid item xs={4} className={classes.successMessage}>
          {props.showSuccessMessage && (
            <Typography>Modifiche salvate con successo.</Typography>
          )}
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <ActionButton
            label="Salva modifiche"
            disabled={
              props.formChangesSaved ||
              props.disabled ||
              props.gruppo.nome.length === 0
            }
            onClick={props.onSubmit}
            grayVersion={false}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

FormModificaGruppo.propTypes = {
  gruppo: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  showSuccessMessage: PropTypes.bool.isRequired,
  formChangesSaved: PropTypes.bool.isRequired,
};
