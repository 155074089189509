export const paletteColors = [
  "#D32F2F", // Red
  "#E17414", // Orange
  "#FBC02D", // Yellow
  "#27AE60", // Green
  "#1976D2", // Blue
  "#7B1FA2", // Purple
  "#C2185B", // Pink
  "#00796B", // Teal
  "#303F9F", // Indigo
  "#512DA8", // Deep Purple
];
