import React from "react";
import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import Moment from "moment";
import validator from "validator";

import SpinnerComponent from "../../../components/SpinnerComponent";
import ErrorDialog from "../../../components/ErrorDialog";
import IndietroButton from "../../../components/IndietroButton";
import { clearToken } from "../../../utils/storage";
import GiorniChiusuraEsercizio from "./GiorniChiusuraEsercizio";
import SelezioneGruppo from "../components/SelezioneGruppo";
import FormPagamento from "./FormPagamento";
import { registerEsercizioAsAdmin } from "../../../utils/api/esercizi_commerciali_api";
import { getGruppi } from "../../../utils/api/gruppi_api";

import PropTypes from "prop-types";

const styles = (theme) => ({
  mainContainer: {
    textAlign: "center",
    color: "black",
    paddingTop: "10px",
  },
  sectionContainer: {
    border: `1px solid ${theme.palette.disabled.main}`,
    borderRadius: "6px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  selgruppoContainer: {
    border: `1px solid ${theme.palette.disabled.main}`,
    borderRadius: "6px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    width: "100%",
  },
  gridContainer: {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingBottom: "10px",
  },
  fieldsContainer: {
    marginTop: "10px",
    marginBottom: "10px",
    padding: "10px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "250px",
  },
});

class NuovoEsercizioCommerciale extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      record: {
        esercizio: {
          id: null,
          nome: null,
          ragioneSociale: null,
          partitaIva: null,
          codiceAteco: null,
          telefono: null,
          nomeGestore: null,
          cognomeGestore: null,
          emailGestore: null,
          telefonoGestore: null,
          indirizzoSede: null,
          numeroSede: null,
          cittaSede: null,
          provinciaSede: null,
          codiceSdi: null,
          pec: null,
          gruppoId: null,
        },
        storicoPricingDTO: {
          moltiplicatore: 1,
          anniValidita: 1,
          valoreIva: "",
          imponibile: "",
          totaleLordo: "",
          modalita: "PAYPAL",
          consulenteId: this.props.consulenteId,
          esercizioCommercialeId: "",
          dataAggiornamento: "",
        },
        giorniChiusura: [],
      },
      validationErrors: {
        nome: "",
        ragioneSociale: "",
        emailGestore: "",
      },
      gruppi: [],
      costoUnitario: "",
      errorDialogVisible: false,
      errorDialogMessage: "",
      loading: true,
      notfoundRecord: false,
      saveButtonEnabled: false,
      disabledButtons: false,
      saveMessageVisible: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchGruppi();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchGruppi = () => {
    this.setState({
      loading: true,
    });
    getGruppi(null, null, 0, 1000, "id")
      .then((result) => {
        if (this._isMounted) {
          let record = this.state.record;
          let esercizio = record.esercizio;
          let gruppoId = esercizio.gruppoId;

          const defaultGruppo = result.find(
            (gruppo) => gruppo.nome === "BS-FOOD"
          );

          if (!gruppoId && defaultGruppo) {
            gruppoId = defaultGruppo.id;
            this.setState({ costoUnitario: defaultGruppo.costoUnitario });
          }

          esercizio.gruppoId = gruppoId;
          record.esercizio = esercizio;
          this.setState({
            loading: false,
            gruppi: result,
            record,
          });
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
            });
            this.handleError(true, error.message);
          }
        }
      });
  };

  handleInvalidToken = () => {
    this.handleError(
      true,
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi."
    );
    clearToken();
    const { path } = this.props.theme;
    window.setTimeout(function () {
      window.location.href = "/" + path;
    }, 4000);
  };

  updateParentState = (data) => {
    let record = this.state.record;
    record.giorniChiusura = data;
    this.setState({
      record: record,
      saveButtonEnabled: true,
    });
  };

  validateForm = () => {
    let data = this.state.record.esercizio;
    Object.keys(data).forEach((key) => {
      this.validateField(key, data[key]);
    });
    //Return false if there are validation errors:
    let valid = true;
    let validationErrors = this.state.validationErrors;
    Object.values(validationErrors).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  validateField = (key, value) => {
    let validationErrors = this.state.validationErrors;
    switch (key) {
      case "nome":
        if (value === null || value.length === 0) {
          validationErrors.nome = "Inserire nome";
        } else {
          validationErrors.nome = "";
        }
        break;
      case "ragioneSociale":
        if (value === null || value.length === 0) {
          validationErrors.ragioneSociale = "Inserire ragione sociale";
        } else {
          validationErrors.ragioneSociale = "";
        }
        break;
      case "emailGestore":
        if (value === null || value.length === 0) {
          validationErrors.emailGestore =
            "Inserire indirizzo email del titolare";
        } else {
          if (validator.isEmail(value)) {
            validationErrors.emailGestore = "";
          } else {
            validationErrors.emailGestore =
              "L'indirizzo email inserito non è valido";
          }
        }
        break;
      default:
        break;
    }
    this.setState({
      validationErrors,
    });
  };

  handleChange = (event) => {
    this.handleFormChange(event.target.name, event.target.value);
  };

  handleChangeAbbonamento = (value, fielName) => {
    if (!this.state.saveButtonEnabled) {
      this.setState({
        saveButtonEnabled: true,
      });
    }
    let data = this.state.record;
    data.storicoPricingDTO[fielName] = value;
    this.setState({
      record: data,
    });
  };

  handleSelectedGruppo = (gruppoId) => {
    this.handleFormChange("gruppoId", gruppoId);

    const selectedGruppo = this.state.gruppi.find(
      (gruppo) => gruppo.id === gruppoId
    );

    if (selectedGruppo) {
      this.setState({ costoUnitario: selectedGruppo.costoUnitario });
    }
  };

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };

  handleFormChange = (fielName, value) => {
    if (!this.state.saveButtonEnabled) {
      this.setState({
        saveButtonEnabled: true,
      });
    }
    let data = this.state.record;
    data.esercizio[fielName] = value;
    this.setState({
      record: data,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.validateForm()) return;

    const data = {
      ...this.state.record,
      storicoPricingDTO: {
        ...this.state.record.storicoPricingDTO,
        dataAggiornamento: null,
      },
    };

    this.setState({
      loading: true,
    });
    registerEsercizioAsAdmin(data)
      .then((_result) => {
        this.setState({
          saveButtonEnabled: false,
          disabledButtons: true,
          saveMessageVisible: true,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      })
      .finally(() => this.setState({ loading: false }));
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  render() {
    const { classes } = this.props;
    Moment.locale("it-IT");
    const disabledSubmitbutton =
      this.state.loading ||
      !this.state.saveButtonEnabled ||
      this.state.saveMessageVisible;
    let textFieldsDisabled = this.state.saveMessageVisible;

    return (
      <div className={classes.mainContainer}>
        <Typography variant="h4">Nuovo esercizio commerciale</Typography>
        <Grid
          container
          justify="center"
          style={{ marginTop: "26px", marginBottom: "26px" }}
        >
          <div style={{ minHeight: "500px" }}>
            {this.state.loading ? (
              <SpinnerComponent size={24} />
            ) : (
              <form autoComplete="off" onSubmit={this.handleSubmit}>
                <Container
                  style={{ width: "100%", paddingLeft: 0, paddingRight: 0 }}
                >
                  <Grid container justify="center" alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className={classes.sectionContainer}
                    >
                      <Grid container className={classes.fieldsContainer}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            className={classes.gridContainer}
                          >
                            <TextField
                              label="Nome*"
                              defaultValue={
                                this.state.record.esercizio.nome === null
                                  ? ""
                                  : this.state.record.esercizio.nome
                              }
                              name="nome"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                              helperText={this.state.validationErrors.nome}
                              error={
                                this.state.validationErrors.nome.length > 0
                              }
                            />
                            <TextField
                              label="Ragione sociale*"
                              defaultValue={
                                this.state.record.esercizio.ragioneSociale ===
                                null
                                  ? ""
                                  : this.state.record.esercizio.ragioneSociale
                              }
                              name="ragioneSociale"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                              helperText={
                                this.state.validationErrors.ragioneSociale
                              }
                              error={
                                this.state.validationErrors.ragioneSociale
                                  .length > 0
                              }
                            />
                            <TextField
                              label="Partita IVA"
                              defaultValue={
                                this.state.record.esercizio.partitaIva === null
                                  ? ""
                                  : this.state.record.esercizio.partitaIva
                              }
                              name="partitaIva"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="Codice ATECO"
                              defaultValue={
                                this.state.record.esercizio.codiceAteco === null
                                  ? ""
                                  : this.state.record.esercizio.codiceAteco
                              }
                              name="codiceAteco"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="Telefono"
                              defaultValue={
                                this.state.record.esercizio.telefono === null
                                  ? ""
                                  : this.state.record.esercizio.telefono
                              }
                              name="telefono"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="Nome titolare"
                              defaultValue={
                                this.state.record.esercizio.nomeGestore === null
                                  ? ""
                                  : this.state.record.esercizio.nomeGestore
                              }
                              name="nomeGestore"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="Cognome titolare"
                              defaultValue={
                                this.state.record.esercizio.cognomeGestore ===
                                null
                                  ? ""
                                  : this.state.record.esercizio.cognomeGestore
                              }
                              name="cognomeGestore"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="Email titolare*"
                              defaultValue={
                                this.state.record.esercizio.emailGestore ===
                                null
                                  ? ""
                                  : this.state.record.esercizio.emailGestore
                              }
                              name="emailGestore"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                              helperText={
                                this.state.validationErrors.emailGestore
                              }
                              error={
                                this.state.validationErrors.emailGestore
                                  .length > 0
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            className={classes.gridContainer}
                          >
                            <TextField
                              label="Telefono titolare"
                              defaultValue={
                                this.state.record.esercizio.telefonoGestore ===
                                null
                                  ? ""
                                  : this.state.record.esercizio.telefonoGestore
                              }
                              name="telefonoGestore"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="Indirizzo"
                              defaultValue={
                                this.state.record.esercizio.indirizzoSede ===
                                null
                                  ? ""
                                  : this.state.record.esercizio.indirizzoSede
                              }
                              name="indirizzoSede"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="Numero civico"
                              defaultValue={
                                this.state.record.esercizio.numeroSede === null
                                  ? ""
                                  : this.state.record.esercizio.numeroSede
                              }
                              name="numeroSede"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />

                            <TextField
                              label="Città"
                              defaultValue={
                                this.state.record.esercizio.cittaSede === null
                                  ? ""
                                  : this.state.record.esercizio.cittaSede
                              }
                              name="cittaSede"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="Provincia"
                              defaultValue={
                                this.state.record.esercizio.provinciaSede ===
                                null
                                  ? ""
                                  : this.state.record.esercizio.provinciaSede
                              }
                              name="provinciaSede"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="Codice SDI"
                              defaultValue={
                                this.state.record.esercizio.codiceSdi === null
                                  ? ""
                                  : this.state.record.esercizio.codiceSdi
                              }
                              name="codiceSdi"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                            <TextField
                              label="PEC"
                              defaultValue={
                                this.state.record.esercizio.pec === null
                                  ? ""
                                  : this.state.record.esercizio.pec
                              }
                              name="pec"
                              onChange={this.handleChange}
                              className={classes.textfield}
                              disabled={textFieldsDisabled}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
                <Container className={classes.selgruppoContainer}>
                  <Typography variant="h6" style={styles.text}>
                    Dati gruppo
                  </Typography>
                  <SelezioneGruppo
                    gruppoId={this.state.record.esercizio.gruppoId}
                    gruppi={this.state.gruppi}
                    disabled={textFieldsDisabled}
                    description="Scegliere il gruppo di appartenenza del nuovo esercizio commerciale."
                    onGruppoSelected={this.handleSelectedGruppo}
                  />
                </Container>
                <FormPagamento
                  costoUnitario={this.state.costoUnitario}
                  abbonamentoData={this.state.record.storicoPricingDTO}
                  handleChangeAbbonamento={this.handleChangeAbbonamento}
                />
                <GiorniChiusuraEsercizio
                  esercizioId={this.state.record.esercizio.id}
                  giorniChiusura={this.state.record.giorniChiusura}
                  updateParentState={this.updateParentState}
                  disabledButtons={this.state.disabledButtons}
                  theme={this.props.theme}
                />
                <Grid container justify="center" style={{ paddingTop: "10px" }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ textAlign: "center" }}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        color: "green",
                        visibility: this.state.saveMessageVisible
                          ? "inherit"
                          : "hidden",
                      }}
                    >
                      Nuovo esercizio commerciale registrato con successo.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ textAlign: "right" }}
                  >
                    <IndietroButton isDisabled={this.state.loading} />
                    <Button
                      type="submit"
                      onClick={this.handleSubmit}
                      variant="contained"
                      size="medium"
                      disabled={disabledSubmitbutton}
                      style={{
                        color: disabledSubmitbutton
                          ? this.props.theme.palette.text.disabled
                          : this.props.theme.palette.text.main,
                        margin: "10px",
                        backgroundColor: disabledSubmitbutton
                          ? this.props.theme.palette.disabled.main
                          : this.props.theme.palette.primary.main,
                      }}
                    >
                      Salva
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </div>
        </Grid>

        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        ></ErrorDialog>
      </div>
    );
  }
}

NuovoEsercizioCommerciale.propTypes = {
  consulenteId: PropTypes.number,
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NuovoEsercizioCommerciale);
