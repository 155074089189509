import React from "react";
import Container from '@material-ui/core/Container';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';

import ActionButton from "../../../components/ActionButton";

import theme from '../../../theme.js'
import { useHistory } from "react-router-dom";

import PropTypes from 'prop-types';
import { setChapterIndex, setParagraphIndex } from "../../../utils/storage";

const styles = {
    modalcontainer: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        //paddingTop: '6px',
        //paddingBottom: '6px',
        //paddingLeft: '10px',
        //paddingRight: '10px',
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
        marginLeft: '35%',
        marginRight: '35%',
        overflowY: 'scroll',
        height: '300px',
        maxWidth: '560px',
    },
    title: {
        paddingTop: '20px',
        paddingBottom: '20px'
    }
}

export default function GoToPageModal(props) {

    const history = useHistory();

    const goto = () => {
        setChapterIndex(0);
        setParagraphIndex(0);
        history.push(props.path);
    }

    return (
        <Modal
            open={props.open}
            onClose={props.onCancel}
        >
            <div style={styles.modalcontainer}>
                <Container>
                    <Typography variant="h6" style={styles.title}>Operazione completata con successo.</Typography>
                    <Typography variant="h6" style={styles.title}>{props.message}</Typography>
                    <Container style={{ paddingTop: '20px', textAlign: 'center' }}  >
                        <ActionButton
                            secondaryButton
                            label="Indietro"
                            disabled={false}
                            onClick={props.onCancel}
                            grayVersion={false}
                        />
                        <ActionButton
                            label="Avanti"
                            disabled={false}
                            onClick={() => {goto()}}
                            grayVersion={false}
                        />
                    </Container>
                </Container>

            </div>

        </Modal>
    );
}

GoToPageModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
}