import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  colorCombinationWrapper: {
    display: "flex",
  },
  colorBlockContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "4px",
  },
  colorTypography: {
    color: theme.palette.text.disabled,
    textTransform: "uppercase",
  },
  colorBlock: {
    width: "120px",
    height: "40px",
  },
}));

const AppColorCombination = ({ analogousColors }) => {
  const classes = useStyles();

  return (
    <div className={classes.colorCombinationWrapper}>
      {Object.values(analogousColors).map((color, index) => (
        <div key={index} className={classes.colorBlockContainer}>
          <Box className={classes.colorBlock} bgcolor={color} />
          <Typography className={classes.colorTypography}>{color}</Typography>
        </div>
      ))}
    </div>
  );
};

AppColorCombination.propTypes = {
  analogousColors: PropTypes.object.isRequired,
};

export default AppColorCombination;
