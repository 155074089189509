import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom";

import LoginResult from "./LoginResult";
import LoginForm from "./LoginForm";

import { withStyles } from "@material-ui/styles";

import {
  saveToken,
  retrieveToken,
  clearToken,
  setConsulenteId,
  setSuperconsulente,
  setGruppoId,
} from "../../utils/storage";
import { logIn, getAccountData } from "../../utils/api/account_api";

const styleToUse = (theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    padding: "10px",
    color: theme.palette.secondary.main,
  },
  loginTitle: {
    color: theme.palette.text.main,
  },
  text: {
    color: theme.palette.text.main,
  },
});

class LoginPage extends Component {
  constructor() {
    super();
    this.state = {
      data: {
        username: "",
        password: "",
      },
      showForm: true,
      loginError: false,
      loginMessage: "",
      userAlreadyLogged: false,
    };
  }

  componentDidMount() {
    const token = retrieveToken();
    if (token !== null) {
      this.setState({
        loginError: true,
        showForm: false,
        loginMessage: "Eseguire il logout prima di ripetere il login.",
        userAlreadyLogged: true,
      });
    }
  }

  executeLogin = (loginData) => {
    let data = this.state.data;
    data.username = loginData.username;
    data.password = loginData.password;
    this.setState({
      data,
    });
    logIn(loginData.username, loginData.password)
      .then((result) => {
        let jwtToken = result.token;
        let message = "";
        if (jwtToken !== null) {
          saveToken(jwtToken);
          this.getUserData();
        } else {
          message = "Errore di servizio. Per favore, riprovare più tardi.";
          this.setState({
            loginError: true,
            showForm: false,
            loginMessage: message,
          });
        }
      })
      .catch((exception) => {
        let errorMessage = exception.message;
        this.setState({
          loginError: true,
          showForm: false,
          loginMessage: errorMessage,
        });
      });
  };

  handleInvalidToken = () => {
    this.setState({
      loginError: true,
      showForm: false,
      loginMessage:
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.",
    });
    clearToken();
    const { path } = this.props.theme;
    window.setTimeout(function () {
      window.location.href = `/${path}`;
    }, 4000);
  };

  getUserData = () => {
    getAccountData()
      .then((result) => {
        let ruoli = result.ruoli;
        let consulenteId = null;
        let superconsulente = false;
        let gruppoId = result.gruppoId;
        ruoli.forEach(function (item, index, array) {
          if (item === "ROLE_CONSULENTE") {
            consulenteId = result.id;
          } else if (item === "ROLE_SUPERCONSULENTE") {
            consulenteId = result.id;
            superconsulente = true;
          }
        });
        setConsulenteId(consulenteId);
        setSuperconsulente(superconsulente);
        setGruppoId(gruppoId);

        this.setState({
          loginError: false,
          showForm: false,
          loginMessage: "Log in effettuato con successo",
        });
      })
      .catch((error) => {
        if (error.status === 401 || error.status === 403) {
          this.handleInvalidToken();
        }
        this.setState({
          loginError: true,
          showForm: false,
          loginMessage: error.message,
        });
      });
  };

  returnToForm = () => {
    this.setState({ showForm: true });
  };

  render() {
    const { classes, theme } = this.props;

    return (
      <div style={{ textAlign: "center" }}>
        <header className={classes.header}>
          <h1 className={classes.loginTitle}>Benvenuto</h1>
        </header>
        {this.state.showForm ? (
          <LoginForm onLoginButtonPressed={this.executeLogin} />
        ) : this.state.userAlreadyLogged ? (
          <div style={{ textAlign: "center", padding: "10px" }}>
            <p style={{ fontSize: "20px" }}>{this.state.loginMessage}</p>
            <div style={{ textAlign: "center" }}>
              <NavLink to={"/" + theme.path} style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => {
                    clearToken();
                  }}
                  style={{
                    color: theme.palette.text.main,
                    backgroundColor: theme.palette.primary.main,
                    margin: 10,
                  }}
                >
                  Logout
                </Button>
              </NavLink>
            </div>
          </div>
        ) : (
          <LoginResult
            theme={theme}
            hasError={this.state.loginError}
            message={this.state.loginMessage}
            onBackButtonPressed={this.returnToForm}
          />
        )}
      </div>
    );
  }
}
export default withStyles(styleToUse, { withTheme: true })(LoginPage);
