import React, { Fragment } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";
import { ArrowDownward, ArrowUpward, Edit } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import { postUploadImmaginiSottoparagrafi } from "../../../utils/api/paragrafi_api.js";
import ImmaginiNuovoManuale from "./ImmaginiNuovoManuale.js";

const styles = {
  header: {
    fontSize: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textBox: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "100%",
  },
};
const theme2 = createMuiTheme({});

class NewSottoparagrafoTestoEImmagine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titoloInizialeSottoparagrafo: props.titoloInizialeSottoparagrafo,
      testoSottoparagrafo: props.testoSottoParagrafo,
      currentFile: "",
      previewImage: "",
      isBlocked: props.isParagrafoBlocked,
      dialogVisible: false,
      idImmagine: props.idImmagine,
      borderWidth: props.borderWidth,
      componentHeight: props.componentHeight,
      sottoParagrafiData: props.sottoParagrafiData,
      componentWidth: props.componentWidth,
      photoWidth: props.photoWidth,
      isTitleEditable: false,
      indexValue: this.props.indexValue,
      photoHeight: props.photoHeight,
      idImages: props.immaginiParagrafo,
      imagesNames: [],
      images: [],
    };
  }

  componentDidMount() {
    this.loadImages(this.state.idImages);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.sottoParagrafiData !== this.props.sottoParagrafiData) {
      this.setState(
        {
          titoloInizialeSottoparagrafo: this.props.titoloInizialeSottoparagrafo,
          testoSottoparagrafo: this.props.testoSottoParagrafo,
          idImmagine: this.props.idImmagine,
          borderWidth: this.props.borderWidth,
          componentHeight: this.props.componentHeight,
          sottoParagrafiData: this.props.sottoParagrafiData,
          componentWidth: this.props.componentWidth,
          indexValue: this.props.indexValue,
          photoWidth: this.props.photoWidth,
          photoHeight: this.props.photoHeight,
          idImages: this.props.immaginiParagrafo,
          isBlocked: this.props.isParagrafoBlocked,
          images: [],
          imagesNames: [],
        },
        () => {
          this.loadImages(this.state.idImages);
        }
      );
    }
    if (
      prevProps.sottoParagrafiData === this.props.sottoParagrafiData &&
      this.state.indexValue !== this.props.indexValue
    ) {
      this.setState(
        {
          titoloInizialeSottoparagrafo: this.props.titoloInizialeSottoparagrafo,
          testoSottoparagrafo: this.props.testoSottoParagrafo,
          idImmagine: this.props.idImmagine,
          borderWidth: this.props.borderWidth,
          componentHeight: this.props.componentHeight,
          sottoParagrafiData: this.props.sottoParagrafiData,
          componentWidth: this.props.componentWidth,
          photoWidth: this.props.photoWidth,
          photoHeight: this.props.photoHeight,
          indexValue: this.props.indexValue,
          idImages: this.props.immaginiParagrafo,
          isBlocked: this.props.isParagrafoBlocked,
        },
        () => {
          this.loadImages(this.state.idImages);
        }
      );
    }
  }

  loadImages = (arrayOfImagesId) => {
    arrayOfImagesId.map((item, index) => {
      item.posizione = index;
    });
    /*let newArrayOfImages = [...this.state.images];
      if(arrayOfImagesId !== undefined){
      arrayOfImagesId.map(item => {
        console.log(item);
        getImmaginiSottoparagrafo(item.id).then(result => {
          newArrayOfImages.push(result.url);
          this.setState({
            images:newArrayOfImages,
          },() => {
          })
        }).catch(error => {
          if (error.status === 403) {
            this.props.handleInvalidToken();
          } else {
            this.props.handleError(true, error.message);
          }
        })
      })
    }*/
  };
  bringImageToParent = (image, names, index, changer) => {
    let newSubparagraphArray = [...this.state.sottoParagrafiData];
    let newIdsArray = [...this.state.idImages];
    let elementToChange = newSubparagraphArray.filter(
      (item) => item.numeroComponente === index
    );
    if (image[image.length - 1].type !== "image/png") {
      this.props.handleError(
        true,
        "Attenzione, possono essere caricate solo immagini in formato PNG"
      );
    } else if (image[image.length - 1].size > 500000) {
      this.props.handleError(
        true,
        "Attenzione, la dimensione dell'immagine caricata è maggiore 500kb "
      );
    } else {
      postUploadImmaginiSottoparagrafi(image[image.length - 1])
        .then((result) => {
          elementToChange[0].immagini.push({
            id: result,
            posizione: newIdsArray.length,
            note: "",
            titolo: "",
          });
          newIdsArray.push({
            id: result,
            posizione: newIdsArray.length,
            note: "",
            titolo: "",
          });
          this.setState(
            {
              sottoParagrafiData: newSubparagraphArray,
              idImages: newIdsArray,
            },
            () => {
              this.props.handleEditChange();
            }
          );
          this.setState({
            currentFile: changer,
            previewImage: URL.createObjectURL(changer),
            images: [...this.state.images, URL.createObjectURL(changer)],
            imagesNames: [...this.state.imagesNames, changer.name],
          });
        })
        .catch((error) => {
          if (error.status === 403) {
            this.props.handleInvalidToken();
          } else {
            this.props.handleError(true, error.message);
          }
        });
    }
  };

  swapElements = (arr, i1, i2) => {
    let temp = arr[i1];

    arr[i1] = arr[i2];

    arr[i2] = temp;
  };
  swapImmagini = (arr, i1, i2) => {
    let temp = arr[i1];
    let tempPosizione1 = arr[i1].posizione;
    let tempPosizione2 = arr[i2].posizione;
    arr[i1] = arr[i2];

    arr[i2] = temp;
    arr[i1].posizione = tempPosizione1;
    arr[i2].posizione = tempPosizione2;
  };

  rimuoviImmagine = (img) => {
    if (
      !this.state.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let arrayOfImagesId = [...this.state.idImages];
      let indexOfImages = arrayOfImagesId.indexOf(img);
      if (indexOfImages !== -1) {
        arrayOfImagesId.splice(indexOfImages, 1);
        this.setState(
          {
            idImages: arrayOfImagesId,
          },
          () => {
            this.props.handleEditChange();
          }
        );
      }
      this.props.deleteImage(arrayOfImagesId, this.props.indexValue);
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  moveUpwardImage = (index, indexToMove) => {
    if (
      !this.state.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let arrayOfImages = [...this.state.idImages];
      let arrayOfData = [...this.state.sottoParagrafiData];
      this.swapElements(
        arrayOfData[this.state.indexValue].immagini,
        index,
        indexToMove
      );
      this.swapImmagini(arrayOfImages, index, indexToMove);
      this.setState(
        {
          idImages: arrayOfImages,
          sottoParagrafiData: arrayOfData,
        },
        () => {
          this.props.handleEditChange();
        }
      );
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };
  moveDownwardImage = (index, indexToMove) => {
    if (
      !this.state.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let arrayOfImages = [...this.state.idImages];
      let arrayOfData = [...this.state.sottoParagrafiData];
      this.swapElements(
        arrayOfData[this.state.indexValue].immagini,
        index,
        indexToMove
      );
      this.swapImmagini(arrayOfImages, index, indexToMove);
      this.setState(
        {
          idImages: arrayOfImages,
          sottoParagrafiData: arrayOfData,
        },
        () => {
          this.props.handleEditChange();
        }
      );
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };
  changeNote = (event, index) => {
    if (
      !this.state.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let arrayOfImages = [...this.state.idImages];
      let arrayOfData = [...this.state.sottoParagrafiData];
      arrayOfData[this.state.indexValue].immagini[index].note = event;
      arrayOfImages[index].note = event;
      this.setState(
        {
          images: arrayOfImages,
          sottoParagrafiData: arrayOfData,
        },
        () => {
          this.props.handleEditChange();
        }
      );
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };
  changeTitolo = (event, index) => {
    if (
      !this.state.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let arrayOfImages = [...this.state.idImages];
      let arrayOfData = [...this.state.sottoParagrafiData];
      arrayOfData[this.state.indexValue].immagini[index].titolo = event;
      arrayOfImages[index].titolo = event;
      this.setState(
        {
          images: arrayOfImages,
          sottoParagrafiData: arrayOfData,
        },
        () => {
          this.props.handleEditChange();
        }
      );
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  selectFile(event) {
    this.setState({
      currentFile: event.target.files[0],
      previewImage: URL.createObjectURL(event.target.files[0]),
    });
  }
  upload() {}

  render() {
    return (
      <div
        style={{
          alignSelf: "center",
          textAlign: "center",
          color: "black",
          paddingBottom: "1%",
          display: "flex",
          flexDirection: "column",
          height: this.props.componentHeight,
          width: "100%",
        }}
      >
        <div style={styles.header}>
          <Grid>
            <TextField
              InputProps={{ disableUnderline: true, style: { fontSize: 30,width:"18em" } }}
              style={{}}
              multiline
              InputLabelProps={{ style: { fontSize: 30 } }}
              disabled={!this.state.isTitleEditable}
              onInput={(e) => {
                this.setState({
                  titoloInizialeSottoparagrafo: e.target.value,
                });
              }}
              onBlur={() => {
                this.props.bringTitleToParent(
                  this.state.titoloInizialeSottoparagrafo,
                  this.props.indexValue
                );
              }}
              value={this.state.titoloInizialeSottoparagrafo}
            ></TextField>
          </Grid>
          <div style={{ display: "flex", flexDirection: "row",height:"50%",marginTop:"auto" }}>
            {!this.props.firstElement && (
              <IconButton
                size="medium"
                onClick={() => {
                  if (
                    !this.state.isBlocked ||
                    localStorage.getItem("superconsulente") === "true" ||
                    localStorage.getItem("consulenteId") === "null"
                  ) {
                    this.setState({
                      isTitleEditable: !this.state.isTitleEditable,
                    });
                  } else {
                    alert(
                      "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
                    );
                  }
                }}
              >
                <Edit htmlColor={this.state.isTitleEditable ? this.props.theme.palette.primary.main : "#707070"}></Edit>
              </IconButton>
            )}
            {!this.props.firstElement && (
              <IconButton
                size="medium"
                onClick={() => {
                  this.props.rimuoviSottoparagrafo(this.props.indexValue);
                }}
              >
                <CloseIcon></CloseIcon>
              </IconButton>
            )}
            {!this.props.firstElement && (
              <IconButton
                size="small"
                onClick={() => {
                  this.props.moveUpward(this.props.indexValue);
                }}
              >
                <ArrowUpward></ArrowUpward>
              </IconButton>
            )}
            {!this.props.firstElement && (
              <IconButton
                size="small"
                onClick={() => {
                  this.props.moveDownward(this.props.indexValue);
                }}
              >
                <ArrowDownward></ArrowDownward>
              </IconButton>
            )}
          </div>
        </div>

        <div style={styles.textBox}>
          <ThemeProvider theme={theme2}>
            <TextField
              label="Inserire il testo..."
              variant="outlined"
              inputProps={{ disableunderline: "true" }}
              value={this.state.testoSottoparagrafo}
              name="contenuto"
              onChange={(e) => {
                this.setState({
                  testoSottoparagrafo: e.currentTarget.value,
                });
              }}
              disabled={
                !(
                  !this.state.isBlocked ||
                  localStorage.getItem("superconsulente") === "true" ||
                  localStorage.getItem("consulenteId") === "null"
                )
              }
              onBlur={() => {
                //if(this.state.testoSottoparagrafo !== ""){
                this.props.bringDataToParent(
                  this.state.testoSottoparagrafo,
                  this.props.indexValue
                );
                //}
              }}
              multiline
              rows={20}
              fullWidth
              style={styles.textfield}
            ></TextField>
          </ThemeProvider>
        </div>
        <div
          style={{
            border: "1px solid black",
            width: this.state.borderWidth,
            marginLeft: "0%",
          }}
        ></div>
        {this.state.idImages.length === 0 ? (
          <Fragment key={this.props.indexValue}>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(event) => {
                if (
                  !this.state.isBlocked ||
                  localStorage.getItem("superconsulente") === "true" ||
                  localStorage.getItem("consulenteId") === "null"
                ) {
                  let arrayOfImagesToPass = [
                    ...this.state.images,
                    event.target.files[0],
                  ];
                  let arrayOfNamesToPass = [
                    ...this.state.imagesNames,
                    event.target.files[0].name,
                  ];
                  //this.props.bringImageToParent(arrayOfImagesToPass,arrayOfNamesToPass,this.props.indexValue);
                  this.bringImageToParent(
                    arrayOfImagesToPass,
                    arrayOfNamesToPass,
                    this.props.indexValue,
                    event.target.files[0]
                  );
                } else {
                  alert(
                    "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
                  );
                }
                //console.log(this.state.images.length);
                //console.log("preview image: "+this.state.previewImage);
              }}
              id={this.props.idImmagine}
            />
            <Typography align="left">
              <label htmlFor={this.props.idImmagine}>
                <Button
                  variant="contained"
                  style={{
                    color: this.props.theme.palette.text.main,
                    backgroundColor: this.props.theme.palette.primary.main,
                    marginBottom: "1%",
                    marginLeft: "0%",
                    marginTop: "1%",
                  }}
                  component="span"
                  onClick={this.upload}
                >
                  Scegli file
                </Button>
              </label>
            </Typography>
          </Fragment>
        ) : (
          <Fragment>
            <ImmaginiNuovoManuale
              theme={this.props.theme}
              ImmaginiNuovoManuale={this.state.idImages}
              onRimuoviImage={this.rimuoviImmagine}
              onMoveUpward={this.moveUpwardImage}
              onMoveDownward={this.moveDownwardImage}
              onChangeNote={this.changeNote}
              onChangeTitolo={this.changeTitolo}
            ></ImmaginiNuovoManuale>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(event) => {
                if (
                  !this.state.isBlocked ||
                  localStorage.getItem("superconsulente") === "true" ||
                  localStorage.getItem("consulenteId") === "null"
                ) {
                  let arrayOfImagesToPass = [
                    ...this.state.images,
                    event.target.files[0],
                  ];
                  let arrayOfNamesToPass = [
                    ...this.state.imagesNames,
                    event.target.files[0].name,
                  ];
                  //this.props.bringImageToParent(arrayOfImagesToPass,arrayOfNamesToPass,this.props.indexValue);
                  this.bringImageToParent(
                    arrayOfImagesToPass,
                    arrayOfNamesToPass,
                    this.props.indexValue,
                    event.target.files[0]
                  );
                } else {
                  alert(
                    "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
                  );
                }
                //console.log(this.state.images.length);
                //console.log("preview image: "+this.state.previewImage);
              }}
              id={this.props.idImmagine}
            />
            <Typography align="left">
              <label htmlFor={this.props.idImmagine}>
                <Button
                  variant="contained"
                  style={{
                    color: this.props.theme.palette.text.main,
                    backgroundColor: this.props.theme.palette.primary.main,
                    marginBottom: "1%",
                    marginLeft: "0%",
                    marginTop: "1%",
                  }}
                  component="span"
                  onClick={this.upload}
                >
                  Scegli file
                </Button>
              </label>
            </Typography>
          </Fragment>
        )}
        {/*
                {!this.state.images && <div style={{display:'flex', gap:"1%",marginLeft:"0%"}}>
                {/*previewImage !== '' ? (
                  <img src={previewImage} alt="" style={{marginTop:"1%",maxWidth:this.props.photoWidth,maxHeight:this.props.photoHeight}}/>
                ):(
                  <img src={previewImage} alt="" style={{maxWidth:this.props.photoWidth,maxHeight:this.props.photoHeight}}/>
                )}
                </div>}
                {this.state.images.length > 0 && <Grid container spacing = {2} mx={"1%"} style={{marginTop:1}} xs={6}>
                {this.state.images.map((img) => {
                 return <Grid item style={{display:"flex"}}>
                 <img src={img} alt="" style={{maxWidth:this.props.photoWidth,maxHeight:this.props.photoHeight}}/>
                 <div>
                 <IconButton onClick={() => {
                  if(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null")){
                    let newArray = [...this.state.images];
                    let arrayOfImagesId = [...this.state.idImages];
                    let index = newArray.indexOf(img);
                    if(index !== -1){
                      newArray.splice(index,1);
                      this.setState({
                        images:newArray,
                      })
                    }
                    this.props.deleteImage(arrayOfImagesId.at(index),this.props.indexValue);
                  }else{
                    alert("Attenzione, operazione non permessa poichè il paragrafo è bloccato");
                  }
                 }}>
                 <CloseIcon></CloseIcon>
                 </IconButton>
                 </div>
                 </Grid>
                })}
                </Grid>}
                <input
                  type="file"
                  accept="image/*"
                  style={{display:'none'}}
                  onChange={(event) => {
                    if(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null")){
                    let arrayOfImagesToPass = [...this.state.images,event.target.files[0]];
                    let arrayOfNamesToPass = [...this.state.imagesNames,event.target.files[0].name];
                    //this.props.bringImageToParent(arrayOfImagesToPass,arrayOfNamesToPass,this.props.indexValue);
                    this.bringImageToParent(arrayOfImagesToPass,arrayOfNamesToPass,this.props.indexValue,event.target.files[0]);
                    }else{
                      alert("Attenzione, operazione non permessa poichè il paragrafo è bloccato")
                    }
                    //console.log(this.state.images.length);
                    //console.log("preview image: "+this.state.previewImage);
                  }}
                  id={this.props.idImmagine}
                />
              <Typography align="left">
                <label htmlFor={this.props.idImmagine}>
                <Button 
                  variant="contained" 
                  style={{ 
                     color: theme.palette.secondary.main,
                     backgroundColor: theme.palette.primary.main,
                     marginBottom:"1%",
                     marginLeft:"0%",
                     marginTop:"1%"
                  }}
                  component = "span"
                  onClick={this.upload}
                  >
                  Scegli file
                </Button>
                </label>
              </Typography>*/}
        <div
          style={{
            border: "1px solid black",
            width: this.state.borderWidth,
            marginBottom: "1%",
            marginLeft: "0%",
          }}
        ></div>
      </div>
    );
  }
}

export default NewSottoparagrafoTestoEImmagine;
