import React from "react";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import PropTypes from "prop-types";

import moment from "moment";
import "moment/locale/it";
import CheckboxSection from "./CheckboxSection";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: "6px",
  },
  textfieldscontainer: {
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "300px",
  },
  formcontrol: {
    marginBottom: "30px",
    width: "300px",
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "190px",
      width: 250,
    },
  },
};

export default function FormManuale(props) {
  const classes = useStyles();
  const themeToUse = useTheme();
  const dateFormat = "DD-MM-YYYY HH:mm";

  return (
    <form noValidate autoComplete="off" onSubmit={props.onSubmit}>
      <Grid container justify="center" alignItems="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.sectionContainer}
        >
          <Grid container className={classes.textfieldscontainer}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ paddingBottom: "10px" }}
              >
                <TextField
                  label="ID manuale"
                  disabled
                  name="id"
                  value={props.record.manuale.id}
                  InputProps={{
                    readOnly: true,
                  }}
                  className={classes.textfield}
                />
                <TextField
                  label="ID manuale base"
                  disabled
                  name="manualeBaseId"
                  value={
                    props.record.manuale.manualeBaseId === null
                      ? " - "
                      : props.record.manuale.manualeBaseId
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  className={classes.textfield}
                />
                <TextField
                  label="ID revisione"
                  disabled
                  name="id"
                  value={props.record.id}
                  InputProps={{
                    readOnly: true,
                  }}
                  className={classes.textfield}
                />
                <TextField
                  label="ID consulente"
                  disabled
                  name="consulenteId"
                  value={
                    props.record.consulenteId === null
                      ? ""
                      : props.record.consulenteId
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  className={classes.textfield}
                />
                <TextField
                  label="Data creazione manuale"
                  disabled
                  name="dataCreazione"
                  value={
                    props.record.manuale.dataCreazione === null
                      ? ""
                      : moment(props.record.manuale.dataCreazione).format(
                          dateFormat
                        )
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  className={classes.textfield}
                />
                <TextField
                  label="Data aggiornamento manuale"
                  disabled
                  name="dataAggiornamento"
                  value={
                    props.record.manuale.dataUltimoAggiornamento === null
                      ? ""
                      : moment(
                          props.record.manuale.dataUltimoAggiornamento
                        ).format(dateFormat)
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  className={classes.textfield}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ paddingBottom: "10px" }}
              >
                <TextField
                  label="Titolo del manuale"
                  name="titolo"
                  value={props.record.manuale.titolo || ""}
                  onChange={props.onChange}
                  className={classes.textfield}
                  disabled={props.disabledForm}
                />
                <TextField
                  label="Regolamenti"
                  name="regolamenti"
                  value={props.record.manuale.regolamenti || ""}
                  onChange={props.onChange}
                  className={classes.textfield}
                  disabled={props.disabledForm}
                />
                <TextField
                  label="Numero di revisione"
                  name="indiceRevisione"
                  value={props.record.indiceRevisione}
                  onChange={props.onChange}
                  className={classes.textfield}
                  disabled={props.disabledForm}
                />
                <TextField
                  label="Motivo della revisione"
                  value={props.record.motivoRevisione}
                  name="motivoRevisione"
                  onChange={props.onChange}
                  className={classes.textfield}
                  disabled={props.disabledForm}
                />
                <TextField
                  label="Stato pubblicazione"
                  disabled
                  name="stato"
                  value={props.record.stato.replace(/_/g, " ")}
                  InputProps={{
                    readOnly: true,
                  }}
                  className={classes.textfield}
                />
                <TextField
                  label="Stato calendario"
                  disabled
                  name="statoCalendario"
                  value={props.record.statoLogDescrizione}
                  InputProps={{
                    readOnly: true,
                  }}
                  className={classes.textfield}
                />
                <Typography
                  variant="body2"
                  style={{ width: "250px", padding: "20px 20px 12px 20px" }}
                >
                  Esercizio commerciale:
                </Typography>
                <FormControl className={classes.formcontrol}>
                  <Select
                    value={
                      props.record.esercizioCommercialeId === null
                        ? ""
                        : props.record.esercizioCommercialeId
                    }
                    name="esercizioCommercialeId"
                    onChange={props.onChange}
                    MenuProps={MenuProps}
                    disabled={props.disabledForm}
                  >
                    <MenuItem value={""}>
                      <em>Selezionare un esercizio</em>
                    </MenuItem>
                    {props.esercizi.map((esercizio) => (
                      <MenuItem key={esercizio.id} value={esercizio.id}>
                        {esercizio.nome === null ? "" : esercizio.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CheckboxSection
        notificheAggregate={props.notificheAggregate}
        multiselect={props.multiselect}
        idOperatoreObbligatorio={props.idOperatoreObbligatorio}
        onChange={props.onChange}
        disabledForm={props.disabledForm}
      />
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ padding: "10px 0px 0px 0px" }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            variant="body1"
            style={{
              color: "green",
              //visibility: props.saveSuccessMessageVisible ? 'inherit' : 'hidden'
              display: props.saveSuccessMessageVisible ? "inherit" : "none",
            }}
          >
            Dati aggiornati con successo.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ textAlign: "right" }}
        >
          <Button
            type="button"
            onClick={props.onSubmit}
            variant="contained"
            size="medium"
            disabled={props.disabledSubmitButton}
            style={{
              color: props.disabledSubmitButton
                ? themeToUse.palette.text.disabled
                : themeToUse.palette.text.main,
              margin: "10px",
              backgroundColor: props.disabledSubmitButton
                ? themeToUse.palette.grey[300]
                : themeToUse.palette.primary.main,
            }}
          >
            Salva modifiche
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

FormManuale.propTypes = {
  record: PropTypes.object.isRequired,
  esercizi: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabledForm: PropTypes.bool.isRequired,
  disabledSubmitButton: PropTypes.bool.isRequired,
  saveSuccessMessageVisible: PropTypes.bool.isRequired,
  notificheAggregate: PropTypes.bool.isRequired,
  multiselect: PropTypes.bool.isRequired,
  idOperatoreObbligatorio: PropTypes.bool.isRequired,
};
