import React, { Component, Fragment } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import SpinnerComponent from "../../../components/SpinnerComponent";
import ErrorDialog from "../../../components/ErrorDialog";
import FormRegistrazioneConsulenteGruppo from "./FormRegistrazioneConsulenteGruppo";

import { getGruppo } from "../../../utils/api/gruppi_api";
import { registerConsulente } from "../../../utils/api/consulenti_api";
import { clearToken } from "../../../utils/storage";

import validator from "validator";

import PropTypes from "prop-types";

const styles = {
  root: {
    textAlign: "center",
    color: "black",
    paddingTop: "26px",
    paddingLeft: "4px",
    paddingRight: "4px",
    minHeight: "86vh",
  },
};

export default class RegistraConsulenteGruppoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      consulente: {
        id: null,
        email: "",
        nome: "",
        password: "",
        confirmPassword: "",
        cognome: "",
        telefono: "",
        terminiUsoAccettati: "",
        privacyPolicyAccettata: "",
        partitaIva: "",
        codiceSdi: "",
        pec: "",
        gruppoId: "",
        superconsulente: false,
      },
      gruppo: {
        id: null,
        nome: "",
        brand: {
          id: null,
          nome: "",
        },
      },
      validationErrors: {
        email: "",
        password: "",
        confirmPassword: "",
      },
      changesNotSaved: false,
      submitDisabled: true,
      disabledForm: false,
      errorDialogVisible: false,
      errorDialogTitle: null,
      errorDialogMessage: "",
    };
  }

  componentDidMount() {
    this.fetchGruppo();
  }

  handleInvalidToken = () => {
    const errorMessage =
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    this.openErrorDialog(errorMessage);
    clearToken();
    const { path } = this.props.theme;
    window.setTimeout(function () {
      window.location.href = "/" + path;
    }, 4000);
  };

  openErrorDialog = (errorMessage) => {
    this.setState({
      errorDialogTitle: null,
      errorDialogVisible: true,
      errorDialogMessage: errorMessage,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  // Using the ErrorDialog to display a message if operation is successful.
  displayMessage = (title, message) => {
    this.setState({
      errorDialogTitle: title,
      errorDialogVisible: true,
      errorDialogMessage: message,
    });
  };

  fetchGruppo = () => {
    let gruppoId = this.props.gruppoId;
    getGruppo(gruppoId)
      .then((result) => {
        let record = Object.assign({}, this.state.consulente);
        record.gruppoId = result.id;
        this.setState({
          consulente: record,
          gruppo: result,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  validateForm = () => {
    let record = this.state.consulente;
    Object.keys(record).forEach((key) => {
      this.validateField(key, record[key]);
    });
    let hasErrors = false;
    let validationErrors = this.state.validationErrors;
    Object.values(validationErrors).forEach((val) => {
      hasErrors = hasErrors || (val.length > 0 ? true : false);
    });
    return hasErrors;
  };

  validateField = (key, value) => {
    let validationErrors = this.state.validationErrors;
    switch (key) {
      case "email":
        if (value.length === 0) {
          validationErrors.email = "Inserire l'indirizzo email";
        } else {
          if (validator.isEmail(value)) {
            validationErrors.email = "";
          } else {
            validationErrors.email = "L'indirizzo email inserito non è valido";
          }
        }
        break;
      case "password":
        if (value.length >= 8) {
          validationErrors.password = "";
        } else {
          validationErrors.password =
            "Inserire una password di almeno 8 caratteri";
        }
        break;
      case "confirmPassword":
        if (value.length === 0) {
          validationErrors.confirmPassword = "Ripetere la password";
        } else {
          if (value === this.state.consulente.password) {
            validationErrors.confirmPassword = "";
          } else {
            validationErrors.confirmPassword =
              "Le due password non corrispondono";
          }
        }
        break;
      default:
        break;
    }
    this.setState({
      validationErrors,
    });
  };

  handleChanges = (event) => {
    let consulente = this.state.consulente;
    consulente[event.target.name] = event.target.value;
    let validationErrors = this.state.validationErrors;
    for (let key in validationErrors) {
      if (event.target.name === key) {
        validationErrors[key] = "";
        break;
      }
    }
    this.setState({
      consulente,
      changesNotSaved: true,
      submitDisabled: false,
      validationErrors,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const invalidForm = this.validateForm();
    if (!invalidForm) {
      this.setState({
        loading: true,
      });
      registerConsulente(false, this.state.consulente)
        .then((result) => {
          this.setState({
            loading: false,
            disabledForm: true,
            changesNotSaved: false,
          });
          this.displayMessage(
            "Registrazione effettuata",
            "Il nuovo consulente è stato registrato con successo."
          );
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            let disabledForm = true;
            if (error.status === 409) {
              disabledForm = false;
            }
            this.setState({
              loading: false,
              disabledForm,
            });
            this.openErrorDialog(error.message);
          }
        });
    }
  };

  render() {
    return (
      <Container maxWidth={false} style={styles.root}>
        {this.state.loading ? (
          <SpinnerComponent size={24} />
        ) : this.state.gruppo.id === null ? (
          <Typography variant="body1" style={styles.texts}>
            Caricamento dati fallito.
          </Typography>
        ) : (
          <Fragment>
            <Typography variant="h4" style={styles.texts}>
              {"Registra un nuovo consulente per il gruppo " +
                this.state.gruppo.nome}
            </Typography>
            <Container maxWidth="md" style={styles.root}>
              <FormRegistrazioneConsulenteGruppo
                consulente={this.state.consulente}
                validationErrors={this.state.validationErrors}
                onChange={this.handleChanges}
                onSubmit={this.handleSubmit}
                disabled={this.state.disabledForm}
                submitDisabled={this.state.submitDisabled}
                changesNotSaved={this.state.changesNotSaved}
              />
            </Container>
          </Fragment>
        )}
        <ErrorDialog
          open={this.state.errorDialogVisible}
          title={this.state.errorDialogTitle}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
      </Container>
    );
  }
}

RegistraConsulenteGruppoPage.propTypes = {
  gruppoId: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
};
