import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

import PropTypes from "prop-types";

import { useTheme } from "@material-ui/core";

export default function NewRecordButton(props) {
  let history = useHistory();
  const match = useRouteMatch();
  const themeToColor = useTheme();
  return (
    <Button
      disabled={props.disabled}
      variant="contained"
      startIcon={<AddIcon />}
      style={{
        color: themeToColor.palette.text.main,
        backgroundColor: themeToColor.palette.primary.main,
      }}
      onClick={() => {
        history.push(`${match.url}/nuovo`);
      }}
    >
      {props.label === null || props.label === undefined
        ? "NUOVO"
        : props.label}
    </Button>
  );
}

NewRecordButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string,
};
