import { Container, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SpinnerComponent from "../../../components/SpinnerComponent";
import { clearToken } from "../../../utils/storage";
import ErrorDialog from "../../../components/ErrorDialog";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  createBrand,
  getLogoApp,
  getLogoByPath,
  getLogoLeftByPath,
  updateBrand,
} from "../../../utils/api/brand_api";

import DettagliApp from "./DettagliApp";
import DettagliWebApp from "./DettagliWebApp";
import TwoActionsAlertDialog from "../../../components/TwoActionsAlertDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    textAlign: "center",
    color: "black",
    paddingTop: "26px",
    paddingLeft: "4px",
    paddingRight: "4px",
    minHeight: "86vh",
  },
  tabsPaper: {
    boxShadow: theme.shadows[6],
  },
  text: {
    margin: "8px 0px 32px 0",
  },
}));

export default function ModificaTemplate(props) {
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const isEditMode = !!location.state;

  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [templateData, setTemplateData] = useState({
    nome: isEditMode ? location.state.brandName : "",
    path: isEditMode ? location.state.path : "",
    logo: "",
    logoLeft: "",
    primaryColor: isEditMode
      ? location.state.primaryColor
      : theme.palette.primary.main,
    secondaryColor: isEditMode
      ? location.state.secondaryColor
      : theme.palette.secondary.main,
    textColor: isEditMode
      ? location.state.textColor
      : theme.palette.text.primary,
    primaryColorApp:
      isEditMode && location.state.primaryColorApp
        ? location.state.primaryColorApp
        : "#FF4600",
    secondaryColorApp:
      isEditMode && location.state.secondaryColorApp
        ? location.state.secondaryColorApp
        : "#FD7800",
    tertiaryColorApp:
      isEditMode && location.state.tertiaryColorApp
        ? location.state.tertiaryColorApp
        : "#6FC3DC",
    logoApp: "",
  });
  const [brandName, setBrandName] = useState(templateData.nome);
  const [loghiTemplate, setLoghiTemplate] = useState([templateData.logo]);
  const [logoLeftUrl, setLogoLeftUrl] = useState("");
  const [logoAppUrl, setLogoAppUrl] = useState("");
  const [changesNotSaved, setChangesNotSaved] = useState(false);
  const [selectedColor, setSelectedColor] = useState({
    primary: templateData.primaryColor,
    secondary: templateData.secondaryColor,
    text: templateData.textColor,
    appPrimary: templateData.primaryColorApp,
    appSecondary: templateData.secondaryColorApp,
    appTertiary: templateData.tertiaryColorApp,
  });
  const [errorDialog, setErrorDialog] = useState({
    isVisible: false,
    title: null,
    message: "",
  });
  const [twoActionAlert, setTwoActionAlert] = useState({
    isVisible: false,
    title: "",
    message: "",
  });

  useEffect(() => {
    let isMounted = true;
    if (location.state?.path) {
      setLoading(true);

      const fetchLogos = async () => {
        try {
          const response = await getLogoByPath(location.state.path);
          if (isMounted) {
            const blob = await response.blob();
            const logoUrl = URL.createObjectURL(blob);
            setLoghiTemplate([logoUrl]);
            setTemplateData((prev) => ({ ...prev, logo: blob }));
          }

          const responseLeft = await getLogoLeftByPath(location.state.path);
          if (isMounted) {
            const blobLeft = await responseLeft.blob();
            const logoLeftUrl = URL.createObjectURL(blobLeft);
            setLogoLeftUrl(logoLeftUrl);
            setTemplateData((prev) => ({ ...prev, logoLeft: blobLeft }));
          }

          const responseApp = await getLogoApp(location.state.id);
          if (isMounted) {
            const blobApp = await responseApp.blob();
            const logoAppURL = URL.createObjectURL(blobApp);
            setLogoAppUrl(logoAppURL);
            setTemplateData((prev) => ({ ...prev, logoApp: blobApp }));
          }
        } catch (error) {
          if (isMounted) {
            if (error.status === 403) {
              handleInvalidToken();
            } else if (error.status === 404) {
              console.log("Logo header non trovato.");
            }
          }
        } finally {
          if (isMounted) setLoading(false);
        }
      };

      fetchLogos();
    }

    return () => {
      isMounted = false;
    };
  }, [location.state?.path, location.state?.id]);

  const handleTabChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  const handleBrandNameChange = (e) => {
    const inputValue = e.target.value;
    setBrandName(inputValue);
    setChangesNotSaved(true);
    if (!isEditMode) {
      const restrictedInput = inputValue
        .replace(/\s+/g, "-")
        .replace(/[^a-zA-Z0-9-]/g, "")
        .toLowerCase();
      setTemplateData((prev) => ({ ...prev, path: restrictedInput }));
    }
  };

  const handlePathChange = (e) => {
    const inputValue = e.target.value;
    const restrictedInput = inputValue
      .replace(/\s+/g, "-")
      .replace(/[^a-zA-Z0-9-]/g, "")
      .toLowerCase();
    setTemplateData((prev) => ({ ...prev, path: restrictedInput }));
    setChangesNotSaved(true);
  };

  const handleColorChange = (colorType, color) => {
    if (color?.hex) {
      setSelectedColor((prev) => ({ ...prev, [colorType]: color.hex }));
      setChangesNotSaved(true);
    }
  };

  const uploadImage = (file, event) => {
    if (
      (file.type === "image/png" || file.type === "image/jpeg") &&
      Math.floor(file.size / 1000) < 150
    ) {
      const blob = new Blob([file], { type: "image/png" });
      const url = URL.createObjectURL(blob);
      const loghiTemplateToUse = [...loghiTemplate];
      loghiTemplateToUse[0] = url;
      setLoghiTemplate(loghiTemplateToUse);
      setTemplateData((prev) => ({
        ...prev,
        logo: file,
      }));
      setChangesNotSaved(true);
    } else {
      openErrorDialog(
        "È possibile caricare solo immagini in formato PNG di dimensione inferiore a 150kb.",
        "Attenzione"
      );
    }
  };

  const uploadLogoLeft = (file) => {
    if (!file) {
      openErrorDialog("Nessun file selezionato.");
      return;
    }
    if (
      (file.type === "image/png" || file.type === "image/jpeg") &&
      Math.floor(file.size / 1000) < 150
    ) {
      const blob = new Blob([file], { type: "image/png" });
      const url = URL.createObjectURL(blob);
      setTemplateData((prev) => ({
        ...prev,
        logoLeft: blob,
      }));
      setLogoLeftUrl(url);
      setChangesNotSaved(true);
    } else {
      openErrorDialog(
        "È possibile caricare solo immagini in formato PNG di dimensione inferiore a 150kb.",
        "Attenzione"
      );
    }
  };

  const uploadLogoApp = (file) => {
    if (file.type === "image/svg+xml") {
      const blob = new Blob([file], { type: "image/svg+xml" });
      const url = URL.createObjectURL(blob);
      setTemplateData((prev) => ({
        ...prev,
        logoApp: blob,
      }));
      setLogoAppUrl(url);
      setChangesNotSaved(true);
    } else {
      openErrorDialog(
        "È possibile caricare solo immagini in formato SVG.",
        "Attenzione"
      );
    }
  };

  const openErrorDialog = (message, title = null) => {
    setErrorDialog({ isVisible: true, title, message });
  };

  const closeErrorDialog = () => {
    setErrorDialog({
      isVisible: false,
      title: null,
      message: "",
    });
  };

  const handleInvalidToken = () => {
    const errorMessage =
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    openErrorDialog(errorMessage);
    clearToken();
    const { path } = theme;
    window.setTimeout(() => {
      window.location.href = `/${path}`;
    }, 4000);
  };

  const createOrUpdateBrand = () => {
    const formData = new FormData();

    if (isEditMode) {
      if (!brandName.trim() || !templateData.logo || !templateData.logoLeft) {
        openErrorDialog(
          "Occorre inserire il nome del brand ed entrambi i loghi per procedere.",
          "Attenzione"
        );
        return;
      }
      formData.append("id", location.state.id);
      formData.append("name", brandName);
      formData.append("primaryColor", selectedColor.primary);
      formData.append("secondaryColor", selectedColor.secondary);
      formData.append("textColor", selectedColor.text);
      formData.append("primaryColorApp", selectedColor.appPrimary);
      formData.append("secondaryColorApp", selectedColor.appSecondary);
      formData.append("tertiaryColorApp", selectedColor.appTertiary);
      if (typeof templateData.logo === "object") {
        formData.append("logo", templateData.logo);
      }
      if (typeof templateData.logoLeft === "object")
        formData.append("logoLeft", templateData.logoLeft);

      if (templateData.logoApp.type === "image/svg+xml")
        formData.append("logoApp", templateData.logoApp);

      updateBrand(formData)
        .then((_response) => {
          console.log("Brand aggiornato con successo!");
          setChangesNotSaved(false);
          history.goBack();
        })
        .catch((error) => openErrorDialog(error.message, "Attenzione"));
    } else {
      if (
        !brandName.trim() ||
        !templateData.path.trim() ||
        !templateData.logo ||
        !templateData.logoLeft
      ) {
        openErrorDialog(
          "Per poter proseguire con la creazione, occorre inserire tutti i dati relativi al brand lato web app (nome, path, palette di colori e loghi).",
          "Attenzione"
        );
        return;
      }

      if (!selectedColor.appPrimary || !templateData.logoApp) {
        setTwoActionAlert({
          isVisible: true,
          title: "Attenzione",
          message:
            "Non sono stati impostati i colori o il logo da utilizzare sull'applicazione. Si desidera proseguire con la creazione del brand? Sarà comunque possibile modificare gli elementi relativi all'app in fase di modifica.",
        });
        return;
      }

      formData.append("nome", brandName);
      formData.append("path", templateData.path);
      formData.append("primaryColor", selectedColor.primary);
      formData.append("secondaryColor", selectedColor.secondary);
      formData.append("textColor", selectedColor.text);
      formData.append("primaryColorApp", selectedColor.appPrimary);
      formData.append("secondaryColorApp", selectedColor.appSecondary);
      formData.append("tertiaryColorApp", selectedColor.appTertiary);
      formData.append("logo", templateData.logo);
      formData.append("loghi", []);
      if (typeof templateData.logoLeft === "object")
        formData.append("logoLeft", templateData.logoLeft);
      if (templateData.logoApp.type === "image/svg+xml")
        formData.append("logoApp", templateData.logoApp);

      createBrand(formData)
        .then((_response) => {
          console.log("Il nuovo brand è stato registrato con successo.");
          setChangesNotSaved(false);
          history.goBack();
        })
        .catch((error) => {
          if (error.status === 409) {
            openErrorDialog(error.message, "Attenzione");
          } else {
            openErrorDialog("Attenzione, creazione del brand fallita.");
          }
        });
    }
  };

  const handleBackClick = () => {
    if (changesNotSaved) {
      const confirmLeave = window.confirm(
        "Hai delle modifiche non salvate. Sei sicuro di voler uscire?"
      );
      if (confirmLeave) {
        history.goBack();
      }
    } else {
      history.goBack();
    }
  };

  const closeTwoActionsAlert = () =>
    setTwoActionAlert({
      isVisible: false,
      title: "",
      message: "",
    });

  const confirmBrandCreation = () => {
    setLoading(true);
    closeTwoActionsAlert();
    const formData = new FormData();
    formData.append("nome", brandName);
    formData.append("path", templateData.path);
    formData.append("primaryColor", selectedColor.primary);
    formData.append("secondaryColor", selectedColor.secondary);
    formData.append("textColor", selectedColor.text);
    formData.append("primaryColorApp", selectedColor.appPrimary);
    formData.append("secondaryColorApp", selectedColor.appSecondary);
    formData.append("tertiaryColorApp", selectedColor.appTertiary);
    formData.append("logo", templateData.logo);
    formData.append("loghi", []);
    if (typeof templateData.logoLeft === "object")
      formData.append("logoLeft", templateData.logoLeft);
    if (templateData.logoApp.type === "image/svg+xml")
      formData.append("logoApp", templateData.logoApp);

    createBrand(formData)
      .then((_response) => {
        console.log("Il nuovo brand è stato registrato con successo.");
        setChangesNotSaved(false);

        history.replace("/amministrazione/brand");
      })
      .catch((error) => {
        if (error.status === 409) {
          openErrorDialog(error.message, "Attenzione");
        } else {
          openErrorDialog("Attenzione, creazione del brand fallita");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container maxWidth={false} className={classes.root}>
      {loading ? (
        <SpinnerComponent size={24} />
      ) : (
        <div>
          <Typography variant="h4" className={classes.text}>
            {`Dettagli del brand ${templateData.nome}`}
          </Typography>
          <Paper className={classes.tabsPaper}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              variant="fullWidth"
            >
              <Tab label="Dettagli web app" value={0} />
              <Tab label="Dettagli app" value={1} />
            </Tabs>
            <div hidden={tabValue !== 0}>
              {tabValue === 0 && (
                <DettagliWebApp
                  isEditMode={isEditMode}
                  brandName={brandName}
                  handleBrandNameChange={handleBrandNameChange}
                  templateData={templateData}
                  handlePathChange={handlePathChange}
                  loghiTemplate={loghiTemplate}
                  uploadImage={uploadImage}
                  logoLeftUrl={logoLeftUrl}
                  uploadLogoLeft={uploadLogoLeft}
                  selectedColor={selectedColor}
                  handleColorChange={handleColorChange}
                  handleBackClick={handleBackClick}
                  createOrUpdateBrand={createOrUpdateBrand}
                />
              )}
            </div>
            <div hidden={tabValue !== 1}>
              {tabValue === 1 && (
                <DettagliApp
                  logoAppUrl={logoAppUrl}
                  uploadLogoApp={uploadLogoApp}
                  templateData={templateData}
                  selectedColor={selectedColor}
                  handleColorChange={handleColorChange}
                  handleBackClick={handleBackClick}
                  createOrUpdateBrand={createOrUpdateBrand}
                />
              )}
            </div>
          </Paper>
        </div>
      )}
      <TwoActionsAlertDialog
        visible={twoActionAlert.isVisible}
        title={twoActionAlert.title}
        message={twoActionAlert.message}
        onIndietroClicked={closeTwoActionsAlert}
        onOkClicked={confirmBrandCreation}
      />
      <ErrorDialog
        open={errorDialog.isVisible}
        title={errorDialog.title}
        message={errorDialog.message}
        onCloseButtonClicked={closeErrorDialog}
      />
    </Container>
  );
}
