import React, { Component } from "react";
import {
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment-timezone";
import "moment/locale/it";
import ActionButton from "../../../components/ActionButton";
import TwoActionsAlertDialog from "../../../components/TwoActionsAlertDialog";
import PropTypes from "prop-types";

const styles = (theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    borderRadius: "6px",
    padding: "10px",
    boxShadow: `0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px
      ${theme.palette.primary.main}`,
    overflowY: "scroll",
    height: "85%",
    maxHeight: "620px",
  },
  title: {
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "270px",
  },
  formcontrol: {
    marginBottom: "30px",
    width: "270px",
    padding: "0px 4px",
  },
  sectionContainer: {
    border: "1px solid #e0e0e0",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
});

const dateFormat = "DD-MM-YYYY";
const dateTimeFormat = "DD-MM-YYYY HH:mm";

moment.locale("it"); // it is required to select default locale manually

class ModalRegistrazionePagamento extends Component {
  constructor(props) {
    super(props);
    this.isConsulenteAbbonamento = !props.abbonamento.esercizioCommercialeId;

    this.state = {
      record: {
        abbonamentoId: props.abbonamento.id,
        dataPagamento: null,
        transazioneId: null,
        nomeAcquirente: "",
        cognomeAcquirente: "",
        ...(this.isConsulenteAbbonamento ? { modalita: "" } : {}),

        dataInizioAbbonamento: props.abbonamento.dataInizio
          ? moment(props.abbonamento.dataInizio).format(dateFormat)
          : null,
        dataFineAbbonamento: props.abbonamento.dataFine
          ? moment(props.abbonamento.dataFine).format(dateFormat)
          : null,
      },
      validationErrors: {
        ...(this.isConsulenteAbbonamento ? { modalita: "" } : {}),
        dataInizioAbbonamento: "",
        dataFineAbbonamento: "",
      },
      saveDisabled: true,
      showConfirmModal: false,
    };
  }

  get disableDate() {
    const { stato } = this.props.abbonamento;
    return stato === "SCADUTO" || stato === "ATTIVO";
  }

  validateForm = () => {
    let data = this.state.record;
    Object.keys(data).forEach((key) => {
      this.validateField(key, data[key]);
    });
    let valid = true;
    let validationErrors = this.state.validationErrors;
    Object.values(validationErrors).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  validateField = (key, value) => {
    let record = this.state.record;
    const { validationErrors } = this.state;
    switch (key) {
      case "modalita":
        validationErrors[key] = value
          ? ""
          : "Selezionare una modalità di pagamento";

        break;
      case "dataInizioAbbonamento":
        if (!this.disableDate) {
          if (value === null) {
            validationErrors[key] =
              "Indicare la data di inizio validità dell'abbonamento";
            return;
          }
          const datafine = moment(record.dataFineAbbonamento, dateFormat);
          const currentDataInizio = moment(value, dateFormat);
          if (datafine !== null && currentDataInizio >= datafine) {
            validationErrors[key] =
              "La data di inizio non sono coerenti con gli anni di  può essere successiva alla data di fine dell'abbonamento";
          } else {
            validationErrors[key] = "";
          }
        }
        break;
      case "dataFineAbbonamento":
        if (!this.disableDate) {
          if (value === null) {
            validationErrors[key] =
              "Indicare la data di fine validità dell'abbonamento";
            return;
          }
          const datainizio = moment(record.dataInizioAbbonamento, dateFormat);
          const currentDataFine = moment(value, dateFormat);
          if (datainizio !== null && currentDataFine <= datainizio) {
            validationErrors[key] =
              "La data di fine abbonamento deve essere maggiore della data di inizio.";
          } else {
            const now = moment();
            const diff = currentDataFine.diff(now, "days");
            validationErrors[key] =
              diff < 60
                ? "Impostare la data di fine ad almeno 60 giorni dalla data attuale"
                : "";
          }
        }
        break;
      default:
        break;
    }
    this.setState({
      validationErrors,
    });
  };

  handleChange = (name, value) => {
    if (this.state.saveDisabled) {
      this.setState({
        saveDisabled: false,
      });
    }
    let validationErrors = this.state.validationErrors;
    let record = this.state.record;
    record[name] = value;
    //Reset validation :
    if (name === "dataInizioAbbonamento" || name === "dataFineAbbonamento") {
      validationErrors[name] = "";
    }
    this.setState({
      record,
      validationErrors,
    });
  };

  onTextChanged = (event) => {
    this.handleChange(event.target.name, event.target.value);
  };

  onDateChanged = (name, value) => {
    const { anniValidita } = this.props.ultimoStoricoPricing;
    let dateString = value.format(dateFormat);

    this.handleChange(name, dateString);

    if (name === "dataInizioAbbonamento") {
      const newDataFine = anniValidita
        ? moment(dateString, dateFormat)
            .add(anniValidita, "years")
            .format(dateFormat)
        : moment(dateString, dateFormat).add(1, "years").format(dateFormat);

      this.handleChange("dataFineAbbonamento", newDataFine);
    }
  };

  onConfirmDate = () => {
    this.setState({ showConfirmModal: false }, this.submitForm);
  };

  onModalClose = () =>
    this.setState({
      showConfirmModal: false,
    });

  onDateTimeChanged = (name, value) => {
    let date = value;
    this.handleChange(name, date);
  };

  discardChanges = () => {
    this.resetForm();
    this.props.onCancel();
  };

  onSubmit = (event) => {
    event.preventDefault();
    let isFormValid = this.validateForm();
    if (!isFormValid) return;

    const dataInizioAbbonamento = moment(
      this.state.record.dataInizioAbbonamento,
      dateFormat
    );
    const dataFineAbbonamento = moment(
      this.state.record.dataFineAbbonamento,
      dateFormat
    );

    const { anniValidita } = this.props.ultimoStoricoPricing;
    const anniDaAggiungere = anniValidita || 1;

    const dataLimite = dataInizioAbbonamento
      .clone()
      .add(anniDaAggiungere, "years");

    if (dataFineAbbonamento.isAfter(dataLimite)) {
      this.setState({ showConfirmModal: true });
      return;
    }

    this.submitForm();
  };

  submitForm = () => {
    const formattedData = {
      ...this.state.record,
      dataInizioAbbonamento: moment(
        this.state.record.dataInizioAbbonamento,
        dateFormat
      ).format("YYYY-MM-DD"),
      dataFineAbbonamento: moment(
        this.state.record.dataFineAbbonamento,
        dateFormat
      ).format("YYYY-MM-DD"),
      dataPagamento: this.state.record.dataPagamento
        ? moment
            .tz(this.state.record.dataPagamento, dateTimeFormat, "Europe/Rome")
            .utc()
            .format()
        : null,
    };

    this.resetForm();
    this.props.onSubmit(formattedData);
  };

  resetForm = () => {
    let record = {
      dataPagamento: null,
      transazioneId: null,
      nomeAcquirente: null,
      cognomeAcquirente: null,
      dataInizioAbbonamento: null,
      dataFineAbbonamento: null,
      ...(this.isConsulenteAbbonamento && { modalita: "" }),
    };
    let validationErrors = {
      ...(this.isConsulenteAbbonamento && { modalita: "" }),
      dataInizioAbbonamento: "",
      dataFineAbbonamento: "",
    };
    this.setState({
      record,
      validationErrors,
    });
  };

  render() {
    const { classes, open, ultimoStoricoPricing } = this.props;
    const { record, validationErrors, saveDisabled, showConfirmModal } =
      this.state;

    return (
      <Modal
        open={open}
        onClose={this.discardChanges}
        aria-labelledby="modal_registrazione_pagamento"
        aria-describedby="modal_registrazione_pagamento"
        className={classes.modal}
      >
        <div className={classes.modalContainer}>
          <Container maxWidth="md">
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={MomentUtils}
              locale={"it"}
            >
              <form autoComplete="off" onSubmit={this.onSubmit}>
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" className={classes.title}>
                      Registra pagamento
                    </Typography>
                  </Grid>
                  {!this.isConsulenteAbbonamento ? (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className={classes.sectionContainer}
                    >
                      <Grid container>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            style={{ paddingBottom: "10px" }}
                          >
                            <TextField
                              label="Importo abbonamento (Costo unitario base)"
                              name="costoUnitario"
                              disabled
                              value={
                                ultimoStoricoPricing?.costoUnitario?.toFixed(
                                  2
                                ) ?? ""
                              }
                              InputProps={{
                                readOnly: true,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    €
                                  </InputAdornment>
                                ),
                              }}
                              className={classes.textfield}
                            />
                            <TextField
                              label="Moltiplicatore (utenza/reparti/...altro)"
                              name="moltiplicatore"
                              disabled
                              value={ultimoStoricoPricing.moltiplicatore || ""}
                              InputLabelProps={{ shrink: true }}
                              className={classes.textfield}
                            />
                            <TextField
                              label="Validità abbonamento (anni)"
                              name="anniValidita"
                              disabled
                              value={ultimoStoricoPricing.anniValidita || ""}
                              InputLabelProps={{ shrink: true }}
                              className={classes.textfield}
                            />
                            <TextField
                              label="IVA"
                              name="valoreIva"
                              disabled
                              value={ultimoStoricoPricing.valoreIva || ""}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    €
                                  </InputAdornment>
                                ),
                              }}
                              className={classes.textfield}
                            />
                            <TextField
                              label="Importo Totale"
                              name="totaleLordo"
                              disabled
                              value={ultimoStoricoPricing.totaleLordo || ""}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    €
                                  </InputAdornment>
                                ),
                              }}
                              className={classes.textfield}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            style={{ paddingBottom: "10px" }}
                          >
                            <DateTimePicker
                              disableFuture
                              ampm={false}
                              value={
                                record.dataPagamento
                                  ? moment(record.dataPagamento, dateTimeFormat)
                                  : null
                              }
                              onChange={(date) => {
                                this.onDateTimeChanged("dataPagamento", date);
                              }}
                              format={dateTimeFormat}
                              label="Data pagamento"
                              invalidLabel="Data non sono coerenti con gli anni di  corretta"
                              className={classes.textfield}
                            />
                            <TextField
                              label="ID transazione"
                              name="transazioneId"
                              onChange={this.onTextChanged}
                              value={record.transazioneId || ""}
                              className={classes.textfield}
                            />
                            <TextField
                              label="Nome acquirente"
                              name="nomeAcquirente"
                              onChange={this.onTextChanged}
                              value={record.nomeAcquirente || ""}
                              className={classes.textfield}
                            />
                            <TextField
                              label="Cognome acquirente"
                              name="cognomeAcquirente"
                              onChange={this.onTextChanged}
                              value={record.cognomeAcquirente || ""}
                              className={classes.textfield}
                            />
                            <TextField
                              label="Modalità pagamento"
                              name="modalita"
                              disabled
                              value={ultimoStoricoPricing.modalita || ""}
                              InputLabelProps={{ shrink: true }}
                              className={classes.textfield}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className={classes.sectionContainer}
                    >
                      <Grid container>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            style={{ paddingBottom: "10px" }}
                          >
                            <DateTimePicker
                              disableFuture
                              ampm={false}
                              value={
                                record.dataPagamento
                                  ? moment(record.dataPagamento, dateTimeFormat)
                                  : null
                              }
                              onChange={(date) => {
                                this.handleChange("dataPagamento", date);
                              }}
                              format={dateTimeFormat}
                              label="Data pagamento"
                              invalidLabel="Data non sono coerenti con gli anni di  corretta"
                              className={classes.textfield}
                            />
                            <TextField
                              label="ID transazione"
                              name="transazioneId"
                              onChange={this.onTextChanged}
                              value={record.transazioneId || ""}
                              className={classes.textfield}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            style={{ paddingBottom: "10px" }}
                          >
                            <TextField
                              label="Nome acquirente"
                              name="nomeAcquirente"
                              onChange={this.onTextChanged}
                              value={record.nomeAcquirente || ""}
                              className={classes.textfield}
                            />
                            <TextField
                              label="Cognome acquirente"
                              name="cognomeAcquirente"
                              onChange={this.onTextChanged}
                              value={record.cognomeAcquirente || ""}
                              className={classes.textfield}
                            />
                            <FormControl style={{ width: "270px" }}>
                              <InputLabel>Modalità di pagamento</InputLabel>
                              <Select
                                name="modalita"
                                label="Modalità di pagamento"
                                value={record.modalita || ""}
                                onChange={this.onTextChanged}
                                error={validationErrors.modalita.length > 0}
                              >
                                <MenuItem value="" disabled>
                                  <em>Seleziona una modalità</em>
                                </MenuItem>
                                <MenuItem value="PAYPAL">PayPal</MenuItem>
                                <MenuItem value="SUM_UP">Sum Up</MenuItem>
                                <MenuItem value="ASSEGNO">Assegno</MenuItem>
                                <MenuItem value="BONIFICO">Bonifico</MenuItem>
                              </Select>
                              <FormHelperText style={{ color: "red" }}>
                                {validationErrors.modalita}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" className={classes.title}>
                      Attivazione abbonamento
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.sectionContainer}
                  >
                    <Grid container>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          style={{ paddingBottom: "10px" }}
                        >
                          <DatePicker
                            disabled={this.disableDate}
                            ampm={false}
                            value={
                              record.dataInizioAbbonamento
                                ? moment(
                                    record.dataInizioAbbonamento,
                                    dateFormat
                                  )
                                : null
                            }
                            onChange={(date) => {
                              this.onDateChanged("dataInizioAbbonamento", date);
                            }}
                            format={dateFormat}
                            label="Data inizio abbonamento"
                            invalidLabel="Data non sono coerenti con gli anni di  corretta"
                            className={classes.textfield}
                            helperText={validationErrors.dataInizioAbbonamento}
                            error={
                              validationErrors.dataInizioAbbonamento.length > 0
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          style={{ paddingBottom: "10px" }}
                        >
                          <DatePicker
                            disabled={this.disableDate}
                            minDate={
                              ultimoStoricoPricing.anniValidita
                                ? moment(
                                    record.dataInizioAbbonamento,
                                    dateFormat
                                  ).add(
                                    ultimoStoricoPricing.anniValidita,
                                    "years"
                                  )
                                : moment(
                                    record.dataInizioAbbonamento,
                                    dateFormat
                                  ).add(1, "years")
                            }
                            ampm={false}
                            value={
                              record.dataFineAbbonamento
                                ? moment(record.dataFineAbbonamento, dateFormat)
                                : null
                            }
                            onChange={(date) => {
                              this.onDateChanged("dataFineAbbonamento", date);
                            }}
                            format={dateFormat}
                            label="Data fine abbonamento"
                            invalidLabel="Data non sono coerenti con gli anni di  corretta"
                            className={classes.textfield}
                            helperText={validationErrors.dataFineAbbonamento}
                            error={
                              validationErrors.dataFineAbbonamento.length > 0
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ textAlign: "right" }}
                  >
                    <ActionButton
                      label="Annulla"
                      onClick={this.discardChanges}
                      disabled={false}
                      grayVersion={false}
                      secondaryButton
                    />
                    <ActionButton
                      label="Salva"
                      onClick={this.onSubmit}
                      disabled={saveDisabled}
                      grayVersion={false}
                    />
                  </Grid>
                  <TwoActionsAlertDialog
                    visible={showConfirmModal}
                    title="Attenzione!"
                    message="L'intervallo di tempo selezionato supera gli anni di validità previsti per l'abbonamento. Si desidera proseguire?"
                    onIndietroClicked={this.onModalClose}
                    onOkClicked={this.onConfirmDate}
                  />
                </Grid>
              </form>
            </MuiPickersUtilsProvider>
          </Container>
        </div>
      </Modal>
    );
  }
}

ModalRegistrazionePagamento.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  abbonamento: PropTypes.object.isRequired,
  ultimoStoricoPricing: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default withStyles(styles)(ModalRegistrazionePagamento);
