import { retrieveToken } from "../storage";
import { wrapFetch, ApiError } from "./api";

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getEserciziCommerciali = (
  gruppoId,
  consulenteId,
  responsabileSicurezzaId,
  page,
  size,
  sortby
) => {
  const token = retrieveToken();
  const PATH = "/api/esercizi_commerciali";
  let query = `?page=${page}&size=${size}&sort=${sortby}&${sortby}.dir=ASC`;
  if (gruppoId !== null && gruppoId !== -1) {
    // NB: -1 is a special value used for "all records" in dropdown menu.
    query = query + "&gruppoId.equals=" + gruppoId;
  }
  if (consulenteId !== null) {
    query = query + "&consulenteId.equals=" + consulenteId;
  }
  if (responsabileSicurezzaId != null) {
    query =
      query + "&responsabileSicurezzaId.equals=" + responsabileSicurezzaId;
  }

  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getEserciziCommercialiFiltered = (
  gruppoId,
  consulenteId,
  responsabileSicurezzaId,
  page,
  size,
  sortby,
  email,
  nome,
  esercizioId,
  ragioneSociale,
  partitaIva,
  telefono,
  cittaSede
) => {
  const token = retrieveToken();
  const PATH = "/api/esercizi_commerciali";
  let query = `?page=${page}&size=${size}&sort=${sortby}&${sortby}.dir=ASC`;

  if (gruppoId !== null && gruppoId !== -1) {
    // NB: -1 is a special value used for "all records" in dropdown menu.
    query += "&gruppoId.equals=" + gruppoId;
  }
  if (consulenteId !== null && consulenteId !== "") {
    query += "&consulenteId.equals=" + consulenteId;
  }
  if (responsabileSicurezzaId != null) {
    query += "&responsabileSicurezzaId.equals=" + responsabileSicurezzaId;
  }
  if (email !== null && email !== "") {
    query += `&email.contains=${encodeURIComponent(email)}`;
  }
  if (nome !== null && nome !== "") {
    query += `&nome.contains=${encodeURIComponent(nome)}`;
  }
  if (esercizioId) {
    query += `&id.equals=${esercizioId}`;
  }
  if (ragioneSociale) {
    query += `&ragioneSociale.contains=${encodeURIComponent(ragioneSociale)}`;
  }
  if (partitaIva) {
    query += `&partitaIva.contains=${encodeURIComponent(partitaIva)}`;
  }
  if (telefono) {
    query += `&telefono.contains=${encodeURIComponent(telefono)}`;
  }
  if (cittaSede) {
    query += `&cittaSede.contains=${encodeURIComponent(cittaSede)}`;
  }
  const URL = BASE_URL + PATH + query;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getEsercizioCommerciale = (esercizioId) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/esercizi_commerciali_giorni/" + esercizioId;
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 404) {
      message =
        "Errore durante il caricamento dei dati: esercizio commerciale non trovato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getCountEserciziCommerciali = (
  gruppoId,
  consulenteId,
  esercizioId,
  ragioneSociale,
  partitaIva,
  telefono,
  cittaSede
) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/esercizi_commerciali/count";
  let URL = BASE_URL + PATH;
  if ((gruppoId !== null && gruppoId !== -1) || consulenteId !== null) {
    let query = "?";
    if (gruppoId !== null && gruppoId !== -1) {
      query += "gruppoId.equals=" + gruppoId;
    }
    if (consulenteId !== null) {
      query += "&consulenteId.equals=" + consulenteId;
    }
    if (esercizioId) {
      query += `&esercizioId.equals=${esercizioId}`;
    }
    if (ragioneSociale) {
      query += `&ragioneSociale.contains=${encodeURIComponent(ragioneSociale)}`;
    }
    if (partitaIva) {
      query += `&partitaIva.contains=${encodeURIComponent(partitaIva)}`;
    }
    if (telefono) {
      query += `&telefono.contains=${encodeURIComponent(telefono)}`;
    }
    if (cittaSede) {
      query += `&cittaSede.contains=${encodeURIComponent(cittaSede)}`;
    }
    URL += query;
  }
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const registerEsercizioAsAdmin = (requestBody) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/esercizi_commerciali_giorni";
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      // 404 if the gruppoId does not correspond to a registered Gruppo
      message = "Registrazione non riuscita: dati non corretti.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};

export const registerEsercizioWithResponsabile = (requestBody) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/esercizi_commerciali_giorni_consulente";
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      // 404 if the gruppoId does not correspond to a registered Gruppo
      message = "Registrazione non riuscita: dati non corretti.";
    } else if (status === 409) {
      message =
        "Registrazione non riuscita: esiste già un utente registrato con lo stesso indirizzo email indicato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};

export const updateEsercizioCommerciale = (requestBody) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/esercizi_commerciali_giorni";
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Errore salvataggio: i dati non sono corretti.";
    } else if (status === 404) {
      message = "Errore salvataggio: esercizio commerciale non trovato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};

export const sendEmailToConfirmRegistration = (esercizioCommercialeId) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/esercizi_commerciali/invio_mail/" + esercizioCommercialeId;
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Registrazione esercizio già confermata.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const sendEmailToAlertEsercizioAndConsulente = (
  esercizioCommercialeId
) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH =
    "/api/esercizi_commerciali/invio_mail_notifica_rinnovo/" +
    esercizioCommercialeId;
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = "Errore generico";
    if (status === 400) {
      message = "Attenzione, richiesta non valida";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getFunzionalitaApp = (esercizioCommercialeId) => {
  const token = retrieveToken();
  const authtoken = `Bearer ${token}`;
  const PATH = `/api/funzionalitaApp/${esercizioCommercialeId}`;
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const updateFunzionalitaApp = (requestBody) => {
  const token = retrieveToken();
  const authtoken = `Bearer ${token}`;
  const PATH = "/api/funzionalitaApp";
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Errore salvataggio: i dati non sono corretti.";
    } else if (status === 404) {
      message = "Errore salvataggio: esercizio commerciale non trovato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};
