import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import SpinnerComponent from "../components/SpinnerComponent";
import ErrorDialog from "../components/ErrorDialog";
import FormAccettazioneEsercizio from "../components/FormAccettazioneEsercizio";

const styles = (theme) => ({
  mainContainer: {
    textAlign: "center",
    color: "black",
    padding: "26px",
  },
});

class AccettaEsercizioCommerciale extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {
        id: null,
        consulenteId: null,
        responsabileSicurezzaId: null,
        nome: null,
        ragioneSociale: null,
        partitaIva: null,
        codiceAteco: null,
        telefono: null,
        nomeGestore: null,
        cognomeGestore: null,
        emailGestore: null,
        telefonoGestore: null,
        indirizzoSede: null,
        numeroSede: null,
        cittaSede: null,
        provinciaSede: null,
        giornoChiusura: "",
        privacyPolicyAccettata: false,
        terminiUsoAccettati: false,
      },
      errorDialogVisible: false,
      errorDialogTitle: "",
      errorDialogMessage: "",
      loading: false,
      notfoundRecord: false,
      saveButtonEnabled: false,
      saveMessageVisible: false,
    };
  }

  componentDidMount() {
    this.fetchRecord(this.props.activationKey);
  }

  fetchRecord = (activationKey) => {
    this.setState({
      loading: true,
    });
    const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
    const url =
      ENDPOINT + "/api/esercizi_commerciali/attivazione/" + activationKey;
    fetch(url, {
      method: "GET",
    })
      .then((response) => {
        const status = response.status;
        if (status === 200) {
          return response.json();
        }
        if (status === 404) {
          throw new Error("" + status);
        } else {
          throw new Error(response.message);
        }
      })
      .then((result) => {
        if (result.length === 0) {
          this.setState({
            loading: false,
            notfoundRecord: true,
          });
        } else {
          if (result.giornoChiusura === null) {
            result.giornoChiusura = "";
          }
          this.setState({
            loading: false,
            record: result,
          });
        }
      })
      .catch((error) => {
        // Default: print the message of the exception.
        let title = "";
        let msg = error.message;
        if (msg === "404") {
          title = "Attenzione";
          msg = "Registrazione già confermata.";
        } else if (msg === "Failed to fetch") {
          msg =
            "Servizio temporaneamente non disponibile. Riprovare più tardi.";
        }
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogTitle: title,
          errorDialogMessage: msg,
          notfoundRecord: true,
        });
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
    const url =
      ENDPOINT +
      "/api/esercizi_commerciali/attivazione/" +
      this.props.activationKey;
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        let status = response.status;
        if (status === 200) {
          return response.json();
        }
        // Error cases:
        if (status === 401 || status === 404) {
          throw new Error("" + status);
        } else {
          throw new Error(response.message);
        }
      })
      .then((result) => {
        this.setState({
          loading: false,
          saveButtonEnabled: false,
          saveMessageVisible: true,
        });
      })
      .catch((error) => {
        // Default: print the message of the exception.
        let msg = error.message;
        if (msg === "404") {
          msg = "Codice di attivazione non trovato.";
        } else if (msg === "401") {
          msg = "Si è verificato un errore.";
        }
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: msg,
          notfoundRecord: true,
        });
      });
  };

  handleCheck = () => {
    this.setState((prevState) => {
      const updatedRecord = {
        ...prevState.record,
        privacyPolicyAccettata: !prevState.record.privacyPolicyAccettata,
        terminiUsoAccettati: !prevState.record.terminiUsoAccettati,
      };
      return { record: updatedRecord };
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.mainContainer}>
        <Typography variant="h4">
          Conferma registrazione esercizio commerciale
        </Typography>
        <Grid
          container
          justify="center"
          style={{ marginTop: "26px", marginBottom: "26px" }}
        >
          <div style={{ width: "700px", minHeight: "700px" }}>
            {this.state.loading ? (
              <SpinnerComponent size={32} />
            ) : (
              !this.state.notfoundRecord && (
                <div>
                  {this.state.saveMessageVisible ? (
                    <Typography
                      variant="h6"
                      style={{ paddingTop: "6px", paddingBottom: "6px" }}
                    >
                      Procedura completata, benvenuto in{" "}
                      {this.props.theme.nomeBrand}.
                    </Typography>
                  ) : (
                    <FormAccettazioneEsercizio
                      record={this.state.record}
                      handleSubmit={this.handleSubmit}
                      handleCheck={this.handleCheck}
                      saveMessageVisible={this.state.saveMessageVisible}
                    />
                  )}
                </div>
              )
            )}
          </div>
        </Grid>

        <ErrorDialog
          open={this.state.errorDialogVisible}
          title={this.state.errorDialogTitle}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
      </div>
    );
  }
}

AccettaEsercizioCommerciale.propTypes = {
  activationKey: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AccettaEsercizioCommerciale);
