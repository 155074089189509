import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    border: `1px solid ${theme.palette.disabled.main}`,
    borderRadius: "6px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  fieldsContainer: {
    marginTop: "10px",
    marginBottom: "10px",
    padding: "10px",
  },
  columnContainer: {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingBottom: "10px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "320px",
  },
}));

const FormPagamento = (props) => {
  const classes = useStyles();

  const handleChange = (event) => {
    const { name, value } = event.target;

    props.handleChangeAbbonamento(value, name);
  };

  return (
    <Grid container justify="center" alignItems="center">
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.sectionContainer}
      >
        <Grid container className={classes.fieldsContainer}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid
              container
              direction="column"
              alignItems="center"
              className={classes.columnContainer}
            >
              <TextField
                name="costoUnitario"
                label="Importo abbonamento (Costo unitario base)"
                className={classes.textfield}
                value={props.costoUnitario?.toFixed(2) ?? ""}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
              />
              <TextField
                name="moltiplicatore"
                label="Moltiplicatore (utenza/reparti/...altro)"
                className={classes.textfield}
                type="number"
                value={props.abbonamentoData.moltiplicatore || ""}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key !== "ArrowUp" && e.key !== "ArrowDown") {
                    e.preventDefault();
                  }
                }}
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                }}
              />
              <TextField
                name="anniValidita"
                label="Validità abbonamento (anni)"
                className={classes.textfield}
                type="number"
                value={props.abbonamentoData.anniValidita || ""}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key !== "ArrowUp" && e.key !== "ArrowDown") {
                    e.preventDefault();
                  }
                }}
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid
              container
              direction="column"
              alignItems="center"
              className={classes.columnContainer}
            >
              <TextField
                name="valoreIva"
                label="IVA"
                className={classes.textfield}
                value={(
                  ((props.costoUnitario *
                    props.abbonamentoData.moltiplicatore *
                    props.abbonamentoData.anniValidita) /
                    100) *
                  22
                ).toFixed(2)}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
              />
              <TextField
                name="imponibile"
                label="Imponibile"
                disabled
                className={classes.textfield}
                value={
                  props.abbonamentoData !== undefined ||
                  props.abbonamentoData !== null
                    ? (
                        props.costoUnitario *
                        props.abbonamentoData.moltiplicatore *
                        props.abbonamentoData.anniValidita
                      ).toFixed(2)
                    : 0
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
              />
              <TextField
                name="totaleLordo"
                label="Importo totale (calcolato)"
                disabled
                className={classes.textfield}
                value={
                  props.abbonamentoData !== undefined ||
                  props.abbonamentoData !== null
                    ? (
                        props.costoUnitario *
                          props.abbonamentoData.moltiplicatore *
                          props.abbonamentoData.anniValidita +
                        ((props.costoUnitario *
                          props.abbonamentoData.moltiplicatore *
                          props.abbonamentoData.anniValidita) /
                          100) *
                          22
                      ).toFixed(2)
                    : 0
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
              />
              <FormControl style={{ width: "320px" }}>
                <InputLabel>Modalità di pagamento</InputLabel>
                <Select
                  name="modalita"
                  label="Modalità di pagamento"
                  value={props.abbonamentoData.modalita || ""}
                  onChange={handleChange}
                >
                  <MenuItem value={""} disabled>
                    <em>Seleziona una modalità</em>
                  </MenuItem>
                  <MenuItem value={"PAYPAL"}>PayPal</MenuItem>
                  <MenuItem value={"SUM_UP"}>Sum Up</MenuItem>
                  <MenuItem value={"ASSEGNO"}>Assegno</MenuItem>
                  <MenuItem value={"BONIFICO"}>Bonifico</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

FormPagamento.propTypes = {
  handleChangeAbbonamento: PropTypes.func.isRequired,
  abbonamentoData: PropTypes.object.isRequired,
  costoUnitario: PropTypes.number,
};

export default FormPagamento;
