import { Component } from "react";
import React from "react";
import { Modal, Typography } from "@material-ui/core";
import ReactTable from "react-table";
import ActionButton from "../../../components/ActionButton";

const styles = {
  modalcontainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
    marginLeft: "20%",
    marginRight: "20%",
    //height: '85%'
    overflowY: "auto",
    height: "600px",
    display: "flex",
    width: "55%",
  },
  modalsectioncontainer: {
    flex: 1,
    display: "flex",
    //marginTop: 20,
    //marginBottom: 20,
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
};

export default class ModalListaAttrezzatura extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: this.props.attrezzatureToRead,
      columns: [],
    };
  }

  componentDidMount = () => {
    //console.log(this.props.attrezzatureToRead);
    let a = this.getColumnsForListaAttrezzatura();
    this.setState({
      columns: a,
    });
  };
  getColumnsForListaAttrezzatura = () => {
    return [
      {
        Header: "Nome Attrezzatura",
        id: "attrezzatura",
        accessor: (d) => d,
      },
    ];
  };

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.onClose}>
        <div style={styles.modalcontainer}>
          <div style={styles.modalsectioncontainer}>
            <Typography
              style={{
                wordWrap: "break-word",
                align: "center",
                fontSize: 30,
                flex: 1,
              }}
            >
              Attrezzature associate al controllo conformità
            </Typography>
            <div style={{ flex: 1 }}>
              <ReactTable
                data={this.state.records}
                columns={this.state.columns}
                defaultPageSize={5}
                style={{ width: "35vw" }}
                previousText="Precedente"
                nextText="Successivo"
                noDataText="Nessun record"
                pageText="Pagina"
                ofText="di"
                rowsText="righe"
                pageJumpText="Vai a pagina"
                rowsSelectorText="righe per pagina"
              />
            </div>
            <div style={{ flex: 1, marginTop: "7vh" }}>
              <ActionButton
                label="Chiudi"
                secondaryButton
                onClick={() => this.props.onClose()}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
