import React from 'react';
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import PropTypes from 'prop-types';

import theme from '../theme.js';
import { useTheme } from '@material-ui/core';

export default function IndietroButtonColorFul(props) {
    let themeToUse = useTheme();
    let history = useHistory();
    return (
        <Button
            onClick={() => {
                if (props.alert === true) {
                    var r = window.confirm("Modifiche non salvate, andare indietro?");
                    if (r === true) {
                        history.goBack();
                    }
                }
                else { history.goBack(); }
            }}
            variant="contained"
            className="float-right"
            size="medium"
            disabled={props.isDisabled}
            style={{
                color: props.isDisabled ? theme.palette.text.disabled : themeToUse.palette.text.main, 
                margin: '10px',
                backgroundColor: props.isDisabled ? theme.palette.disabled.main : props.secondaryButton ? themeToUse.palette.secondary.main : themeToUse.palette.primary.main
            }}
        >
            { props.label === null || props.label === undefined ? "Indietro" : props.label }
        </Button>
    );

}

IndietroButtonColorFul.propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    alert: PropTypes.bool,
    label: PropTypes.string
}