import { retrieveToken } from '../storage';
import { wrapFetch, ApiError } from './api';
const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const logIn = (username, password) => {
  const PATH = '/api/login/backoffice';
  const URL = BASE_URL + PATH;
  const requestBody = {
    username: username,
    password: password
  }
  const errorFunction = (status) => {
    let message = null;
    if (status === 401 || status === 403 || status === 404) {
      message = 'Username e/o password errata.';
    } else if (status === 400) {
      message = 'Richiesta non valida.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    }), errorFunction
  );
}

export const getAccountData = () => {
  const token = retrieveToken();
  const PATH = '/api/account';
  const URL = BASE_URL + PATH;
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 401 || status === 403 || status === 404) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
        headers: {
          'Authorization' : authtoken
        },
        withCredentials: true,
        credentials: 'include'
    }), errorFunction
  );
}

export const resetPassword = (userEmail, psw) => {
  const token = retrieveToken();
  const PATH = '/api/account/reset_password';
  const URL = BASE_URL + PATH;
  const authtoken = 'Bearer '.concat(token);
  const requestBody = {
    email: userEmail,
    password: psw
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
        message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400) {
        message = 'Registrazione del pagamento non riuscita: dati non corretti.';
    } else if (status === 404) {
        message = 'Nessun utente trovato con indirizzo email ' + userEmail;
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
    method: 'POST',
    headers: {
      'Authorization' : authtoken,
      'Content-Type': 'application/json'
    },
    withCredentials: true,
    credentials: 'include',
    body: JSON.stringify(requestBody)
    }), errorFunction
  );
}