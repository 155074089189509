import React, { useEffect, useState } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";

import ResetPswSuccessPage from "./scenes/resetpsw/ResetPswSuccessPage";
import AppMainComponent from "./AppMainComponent";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import {
  getBrandByPath,
  getLogoByPath,
  getLogoLeftByPath,
} from "./utils/api/brand_api";
import "./App.css";
import Footer from "./components/Footer";
import Favicon from "react-favicon";
import logo from "../src/logo.png";

export default function App() {
  const [theme, setTheme] = useState();
  const [loading, setLoading] = useState(false);
  const [is404, setIs404] = useState(false);

  useEffect(() => {
    setLoading(true);
    let url = window.location.pathname.split("/")[1];
    const currentPath = url === "" ? "bsfood" : url;

    getBrandByPath(currentPath)
      .then((response) => {
        Promise.all([
          getLogoByPath(currentPath),
          getLogoLeftByPath(currentPath),
        ])
          .then(([logoResponse, logoLeftResponse]) => {
            setTheme({
              primaryColor: response.primaryColor,
              secondaryColor: response.secondaryColor,
              textColor: response.textColor,
              logo: logoResponse,
              logoLeft: logoLeftResponse,
              nomeBrand: response.nome,
              path: response.path || currentPath,
            });

            document.title =
              url !== ""
                ? `${response.nome} Control Panel`
                : "BS-FOOD Control Panel";

            setLoading(false);
            setIs404(false);
          })
          .catch((error) => {
            console.error(error);
            setLoading(false);
          });
      })
      .catch((error) => {
        if (error.status === 404) {
          console.log(error);
          setIs404(true);
        } else {
          console.error(error);
        }

        setLoading(false);
      });
  }, []);

  const muiTheme = createMuiTheme({
    // ... definisci qui le personalizzazioni del tema usando i dati ottenuti
    palette: {
      primary: {
        main: theme?.primaryColor || "#e17414",
      },
      secondary: {
        main: theme?.secondaryColor || "#ff0000",
      },
      text: {
        main: theme?.textColor || "#ffffff",
      },

      // ... altre personalizzazioni del tema
      warning: {
        main: "#fef8a0",
      },
      disabled: {
        main: "#e0e0e0", //light gray
      },
    },
    logo: theme?.logo || "",
    logoLeft: theme?.logoLeft || "",
    nomeBrand: theme?.nomeBrand || "BS-FOOD",
    path: theme?.path || "bsfood",
    // ... altre opzioni del tema (magari immagini)
  });

  if (!theme) {
    // Puoi decidere come gestire il caso in cui i dati del tema non siano ancora disponibili
    //return null; // o un componente di caricamento, un messaggio, ecc.
  }

  return loading ? (
    <div className="loadingBrand">
      <div className="spinnerBrand"></div>
      <p>Caricamento...</p>
    </div>
  ) : (
    <MuiThemeProvider theme={muiTheme}>
      <Favicon url={muiTheme.logoLeft.url || logo} />
      <BrowserRouter>
        <div className="App">
          <Switch>
            <Route
              path="/reset_password_success"
              component={ResetPswSuccessPage}
            />
            <Route path="/" render={() => <AppMainComponent is404={is404} />} />
          </Switch>
          {!is404 && <Footer />}
        </div>
      </BrowserRouter>
    </MuiThemeProvider>
  );
}
