import React, { Component } from "react";
import { Modal, TextField, Typography } from "@material-ui/core";
import ActionButton from "../../../components/ActionButton";

const styles = {
  modalcontainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
    marginLeft: "30%",
    marginRight: "30%",
    //height: '85%'
    overflowY: "scroll",
    height: "200px",
    display: "flex",
  },
  miniModalContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
    marginLeft: "20%",
    marginRight: "20%",
    //height: '85%'
    overflowY: "scroll",
    height: "300px",
    display: "flex",
  },
  modalsectioncontainer: {
    flex: 1,
    display: "flex",
    //marginTop: 20,
    //marginBottom: 20,
    flexDirection: "column",
    justifyContent: "space-evely",
  },
  buttoncontainer: {
    justifyContent: "center",
    padding: "10px 0px",
  },
  modalTitle: {
    fontSize: 20,
    alignSelf: "center",
  },
};

export default class ModalChangeChapterName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chapter: this.props.chapter,
    };
  }
  render() {
    return (
      <Modal
        open={this.props.isChangeChapterNameModalOpen}
        onClose={this.props.closeModalToChangeChapterName}
      >
        <div style={styles.modalcontainer}>
          <div style={styles.modalsectioncontainer}>
            <Typography
              variant="h6"
              style={{ paddingTop: "8px", paddingBottom: "8px" }}
            >
              Modifica titolo capitolo
            </Typography>
            <TextField
              value={this.state.chapter.name}
              onInput={(e) => {
                let chapter = this.state.chapter;
                chapter.name = e.target.value;
                this.setState({
                  chapter: chapter,
                });
              }}
            ></TextField>
          </div>
          <div style={styles.buttoncontainer}>
            <ActionButton
              label="Indietro"
              onClick={() => {
                this.props.closeModalToChangeChapterName();
              }}
            ></ActionButton>
            <ActionButton
              label="Salva"
              onClick={() => {
                this.props.saveChapterName(this.state.chapter);
              }}
            ></ActionButton>
          </div>
        </div>
      </Modal>
    );
  }
}
