import React from "react";
import { Modal, TextField, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import FormGiornaliero from "../manualecomponents/dettaglio_procedura/FormGiornaliero";
import FormMensile from "../manualecomponents/dettaglio_procedura/FormMensile";
import FormSettimanale from "../manualecomponents/dettaglio_procedura/FormSettimanale";
import FormAnnuale from "../manualecomponents/dettaglio_procedura/FormAnnuale";
import ActionButton from "../../../components/ActionButton";
import FormAltraCadenza from "./FormAltraCadenza";
import PropTypes from "prop-types";

const styles = (theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    borderRadius: "6px",
    padding: "10px",
    boxShadow: theme.shadows[6],
    overflowY: "auto",
    width: "70%",
    maxWidth: "1200px",
    height: "620px",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  miniModalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    borderRadius: "6px",
    padding: "10px",
    boxShadow: theme.shadows[6],
    overflowY: "auto",
    width: "45%",
    maxWidth: "600px",
    height: "270px",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  modalSectionContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evely",
  },
});

class ModalSelezioneOrario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orariDisponibili: [
        "0:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
        "5:00",
        "6:00",
        "7:00",
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
      colonneOrario: [],
      altraCadenza: this.props.controlloConformita.altraCadenza,
      selectedOrario: null,
      cadenze: this.props.cadenze,
    };
  }
  componentDidMount = () => {};
  onChange = () => {};
  onAddCadenza = (cadenza) => {
    let arrayOfCadenze = [...this.state.cadenze];
    arrayOfCadenze.push(cadenza);
    arrayOfCadenze.map((item) => {
      item["controlloConformitaId"] = this.props.controlloConformita.id;
    });
    this.setState({
      cadenze: arrayOfCadenze,
    });
    //this.props.setCadenzeToControllo(arrayOfCadenze);
  };
  onEliminaCadenzaButtonClicked = (selectedOrario) => {
    let arrayOfCadenze = [...this.state.cadenze];
    arrayOfCadenze.splice(selectedOrario, 1);
    this.setState({
      cadenze: arrayOfCadenze,
    });
    //this.props.setCadenzeToControllo(arrayOfCadenze);
  };

  render() {
    const { classes } = this.props;

    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
        className={classes.modal}
      >
        {this.props.mode === "Normal" ? (
          <div className={classes.modalContainer}>
            <div className={classes.modalSectionContainer}>
              <Typography style={{ align: "center", fontSize: 30 }}>
                Orario del controllo
              </Typography>
              {this.props.cadenza === "GIORNALIERA" ? (
                <FormGiornaliero
                  theme={this.props.theme}
                  controlloConformita={this.props.controlloConformita}
                  onChange={this.onChange}
                  onAddCadenza={this.onAddCadenza}
                  cadenze={this.state.cadenze}
                  onEliminaCadenzaButtonClicked={
                    this.onEliminaCadenzaButtonClicked
                  }
                />
              ) : null}
              {this.props.cadenza === "SETTIMANALE" ? (
                <FormSettimanale
                  theme={this.props.theme}
                  controlloConformita={this.props.controlloConformita}
                  onChange={this.onChange}
                  onAddCadenza={this.onAddCadenza}
                  cadenze={this.state.cadenze}
                  onEliminaCadenzaButtonClicked={
                    this.onEliminaCadenzaButtonClicked
                  }
                />
              ) : null}
              {this.props.cadenza === "MENSILE" ? (
                <FormMensile
                  theme={this.props.theme}
                  controlloConformita={this.props.controlloConformita}
                  onChange={this.onChange}
                  onAddCadenza={this.onAddCadenza}
                  cadenze={this.state.cadenze}
                  onEliminaCadenzaButtonClicked={
                    this.onEliminaCadenzaButtonClicked
                  }
                />
              ) : null}
              {this.props.cadenza === "ANNUALE" ? (
                <FormAnnuale
                  theme={this.props.theme}
                  controlloConformita={this.props.controlloConformita}
                  onChange={this.onChange}
                  onAddCadenza={this.onAddCadenza}
                  cadenze={this.state.cadenze}
                  onEliminaCadenzaButtonClicked={
                    this.onEliminaCadenzaButtonClicked
                  }
                />
              ) : null}
              {this.props.cadenza !== "ANNUALE" &&
              this.props.cadenza !== "MENSILE" &&
              this.props.cadenza !== "SETTIMANALE" &&
              this.props.cadenza !== "GIORNALIERA" ? (
                <FormAltraCadenza
                  theme={this.props.theme}
                  altraCadenza={this.props.cadenza}
                  onEliminaCadenzaButtonClicked={
                    this.onEliminaCadenzaButtonClicked
                  }
                  controlloConformita={this.props.controlloConformita}
                  onChange={this.onChange}
                  onAddCadenza={this.onAddCadenza}
                  cadenze={this.state.cadenze}
                />
              ) : null}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <ActionButton
                  label="Chiudi"
                  secondaryButton
                  onClick={this.props.onClose}
                />
                <ActionButton
                  label="Salva"
                  onClick={() =>
                    this.props.setCadenzeToControllo(this.state.cadenze)
                  }
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.miniModalContainer}>
            <div className={classes.modalSectionContainer}>
              <Typography
                style={{ align: "center", fontSize: 30, marginBottom: 20 }}
              >
                Indicare altra cadenza
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                onChange={(event) => {
                  this.setState({ altraCadenza: event.target.value });
                }}
                value={this.state.altraCadenza}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ActionButton
                label="Chiudi"
                secondaryButton
                onClick={this.props.onClose}
              />
              <ActionButton
                label="Salva"
                onClick={() =>
                  this.props.addCadenza(
                    this.state.altraCadenza,
                    this.props.indexToUpdate
                  )
                }
              />
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

ModalSelezioneOrario.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  controlloConformita: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  cadenza: PropTypes.string.isRequired,
  setCadenzeToControllo: PropTypes.func.isRequired,
  addCadenza: PropTypes.func.isRequired,
  indexToUpdate: PropTypes.number.isRequired,
};

export default withStyles(styles)(ModalSelezioneOrario);
