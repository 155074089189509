import React from "react";
// React-table library requires to import css as well:
import ReactTable from "react-table";
import "react-table/react-table.css"; // eslint-disable-next-line
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import PropTypes from "prop-types";

import AquistaButton from "../../../components/AquistaButton";
import ModalDettagliAbbonamento from "../components/ModalDettagliAbbonamento";
import { getConsulenteId } from "../../../utils/storage";

import moment from "moment";
import "moment/locale/it";

const styles = {
  container: {
    padding: "0px 0px 20px 0px",
  },
};

export default class StoricoAbbonamentiConsulente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      abbonamento: {
        pagamento: {
          id: null,
          dataCreazione: null,
          dataAggiornamento: null,
          stato: null,
          importoLordo: null,
          importoNetto: null,
          tariffaServizio: null,
          iva: null,
          nomePayer: null,
          cognomePayer: null,
          emailPayer: null,
          modalita: null,
          transazioneId: null,
          dataPagamento: null,
        },
      },
    };
  }

  fetchAbbonamento = (row) => {
    this.setState({
      abbonamento: row,
      modal: true,
    });
  };

  closeModalPagamento = () => {
    this.setState({
      modal: false,
    });
  };

  render() {
    let isUserConsulente =
      getConsulenteId() !== null && getConsulenteId() !== "null";
    const dateFormat = "DD-MM-YYYY HH:mm";
    const columns = [
      {
        Header: "Stato",
        id: "stato",
        accessor: (d) => d.stato,
        minWidth: 180,
      },
      {
        Header: "Data inizio",
        id: "dataInizio",
        sortable: false,
        filterable: false,
        accessor: (d) =>
          d.dataInizio ? moment(d.dataInizio).format(dateFormat) : "-",
        minWidth: 180,
      },
      {
        Header: "Data fine",
        id: "dataFine",
        sortable: false,
        filterable: false,
        accessor: (d) =>
          d.dataFine ? moment(d.dataFine).format(dateFormat) : "-",
        minWidth: 180,
      },
      {
        Header: "Trial in corso",
        id: "trial",
        accessor: (d) => (d.trial ? "si" : "no"),
        minWidth: 180,
      },
      {
        Header: "Scadenza Trial",
        id: "scadenzaTrial",
        sortable: false,
        filterable: false,
        accessor: (d) =>
          d.scadenzaTrial ? moment(d.scadenzaTrial).format(dateFormat) : "-",
        width: 180,
      },
      {
        Header: "Acquisto completato",
        id: "acquistato",
        sortable: false,
        filterable: false,
        accessor: (d) =>
          d.pagamento !== null
            ? d.pagamento.stato === "COMPLETATO"
              ? "Sì"
              : "No"
            : "No",
        width: 180,
      },
      {
        Header: "Pagamento",
        id: "pagamento",
        sortable: false,
        filterable: false,
        width: 180,
        accessor: (row) => {
          if (row.pagamento === null) {
            return <AquistaButton disabled={this.props.tipologiaPagamento === "PARTNER"} isEdited={false} abbonamentoId={row.id} />;
          } else {
            if (
              row.pagamento.stato === "APPROVATO" ||
              row.pagamento.stato === "PENDENTE" ||
              row.pagamento.stato === "COMPLETATO"
            ) {
              return (
                <Button
                  type="button"
                  onClick={() => this.fetchAbbonamento(row)}
                  variant="contained"
                  size="medium"
                  style={{
                    color: this.props.theme.palette.text.main,
                    backgroundColor: this.props.theme.palette.primary.main,
                  }}
                >
                  DETTAGLI
                </Button>
              );
            } else {
              return <AquistaButton disabled={this.props.tipologiaPagamento === "PARTNER"} isEdited={false} abbonamentoId={row.id} />;
            }
          }
        },
      },
    ];
    return (
      <Container style={styles.container}>
        <ModalDettagliAbbonamento
          abbonamento={this.state.abbonamento}
          handleClose={this.closeModalPagamento}
          modal={this.state.modal}
          isConsulente={isUserConsulente}
        />
        <Typography
          variant="h6"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          Storico degli abbonamenti al servizio
        </Typography>
        <ReactTable
          filterable={true}
          resizable={true}
          showPageSizeOptions={true}
          showPageJump={true}
          defaultPageSize={10}
          //pages={this.state.pages}
          data={this.props.abbonamenti}
          columns={columns}
          defaultFilterMethod={(filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined
              ? String(row[id])
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              : true;
          }}
          //manual // informs React Table that you'll be handling sorting and pagination server-side
          //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
          previousText="Precedente"
          nextText="Successivo"
          noDataText="Nessun record"
          pageText="Pagina"
          ofText="di"
          rowsText="righe"
          pageJumpText="Vai a pagina"
          rowsSelectorText="righe per pagina"
        />
      </Container>
    );
  }
}

StoricoAbbonamentiConsulente.propTypes = {
  abbonamenti: PropTypes.array.isRequired,
};
