import React, { Component } from "react";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import ErrorDialog from "../../../components/ErrorDialog";
import ActionButton from "../../../components/ActionButton";
import SpinnerComponent from "../../../components/SpinnerComponent";
import ModalDettagliAbbonamento from "../components/ModalDettagliAbbonamento";
import BasicTable from "../../../components/BasicTable.js";
import ModalRegistrazionePagamento from "../components/ModalRegistrazionePagamento";
import SelezioneGruppo from "../components/SelezioneGruppo";

import { clearToken } from "../../../utils/storage.js";
import {
  getAbbonamenti,
  getAbbonamentiFiltered,
  getCountAbbonamenti,
  getCountAbbonamentiFiltered,
  registerPagamento,
} from "../../../utils/api/abbonamenti_api";
import { getUltimoStoricoPricing } from "../../../utils/api/storicoPricing.js";
import { getGruppi } from "../../../utils/api/gruppi_api";

import moment from "moment";
import "moment/locale/it";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
  },
};

const dateFormat = "DD-MM-YYYY";
const dateTimeFormat = "DD-MM-YYYY HH:mm";

export default class AbbonamentiTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      abbonamenti: [],
      gruppoId: null,
      gruppi: [],
      loading: false,
      errorDialogVisible: false,
      errorDialogMessage: "",
      showModalDettagli: false,
      showModalRegistrazionePagamento: false,
      totalPages: 10,
      loadedPage: 0,
      pageSize: 10,
      abbonamento: {
        pagamento: {
          id: null,
          dataCreazione: null,
          dataAggiornamento: null,
          stato: null,
          totaleLordo: null,
          totaleNetto: null,
          tassa: null,
          nomePayer: null,
          cognomePayer: null,
          emailPayer: null,
        },
      },
      filters: {
        idAbbonamento: "",
        stato: "",
        idConsulente: "",
        idEsercizio: "",
        ragioneSociale: "",
      },
      ultimoStoricoPricing: {},
    };
  }

  componentDidMount() {
    this.fetchGruppi();
  }

  handleInvalidToken = () => {
    this.handleError(
      true,
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi."
    );
    clearToken();
    const { path } = this.props.theme;
    window.setTimeout(function () {
      window.location.href = `/${path}`;
    }, 4000);
  };

  fetchGruppi = () => {
    this.setState({
      loading: true,
    });
    getGruppi(null, null, 0, 1000, "id")
      .then((result) => {
        let newarray = [{ id: -1, nome: "Tutti i gruppi" }];
        newarray.push(...result);
        let gruppoId = this.state.gruppoId;
        if (gruppoId === null) {
          gruppoId = -1;
        }
        this.setState({
          gruppi: newarray,
          gruppoId,
        });
        this.fetchAbbonamenti(this.state.gruppoId);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };

  fetchAbbonamenti = (gruppoId) => {
    getCountAbbonamenti(gruppoId, null, null)
      .then((countResult) => {
        getAbbonamenti(gruppoId, null, null, 0, 10, "id")
          .then((result) => {
            this.setState({
              abbonamenti: result,
              loading: false,
              filters: {
                idAbbonamento: "",
                stato: "",
                idConsulente: "",
                idEsercizio: "",
                ragioneSociale: "",
              },
              totalPages: Math.ceil(countResult / 10),
              loadedPage: 0,
            });
          })
          .catch((error) => {
            if (error.status === 403) {
              this.handleInvalidToken();
            } else {
              this.setState({
                loading: false,
              });
              this.handleError(true, error.message);
            }
          });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };

  onPageChange = (pageIndex) => {
    this.setState(
      {
        loadedPage: pageIndex,
      },
      () =>
        this.fetchAbbonamentiForPageChange(
          this.state.gruppoId,
          this.state.loadedPage
        )
    );
  };

  fetchAbbonamentiForPageChange = (gruppoId, loadedPage) => {
    const { filters } = this.state;
    getAbbonamentiFiltered(
      gruppoId,
      filters.idConsulente,
      filters.idEsercizio,
      loadedPage,
      10,
      "id",
      filters.idAbbonamento,
      filters.ragioneSociale,
      filters.stato
    )
      .then((result) => {
        this.setState({
          abbonamenti: result,
          loading: false,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };

  fetchAbbonamentiForResearch = (gruppoId) => {
    const { filters } = this.state;
    getCountAbbonamentiFiltered(
      gruppoId,
      filters.idConsulente,
      filters.idEsercizio,
      filters.idAbbonamento,
      filters.ragioneSociale,
      filters.stato
    )
      .then((countResult) => {
        getAbbonamentiFiltered(
          gruppoId,
          filters.idConsulente,
          filters.idEsercizio,
          this.state.loadedPage,
          10,
          "id",
          filters.idAbbonamento,
          filters.ragioneSociale,
          filters.stato
        )
          .then((result) => {
            this.setState({
              abbonamenti: result,
              loading: false,
              totalPages:
                Math.ceil(countResult / 10) > 0
                  ? Math.ceil(countResult / 10)
                  : 1,
            });
          })
          .catch((error) => {
            if (error.status === 403) {
              this.handleInvalidToken();
            } else {
              this.setState({
                loading: false,
              });
              this.handleError(true, error.message);
            }
          });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };

  generateFilterColumn = ({ id, placeholder, filterValue, onChange }) => {
    return {
      Header: placeholder,
      id: id,
      accessor: (d) => (d[id] === null ? "-" : d[id]),
      filterable: true,
      minWidth: 120,
      Filter: (
        <TextField
          variant="outlined"
          fullWidth
          value={filterValue}
          onChange={(e) => {
            const value = e.target.value;
            onChange(value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => {
                    this.setState(
                      {
                        loadedPage: 0,
                      },
                      () => {
                        this.fetchAbbonamentiForResearch(this.state.gruppoId);
                      }
                    );
                  }}
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ),
    };
  };

  fetchUltimoStoricoPrincing = (esercizioId) => {
    return getUltimoStoricoPricing(esercizioId)
      .then((response) =>
        this.setState({
          ultimoStoricoPricing: response,
        })
      )
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        }
        this.handleError(true, error.message);
      });
  };

  registerPagamentoAbbonamento = (pagamento) => {
    this.setState({
      showModalRegistrazionePagamento: false,
      loading: true,
    });
    registerPagamento(pagamento)
      .then((result) => {
        this.fetchAbbonamenti(this.state.gruppoId);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        }
        this.setState({
          loading: false,
        });
        this.handleError(true, error.message);
      });
  };

  fetchAbbonamento = (row) => {
    this.setState({
      abbonamento: row,
      showModalDettagli: true,
    });
  };

  handleClose = () => {
    this.setState({
      showModalDettagli: false,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };

  openModalRegistrazionePagamento = (abbonamento) => {
    this.setState({
      abbonamento,
    });

    if (abbonamento.esercizioCommercialeId) {
      this.fetchUltimoStoricoPrincing(abbonamento.esercizioCommercialeId)
        .then(() => {
          // Only show the modal after successful fetch
          this.setState({ showModalRegistrazionePagamento: true });
        })
        .catch((error) => {
          this.handleError(true, `Error fetching pricing: ${error.message}`);
        });
    } else {
      this.setState({ showModalRegistrazionePagamento: true });
    }
  };

  closeModalRegistrazionePagamento = () => {
    this.setState({
      showModalRegistrazionePagamento: false,
      ultimoStoricoPricing: {},
    });
  };

  handleSelectedGruppo = (gruppoId) => {
    this.setState({
      gruppoId,
      loading: true,
    });
    this.fetchAbbonamenti(gruppoId);
  };

  render() {
    const columns = [
      {
        Header: "Numero",
        id: "numero",
        accessor: (row) => row,
        width: 120,
        filterable: false,
        Cell: (props) =>
          this.state.loadedPage === 0
            ? "" + props.index
            : this.state.loadedPage + "" + props.index,
      },
      this.generateFilterColumn({
        id: "id",
        placeholder: "ID abbonamento",
        filterValue: this.state.filters.idAbbonamento,
        onChange: (value) => {
          if (/^\d*$/.test(value)) {
            this.setState((prevState) => ({
              filters: {
                ...prevState.filters,
                idAbbonamento: value,
              },
            }));
          }
        },
      }),
      {
        Header: "Stato",
        id: "stato",
        accessor: (row) => row.stato,
        width: 120,
        filterable: true,
        Filter: (props) => (
          <Select
            displayEmpty
            defaultValue={""}
            value={this.state.filters.stato}
            onChange={(e) => {
              this.setState(
                (prevState) => ({
                  filters: {
                    ...prevState.filters,
                    stato: e.target.value,
                  },
                  loadedPage: 0,
                }),
                () => {
                  this.fetchAbbonamentiForResearch(this.state.gruppoId);
                }
              );
            }}
          >
            <MenuItem value="">TUTTI</MenuItem>
            <MenuItem value="SOSPESO">SOSPESO</MenuItem>
            <MenuItem value="ATTIVO">ATTIVO</MenuItem>
            <MenuItem value="SCADUTO">SCADUTO</MenuItem>
          </Select>
        ),
      },
      {
        Header: "Trial",
        id: "trial",
        accessor: (d) => (d.trial ? "Sì" : "No"),
        filterable: false,
        minWidth: 60,
      },
      {
        Header: "Data inizio",
        id: "dataInizio",
        filterable: false,
        accessor: (d) =>
          d.dataInizio
            ? moment(d.dataInizio).locale("it").format(dateFormat)
            : "-",
        minWidth: 160,
      },
      {
        Header: "Data fine",
        id: "dataFine",
        filterable: false,
        accessor: (d) =>
          d.dataFine ? moment(d.dataFine).locale("it").format(dateFormat) : "-",
        minWidth: 160,
      },
      this.generateFilterColumn({
        id: "consulenteId",
        placeholder: "ID consulente",
        filterValue: this.state.filters.idConsulente,
        onChange: (value) => {
          if (/^\d*$/.test(value)) {
            this.setState((prevState) => ({
              filters: {
                ...prevState.filters,
                idConsulente: value,
              },
            }));
          }
        },
      }),
      this.generateFilterColumn({
        id: "esercizioCommercialeId",
        placeholder: "ID esercizio",
        filterValue: this.state.filters.idEsercizio,
        onChange: (value) => {
          if (/^\d*$/.test(value)) {
            this.setState((prevState) => ({
              filters: {
                ...prevState.filters,
                idEsercizio: value,
              },
            }));
          }
        },
      }),
      this.generateFilterColumn({
        id: "ragioneSocialeEsercizio",
        placeholder: "Ragione sociale",
        filterValue: this.state.filters.ragioneSociale,
        onChange: (value) => {
          this.setState((prevState) => ({
            filters: {
              ...prevState.filters,
              ragioneSociale: value,
            },
          }));
        },
      }),
      {
        Header: "Data pagamento",
        id: "dataAggiornamento",
        filterable: false,
        accessor: (d) =>
          d.pagamento && d.pagamento.stato !== "CREATO"
            ? d.pagamento.dataAggiornamento
              ? moment(d.pagamento.dataAggiornamento)
                  .locale("it")
                  .format(dateTimeFormat)
              : "-"
            : "-",
        width: 160,
      },
      {
        Header: "Pagamento",
        id: "pagamento",
        sortable: false,
        filterable: false,
        width: 180,
        accessor: (row) => {
          if (row.pagamento === null) {
            return (
              <ActionButton
                label="Registra"
                onClick={() => {
                  this.openModalRegistrazionePagamento(row);
                }}
                disabled={false}
                grayVersion={false}
              />
            );
          } else {
            if (
              row.pagamento.stato === "INSERITO" ||
              row.pagamento.stato === "CREATO" ||
              row.pagamento.stato === "APPROVATO" ||
              row.pagamento.stato === "PENDENTE" ||
              row.pagamento.stato === "COMPLETATO"
            ) {
              return (
                <ActionButton
                  label="DETTAGLI"
                  onClick={() => {
                    this.fetchAbbonamento(row);
                  }}
                  disabled={false}
                  grayVersion={false}
                />
              );
            } else {
              return (
                <ActionButton
                  size="small"
                  label="Registra"
                  onClick={() => this.openModalRegistrazionePagamento(row)}
                  disabled={false}
                  grayVersion={false}
                />
              );
            }
          }
        },
      },
    ];

    return (
      <div style={{ paddingTop: "30px" }}>
        {this.state.loading ? (
          <SpinnerComponent size={24} />
        ) : (
          <div style={styles.mainContainer}>
            <SelezioneGruppo
              gruppoId={this.state.gruppoId}
              gruppi={this.state.gruppi}
              disabled={false}
              description="Selezionare il gruppo su cui filtrare i risultati:"
              onGruppoSelected={this.handleSelectedGruppo}
            />
            <BasicTable
              filterable={false}
              resizable={true}
              sortable={false}
              defaultPageSize={this.state.pageSize}
              page={this.state.loadedPage}
              pages={this.state.totalPages}
              onPageChange={this.onPageChange}
              data={this.state.abbonamenti}
              columns={columns}
            />
          </div>
        )}
        {this.state.showModalDettagli && (
          <ModalDettagliAbbonamento
            abbonamento={this.state.abbonamento}
            handleClose={this.handleClose}
            modal={this.state.showModalDettagli}
            isConsulente={false}
          />
        )}
        {this.state.showModalRegistrazionePagamento && (
          <ModalRegistrazionePagamento
            open={this.state.showModalRegistrazionePagamento}
            abbonamento={this.state.abbonamento}
            ultimoStoricoPricing={this.state.ultimoStoricoPricing}
            onSubmit={this.registerPagamentoAbbonamento}
            onCancel={this.closeModalRegistrazionePagamento}
          />
        )}
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
      </div>
    );
  }
}
