import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { clearToken } from "../../utils/storage.js";

import { useTheme } from "@material-ui/core";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
    padding: "10px",
  },
};

export default function LogoutPage() {
  const themeToColor = useTheme();
  return (
    <div style={styles.mainContainer}>
      <Typography variant="h5" style={{ padding: "30px" }}>
        Effettua il log out dalla piattaforma.
      </Typography>
      <div style={{ textAlign: "center" }}>
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            clearToken();
            window.location.href = "/" + themeToColor.path;
          }}
          style={{
            color: themeToColor.palette.text.main,
            backgroundColor: themeToColor.palette.primary.main,
            margin: 10,
          }}
        >
          Logout
        </Button>
      </div>
    </div>
  );
}
